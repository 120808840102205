import axios from "axios";
import { errorResponseHandler, responseHandler } from "../utilities/response";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL + "/v1";


export const getMe = async () => {
  try {
    const data = await axios.get("/me");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deleteAccount = async () => {
  try {
    const data = await axios.delete("/recruiter");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const resetPassword = async () => {
  try {
    const data = await axios.get("/me/resetpassword");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};