// Styled components
import styled from "styled-components/macro";

export const ButtonContainer = styled.div`
display: flex;
height: 100%; 
${props => props.selected && `
button {
    color: #181F38 !important; 
    &:after {
        content: '';
        position: absolute;
        height: 4px; 
        width: 100%; 
        bottom: 0; 
        left: 0; 
        background-color: #FF385C; 
        animation: animate .5s ease; 
        cursor: pointer; 
    }
}
`}

@keyframes animate {
    0% {
        width: 0; 
    }
    100% {
        width: 100%; 
    }
}
button {
    appearance: none;
    background-color: transparent;
    border: none; 
    font-size: 14px; 
    padding: 0 10px;
    color: ${props => props.theme.colors.faceliftBlueLight};
    position: relative;
}
`
