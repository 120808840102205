import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getPositions } from "../../../../api/endpoints/position";
import Accordion from "../../Accordion";
import { ApplicationParamsContext } from "../ApplicationContextWrapper";
import ApplicationFilterSkillSlider from "./ApplicationFilterSkillSlider";

/**
 * Get all positions belonging to organization to filter by application list by positionId
 */
const ApplicationFilterComps = ({ type }) => {
  const { status, data } = useQuery("positions", getPositions);
  const { state, dispatch } = useContext(ApplicationParamsContext);
  const { t } = useTranslation();

  const handleSliderCheckboxClick = (e, type) => {
    const { name, checked } = e.target;
    const comps_copy = { ...JSON.parse(state.params[type]) }; //Make a copy of the state
    if (checked) {
      if (!comps_copy.hasOwnProperty(name)) comps_copy[name] = 1;
    } else {
      delete comps_copy[name];
    }
    dispatch({
      type: "update_params",
      payload: { [type]: JSON.stringify(comps_copy) },
    });
  };
  
  const handleSliderRangeChange = (e, type) => {
    let { name, value } = e.target;
    const comps_copy = { ...JSON.parse(state.params[type]) };
    comps_copy[name] = value;
    dispatch({
      type: "update_params",
      payload: { [type]: JSON.stringify(comps_copy) },
    });
  };

  const [list, setList] = useState([]);
  useEffect(() => {
    if (data?.data && type && state) {
      const filtered = state.params.positionId
        ? data.data.filter((pos) => {
            return (
              pos.id === parseInt(state.params.positionId) ||
              (state.params.positionId === "unsolicited" && pos.unsolicited)
            );
          })
        : data.data;

      setList(getSkillsAndLangs(filtered)[type]);
    }
  }, [type, data, state]);

  return (
    <>
      {status === "success" && (
        <>
          {list.length > 0 && (
            <Accordion
              title={
                type === "skills" ? t("common.skills") : t("common.languages")
              }
              // initiallyOpen
            >
              {list.map((singleComp) => (
                <ApplicationFilterSkillSlider
                  key={singleComp.name}
                  name={singleComp.name}
                  value={JSON.parse(state.params[type])[singleComp.name]}
                  on={JSON.parse(state.params[type]).hasOwnProperty(
                    singleComp.name
                  )} //is on if this skill.name if in state
                  type={type}
                  onCheckChange={handleSliderCheckboxClick}
                  onSliderChange={handleSliderRangeChange}
                />
              ))}
            </Accordion>
          )}
        </>
      )}
    </>
  );
};

export default ApplicationFilterComps;

/*
return true if array contains object with paramater name equal to argument string.
input : array, string
output : boolean
*/
const compContains = (arr, name) => {
  let contains = false;
  arr.forEach((i) => {
    if (i.name === name) {
      contains = true;
    }
  });
  return contains;
};

/**
 * Extract skills and languages objects from positions array
 * @param {array} positions
 * @returns {object} { skills : skills , languages : languages }
 */
const getSkillsAndLangs = (positions) => {
  const skills = [];
  const languages = [];
  if (positions && positions.length > 0) {
    positions.forEach((position) => {
      if (position.competencies) {
        position.competencies.forEach((comp) => {
          if (!compContains(skills, comp) && comp !== "") {
            skills.push({ name: comp, on: false, value: 1 });
          }
        });
      }
      if (position.languages) {
        position.languages.forEach((lang) => {
          if (!compContains(languages, lang) && lang !== "") {
            languages.push({ name: lang, on: false, value: 1 });
          }
        });
      }
    });
  }
  return { skills: skills, languages: languages };
};
