import styled from "styled-components/macro";
export const StyledMenuGroup = styled.div`
  li {
    margin: 5px 0px;
  }
  h3 {
    margin: 0;
    color: #fff;
  }
`;

export const StyledMenuGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 6px 0;
  /* background: red; */
  a {
    font-size: 18px;
    display: flex;
    div:first-child {
      width: 16px;
      margin-right: 8px;
    }
    svg * {
      fill: #fff;
    }
    &.active {
      svg * {
        fill: ${(props) => props.theme.colors.red};
      }
    }
  }
`;

export const StyledGroupContainer = styled.div`
  padding-left: 24px;
  display: none;
  &.open {
    display: block;
  }
  &.all_positions_active { 
    li:first-child a { 
      color: ${props=>props.theme.colors.red};
    }
  }
`;
