import React from 'react'
import BackToLink from './BackToLink'
import { StyledContextMenu } from './styled'
import { getGenericLocalStorage } from "../../../utils/localSave";

const ContextMenu = ({ children, backToUrl, backToLabel, goBack, useKeyLocation }) => {
    // useEffect(() => {
    // }, [useKeyLocation])
    
    let _backToUrl = backToUrl;
    if ( useKeyLocation ) {
        if ( getGenericLocalStorage('admin_settings')   ) {
            _backToUrl = getGenericLocalStorage('admin_settings', true).keyBackLocation;  
        }
    }
    return (
        <StyledContextMenu>
            { backToUrl || goBack ? <BackToLink to={_backToUrl || false } label={backToLabel || false} /> : ""}
            { children }
        </StyledContextMenu>
    )
}

export default ContextMenu
