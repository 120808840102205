import React, {useContext} from 'react'

import MetaEditorGroup from '../../../components/MetaEditor/MetaEditorGroup/MetaEditorGroup'
import StatusItem from '../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PublishMG/StatusItem'

import { formatDate } from '../../../../utils/various'
import { PageContext } from '../../../context/PageContext'

import { useTranslation } from 'react-i18next'
import PageMG from '../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PageMG'
import BrandingSettings from '../../../components/BrandingSettings/BrandingSettings'

const PageMetaEditor = ({site, brandingSettingsRef}) => {
    const { t } = useTranslation();
    const { state } = useContext(PageContext);

  return (
    <MetaEditorGroup name={t('common.status')} page>
    <div>
      {state.page.position && (
        <StatusItem
        page
          label={t(
            'common.applicationFormStatusLabel',
            'State'
          )}
          value={
            state.page.position.status === 'active'
              ? t('common.active')
              : t('common.inactive')
          }
        />
      )}
      <StatusItem
      page
        label={t(
          'common.publishedStatusLabel',
          'Status'
        )}
        value={
          state.page.status === 'publish'
            ? t('common.is_published', 'Published')
            : t('common.draft', 'Draft')
        }
        green={state.page.status === 'publish'}
      />

      <StatusItem
      page
        label={t(
          'common.dateCreated',
          'Date created'
        )}
        value={
          state.page.createdAt
            ? formatDate(state.page.createdAt)
            : 'N/A'
        }
        green={false}
      />
    </div>
    <PageMG page pt="14"/>
    <BrandingSettings page site={site} ref={brandingSettingsRef}/>
  </MetaEditorGroup>
  )
}

export default PageMetaEditor