/* test for ok subscription */
export const isSubscriptionStatusOk = (status) => {
  switch (status) {
    case 'active':
      return true;
    case 'trialing':
      return true;
    case 'incomplete':
      return true;
    case 'past_due':
      return true;
    case 'incomplete_expired':
      return false;
    case 'unpaid':
      return false;
    case 'canceled':
      return false;
    default:
      return false;
  }
};
