import React from "react";
import { useTranslation } from "react-i18next";
import { Stat } from "..";
import styled from "styled-components/macro";

const Stats = ({ stats, breakpoint }) => {
  const { t } = useTranslation();
  return (
    <StyledStats breakpoint={breakpoint}>
      <Stat
        icon="user"
        text={t("component.statistics.candidates_overall")}
        value={stats.applications}
      />
      <Stat
        icon="add"
        text={t("component.statistics.shortlisted_talents")}
        value={stats.shortlisted}
      />
      <Stat
        icon="suitcase"
        text={t("component.statistics.candidates_pr_position")}
        value={Math.round(stats.avgApplicants)}
      />
      <Stat
        icon="users"
        text={t("component.statistics.recruiter_colleagues")}
        value={stats.colleagues}
      />
    </StyledStats>
  );
};

const StyledStats = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints[props.breakpoint]}) {
    overflow: auto;
    display: flex;
    & > div {
        flex-basis: 280px;
        flex-shrink: 0;
        margin-right: 20px;
        margin-bottom: 20px;
        &:last-child { margin-right: 0; }
    }
  }
`;

export default Stats;
