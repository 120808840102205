import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InputFieldWithLabel } from "../../../../../../components";

import { PageContext } from "../../../../../context/PageContext";
import { LIST_TYPES } from "../blockConstants";
import CommonBlockSettings from "./CommonBlockSettings";

import Dropdown from "react-dropdown";
import { Typography } from "../../../../../../components/Typography";
import { InputGroup } from "../../../../../../components/Input/Input";

export const ListContent = ({ block }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);
  const listTypeOptions = LIST_TYPES.map((p) => ({
    value: p.value,
    label: p.label,
  }));
  return (
    <>
      <InputFieldWithLabel
        tiny
        mbLG="-2"
        borderBottom
        block
        label={t("component.block.section_header_label", "Section header")}
        byline={t(
          "component.block.section_header_byline",
          "Choose a header for this section of text"
        )}
        onChange={(e) => {
          dispatch({
            type: "update_block",
            payload: { id: block.id, name: "title", value: e.target.value },
          });
        }}
        name="title"
        type="text"
        value={block.title || ""}
      />

      <InputGroup block noBorderBottom>
        <Typography tag="p" mb="4" mbXL="10" bold>
          {t("component.block.list_type_label")}
        </Typography>
        <Typography tag="p" lowOpacity mb="22" mbXL="10">
          {t("component.block.list_type_byline")}
        </Typography>
        <Dropdown
          options={listTypeOptions}
          onChange={(selected) => {
            dispatch({
              type: "update_block",
              payload: {
                id: block.id,
                name: "listType",
                value: selected.value,
              },
            });
          }}
          value={block.listType || ""}
          placeholder="Select list type"
        />
      </InputGroup>
    </>
  );
};

export const ListSettings = ({ block }) => {
  return (
    <>
      <CommonBlockSettings block={block} pt="0" />
    </>
  );
};
