// React
import React, { useContext, useState } from "react";

// Translations, utility functions and hooks
import { useTranslation } from "react-i18next";

// API endpoints
import { updatePage } from "../../../../../../../api/endpoints/cp";

// State management
import { DispatchErrorContext } from "../../../../../../../context/ErrorContext";
import { PageContext } from "../../../../../../context/PageContext";

// Components
import { ToggleSwitch } from "../../../../../Buttons/Buttons";

const PositionPublish = ({ context }) => {
  const { t } = useTranslation();

  const { state, dispatch } = useContext(PageContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const [isToggling, setIsToggling] = useState(false);

  // Function to update the page status optimistically
  const optimisticToggle = (publish) => {
    setIsToggling(true);

    const newStatus = publish ? "publish" : "draft";

    // Optimistic UI update: update the UI immediately
    dispatch({
      type: "page_loaded",
      payload: { ...state.page, status: newStatus },
    });

    onSaveHandler(publish, newStatus); // Proceed with the API call
  };

  // Function to update the page status
  const onSaveHandler = async (publish, newStatus) => {
    try {
      const request = {
        selector: state.page.id,
        body: { status: newStatus },
      };

      const response = await updatePage(request);

      if (!response.success) throw new Error(response.data.data);

      setIsToggling(false); // Done toggling
    } catch (err) {
      // Handle error and display error message
      errorDispatch({
        type: "set_error",
        payload: err.message,
      });

      // Revert the UI update since the server request failed
      dispatch({
        type: "page_loaded",
        payload: state.page,
      });

      setIsToggling(false); // Done toggling, whether successful or not
    }
  };

  return (
    <>
      <ToggleSwitch
        ml="20px"
        active={state.page.status === "publish"}
        text={
          state.page.status === "publish"
            ? t("common.is_published", "Published")
            : t("common.is_unpublished", "Unpublished")
        }
        onClick={() => {
          optimisticToggle(state.page.status !== "publish");
        }}
      />
    </>
  );
};

export default PositionPublish;
