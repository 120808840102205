import styled from "styled-components/macro";

export const StyledSelectLanguage = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;

  > div > div.Dropdown-menu {
    overflow: hidden !important;
  }
`;
