import styled from "styled-components/macro";
import checkmark from '../../../../components/Icon/checked.svg'; 

export const StyledCardBorder = styled.div`
border-bottom: 1px solid ${props => props.theme.colors.mgrey};
`

export const StyledGenericCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0 16px; 
  padding-left: calc(16px + 5px); 
  ${props => props.isActive ? `background-color: rgba(243, 243, 245);` : null};
  ${props => props.inactive ? 'opacity: 0.15;' : null};
  ${props => props.inactive ? 'padding-left: 32px;' : null};
  & > a,
  & > label {
    cursor: pointer;
    flex: 1;
  }
  a {
    color: ${(props) => props.theme.colors.lgrey};
  }
  //input / checkbox
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    padding-right: 0; 
  }
`;

export const StyledGenericCard = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${(props) => props.theme.colors.lgrey};
  background-color: ${(props) => props.theme.colors.lgreytwo};

  padding: 15px 20px;
  border-radius: 15px;

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    max-width: 40px;
  }
`;

export const StyledGenericCardInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  //first div title
  > div:first-child {
    padding-right: 30px;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    flex-basis: 150px;
    white-space: nowrap;
    & > div {
      margin-right: 15px;
    }
  }

  //second div columns of text
  > div:last-child {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-basis: 50%;
    > div {
      flex: 1 1 50%;
      margin-right: 12px;
    }
  }
`;

export const StyledGenericCardAction = styled.div`
  input {
    cursor: pointer;
    display: block;
    height: 10px;
    width: 10px; 
    border: 1px solid rgba(68, 74, 94, 0.6);
    margin: 26px 10px 10px 0;
    appearance: none;
    background-color: #fff; 
    margin: 0; 
    border-radius: 3px; 
    transition: .5s ease;
    position: relative; 
    appearance: none;    
    margin-top: 21px; 
    @media (max-width: 991px) {
      margin-top: 27px; 
    }
  }
  input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 200ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  display: flex;
  background-image: url(${checkmark}); 
  left: -1px;
  top: -1px; 
  position: absolute;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]:checked {
  transition: .5s ease;
}

`;

export const GenericCardHoverOptions = styled.div`
display: flex; 
opacity: 0; 
transition: .5s ease; 
align-items: center;
`

export const GenericCardHoverOptionsDivider = styled.div`
height: 12px; 
width: 1px; 
background-color: ${props => props.theme.colors.faceliftBlueLight}; 
`