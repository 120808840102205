import React, { useContext, useState, useEffect } from "react";

import { StateUserContext } from "../context/UserContext";
import { GlobalStyle } from "./style";
import { ThemeProvider } from "styled-components";
import { recruiterTheme } from "./theme";

import Layout from "./Layout";
import { Loader } from "../components/";
import { ErrorProvider } from "../context/ErrorContext";
import { MessageProvider } from "../context/MessageContext";

const RecruiterApp = () => {

  const { isLoading } = useContext(StateUserContext);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // Delay for splash to finish.
    const delay = 3170;

    // After the delay, switch to the Layout component
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);
  return (
    <ThemeProvider theme={recruiterTheme}>
      <ErrorProvider>
        <MessageProvider>
        {/* {showLoader ? <Loader /> : isLoading ? <Loader /> : <Layout />} */}
        <Loader fadeOut={!isLoading && !showLoader}/>
        {!showLoader && !isLoading && <Layout />}
          <GlobalStyle />
        </MessageProvider>
      </ErrorProvider>
    </ThemeProvider>
  );
};

export default RecruiterApp;

