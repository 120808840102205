import styled from "styled-components/macro";

export const StyledRadioButton = styled.label`
  display: flex;
  align-items: center;
justify-content: center;
width: 92px; 
height: 40px; 
font-size: 14px; 
border: 1px solid rgba(24, 31, 56, 0.15); 
border-radius: 10px; 
${props => props.mr ? `margin-right: ${props.mr}px;` : null}
${props => props.ml ? `margin-left: ${props.ml}px;` : null}
input[type="radio"] {
    opacity: 0;
    margin: 0;
    display: none; 
}
  cursor: pointer;
  &.active {
    border: 2px solid ${props => props.theme.colors.red}; 
  }
`;

export const StyledInput = styled.input`
  margin-right: 8px;
`;