import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
} from 'react';
import { getOrganization } from '../../api/endpoints/organization';

import { StateUserContext } from '../../context/UserContext';

export const StateOrganizationContext = createContext();
export const DispatchOrganizationContext = createContext();

const organizationReducer = (state, action) => {
  switch (action.type) {
    case 'init_organization': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'loaded_organization': {
      return {
        ...state,
        isLoading: false,
        organization: action.payload,
      };
    }
    case 'error_organization': {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case 'init_update_organization': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'finished_updating_organization': {
      return {
        ...state,
        isLoading: false,
        organization: { ...state.organization, ...action.payload },
      };
    }
    case 'init_users': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'loaded_users': {
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    }
    case 'position_access': {
      return {
        ...state,
        positionAccess: action.payload,
      };
    }
    case 'init_update_users': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'removed_users': {
      //payload is array of recruiterIds
      const updatedUsers = state.users.filter(
        (user) => action.payload.indexOf(user.id) === -1
      );
      return {
        ...state,
        isLoading: false,
        users: updatedUsers,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const OrganizationProvider = ({ children }) => {
  //only load org if authenticated
  const { isAuthenticated, reload } = useContext(StateUserContext);

  //load organization
  useEffect(() => {
    const getOrg = async () => {
      dispatch({ type: 'init_organization' });
      try {
        const org = await getOrganization();

        if (org.success) {
          //@ts-ignore
          dispatch({
            type: 'loaded_organization',
            payload: org.data,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated) {
      getOrg();
    }
  }, [isAuthenticated, reload]);

  const organizationInitialState = {
    organization: {},
    users: [],
    positionAccess: [], 
    isLoading: false,
    isError: false,
  };

  const [state, dispatch] = useReducer(
    organizationReducer,
    organizationInitialState
  );

  return (
    <DispatchOrganizationContext.Provider value={dispatch}>
      <StateOrganizationContext.Provider value={state}>
        {children}
      </StateOrganizationContext.Provider>
    </DispatchOrganizationContext.Provider>
  );
};
