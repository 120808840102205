import styled from 'styled-components/macro';
import { GenericCardHoverOptions } from '../GenericList/GenericCard/GenericCard.styled';

const card_width_breakpoint = 500;

export const StyledCard = styled.div`

  padding: 16px 16px;
  border-radius: 15px;
  min-height: 64px;
  

  color: ${props=>props.theme.colors.dgrey};

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  transition: .5s ease;

  cursor: pointer;

  &:hover ${GenericCardHoverOptions} {
    opacity: 1; 
    transition: .5s ease; 
  }

  .card-main-container {
    @media (min-width: 992px) {
    display: flex;
    flex: 1;
    justify-content: space-between;
    }
    .card-upper-container {
      flex-basis: 50%;
      .card-icon {
        margin-right: 15px;
      }
      .card-title {
        margin-right: 10px;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        color: ${(props) => props.theme.colors.faceliftBlue}; 
        @media (min-width: 1200px) and (max-width: 1480px) {
          width: 170px; 
        }
      }
    }
    .card-lower-container {
      width: 76px; 
      .card-meta {
        display: none;
        @media (min-width: 992px) {
          display: flex; 
          justify-content: space-between;
          width: 150px;
    margin-left: -57px;
        }
        .card-meta-item {
          &:nth-child(1), &:nth-child(2) {
            display: none;
          }
          &:nth-child(4) {
            margin-left: -3px; 
            @media (min-width: 992px) {
              margin-left: -4px;
            }
          }
        }
        > .card-meta-item {
          font-size: 14px; 
          font-weight: 400; 
          width: 75px;
    text-align: center;
}
        }
      }
    }
    .card-lower-container,
    .card-upper-container {
      display: flex;
      align-items: center;
    }
  @media (min-width: 1200px) {
    ${props => props.allPositionsCard && `
        flex-direction: column; 
        align-items: flex-start; 
        .card-main-container {
          width: 100%; 
        }
    `}
  }
`;
