import React, { useContext, useState, useEffect } from "react";
import { Icon, InputFieldWithLabel } from "../../../../../components";
import { Col, Row } from "../../../../../components/Grid";
import { Typography } from "../../../../../components/Typography";
import { AddedFields, AddedFieldsWrapper } from "./FieldList.styled";
import { DispatchPositionContext } from "../../../../context/PositionContext";

const FieldList = ({ name, list, label, max, addText, placeholder, description }) => {
  const positionDispatch = useContext(DispatchPositionContext);
  const [inputValue, setInputValue] = useState("");
  const [fieldList, setFieldList] = useState(list);

  useEffect(() => {
    const handleAddNew = () => {
      if (inputValue.trim() !== "") {
        const updatedList = [...fieldList, inputValue];
        positionDispatch({
          type: "update_position_field",
          payload: [name, updatedList],
        });
        setFieldList(updatedList);
        setInputValue("");
      }
    };

    const handleRemove = (index) => {
      const updatedList = [...fieldList];
      updatedList.splice(index, 1);
      positionDispatch({
        type: "update_position_field",
        payload: [name, updatedList],
      });
      setFieldList(updatedList);
    };

    window[name] = {
      handleAddNew,
      handleRemove,
    };
  }, [inputValue, fieldList, name, positionDispatch]);

  return (
    <>
      <Row>
        <Col>
          <Typography tag="p" bold mt={24} nomargin mbXL="10">
            {label}
          </Typography>
          <Typography tag="p" lowOpacity mb="8" mt="4" mtLG="0" mbLG="0">{description}</Typography>
          <InputFieldWithLabel
            autoFocus
            maxLength="80"
            label={false}
            tiny
            value={inputValue}
            placeholder={placeholder}
            name={name}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (fieldList.length < max) {
                  window[name].handleAddNew();
                }
              }
            }}
          />
          <AddedFieldsWrapper>
            {fieldList.map((item, index) => (
              <AddedFields key={index} index={index}>
                {item}
                <Icon
                  icon="cross"
                  scale="0.8"
                  ml={8}
                  onClick={() => window[name].handleRemove(index)}
                />
              </AddedFields>
            ))}
          </AddedFieldsWrapper>
        </Col>
      </Row>
    </>
  );
};

export default FieldList;
