import * as Yup from 'yup';
import { titleValidation } from '../../../utils/validation';

export const validation = async (objectToValidate, t) => {
  const shape = Yup.object().shape({
    title: Yup.string()
      .required('A title is required')
      .min(2, 'The title is too short')
      .max(100, 'The title is too long')
      .test(
        'Title characters',
        'Special characters not allowed',
        (value) => titleValidation(value)
      ),
  });
  return await shape.validate(objectToValidate);
};

/**
 * copy and modify object bedore sending with request.
 * @param {object} objectToPreflight
 * @returns
 */
export const preflight = (objectToPreflight) => {
  const {
    url,
    createdAt,
    updatedAt,
    active,
    subscriptionInfo,
    ...preflighted
  } = objectToPreflight;

  //if empty competencies or languages remove
  if (preflighted.competencies) {
    preflighted.competencies = preflighted.competencies.filter(
      (comp) => comp !== ''
    );
  }
  if (preflighted.languages) {
    preflighted.languages = preflighted.languages.filter(
      (comp) => comp !== ''
    );
  }

  //remove descriptionUrl if equals "" ( server will reject empty string because not a valid url)
  if (preflighted.descriptionUrl === '')
    preflighted['descriptionUrl'] = null;

  return preflighted;
};
