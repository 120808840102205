import styled from 'styled-components/macro';

export const StyledCardsList = styled.div`

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 15px;

  > * {
    width: 100%;
  }
`;
