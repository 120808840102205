import { t } from "i18next";
import React, { useContext } from "react";
import { TextFieldWithLabel } from "../../../../../../components";
import { PageContext } from "../../../../../context/PageContext";
import MetaEditorGroup from "../../../../MetaEditor/MetaEditorGroup/MetaEditorGroup";

const PageMG = ({page, pt}) => {
  const { state, dispatch } = useContext(PageContext);
  return (
    <MetaEditorGroup name={t("common.page.page", "Page")} pb="0" pt="10" page={page}>
      <TextFieldWithLabel
        onChange={(e) => {
          dispatch({
            type: "update_page",
            payload: { name: "description", value: e.target.value },
          });
        }}
        name="description"
        pt={pt}
        placeholder={t("common.addExcerpt")}
        maxLength={200}
        label={!page ? t("component.metaeditor.position_excerpt_label", "Position excerpt") : t('common.pageExcerpt')}
        byline={!page && t(
          "component.metaeditor.page_excerpt_byline",
          "Write a short text to be displayed when the position is shared on social media."
        )}
        trackValue={state.page.description || 0}
        value={state.page.description || ""}
      />
    </MetaEditorGroup>
  );
};

export default PageMG;
