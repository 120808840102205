import styled, { css } from "styled-components";
import { ResetButton } from "../../../../../../components/Button";
import xIcon from "../../../../../../assets/icons/x.svg";
import up from "../../../../../../assets/icons/up.svg";
import down from "../../../../../../assets/icons/down.svg";

export const StyledBlockControl = styled.div`
  width: 60px;
        margin-right: -10px;
    padding-left: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 0;
      align-items: flex-start; 
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1200px) {
    width: 20px; 
    margin-right: -20px; 
  }
`;

const RoundBlockButton = css`
  width: 12px;
  height: 16px;
  border-radius: 40px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  &:hover {
    background-color: ${(props) => props.theme.colors.lgrey};
  }
  &:disabled {
    opacity: 0.25;
  }
`;

export const MoveBlockButton = styled(ResetButton)`
  ${RoundBlockButton};
  background-image: url(${(props) => (props.direction === "up" ? up : down)});
`;

export const DeleteBlockButton = styled(ResetButton)`
  ${RoundBlockButton};
  background-image: url(${xIcon});
`;
