import React, {
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../components';
import { Grid, Row, Col } from '../../../components/Grid';
import jwt_decode from 'jwt-decode';
import {
  getShortlistToken,
  saveShortlistToken,
} from '../../../utils/localSave';
import {
  authShare,
  getBasicShareInfo,
  getBasicOrganizationInfo,
} from '../../../api/endpoints/share';
import { InputFieldWithLabel } from '../../../components';
import { Typography } from '../../../components/Typography';
import { DispatchErrorContext } from '../../../context/ErrorContext';
import GenericTopBar from '../../../components/GenericTopBar';

import { useTranslation } from 'react-i18next';

import SharedShortlist from './SharedShortlist';

const Share = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [waitingForPin, setWaitingForPin] = useState(false);
  const [basicShareInfo, setBasicShareInfo] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [pin, setPin] = useState('');
  const [orgInfo, setOrgInfo] = useState(null);
  const isMountedRef = useRef(null);

  const dispatchError = useContext(DispatchErrorContext);


  //check for access token in sessionStorage. Returns token if it exists else returns false
  const hasAccessToken = () => {
    //has local access token
    const token = getShortlistToken();
    if (!token) return false;

    const decoded = jwt_decode(token);
    if (decoded.uuid !== uuid) return false;

    //check if token has expired
    if (jwt_decode(token).exp < Date.now() / 1000) {
      return false; //will try to get new token and overwrite this old token.
    } else {
      return token;
    }
  };

  const changePin = (e) => {
    setPin(e.target.value.replace(/\D/, ''));
  };

  const authorize = async (pin) => {
    //if no pin supplied, try to get a pin
    try {
      const request = { selector: uuid };

      //abort if pin is invalid
      if (pin && !pin.match(/^\d{6}$/)) {
        dispatchError({
          type: 'set_error',
          payload: t('error.pincode_wrong_format'),
        });
        return false;
      }

      if (pin || pin === '') request.params = { pin: pin };

      const response = await authShare(request);
      //a token was received.
      if (response.auth && isMountedRef.current) {
        saveShortlistToken(response.token);
        setAuthorized(true);
        return false;
      }

      //pincode sent to user!
      if (response.success && isMountedRef.current) {
        setError(false);
        setWaitingForPin(true);
      } else {
        if (isMountedRef.current) {
          dispatchError({
            type: 'set_error',
            payload: response.data.data,
          });
          setError(true);
        }
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    const sentCode = sessionStorage.getItem('hitalento-sent-code');
    setTimeout(() => {
      if (isMountedRef.current) {
        sessionStorage.removeItem('hitalento-sent-code');
      }
    }, 1000);
    const token = hasAccessToken();
    if (!token) {
      //if no token exists try to get one
      if (!sentCode) {
        authorize();
        sessionStorage.setItem('hitalento-sent-code', 1);
      } else {
        setWaitingForPin(true);
      }
    } else {
      setAuthorized(true);
    }
    return () => (isMountedRef.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    //get public info
    const getPublicInfo = async () => {
      try {
        const request = { selector: uuid };
        const response = await getBasicShareInfo(request);
        if (response.success) {
          if (isMountedRef.current) {
            setBasicShareInfo(response.data);
          }
        }
      } catch (err) {
        console.log('Could not get public info.');
      }
    };

    //get org info
    const getOrgInfo = async () => {
      try {
        const request = { selector: uuid };
        const response = await getBasicOrganizationInfo(request);
        if (response.success) {
          if (isMountedRef.current) {
            setOrgInfo(response.data);
          }
        }
      } catch (err) {
        console.log('Could not get org info.');
      }
    };
    if (authorized) {
      getOrgInfo();
    }
    getPublicInfo();

    return () => {
      isMountedRef.current = false;
    };
  }, [authorized, uuid]);


  return (
    <>
      <GenericTopBar
        manageShareholders
        orgUrl={orgInfo ? orgInfo.organizationUrl : null}
        orgName={orgInfo ? orgInfo.name : null}
        orgLogo={
          orgInfo ? orgInfo.branding?.files[0]?.signedRequest : null
        }
      />
      <Grid fluid> 
        <Row>
          <Col>
            {!authorized && (
              <Row mt={100}>
                <Col collapse="sm"></Col>
                <Col size={16} break="sm">
                  {error && (
                    <>
                      <Typography tag="h3">
                        {t('error.an_error_happened')}
                      </Typography>
                      <Typography tag="p">
                        {t('page.client_view.link_expired')}
                      </Typography>
                    </>
                  )}
                  {authorized && (
                    <Typography tag="h3">
                      {t('page.client_view.authorized_to_view')}
                    </Typography>
                  )}
                  {waitingForPin && !authorized && (
                    <>
                      {basicShareInfo && (
                        <Typography tag="h1">
                          {t('page.client_view.header', {
                            position_title:
                              basicShareInfo.position.title,
                            organization:
                              basicShareInfo.organization.name,
                          })}
                        </Typography>
                      )}
                      <Typography tag="h3">
                        {t('page.client_view.byline')}
                      </Typography>
                      <Typography tag="p">
                        {t('page.client_view.instruction')}
                      </Typography>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          authorize(pin);
                        }}
                      >
                        <Row>
                          <Col>
                            <InputFieldWithLabel
                              type="text"
                              label={t(
                                'page.client_view.pincode_label'
                              )}
                              maxLength="6"
                              value={pin}
                              onChange={changePin}
                            />
                          </Col>
                        </Row>
                        <Row mt={20}>
                          <Col right>
                            <Button
                              disabled={pin.length !== 6}
                              type={'submit'}
                              secondary
                              primaryColor
                            >
                              {t(
                                'page.client_view.pincode_submit_label'
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </>
                  )}
                </Col>
                <Col collapse="sm"></Col>
              </Row>
            )}
            {authorized && (
              <>
                <Row>
                  <Col>
                    {error && (
                      <>
                        <Typography tag="h3">
                          {t('error.an_error_happened')}
                        </Typography>
                        <Typography tag="p">
                          {t('page.client_view.link_expired')}
                        </Typography>
                      </>
                    )}
                  </Col>
                </Row>
                {!error && (
                  <SharedShortlist useTracker positionUuid={uuid} info={basicShareInfo} />
                )}
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default Share;
