// React
import React, {useState} from 'react'

// Hooks - utility functions - API endpoints
import { useTranslation } from 'react-i18next'

// Components
import { SettingsFlexBlocks, SettingsBlockWrapper, SettingsBlockHeading, SettingsBlockContent } from '../Settings.styled'
import { Typography } from '../../../../components/Typography'
import {Modal} from '../../../../components'
import BobIntegration from '../../../components/integrations/Bob/BobIntegration'

// Styled components
import { Flex } from '../../../style'

// Assets
import HiBobLogo from '../../../../assets/icons/hi_bob_logo.svg';
import { PrimaryButton } from '../../../../components/Button'
import { Icon } from '../../../../components'
import { ButtonContainer } from './Integrations.styled'

const Integrations = () => {
    const [modal, setModal] = useState(false);
    const {t} = useTranslation();

    const closeModal = () => {
        setModal(false);
      };

    return (
        <>
        <SettingsFlexBlocks paddingTop="15">
    <SettingsBlockWrapper>
    <SettingsBlockHeading>
    <Typography tag="p" nomargin mb="5" bold>{t('common.integrations')}</Typography>
<Typography tag="p" lowOpacity nomargin large>{t('common.manageIntegrations')}</Typography>
    </SettingsBlockHeading>
    <SettingsBlockContent>
    <Flex flexDirection="column" flexDirectionLG="row" justify="space-between">
        <Flex>
            <img src={HiBobLogo} />
            <div>
                <Typography tag="p" nomargin bold ml="10">HiBob</Typography>
                <Typography tag="p" nomargin lowOpacity ml="10">{t('common.hiBobDescription')}</Typography>
            </div>
            </Flex>
                <ButtonContainer>
                <PrimaryButton red alignLeft mt="20" onClick={() => setModal(true)}>
                    {t('common.connect')}
                    <Icon icon="connect" mr="10" />
                    </PrimaryButton>
                    </ButtonContainer>
</Flex>
</SettingsBlockContent>
</SettingsBlockWrapper>
</SettingsFlexBlocks>

{modal && (
    <Modal title={t("common.integrateHibob", "Integrate with HiBob")} onClose={() => setModal(false)}>
        <BobIntegration closeModal={closeModal} />
    </Modal>
)}

</>

  )
}

export default Integrations