// React
import React, { useEffect, useState, useContext } from "react";

// Translations, hooks, and API endpoints
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { canMove } from "../BlockControl/BlockControl";
import Dropdown from "react-dropdown";

// State management
import { DispatchMessageContext } from "../../../../../../context/MessageContext";

// Components
import { Icon, Modal } from "../../../../../../components";
import { PrimaryButton } from "../../../../../../components/Button";
import { Typography } from "../../../../../../components/Typography";

// State management
import { PageContext } from "../../../../../context/PageContext";
import { BLOCK_TEMPLATES, STATIC_BLOCK_TYPES } from "../blockConstants";
import { StyledBlockAdder } from "./BlockAdder.styled";
import { Row } from "../../../../../../components/Grid";

const BlockAdder = ({ block }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(PageContext);
  const modalDispatch = useContext(DispatchMessageContext);

  const [canAddAfter, setCanAddAfter] = useState(false);

  //Add new block modal
  const [modalOpen, setModalOpen] = useState(false);
  const [newBlockType, setNewBlockType] = useState("paragraph");

  useEffect(() => {
    //if this blocks is before footer display add more button
    setCanAddAfter(!canMove("down", block, state.page.blocks));
  }, [block, state.page.blocks]);

  const onAddBlockHandler = () => {
    setModalOpen(false);
    const template = { ...BLOCK_TEMPLATES[newBlockType], id: uuidv4() };
    dispatch({
      type: "add_block",
      payload: { index: state.page.blocks.length - 1, template: template },
    });
  };

  return (
    <>
      {modalOpen && (
        <>
          <Modal
            openAnimation
            title={t("common.addBlock")}
            containWidth
            onClose={() => {
              setModalOpen(false);
              modalDispatch({ type: "show_modal", payload: true });
            }}
          >
            <Row hCenter justifyContent="center" mt="24" mb="24">
              <Typography tag="p" mw="350" lowOpacity center>
                Please select a block to add to the position page
              </Typography>
            </Row>
            <Dropdown
              options={Object.keys(BLOCK_TEMPLATES)
                .filter(
                  (templateKey) =>
                    STATIC_BLOCK_TYPES.indexOf(templateKey) === -1
                )
                .map((templateKey) => ({
                  value: templateKey,
                  label: BLOCK_TEMPLATES[templateKey].label,
                }))}
              onChange={(selectedOption) => {
                setNewBlockType(selectedOption.value);
              }}
              value={newBlockType}
            />
            <div
              style={{
                margin: "16px 0",
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "center",
              }}
            >
              <PrimaryButton
                transparent
                mt="18"
                onClick={() => {
                  setModalOpen(false);
                  modalDispatch({ type: "show_modal", payload: false });
                }}
              >
                {t("common.cancel")}
                <Icon icon="cross" mr="10" invert />
              </PrimaryButton>
              <PrimaryButton
                red
                mt="10"
                onClick={() => {
                  onAddBlockHandler();
                  modalDispatch({ type: "show_modal", payload: false });
                }}
              >
                {t("page.single_page.add_new_block", "Add new block")}
                <Icon icon="plus" mr="10" />
              </PrimaryButton>
            </div>
          </Modal>
        </>
      )}

      {((STATIC_BLOCK_TYPES.indexOf(block.type) === -1 && canAddAfter) ||
        (state.page.blocks.length === 2 && block.type === "header")) && (
        <StyledBlockAdder>
          <PrimaryButton
            white
            onClick={() => {
              setModalOpen(true);
              modalDispatch({ type: "show_modal", payload: true });
              // Set showModal to true
            }}
          >
            {t("page.single_page.add_new_block", "Add new block")}
            <Icon icon="plus" ml={0} mr="10" />
          </PrimaryButton>
        </StyledBlockAdder>
      )}
    </>
  );
};

export default BlockAdder;
