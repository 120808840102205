import React from 'react';
import GenericCard from './GenericCard/GenericCard';
import { StyledGenericList } from './style';

import { Typography } from '../../../components/Typography';
import { Search } from '..';


const GenericList = ({
  list,
  header,
  background,
  onCheck,
  onSelect,
  initiallyOn,
  baseUrl,
  lockItem,
  genericIcon,
  disabledList,
  options,
  checkedState,
  onSearchAction,
  disableSearchSubmit,
  useLabel,
  afterList,
  placeholder,
  isPinned,
  inactive
}) => {


  return (
    <>
      <div>
        {header && (
          <Typography tag="h3" lineThrough bold>
            {header}
          </Typography>
        )}
        {onSearchAction && (
          <Search
            onSearchAction={onSearchAction}
            searchOnChange
            disableSearchSubmit={disableSearchSubmit}
          />
        )}
        <StyledGenericList
          initiallyOn={initiallyOn}
          background={background}
        >
          {list && list.length > 0 ? (
            <>
              {options && options}
              {list.map((item) => (
                <GenericCard
                  inactive={inactive}
                  useLabel={useLabel}
                  disabled={
                    disabledList
                      ? disabledList.indexOf(item[0][0]) > -1
                      : false
                  }
                  initiallyOn={
                    checkedState ? checkedState : initiallyOn
                  }
                  baseUrl={baseUrl}
                  key={item[0][0]}
                  id={item[0][0]}
                  title={item[0][1]}
                  onSelect={onSelect}
                  onCheck={onCheck}
                  data={item[1]}
                  pin={isPinned ? isPinned.id === item[0][0] : false}
                  genericIcon={genericIcon}
                />
              ))}
            </>
          ) : (
            // <p style={{ textAlign: 'center' }}>
            //   {placeholder ? placeholder : 'No list'}
            // </p>
            null
          )}
          {afterList && afterList}
        </StyledGenericList>
      </div>
    </>
  );
};

export default GenericList;
