// React
import React from "react";

// Tranlsation - Supported languages etc
import { useTranslation } from "react-i18next";
import supportedLanguages from "../../i18n/supportedLanguages.json";
import { StyledSelectRadio } from "./style";

// Components
import "react-dropdown/style.css";

const LanguageRadio = ({
  value,
  onChange,
  allowedLanguages,
  disabled,
  useISO,
  label,
}) => {
  const { t } = useTranslation();

  const handleLanguageChange = (selectedLanguage) => {
    onChange({ target: { value: selectedLanguage } });
  };

  const availableLanguages = allowedLanguages
    ? supportedLanguages.filter((lng) => allowedLanguages.indexOf(lng) > -1)
    : supportedLanguages;

  // const languageOptions = useISO
  // ? ISO6391.getAllCodes().map((language) => ISO6391.getName(language))
  // : availableLanguages.map((language) => t(`component.language.${language}`));

  // Slice the languageOptions array to include only the first two languages
  const firstTwoLanguages = availableLanguages.slice(0, 2);

  return (
    <StyledSelectRadio>
      {firstTwoLanguages.map((language) => (
        <button
          key={language}
          onClick={() => {
            handleLanguageChange(language);
          }}
          disabled={disabled}
          className={language === value ? "active" : ""}
        >
          {language === "en-US" ? t("component.language.en-US", "English") : t("component.language.da-DK", "Danish")}
        </button>
      ))}
    </StyledSelectRadio>
  );
};

export default LanguageRadio;
