import styled from "styled-components/macro";

const smallerInnerGutter = (props) => {
  return props.smallerInnerGutter
    ? `
  margin-left: -${`${
    parseInt(props.theme.grid.gutter.replace("px", "")) * 0.3
  }px`};
  margin-right: -${`${
    parseInt(props.theme.grid.gutter.replace("px", "")) * 0.3
  }px`};
  & ${Col} {
    margin: 0 ${`${
      parseInt(props.theme.grid.gutter.replace("px", "")) * 0.3
    }px`};
  }`
    : ``;
};

export const media = {
  xs: (styles, props) => `
        @media only screen and (max-width: ${props.theme.breakpoints.xs}) {
            ${styles}
        }`,

  sm: (styles, props) => `
        @media only screen and (max-width: ${props.theme.breakpoints.sm}) {
            ${styles}
        }`,
  md: (styles, props) => `
        @media only screen and (max-width: ${props.theme.breakpoints.md}) {
            ${styles}
        }`,
  lg: (styles, props) => `
  @media only screen and (max-width: ${props.theme.breakpoints.lg}) {
      ${styles}
  }`,
  xl: (styles, props) =>
    `
    @media only screen and (max-width: ${props.theme.breakpoints.xl}) {
        ${styles}
    }`,
  xxl: (styles, props) => `
  @media only screen and (max-width: ${props.theme.breakpoints.xxl}) {
      ${styles}
  }`,
  xxxl: (styles, props) => `
  @media only screen and (max-width: ${props.theme.breakpoints.xxxl}) {
      ${styles}
  }`,
};

/**
 *  parameter string `breakpoint-order`, fx. `xl-2`
 *  sets order property on flex element
 */
const getOrder = (order, props) => {
  const [breakpoint, orderNumber] = order.split("-");
  return media[breakpoint](`order: ${orderNumber};`, props);
};

/**
 * parameter string `breakpoint-mt-[margintop]` or `breakpoint-mb-[marginbottom], fx. `xl-mt-2`
 * set margintop or marginbottom property on element
 */
const getMargin = (margin, props) => {
  const [breakpoint, topOrBottom, amount] = margin.split("-");
  return media[breakpoint](
    `
    ${
      topOrBottom === "mt"
        ? `margin-top: ${amount}px`
        : `margin-bottom: ${amount}px`
    };
  `,
    props
  );
};

export const Col = styled.div`
  /* flex: ${(props) => (props.size ? props.size : 1)}; */
  
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1;
  display: flex;
  ${props => props.fluid ? `width: 100%` : null};
  ${props => props.flexDirection ? `flex-direction: ${props.flexDirection};` : null}; 
  //set order at breakpoint
  ${(props) => (props.order ? getOrder(props.order, props) : ``)}
  

  ${props => props.mt ? `margin-top: ${props.mt}px;` : null}; 
  ${props => props.mb ? `margin-bottom: ${props.mb}px;` : 'margin-bottom: 0px;'}; 
  //set top or bottom margin at breakpoint
  ${(props) => (props.m ? getMargin(props.m, props) : ``)} 
  ${(props) =>
    props.size && !props.smallerInnerGutter
      ? `max-width: calc(100%/24*${props.size} - ${props.theme.grid.gutter} * 2);
  `
      : `max-width: calc(100%/24*${props.size} - ${
          parseInt(props.theme.grid.gutter.replace("px", "")) * 0.3
        }px * 2);
  `}

${props => props.allPositions && `
    @media (min-width: 991px) {
height: calc(100vh - 69px) !important;
    }
`}
  //Break a column in breakpoint
  ${(props) =>
    props.break &&
    media[props.break](
      props.smallerInnerGutter
        ? `min-width: calc(100% - ${
            parseInt(props.theme.grid.gutter.replace("px", "")) * 0.3
          }px * 2);`
        : `min-width: calc(100% - ${props.theme.grid.gutter} * 2);` +
            `flex:auto;`,
      props
    )}

  //Collapse a column in breakpoint
  ${(props) => props.collapse && media[props.collapse](`display:none`, props)}


  /* border: 1px solid green; */
  display: ${(props) => {
    if (
      props.vCenter ||
      props.hCenter ||
      props.top ||
      props.bottom ||
      props.left ||
      props.right
    )
      return "flex";
    return "block";
  }};
  align-items: ${(props) => {
    if (props.vCenter) return "center";
    if (props.top) return "flex-start";
    if (props.bottom) return "flex-end";
    return "flex-start";
  }};
  justify-content: ${(props) => {
    if (props.hCenter) return "center";
    if (props.left) return "flex-start";
    if (props.right) return "flex-end";
    return "flex-start";
  }};
  margin: 0
    ${(props) => `${parseInt(props.theme.grid.gutter.replace("px", "")) * 1}px`};
  padding-top: ${(props) => (props.cpt ? props.cpt : "0px")};


  @media (min-width: 992px) {
    ${props => props.mlMD ? `margin-left: ${props.mlMD}px;` : null}; 
    ${props => props.mrMD ? `margin-right: ${props.mrMD}px;` : null}; 
    ${props => props.prMD ? `padding-right: ${props.prMD}px` : null};
  }
  @media (max-width: 1200px) {
    ${props => props.minWidthSM && `min-width: ${props.minWidthSM}`};
  }
  ${props => props.manageShareholderShortlist && `
      max-height: calc(72px * 3);

    margin-bottom: 75px;
    @media (min-width: 769px) {
      height: 100%; 
      max-height: 100%; 
      max-height: 75vh; 
      position: relative; 
    }
  `}

  ${props => props.manageShareholderApplication && `
  max-height: calc(100vh - 356px); 
  // overflow: scroll;
  @media (min-width: 769px) {
    max-height: 100%; 
  }
  `}
  ${props => props.mw && `max-width: 700px; margin: 0 auto; display: flex; align-items: center; flex-direction: column;`};
  ${props => props.manageAccess && `
  max-height: ${props.display ? '400px' : '0'};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; /* Adjust the transition duration and timing function as needed */
`}
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: ${(props) => props.mt || 0}px;
  margin-bottom: ${(props) => props.mb || 0}px;
  height: ${props => props.height ? props.height : null};
  width: ${props => props.width ? props.width : null};
  ${props => props.hCenter ? `margin-left: auto !important; margin-right: auto !important;` : null}; 
  ${props => props.backgroundColor ? `background-color: ${props.backgroundColor};` : null}; 
  ${props => props.paddingSM ? `padding: ${props.paddingSM};` : null};
  ${props => props.bg ? `background-color: ${props.bg};` : null}; 
  ${props => props.overflow ? `overflow: ${props.overflow};` : null}; 
  ${props => props.pb ? `padding-bottom: ${props.pb}px;` : null}; 
  ${props => props.alignCenter ? `align-items: center` : null}; 
  ${props => props.justifyContent ? `justify-content: ${props.justifyContent};` : null}; 
  /* width: 100%; */

  &:before {
    content: "";
    display: ${(props) => (props.divider ? "block" : "none")};
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.mgrey};
  }

  ${props => props.allPositions && `
    overflow: scroll; 
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important; 
    }
    -ms-overflow-style: none;
  scrollbar-width: none;

`}


  margin-left: -${(props) => props.theme.grid.gutter};
  margin-right: -${(props) => props.theme.grid.gutter};
  ${props => props.noMargin ? 'margin: 0;' : null};
  ${(props) => smallerInnerGutter(props)}
  ${props => props.mr ? `margin-right: ${props.mr}px;` : null}
  ${props => props.ml ? `margin-left: ${props.ml}px;` : null}
  @media (max-width: 991px) {
    ${props => props.hideOnMobile ? 'display: none' : null}; 
  }
  @media (min-width: 992px) {
          ${props => props.widthMD ? `width: ${props.widthMD};`: null}; 
            ${props => props.prMD ? `padding-right: ${props.prMD}px;` : null}
  ${props => props.plMD ? `padding-left: ${props.plMD}px;` : null}
  ${props => props.ptMD ? `padding-top: ${props.ptMD}px;` : null}; 
  ${props => props.flexDirectionMD ? `flex-direction: ${props.flexDirectionMD};` : null};
  ${props => props.paddingMD ? `padding: ${props.paddingMD}px` : null}; 
  ${props => props.mwMD ? `max-width: ${props.mwMD}px` : null}; 
  }
  ${props => props.application && `
    flex-flow: unset; 
    overflow-x: scroll; 
    padding-bottom: 325px; 
  `}
  ${props => props.cv && `
  overflow-x: scroll !important;
  padding-bottom: 150px; 
  `}
  @media (min-width: 1200px) {
      ${props => props.mh && `min-height: 100vh`};
      ${props => props.mw && `max-width: ${props.mw}px`}
    ${props => props.flexLG ? `flex: ${props.flexLG}` : null}; 
    ${props => props.scrollable && `
    max-height: 100vh;
    overflow: hidden auto;
    &::-webkit-scrollbar {
              display: none;
              width: 0 !important
            }
            -ms-overflow-style: none;
  scrollbar-width: none;
    `}
    ${props => props.ptLG ? `padding-top: ${props.ptLG}px` : null};
    ${props => props.pbLG ? `padding-bottom: ${props.pbLG}px` : null};
  }
  ${props => props.cp && `
  width: 100%; 
  margin: 0; 
  `}
  @media (max-width: 1200px) {
    ${props => props.widthSM && `width: ${props.widthSM}`};
  }
${props => props.manageShareholder && `
padding-top: 25px; 
margin: 0 auto; 
padding-bottom: 50px !important;
padding-bottom: 0;
overflow: scroll;
`}
${props => props.manageShareholderParent && `
padding: 20px; 
@media (min-width: 768px) {
padding: 50px; 
display: flex;
justify-content: center; 
}
@media (min-width: 1200px) {
  padding: 100px;
  margin: 0 auto;
  max-width: 1800px; 
  width: 100%;
}
`}
${props => props.cv && `
padding-bottom: 300px; 
`}
`;

export const Grid = styled.div`
  height: ${(props) => (props.expand ? "100%" : "auto")};
  /* border: 1px solid magenta; */
  max-width: ${(props) => (props.fluid ? "100%" : "1200px")};
  width: 100%;
  margin: 0 auto;
  ${props => props.initialOpacity && `
  opacity: 0; 
  transition: 1s ease; 
  `}
  ${props => props.allPositions && `
  border-right: 1px solid rgba(24, 31, 56, 0.15); 

  `}
  ${props => props.animated && `
  opacity: 1; 
  transition: 1s ease; 
  `}
  ${props => props.animate && `
  animation: animate .5s ease forwards;
  animation-delay: .3s; 
  opacity: 0;  
  `}
  @keyframes animate {
    0% {
      opacity: 0; 
    }
    100% {
      opacity: 1; 
    }
  }
  overflow-x: visible;
  ${props => props.borderBottom && `border-bottom: 1px solid #F0F0F3;`}
  ${props => props.heightSM ? `height: ${props.heightSM};` : null};
  & ${Row} {
    ${(props) => (props.w100 ? `width: 100%;` : ``)}
    ${(props) => smallerInnerGutter(props)}
  }
  ${props => props.padding ? `padding: ${props.padding}px` : null};
  ${props => props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px` : null}; 
  ${props => props.mw && `max-width: ${props.mw}px;`}
  & > ${Row} {
    /* border: 1px solid red; */
    height: ${(props) => (props.expand ? "100%" : "auto")};

    ${(props) =>
      props.fluid
        ? ``
        : `
      @media (max-width: 1200px) {
        margin-left: 0;
        margin-right: 0;
      }
      `}

    & > ${Col} {
      height: ${(props) => (props.expand ? "100%" : "auto")};
    }
  }
  ${props => props.column ? 'flex-direction: column;' : null};
  ${props => props.flex ? 'display: flex': null};
  @media (min-width: 992px) {
    ${props => props.heightMD ? `height: ${props.heightMD};` : null}; 
  }
  ${props => props.scrollable && `
    max-height: 5000px;
    overflow: scroll; 
    height: 100%; 
    @media (min-width: 1200px) {
      overflow: hidden; 
    }
  `}
  ${props => props.editPosition && `
  @media (min-width: 998px) {
    z-index: 9999; 
    position: fixed;
    right: 0;
    top: 0; 
    width: 100vw; 
    height: 100vh; 
  } 
  @media (min-width: 1200px) {
    width: calc(100vw - 240px); 
    z-index: 999999; 
    max-width: 100%; 
  }
  `}
  ${props => props.alignCenter && `
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  `}
  ${props => props.manageShareholder && `
  height: 100% !important;
  `}
`;
