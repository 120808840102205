// React
import React, { useContext, useEffect, useState } from "react";

// React router
import { useHistory } from "react-router";

// State mangagement
import { SubscriptionContext } from "../../../context/SubscriptionContext";

// Utility functions and hooks
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

// Components
import SiteButton from "../SiteButton/SiteButton";
import { SiteNavWrapper } from "./SiteNav.styled";
import Product from "../../../views/Subscription/Product/Product";

/**
 * Site navigation used on AllPages
 */
const SiteNav = ({ sites, activeSite }) => {
  const history = useHistory();
  const language = useParams();
  const { t } = useTranslation();
  // const [privilegess, setPrivileges] = useState(null);

  const {
    state: {
      main: { subscription_action },
      privileges: { subscriptions: activeAddons },
      available_addons,
    },
  } = useContext(SubscriptionContext);

  const handleSiteButtonClick = (siteLanguage) => {
    history.push(`/cp/site/${siteLanguage}`);
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  return (
    <>
      {sites.map((site, index) => (
        <SiteNavWrapper key={site.id}>
          <SiteButton
            key={index}
            title={
              site.site.language === "en"
                ? t("component.language.en-US", "English")
                : t("component.language.da-DK", "Danish")
            }
            selected={site.site.language === language.language}
            onClick={() => handleSiteButtonClick(site.site.language)}
          />
          {sites.length < 2 && (
            <Product
              type="addCP"
              subscription={available_addons[0]}
              title={available_addons[0]?.name}
              adds={t("common.addCareerSite")}
              description={t("common.careerSiteDesc")}
              // setIsLoading={setIsLoading}
              isPurchased={
                activeAddons.indexOf(available_addons[0]?.metadata?.allows) > -1
              }
            />
          )}
        </SiteNavWrapper>
      ))}
    </>
  );
};

export default SiteNav;
