import React from 'react'
import { StyledIntegrationWrapper } from './style'

const IntegrationWrapper = ({ children }) => {
  return (
    <StyledIntegrationWrapper>
      {children}
    </StyledIntegrationWrapper>
  )
}

export default IntegrationWrapper