export const APPLICATION_REJECTION_TEMPLATE = {
  'en-US': `<p>Dear <strong>{{firstName}}</strong>,</p>
      <p></p>
      <p>Thank you for your application. We appreciate your interest in <strong>{{organizationName}}</strong> and the job.</p>
      <p>We have experienced a great interest in our <strong>{{positionName}}</strong> vacancy.
      We will let you know, we now have selected the candidate we believe most closely matches the position's job requirements.
      </p>
      <p>Thanks again for your interest in <strong>{{organizationName}}</strong>, and best of luck with your job search.</p>
      <p>We constantly advertise more positions and we hope you'll keep us in mind as we encourage you to apply again.</p>
      <p>All the best, <br><strong>{{organizationName}}</strong>.</p>
      `,
  'da-DK': `
      <p>Kære <strong>{{firstName}}</strong>,</p>
      <p></p>
      <p>Tak for din ansøgning. Vi sætter stor pris på din interesse for <strong>{{organizationName}}</strong> og for stillingen.</p>
      <p>
        Vi har oplevet en stor interesse for den ledige position som <strong><strong>{{positionName}}</strong>.
        Vi har nu valgt den kandidat, som, vi tror, passer bedst til jobbet.
      </p>

      <p>Endnu engang tak for din interesse for stillingen hos <strong>{{organizationName}}</strong>, og held og lykke med din fortsatte jobsøgning.</p>
      <p>Vi har næsten altid ledige stillinger og håber at du vil have os i tankerne, når du skal søge job i fremtiden.</p>
      <p>Med venlig hilsen, <br><strong>{{organizationName}}</strong>.</p>
      `,
};
