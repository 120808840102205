// React
import React, { useState } from 'react'

// Styled
import { AccordionContainer, AccordionHeader, AccordionTitle, AccordionContent } from './EditPositionAccordion.styled';

// Components
import { Icon } from '../../../../../components';

const EditPositionAccordion = ({title, children, metaEditor}) => {

  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

    return (
        <AccordionContainer metaEditor={metaEditor}>
          <AccordionHeader onClick={handleToggle} metaEditor={metaEditor}>
            <AccordionTitle>{title}</AccordionTitle>
            <Icon icon="arrow_up" isOpen={!isOpen}/>
          </AccordionHeader>
          <AccordionContent 
          isOpen={isOpen}>
          {children
          }</AccordionContent>
        </AccordionContainer>
      );
    };

export default EditPositionAccordion