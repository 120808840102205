import { useState, useEffect, useContext } from 'react';
import { SubscriptionContext } from '../context/SubscriptionContext';

/**
 * Get a product from subscription context ( availabel addons / product s)
 * @param {string} name
 * @returns {object[]} [product]
 */
const useGetAddon = (name) => {
  const {
    state: { available_addons },
  } = useContext(SubscriptionContext);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    if (!available_addons || available_addons.length === 0) {
      setProduct(null);
    } else {
      setProduct(available_addons.find((prod) => prod.name === name));
    }
  }, [available_addons, name]);

  return [product];
};

export default useGetAddon;
