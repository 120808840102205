import styled from "styled-components/macro";
export const StyledMetaEditor = styled.div`

    & > div {
        //container
        border : 1px solid ${props=>props.theme.colors.mgrey};
        @media (min-width: 1200px) {
            border: none; 
        }
    }
@media (min-width: 1200px) {
    background-color: white;
    height: 100vh;
    overflow: scroll; 
    width: 400px;
    padding-bottom: 125px;
       &::-webkit-scrollbar {
      display: none;
      width: 0 !important; 
    }
    -ms-overflow-style: none;
  scrollbar-width: none;
}
`;