import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { InputLabelWithByline } from '../Input';
import { useOutsideAlerter } from '../../../utils/hooks';
import { StyledColorPicker, StyledColorButton, StyledColorInput } from './style';

const ColorPicker = ({ label, byline, color, onClose, ...props }) => {
  const pickerRef = useRef(null);
  useOutsideAlerter(pickerRef, () => {
    setOpen(false);
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      if (typeof onClose === "function") onClose();
    }
  }, [open])


  return (
    <StyledColorPicker ref={pickerRef}>
      <StyledColorInput>
        <StyledColorButton onClick={() => setOpen(!open)} color={color} />
        <InputLabelWithByline label={label} byline={byline} />
      </StyledColorInput>
      {open && <ChromePicker className='picker' color={color} {...props} disableAlpha />}
    </StyledColorPicker>
  )
}

export default ColorPicker