// React
import React, { useState, useContext, useEffect } from "react";

// Utility functions - Custom hooks - API Endpoints
import { useTranslation } from "react-i18next";

// Components
import {
  CheckboxInput,
  List,
  PositionList,
  PositionTableHeader,
  PositionWrapper,
  SearchPositionContainer,
  SelectButtonContainer,
} from "../ManageAccess.styled";
import { Icon, InputFieldWithLabel } from "../../../../components";
import { Col } from "../../../../components/Grid";
import { Typography } from "../../../../components/Typography";
import { Flex } from "../../../style";

// State management
import { StatePositionContext } from "../../../context/PositionContext";
import { DispatchOrganizationContext } from "../../../context/OrganizationContext";

const PositionAccessList = ({ allPositions, user }) => {
  const { t } = useTranslation();
  const [checkedPositions, setCheckedPositions] = useState(
    user?.positionsAccess
      ? user.positionsAccess.filter((position) => position !== "all")
      : []
  );
  const [searchQuery, setSearchQuery] = useState("");

  const { positions } = useContext(StatePositionContext);

  const dispatchOrganization = useContext(DispatchOrganizationContext);

  const handleCheckboxChange = (positionId) => {
    // Toggle the positionId in the array
    if (checkedPositions.includes(positionId)) {
      setCheckedPositions(checkedPositions.filter((id) => id !== positionId));
    } else {
      setCheckedPositions([...checkedPositions, positionId]);
    }
  };

  const filteredPositions = positions.filter((position) =>
    position.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectAll = () => {
    const allPositionIds = positions.map((position) => position.id);
    setCheckedPositions(allPositionIds.map((id) => id.toString()));
  };

  const handleDeselectAll = () => {
    setCheckedPositions([]);
  };

  // Update the context whenever checkedPositions changes
  useEffect(() => {
    dispatchOrganization({
      type: "position_access",
      payload: checkedPositions,
    });
  }, [checkedPositions, dispatchOrganization]);

  // console.log(positionAccess);

  // useEffect(() => {
  //   console.log("allPositions", allPositions);
  // }, [allPositions]);

  return (
    <Col mw fluid manageAccess display={!allPositions ? "true" : null}>
      <SelectButtonContainer>
        <Flex onClick={handleSelectAll} pointer widthSM="fit-content">
          <Icon icon="select_all_check" />
          <Typography tag="p" lowOpacity nomargin mr="25">
            {t("common.selectAll")}
          </Typography>
        </Flex>
        <Flex onClick={handleDeselectAll} pointer widthSM="fit-content">
          <Icon icon="deselect_all_check" />
          <Typography tag="p" lowOpacity nomargin>
            {t("common.deSelectAll")}
          </Typography>
        </Flex>
      </SelectButtonContainer>
      <PositionWrapper>
        <SearchPositionContainer>
          <InputFieldWithLabel
            label={false}
            placeholder={t("common.searchPositions", "Search positions")}
            mt="0"
            noBorder
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Icon icon="search" pr="20" />
        </SearchPositionContainer>
        <PositionTableHeader>
          <Typography tag="p" nomargin>
            {t("common.name", "Name")}
          </Typography>
          <Typography tag="p" nomargin>
            {t("common.access", "Access")}
          </Typography>
        </PositionTableHeader>
        <PositionList>
          {filteredPositions.map((position) => (
            <List key={position.id}>
              <Typography tag="p" nomargin>
                {position.title}
              </Typography>
              <CheckboxInput
                type="checkbox"
                checked={checkedPositions.includes(position.id.toString())}
                onChange={() => handleCheckboxChange(position.id.toString())}
              />
            </List>
          ))}
        </PositionList>
      </PositionWrapper>
    </Col>
  );
};

export default PositionAccessList;
