import styled from "styled-components/macro";

const StyledFileDownload = styled.a`

    padding: 4px 12px;
    margin: 10px 0px;
    background-color: ${(props) => props.theme.colors.lgrey};
    color: ${(props) => props.theme.colors.dgrey};
    font-size: 9px;
    border-radius: 50px;
    display : block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
        ${props => props.cv && `
    background-color: transparent; 
    margin: 0;
    padding: 0; 
    justify-content: unset !important;
    flex-direction: row-reverse; 
    justify-content: flex-end !important; 
    `}
    ${(props) =>
      props.includeIcon
        ? `
    display: flex;
    justify-content: space-between;
    align-items: center;
    `
        : `width: 100%;
    max-width:100%;`}

    > span:first-child {
        ${(props) =>
          !props.includeIcon &&
          `font-weight: 600;
       `}
       margin-right: 8px;
    > span:last-child {
  
    }
`;

export default StyledFileDownload;
