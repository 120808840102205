import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { updateSite } from "../../../api/endpoints/cp";
import { updateBranding } from "../../../api/endpoints/organization";
import {
  Button,
  FileField,
  Icon,
  InputFieldWithLabel,
  LanguageSelect,
} from "../../../components";
import { initialField } from "../../../components/FileField/reducer";
import ColorPicker from "../../../components/Input/ColorPicker";
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { DispatchMessageContext } from "../../../context/MessageContext";
import {
  DispatchBrandingContext,
  StateBrandingContext,
} from "../../context/BrandingContext";
import {
  DispatchSiteContext,
  StateSiteContext,
} from "../../context/SiteContext";
import { PageContext } from "../../context/PageContext";
import { DispatchUserContext } from "../../../context/UserContext";

import { SubscriptionContext } from "../../context/SubscriptionContext";
import { StyledBrandingSettings } from "./style";
import { validation } from "./validation";
import { Flex } from "../../style";
import { Typography } from "../../../components/Typography";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BrandingSettings = forwardRef(({ site }, ref) => {
  const { t, i18n } = useTranslation();
  const { state } = useContext(PageContext);
  const { sites } = useContext(StateSiteContext);
  const siteKey = Object.keys(sites).find(
    (key) => sites[key].id === state.page.siteId
  );
  const current_site = sites[siteKey];
  const siteDispatch = useContext(DispatchSiteContext);
  const userDispatch = useContext(DispatchUserContext);

  const { branding, isLoading, unsaved } = useContext(StateBrandingContext);
  const brandingDispatch = useContext(DispatchBrandingContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);
  const {
    state: { privileges },
  } = useContext(SubscriptionContext);
  const mutation = useMutation(updateSite);

  const initialLanguage = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [language, setLanguage] = useState(initialLanguage.language);

  // Extract accentColor and brandColor from branding object
  const { accentColor, brandColor, ...remainingBranding } = branding;

  const [brandingColors, setBrandingColors] = useState([]);
  const [isBrandingLoaded, setIsBrandingLoaded] = useState(false);

  useEffect(() => {
    if (branding.brandingColorOne) {
      setIsBrandingLoaded(true);
    }
  }, [branding]);

  // Change the language in the sites object where the id matches the siteId from state.page.siteId
  useEffect(() => {
    if (current_site) {
      setLanguage(current_site.language);
    }
  }, [current_site]);

  // Inintial branding colors
  useEffect(() => {
    if (isBrandingLoaded) {
      setBrandingColors([
        branding?.brandingColorOne,
        branding?.brandingColorTwo,
        branding?.brandingColorThree,
        branding?.brandingColorFour,
      ]);
    }
  }, [isBrandingLoaded]);

  const addBrandingColor = () => {
    if (brandingColors.length < 4) {
      setBrandingColors([...brandingColors, ""]);
    }
  };

  // Mapping function to convert numeric indices to words
  function mapIndexToWord(index) {
    const words = ["One", "Two", "Three", "Four"];
    if (index >= 1 && index <= words.length) {
      return words[index - 1];
    }
    return ""; // Return an empty string for invalid indices
  }

  // Update handler
  const persistBrandingState = async () => {
    try {
      await validation(branding, t);

      // Construct the branding colors object
      const brandingColorsObject = {};
      brandingColors.forEach((color, index) => {
        const wordIndex = mapIndexToWord(index + 1);
        if (wordIndex) {
          brandingColorsObject[`brandingColor${wordIndex}`] = color;
        }
      });

      const updatedBranding = {
        ...remainingBranding, // Without accentColor and brandColor
        accentColor,
        brandColor,
        ...brandingColorsObject,
      };

      // Update branding API call
      const brandingRequest = { body: { ...updatedBranding } };
      const brandingResponse = await updateBranding(brandingRequest);

      // // Update site API call
      // const siteResponse = await updateSite({
      //   selector: { id: state.page.siteId },
      //   body: current_site,
      // });

      // Dispatch actions
      brandingDispatch({
        type: "update_branding",
        payload: {
          ...brandingResponse.data,
        },
      });
      // messageDispatch({ type: 'set_message', payload: 'Saved' });
    } catch (err) {
      // errorDispatch({ type: 'set_error', payload: err.message });
      console.log(err);
    }
  };

  // Update selected language
  const updateSelectedLanguage = async () => {
    // let selectedLanguage; // Declare selectedLanguage here

    try {
      // selectedLanguage = language.split("-")[0]; // Extract the language code
      // selectedLanguage = language.language;
      // const body = { language: selectedLanguage, frontpage: true };
      // await validation(body);

      // Update site API call
      const siteResponse = await updateSite({
        selector: { id: state.page.siteId },
        body: current_site,
      });

      // console.log("siteResponse", siteResponse);

      history.push(`/cp/site/${current_site.language}`);

      // Dispatch actions
      userDispatch({ type: "reload" });
    } catch (err) {
      console.log(err);
      // errorDispatch({ type: 'set_error', payload: err.message });
    }
  };

  const handleChange = (e) => {
    const selectedLanguage = e.target.value.split("-")[0]; // Extract the language code
    setLanguage(selectedLanguage);

    if (state.page.siteId && sites) {
      const updatedSites = { ...sites };
      const siteKey = Object.keys(updatedSites).find(
        (key) => updatedSites[key].id === state.page.siteId
      );
      console.log("siteKey", siteKey);
      console.log("language", language);
      console.log("selectedLanguage", selectedLanguage);
      if (siteKey) {
        updatedSites[siteKey] = {
          ...updatedSites[siteKey],
          title: selectedLanguage,
          slug: selectedLanguage,
          language: selectedLanguage,
        };
        siteDispatch({
          type: "update_site",
          payload: updatedSites,
        });
        console.log("updatedSites", updatedSites);
      }
    }
  };

  // Expose the persistBrandingState function to the parent component
  useImperativeHandle(ref, () => ({
    persistBrandingState,
    updateSelectedLanguage,
  }));

  return (
    <StyledBrandingSettings>
      <Typography tag="p" bold nomargin mb="4">
        {t("common.logo")}
      </Typography>
      <Typography tag="p" nomargin color="rgba(24, 31, 56, 0.6)">
        {t("component.branding.upload_byline")}
      </Typography>
      <FileField
        noBorder
        pt="14"
        page
        darkBackground={true}
        overrideButton={
          <Button transparent>
            <Icon icon="upload" mr={8} />
            {t("component.branding.upload_file_text", "Upload a file here")}
          </Button>
        }
        initialFiles={
          branding.files?.length > 0 &&
          branding?.files.map((file, index) => {
            return {
              ...initialField,
              id: index,
              hasFile: true,
              savedFile: file,
            };
          })
        }
        type="image"
        docType="logo"
        dynamicAddRemove={false}
        onUpload={async (data) => {
          //save the file reference for later use ( state / localStorage )
          //call instance endpoint to associate file immediately if user logged in

          //populate addFiles in branding object with data.uuid
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: "addFiles", value: [data.uuid] },
          });
        }}
        onRemove={async (data) => {
          //update some state on file removal ( if saved file ) / refresh state
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: "files", value: [] },
          });
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: "addFiles", value: [] },
          });
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: "removeFiles", value: [data.uuid] },
          });
        }}
      />

      {/* Display branding colors */}
      <Typography tag="p" bold nomargin mb="4">
        {t("common.brandColors")}
      </Typography>
      <Typography tag="p" nomargin color="rgba(24, 31, 56, 0.6)">
        {t("common.selectBrandColors")}
      </Typography>
      <Flex mb="18">
        {brandingColors.map(
          (color, index) =>
            // Render a color picker only if the color is not "false"
            color !== false && (
              <ColorPicker
                key={index}
                color={color} // Bind ColorPicker color prop to brandingColorsArray
                onChangeComplete={(c) => {
                  const updatedColors = [...brandingColors];
                  updatedColors[index] = c.hex;

                  const payloadName =
                    index === 0
                      ? "accentColor"
                      : index === 1
                      ? "brandColor"
                      : null;

                  brandingDispatch({
                    type: "update_branding_single_state",
                    payload: {
                      name: `brandingColor${index + 1}`,
                      value: c.hex,
                    },
                  });

                  if (payloadName) {
                    brandingDispatch({
                      type: "update_branding_single_state",
                      payload: { name: payloadName, value: c.hex },
                    });
                  }

                  setBrandingColors(updatedColors);
                }}
              />
            )
        )}

        {brandingColors.length < 4 && (
          <Button transparent onClick={addBrandingColor} page>
            <Icon icon="plus" />
          </Button>
        )}
      </Flex>

      <Typography tag="p" bold nomargin mb="4">
        {t("common.socialLinks")}
      </Typography>
      <Typography tag="p" nomargin color="rgba(24, 31, 56, 0.6)">
        {t("common.socialDesc")}
      </Typography>
      <InputFieldWithLabel
        label={false}
        name="linkedinUrl"
        value={branding.linkedinUrl || ""}
        tiny
        placeholder="https://www.linkedin.com/company/hitalento"
        onChange={(e) => {
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: e.target.name, value: e.target.value },
          });
        }}
      />
      <InputFieldWithLabel
        label={false}
        name="facebookUrl"
        value={branding.facebookUrl || ""}
        tiny
        placeholder="https://www.facebook.com/hitalento"
        onChange={(e) => {
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: e.target.name, value: e.target.value },
          });
        }}
      />
      <InputFieldWithLabel
        label={false}
        name="instagramUrl"
        value={branding.instagramUrl || ""}
        tiny
        placeholder="https://instagram.com/hitalento"
        onChange={(e) => {
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: e.target.name, value: e.target.value },
          });
        }}
      />
      <InputFieldWithLabel
        pb="24"
        mt="15"
        label={false}
        name="twitterUrl"
        value={branding.twitterUrl || ""}
        tiny
        placeholder="https://twitter.com/hitalento"
        onChange={(e) => {
          brandingDispatch({
            type: "update_branding_single_state",
            payload: { name: e.target.name, value: e.target.value },
          });
        }}
      />
      {Object.keys(sites).length < 2 && (
        <>
          <Typography tag="p" bold nomargin mb="4">
            {t("component.language.language")}
          </Typography>
          <Typography tag="p" nomargin mb="15" color="rgba(24, 31, 56, 0.6)">
            {t("common.selectCPlanguage")}
          </Typography>
          <LanguageSelect
            style={{ visibility: "hidden" }}
            name="language"
            cp
            onChange={handleChange}
            // disabled={Object.keys(sites).length >= 2}
            // value={language || i18n.language}
            value={
              language === "en"
                ? t("component.language.en-US", "English")
                : t("component.language.da-DK", "Danish")
            }
          />
        </>
      )}
    </StyledBrandingSettings>
  );
});

export default BrandingSettings;
