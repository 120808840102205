import styled from "styled-components/macro";

export const AllPositionsAccordion = styled.div`
  max-height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : '0')};
  overflow: hidden;
  /* transition: max-height 1s ease; */
`;

export const AllPositionsAccordionHeader = styled.div`
display: flex; 
justify-content: space-between;
padding-right: 20px; 
height: 42px; 
box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
font-size: 12px;
font-weight: 500;
color: ${props => props.theme.colors.faceliftBlue};
background-color: ${props => props.theme.colors.almostWhite}; 
padding-left: 20px; 
display: flex; 
align-items: center;
position: relative; 
z-index: 0; 
cursor: pointer;
${props => props.first ? `
margin-top: 56px; 
@media (min-width: 992px) {
    margin-top: 40px; 
}
` : null}
${props => !props.hasActivePositions && `
margin-top: 0; 
`}
`