import styled from "styled-components/macro";


export const SingleStar = styled.div`
  padding: 0px 3px;
  z-index: 9999; 
  position: relative;
  svg {
    fill: ${(props) =>
      props.isOn
        ? props.theme.colors.red
        : 'rgba(255, 56, 92, 0.25)'};
          transition: .5s ease;
  }
`;

export const KanbanStars = styled.div`
  height: 12px;
  background-color: transparent;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: -3px;
  z-index: 98; 
  position: relative;
  span {
    display: flex;
    width: 106px;
    z-index: 2;
  }
  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    width: 98px;
    height: 2px;
    top: 14px;
    left: 10px;
    background-color: white;
  }
`;