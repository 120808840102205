import React, {useEffect, useState} from 'react';
import { StyledMainContainer } from "./style";

const MainContainer = ( { children }) => {

    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
      function handleResize() {
        setHeight(window.innerHeight);
      }
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <StyledMainContainer height={height}>
            { children }
        </StyledMainContainer>
    )
}

export default MainContainer
