import axios from 'axios';
import { getShortlistToken } from '../../utils/localSave';
import { parseRequest } from '../utilities/queries';
import {
  errorResponseHandler,
  responseHandler,
} from '../utilities/response';

export const getBasicOrganizationInfo = async (request = {}) => {
  const token = getShortlistToken();
  const { selector } = parseRequest(request);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const data = await axios.get(
      `/share/organization/${selector ? selector : ''}`,
      config
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getBasicShareInfo = async (request = {}) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.get(
      `/share/public/${selector ? selector : ''}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getShares = async (request = {}) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.get(
      `/share/position/${selector ? selector : ''}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const createShare = async (request = {}) => {
  const { selector, body } = parseRequest(request);
  try {
    const data = await axios.post(
      `/share/${selector ? selector : ''}`,
      body
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deleteShare = async (request = {}) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.delete(
      `/share/${selector ? selector : ''}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const authShare = async (request = {}) => {
  const { params, selector } = parseRequest(request);
  try {
    const data = await axios.get(
      `/share/authorize/${selector}${params ? '/' + params : ''}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getShareApplications = async (request = {}) => {
  //config header for authenticated request
  const token = getShortlistToken();
  const { selector, params } = parseRequest(request);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const data = await axios.get(
    `/share/${selector ? selector : ''}${params && params}`,
    config
  );
  return responseHandler(data);
};

export const submitApplicationViewStat = async (request = {}) => {
  const token = getShortlistToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { selector, applicationId, body } = parseRequest(request);
  try {
    const data = await axios.put(
      `/share/${selector}/${applicationId}`,
      body,
      config
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

