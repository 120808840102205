import styled from 'styled-components/macro';

export const StyledTopBar = styled.nav`
  height: 77px;
  top: 0;
  left: 0;
  position: sticky;
  z-index: 1;
  /* background-color: ${(props) => props.theme.colors.faceliftBlue}; */
  background-color: ${(props) => (props.theme.colors.faceliftBlue ? props.theme.colors.faceliftBlue : 'rgb(24, 31, 56)')};
  margin-bottom: 36px;
  width: 100%;
  ${props => props.manageShareholders && `
  z-index: 99999; 
  `}
`;
