import styled from "styled-components/macro";

export const ViewStatsWrapper = styled.div`
height: 100vh;
width: calc(100% - 400px); 
background-color: #F3F3F5; 
${props => props.isHidden ? `display: none` : null}; 
@media (min-width: 1480px) {
    width: calc(100% - 480px); 
}
@media (max-width: 992px) {
    display: none; 
}
`

export const BlockWrapper = styled.div`
height: calc(100vh - 110px); 
opacity: 0; 
display: flex; 
align-items: center;
flex-direction: column;
justify-content: center;
animation: fadeIn 1s ease forwards;
animation-delay: .3s;
@keyframes fadeIn {
    0% {
        opacity: 0; 
    }
    100% {
        opacity: 1; 
    }
}
`

export const LargeBlockContainer = styled.div`
display: flex;
gap: 20px;
`

export const SmallBlockContainer = styled.div`
margin-top: 20px; 
@media (min-width: 1600px) {
    margin-top: 60px; 
}
`

export const LargeBlockStyled = styled.div`
border-radius: 10px; 
height: 100px; 
width: 175px; 
background-color: rgba(24, 31, 56, 0.06); 
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
@media (min-width: 1600px) {
    height: 170px; 
width: 235px; 
}
`

export const SmallerBlockStyled = styled.div`
display: flex;
    width: 250px;
    background-color: rgba(24, 31, 56, 0.06); 
    border-radius: 10px; 
    height: 50px; 
    align-items: center;
    margin-bottom: 20px;
    @media (min-width: 1600px) {
        height: 60px; 
        width: 400px; 
    }
`

export const SmallerBlockLeft = styled.div`
width: 60px; 
display: flex;
justify-content: center;
position: relative;
&::before {
    content: ''; 
    position: absolute;
    width: 1px; 
    height: 20px; 
    background-color: rgba(24, 31, 56, 1); 
    right: 0; 
}
@media (min-width: 1600px) {
    width: 120px; 
}
`

export const SmallerBlockRight = styled.div`
padding-left: 20px; 
@media (min-width: 1600px) {
    padding-left: 60px; 
}
`