// React
import React, { useState, useEffect, useContext } from "react";

// Utility functions, hooks, and API endpoints
import { updateApplication } from "../../../../api/endpoints/application";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useApplicationActions from "../../../hooks/useApplicationActions";

// Components
import { FileField, BoxLoader } from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { CETextField } from "../../../../components/Input/Input";
import { Col, Row } from "../../../../components/Grid";
import { initialField } from "../../../../components/FileField/reducer";
import { Icon } from "../../../../components";

// Styled components
import {
  AddNotesContainer,
  Notes,
  ButtonGroupContainer,
} from "./UpdateApplication.styled";
import { Typography } from "../../../../components/Typography";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { DispatchMessageContext } from "../../../../context/MessageContext";
const UpdateApplication = ({
  single,
  handleActionClick,
  updateSingleInParent,
}) => {
  const { t } = useTranslation();
  const [summary, setSummary] = useState(single.recruiterSummary);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [savingSummary, setSavingSummary] = useState(false);
  const messageDispatch = useContext(DispatchMessageContext);
  const errorDispatch = useContext(DispatchErrorContext);

  // useEffect(() => {
  //   console.log("summary: ", summary);
  // }, [summary]);

  const { loading: applicationActionLoading, handleActionTaken } =
    useApplicationActions(
      "set_substatus",
      () => {},
      [single], // You may need to adjust this dependency array
      {},
      true
    );

  // to invalidatequery after update
  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries(["single_application", single.id]);
  };

  const handleChange = (e) => {
    setSummary(e.target.textContent);
  };

  useEffect(() => {
    if (single.recruiterSummary) setSummary(single.recruiterSummary);
  }, [single]);

  // SAVE RECRUITER SUMMARY
  const submitSummary = async () => {
    try {
      // Get the value of the summary element instead of the state, because the state can be invalidated on file upload (because of the invalidateQuery makes the "single" reload from DB and the useEffect on "single" makes the summary to be set to the state)
      const summaryElement = document.querySelector(
        '[name="recruiterSummary"]'
      );
      // Get the text content of the summary element
      const summary = summaryElement ? summaryElement.textContent : "";

      setSavingSummary(true);
      const request = {
        selector: single.id,
        body: { recruiterSummary: summary },
      };

      const response = await updateApplication(request);
      if (response.success) {
        //saved success

        // Update state on single.recruiterSummary to the new value of summary
        const checkIfGoToNext = false;
        updateSingleInParent({ ...single, recruiterSummary: summary }, checkIfGoToNext);

        invalidateQuery();
        messageDispatch({
          type: "set_message",
          payload: t("common.summarySuccessfullyUpdated"),
        });
      }

      // Error handling
      if (!response.success) {
        throw new Error(response.data?.data);
      }

      setSavingSummary(false);
    } catch (err) {
      setSavingSummary(false);

      // Handle error
      errorDispatch({ type: "set_error", payload: err.message });
    }
  };

  // SHORT LIST APPLICATION
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const newSubstatus =
        single.substatus === "shortlisted" ? "applied" : "shortlisted";

      // Use handleActionTaken to update the substatus
      await handleActionTaken({ substatus: newSubstatus });

      const request = {
        selector: single.id,
        body: { substatus: newSubstatus },
      };
      // Assuming you have an 'updateApplication' function that makes the API call
      const response = await updateApplication(request);
      if (response.success) {
        // Update the substatus in the parent component through the callback function
        const checkIfGoToNext = true;
        updateSingleInParent({ ...single, substatus: newSubstatus }, checkIfGoToNext);
        invalidateQuery();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //Save file to application
  const handleUpload = async (fileObject) => {
    try {
      setLoading(true);
      const request = {
        selector: single.id,
        body: { addFiles: [fileObject.uuid] },
      };
      await updateApplication(request);
      invalidateQuery();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //Remove file from application
  const handleRemove = async (fileObject) => {
    try {
      setLoading(true);
      const request = {
        selector: single.id,
        body: { removeFiles: [fileObject.uuid] },
      };
      await updateApplication(request);
      invalidateQuery();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //Update file handler state
  useEffect(() => {
    if (single.files) {
      setUploadedFiles(
        single?.files
          .filter((file) => file.docType === "file")
          .map((file, index) => {
            return {
              ...initialField,
              id: index,
              hasFile: true,
              savedFile: file,
            };
          })
      );
    }
  }, [single]);

  return (
    <>
      <AddNotesContainer>
        <Notes>
          <CETextField
            name="recruiterSummary"
            // trackValue={summary}
            // value={summary}
            onInput={handleChange}
            placeholder={t("common.addNotes", "Add notes...")}
            text={single.recruiterSummary}
            noBorder
            padding="0 0 10px 0;"
            contentEditable
            pbMD="20"
            notes
          />
          <Row mt={40}>
            <Col vCenter>
              <FileField
                docType="file"
                type="document"
                notes
                onUpload={handleUpload}
                onRemove={handleRemove}
                initialFiles={uploadedFiles}
                attachment={true}
              />
            </Col>
            <Col right vCenter>
              <PrimaryButton white onClick={submitSummary}>
                {savingSummary ? (
                  <BoxLoader tiny></BoxLoader>
                ) : (
                  <>
                    {t("common.save", "Save")}
                    <Icon icon="save" invert lowOpacity mr="8" />
                  </>
                )}
              </PrimaryButton>
            </Col>
          </Row>
        </Notes>
        <ButtonGroupContainer>
          <Col mlMD="0" mrMD="0">
            <PrimaryButton
              tertiary
              transparent
              onClick={() => {
                handleActionClick("delete");
              }}
            >
              {t("common.deleteCandidate", "Delete")}
              <Icon mr="10" icon="trash" />
            </PrimaryButton>
          </Col>
          <Col mlMD="0" mrMD="0" right>
            <PrimaryButton
              onClick={() => {
                handleActionClick("reject");
              }}
              tertiary
              white
              mr={single.status !== "invited" ? "20" : "0"}
              disabled={single.status !== "submitted"}
            >
              {t(
                "component.card.application.confirm_reject_button_label",
                "Send rejection"
              )}
              <Icon mr="10" icon="circle_cross" />
            </PrimaryButton>
            {single.status !== "invited" && (
              <PrimaryButton
                red
                tertiary
                onClick={handleClick}
                disabled={loading}
              >
                {single.substatus === "shortlisted" ? (
                  <>
                    {t("common.shortlisted")}
                    <Icon mr="10" icon="minus" />
                  </>
                ) : (
                  <>
                    {t("common.shortlist")}
                    <Icon mr="10" icon="thick_plus" />
                  </>
                )}
              </PrimaryButton>
            )}
          </Col>
        </ButtonGroupContainer>
      </AddNotesContainer>
    </>
  );
};

export default UpdateApplication;
