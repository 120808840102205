import styled from "styled-components/macro";

export const StyledQuill = styled.div`
  .ql-editor {
    min-height: 200px; 
    display: inline-block;
    width: 100%; 
    padding: 25px 20px; 
    strong {
      font-size: 14px; 
    }
    p {
      font-size: 14px; 
      line-height: 20px; 
      color: ${props => props.theme.colors.faceliftBlue}; 
    }
}
.ql-container {
    border-radius: 0 0 5px 5px;
    border-top: 0; 
    border: 1px solid rgba(24, 31, 56, 0.15); 
}
.ql-toolbar {
    padding: 16px 20px;
    background-color: ${props=>props.theme.colors.white};
    border-radius: 5px 5px 0 0;
  }
  .ql-dynamicFields:before {
    content: 'Dynamic fields';
  }

  .ql-dynamicFields {
    padding-left: 6px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .ql-dynamicFields .ql-picker-options {
    left: 0;
  }

  .ql-dynamicFields .ql-picker-label {
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% + 35px);
    font-size: 10px;
  }

  .ql-picker-label {
    padding-left: 0; 
  }

  .ql-dynamicFields.ql-expanded {
      color: #ccc;
      .ql-picker-options {
        color: black;
      }
  }
  .ql-dynamicFields .ql-picker-item:before {
  content: attr(data-label);
}
.ql-toolbar.ql-snow {
  border: 1px solid rgba(24, 31, 56, 0.15); 
}
.ql-blank {
  width: 100%; 
}
`;
