// React
import React, { useContext, useState, useEffect, memo } from 'react';

// Hooks, translations, and API endpoints
import { useTranslation } from 'react-i18next';
import {
  getPositions,
  updatePosition,
} from '../../../api/endpoints/position';

// Components
import { Button, Modal } from '../../../components';
import { Col, Row } from '../../../components/Grid';
import { Typography } from '../../../components/Typography';
import { PrimaryButton } from '../../../components/Button';
import QuillEditor from '../cp/SinglePage/QuillEditor/QuillEditor';
import {Icon} from '../../../components';

// State management
import { DispatchMessageContext } from '../../../context/MessageContext';
import { APPLICATION_REJECTION_TEMPLATE } from './emailTemplate';
import ButtonLoading from '../../../components/Loader/ButtonLoading';
import { StatePositionContext } from '../../context/PositionContext';

const EditRejectionEmail = memo(({ positionId, open, onClose }) => {
  const { t } = useTranslation();
  const messageDispatch = useContext(DispatchMessageContext);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); 
  const [body, setBody] = useState('');
  const [language, setLanguage] = useState('en-US');
  const [title, setTitle] = useState('');

  const { single } = useContext(
    StatePositionContext
  );

  useEffect(() => {
    /**
     * Fetch position by id
     */
    const fetchPosition = async () => {
      try {
        setLoading(true);
        const request = { selector: positionId };
        const response = await getPositions(request);
        setLoading(false);
        if (!response.success)
          throw new Error('Error getting position data!');
        setBody(response.data.rejectionEmailBody);
        setLanguage(response.data.positionLanguage);
        setTitle(response.data.title);
      } catch (err) {
        console.log(err);
      }
    };
    if (open) {
      fetchPosition();
    }
  }, [positionId, open]);

  useEffect(() => {
    setLanguage(single.positionLanguage);
  }, [single.positionLanguage])


  const handleSave = async () => {
    try {
      const request = {
        body: { rejectionEmailBody: body },
        selector: positionId,
      };
      // console.log('saving');
      setLoading(true);
      const response = await updatePosition(request);
      // console.log(response, 'Saved success');
      setLoading(false);
      setIsLoaded(true);
      if (!response.success) {
        throw Error;
      }
    } catch (err) {
      console.log(
        'Error updating rejection email text! :',
        err.message
      );
    }
  };

  return (
    <>
    <ButtonLoading loading={loading} success={isLoaded} fw/>
    <div>
      {open && (
        <Modal noBorder={true} onClose={onClose} title={t("component.metaeditor.edit_rejection_email")}>
          <Row hCenter>
              <Col>
                <QuillEditor
                  hasDynamicFields={true}
                  description={t('common.editRejectionDescription')}
                  subDescription={t('common.editRejectionDynamicFields')}
                  title={title}
                  value={
                    /**
                     * Insert custom email body if present on data source.
                     * Else use template in language if language has template
                     * else default to en US!
                     */
                    body && body !== ''
                      ? body
                      : APPLICATION_REJECTION_TEMPLATE.hasOwnProperty(
                          language
                        )
                      ? APPLICATION_REJECTION_TEMPLATE[language]
                      : APPLICATION_REJECTION_TEMPLATE['en-US']
                  }
                  changeHandler={(data) => {
                    setBody(data);
                  }}
                />
                <PrimaryButton
                transparent
                alignRight
                  style={{ marginTop: 20 }}
                  onClick={() =>
                    setBody(
                      APPLICATION_REJECTION_TEMPLATE.hasOwnProperty(
                        language
                      )
                        ? APPLICATION_REJECTION_TEMPLATE[language]
                        : APPLICATION_REJECTION_TEMPLATE['en-US']
                    )
                  }
                  tertiary
                  buttonColorDark
                >
                  {t('common.switchToDefault', 'Switch to default')}
                  <Icon icon="update" lightInverted mr="10" />
                </PrimaryButton>
              </Col>
          </Row>
          <PrimaryButton red onClick={handleSave}>
                {t('common.saveRejection')}
                <Icon icon="save" mr="10" />
              </PrimaryButton>
              <PrimaryButton
                onClick={onClose}
                transparent
                mt="18"
                mb="25"
              >
                {t('common.close')}
                <Icon icon="cross" mr="10" />
              </PrimaryButton>
        </Modal>
      )}
    </div>
    </>
  );
});

export default EditRejectionEmail;
