import styled from 'styled-components/macro';

export const StyledTopBar = styled.div`
background-color: white; 
${props => props.position && `
background-color: transparent;
`}
`;

export const TopBarTop = styled.div`
width: 100%; 
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid ${(props) => props.theme.colors.mgrey};
height: 70px;
min-height: 70px; 
padding-left: 16px; 
background-color: ${props => props.theme.colors.white};
`

export const TopBarBottom = styled.div`
height: 56px; 
width: 100%; 
border-bottom: 1px solid ${(props) => props.theme.colors.mgrey};
background-color: ${props => props.theme.colors.white}; 
position: relative; 

@media (min-width: 992px) {
  height: 40px;
  min-height: 40px; 
}
`

export const TopBarBottomContent = styled.div`
padding: 0 16px; 
display: flex; 
justify-content: space-between;
align-items: center;
height: 100%; 
@media (min-width: 1200px) {
  padding: 20px; 
  ${props => props.page && `
  padding: 0 20px; 
  `}
}
`

export const StyledAccountMenu = styled.div`
@media (max-width: 992px) {
  display: none;
}
  padding-left: 16px;
  margin-left: 16px;
  position: absolute;
  top: 26px; 
  right: 25px;
  width: 240px; 
  z-index: 999999999;
  pointer-events: all;
  & > div {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    padding-bottom: 10px;
    cursor: pointer;
    z-index: 999;
    &:hover {
      ul {
          opacity: 1; 
          transition: .4s ease; 
          pointer-events: all; 
          li {
            opacity: 1; 
            transition: 1s ease; 
          }
      }
    }
    & > div:first-child {
      margin-right: 10px;
    }
  }
  ul {
    display: block;
    opacity: 0; 
    transition: .4s ease; 
    pointer-events: none; 
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%; 
    background-color: ${(props) => props.theme.colors.almostWhite};
    border: 1px solid rgba(24, 31, 56, 0.15);
    border-radius: 5px; 
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    li {
      opacity: 0; 
      transition: 1s ease; 
      a {
        padding: 10px 30px 10px 20px;
        height: 40px; 
        display: flex;
        align-items: center;
        transition: .5s ease;
        border-bottom: 1px solid rgba(24, 31, 56, 0.15);
        &:hover {
          background-color: ${props => props.theme.colors.lightGray};
          transition: .5s ease;
        }
      }
      button {
        font-size: 14px;
    line-height: 16px;
    font-weight: 600 !important;
    text-align: left;
    margin-bottom: 0px;
    color: #181F38;
    white-space: normal;
    height: 40px; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0; 
    padding: 10px 30px 10px 20px;
    transition: .5s ease;
    width: 100%; 
        &:hover {
          background-color: ${props => props.theme.colors.lightGray};
          transition: .5s ease;
        }
      }
    }
  }
`;
