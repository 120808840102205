// Styled
import styled from "styled-components/macro";

// Assets
import dropdownArrow from '../../../assets/icons/dropdown_down.svg';
export const StyledMainContent = styled.div`
  max-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1;
  transform: translateZ(0);

   ${props => props.position && `transform: unset;`}
  @media (min-width: 992px) {
    display: flex; 
  }

/* Dropdown styling */
.Settingsstyled__SettingsMainWrapper-sc-87jvq7-0, .jACDEC {
  .style__StyledSelectLanguage-sc-1q4zk3j-0, .hWYhVo {
    @media (min-width: 569px) {
      width: calc(100% - 22px); 
    }
  }
}
.Dropdown-control {
padding: 0; 
border: none; 
}
.Dropdown-arrow-wrapper {
  display: none; 
}
.Dropdown-root {
  cursor: pointer;
  position: relative; 
  width: 100%; 
  border: none !important;
  &::after {
    display: none;
  }
  &.is-open {
    .Dropdown-placeholder::before {
      transform: rotate(180deg);
      transition: .5s ease;
    }
  }

  .Dropdown-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.colors.faceliftBlueLight};
    width: 100%;
    border: 1px solid ${props => props.theme.colors.faceliftBlueLighter};
    border-radius: 5px;
    background-color: white;
    padding: 12px 20px;
    position: relative;
    cursor: pointer; 
    &::before {
      content: '';
      position: absolute;
      background-image: url(${dropdownArrow});
      width: 9px;
      height: 5px;
      right: 20px;
      top: calc(50% - 2.5px);
      background-size: contain;
      transition: .5s ease;
    }
  }

  @keyframes dropdownFadeIn {
    from {
      max-height: 0;
      transform: scaleY(0);
    }
    to {
      max-height: 184px;
      transform: scaleY(1);
    }
  }

  &.is-open {
    .Dropdown-menu {
      animation-name: dropdownFadeIn;
      animation-duration: .3s;
      transform-origin: top;
      position: absolute;
      width: 100%; 
      background-color: white; 
      max-height: 184px; 
      overflow: scroll; 
      z-index: 999; 
    }
  }

  &:not(.is-open) {
    .Dropdown-menu {
      animation-name: dropdownFadeOut;
      animation-duration: .3s;
      transform-origin: top;
    }
  }

  .Dropdown-menu {
    border: 1px solid ${props => props.theme.colors.faceliftBlueLighter};
    box-shadow: 0 3px 6px ${props => props.theme.colors.faceliftBlueLighter};
    border-radius: 5px;
    margin-top: 6px;

    .Dropdown-option {
      padding: 12px 20px;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      transition: .5s ease;
      color: ${props => props.theme.colors.faceliftBlue};
      border-bottom: 1px solid ${props => props.theme.colors.faceliftBlueLighter};

      &:last-of-type {
        border-bottom: 0;
      }

      &:hover {
        background-color: ${props => props.theme.colors.lightGray};
        transition: .5s ease;
      }
    }
  }
}

`;
