import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { StateUserContext } from '../../context/UserContext';
import { getBranding } from '../../api/endpoints/organization';
import { getSite } from '../../api/endpoints/cp';
import { SubscriptionContext } from './SubscriptionContext';

export const StateBrandingContext = createContext();
export const DispatchBrandingContext = createContext();

const brandingReducer = (state, action) => {
  switch (action.type) {
    case 'init_branding': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'branding_loaded': {
      return {
        ...state,
        isLoading: false,
        branding: action.payload,
      };
    }
    case 'update_branding_single_state': {
      const { name, value } = action.payload;
      return {
        ...state,
        unsaved: true,
        branding: {
          ...state.branding,
          [name]: value,
        },
      };
    }

    case 'site_update_single_state': {
      const { name, value } = action.payload;

      return {
        ...state,
        unsaved: true,
        branding: {
          ...state.branding,
          mainSite: {
            ...state.branding.mainSite,
            [name]: value,
          },
        },
      };
    }

    case 'update_branding': {
      return {
        ...state,
        unsaved: false,
        isLoading: false,
        branding: action.payload,
      };
    }
    case 'reload': {
      return {
        ...state,
        unsaved: false,
        reload: !state.reload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const BrandingProvider = ({ children }) => {
  //only load branding if authenticated
  const { isAuthenticated } = useContext(StateUserContext);

  const brandingInitialState = {
    branding: {},
    isLoading: false,
    reload: false,
    unsaved: false,
  };
  const [state, dispatch] = useReducer(
    brandingReducer,
    brandingInitialState
  );

  const {
    state: { privileges },
  } = useContext(SubscriptionContext);

  //load organization
  useEffect(() => {
    const initBranding = async () => {
      dispatch({ type: 'init_branding' });
      try {
        const branding = await getBranding();
        const mainSite =
          privileges.privileges.career_page > 0
            ? await getSite({ params: { main: true } })
            : null;

        if (branding.success) {
          dispatch({
            type: 'branding_loaded',
            payload: {
              ...branding.data,
              mainSite: mainSite?.success ? mainSite?.data[0] : null,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated) {
      initBranding();
    }
  }, [privileges, isAuthenticated, state.reload]);

  return (
    <DispatchBrandingContext.Provider value={dispatch}>
      <StateBrandingContext.Provider value={state}>
        {children}
      </StateBrandingContext.Provider>
    </DispatchBrandingContext.Provider>
  );
};
