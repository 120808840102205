import styled from "styled-components/macro";

export const StyledHorizontalUl = styled.ul`
    list-style: none;
    margin : 0 0 0 0;
    li {
        display: inline-block;
        margin-right: 50px;
        position: relative;
        &:after { 
            content : "";
            height: 15px;
            position: absolute; right: -25px ; top: 50%; transform: translate(0%, -50%);
            width: 1px;
            background: ${props=>props.theme.colors.mgrey};
        }
        &:last-child { 
            &:after { 
                display: none;
            }
        }
        a { 
            color: ${props=>props.theme.colors.dgrey};
            font-size: 18px;
            &.active {
                color: ${props=>props.theme.colors.highlight};
            }
        }
    }
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        li { margin-right: 24px; &:after { right: -12px; } }
    }
`;