// React
import React, { useContext, useEffect, useState } from "react";

// Api endpoints
import { getRecruiters } from "../../../../api/endpoints/recruiter";

// Translation
import { useTranslation } from "react-i18next";

// Components
import UsersCard from "./UsersCard/UsersCard";
import { PrimaryButton } from "../../../../components/Button";
import { AdministrationTopBar } from "../Administration.styled";
import { Icon } from "../../../../components";
import { Modal } from "../../../../components";
import AddRecruiter from "../../Modals/AddRecruiter/AddRecruiter";

// Styled components
import {
  ManageUsersDescription,
  ManageUsersWrapper,
  NeedHelpWrapper,
  Scrollable,
} from "./ManageUsers.styled";
import { Flex } from "../../../style";
import {
  StyledTable,
  TableHeader,
  TableHeaderElements,
} from "./UsersCard/UsersCard.styled";

// Context
import {
  DispatchOrganizationContext,
  StateOrganizationContext,
} from "../../../context/OrganizationContext";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import {
  StateAdministrationContext,
  DispatchAdministrationContext,
} from "../../../context/AdministrationContext";
import { Typography } from "../../../../components/Typography";
import { Grid } from "../../../../components/Grid";

const ManageUsers = () => {
  const { t } = useTranslation();
  const { users } = useContext(StateOrganizationContext);
  const organizationDispatch = useContext(DispatchOrganizationContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const { modalConfirmation } = useContext(StateAdministrationContext);
  const administrationDispatch = useContext(DispatchAdministrationContext);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  // Modal State
  const [currentModal, setCurrentModal] = useState(null);

  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        organizationDispatch({ type: "users_init" });
        const response = await getRecruiters();
        if (response.success) {
          organizationDispatch({
            type: "loaded_users",
            payload: response.data,
          });
        } else {
          errorDispatch({ type: "set_error", payload: response.data });
        }
      } catch (err) {
        errorDispatch({ type: "set_error", payload: err });
      }
    };
    fetchRecruiters();
    // console.log('refetched');
  }, [errorDispatch, organizationDispatch, modalConfirmation, fetchTrigger]);

  const owners = Array.isArray(users)
    ? users.filter((user) => user.role === "owner")
    : [];
  const otherUsers = Array.isArray(users)
    ? users.filter((user) => user.role !== "owner")
    : [];

  // Modal functions
  const openModal = (modalName) => {
    setCurrentModal(modalName);
  };

  const closeModal = () => {
    setCurrentModal(null);
    administrationDispatch({ type: "modal_confirmation", payload: false });
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  return (
    <Scrollable>
      <AdministrationTopBar>
        <PrimaryButton red onClick={() => openModal("AddRecruiterModal")}>
          {t("page.invite_recruiter.add_recruiter", "Add recruiter")}
          <Icon icon="add_recruiter" mr="10" />
        </PrimaryButton>
      </AdministrationTopBar>
      <ManageUsersWrapper>
        <Flex flexDirection="column" flexDirectionLG="row" align="flex-start">
          <ManageUsersDescription>
            <h2>{t("common.admin", "Admin")}</h2>
            <p>
              {t("common.adminDescription_1")}
              <br />
              <br />
              {t("common.adminDescription_2")}
            </p>
          </ManageUsersDescription>
          <StyledTable>
            <TableHeader>
              <TableHeaderElements>
                <div>
                  <h2>{t("common.name", "Name")}</h2>
                </div>
                <Flex width="100%" justify="flex-end">
                  <p>{t("common.dateAdded", "Date added")}</p>
                  <p>{t("common.lastActive", "Last active")}</p>
                </Flex>
              </TableHeaderElements>
            </TableHeader>
            {owners.map((user) => (
              <UsersCard key={user.id} users={user} />
            ))}
          </StyledTable>
        </Flex>
        <Flex flexDirection="column" flexDirectionLG="row" align="flex-start">
          <ManageUsersDescription>
            <h2>{t("common.recruiter.plural")}</h2>
            <p>
              {t("common.recruiterDescription_1")}
              <br />
              <br />
              {t("common.recruiterDescription_2")}
            </p>
          </ManageUsersDescription>
          <StyledTable>
            <TableHeader>
              <TableHeaderElements>
                <div>
                  <h2>{t("common.name", "Name")}</h2>
                </div>
                <Flex width="100%" justify="flex-end">
                  <p>{t("common.dateAdded", "Date added")}</p>
                  <p>{t("common.lastActive", "Last active")}</p>
                </Flex>
              </TableHeaderElements>
            </TableHeader>

            {otherUsers.map((user) => (
              <UsersCard
                key={user.id}
                users={user}
                setFetchTrigger={setFetchTrigger}
              />
            ))}
          </StyledTable>
        </Flex>
        <NeedHelpWrapper>
          <Icon icon="info" mr="10" />
          <a href="https://hitalento.com/contact-us/" target="_blank">
            <Typography tag="p" large nomargin>
              {t("common.needHelp")}
            </Typography>
          </a>
        </NeedHelpWrapper>
      </ManageUsersWrapper>
      {currentModal === "AddRecruiterModal" && modalConfirmation == false && (
        <Modal
          title={t("page.invite_recruiter.add_recruiter")}
          onClose={closeModal}
        >
          <AddRecruiter
            onClose={closeModal}
            setFetchTrigger={setFetchTrigger}
          />
        </Modal>
      )}
      {modalConfirmation && (
        <Modal title={t("common.recruiterAdded")} onClose={closeModal}>
          <Grid padding={25} fluid>
            <Typography tag="p" center>
              {t("common.recruiterAddedDesc_1")}
            </Typography>
            <Typography tag="p" mb="25" center>
              {t("common.recruiterAddedDesc_2")}
            </Typography>
            <PrimaryButton
              onClick={() =>
                administrationDispatch({
                  type: "modal_confirmation",
                  payload: false,
                })
              }
              center
              red
            >
              {t("common.great")}
              <Icon icon="thumbs_up" mr="10" />
            </PrimaryButton>
          </Grid>
        </Modal>
      )}
    </Scrollable>
  );
};

export default ManageUsers;
