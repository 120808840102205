import React, { useState, useContext, useEffect } from 'react';
import { getTags } from '../../../../../../api/endpoints/cp';
import {
  BoxLoader,
  InputFieldWithLabel,
} from '../../../../../../components';
import { PageContext } from '../../../../../context/PageContext';
import {
  StyledTagContainer,
  StyledSuggestionContainer,
} from './style';
import Suggestion from './Suggestion';
import Tag from './Tag';

const TagField = ({ label, byline, tagType, placeholder }) => {
  const { state, dispatch } = useContext(PageContext);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const addTag = (tagName) => {
    dispatch({
      type: 'add_tag',
      payload: { tagName: tagName, type: tagType },
    });
    setValue('');
    setSuggestions([]);
  };

  //Search when value is greater than len 2
  useEffect(() => {
    const handleSearch = async () => {
      try {
        setIsloading(true);
        const request = { params: { s: value, tagType: tagType } };

        const response = await getTags(request);
        if (response.success) {
          setSuggestions(response.data);
          setIsloading(false);
        }
      } catch (err) {
        //display error
      }
    };
    if (value.length > 1) {
      handleSearch();
    }
  }, [value, tagType]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (value.length > 0) {
        addTag(value, tagType);
      }
    }
  };
  const handleBlur = (e) => {
    if (e.target.value !== '') {
      // addTag(e.target.value, tagType);
    }
  };

  return (
    <div>
      <InputFieldWithLabel
        name="addTags"
        autoComplete="off"
        tiny
        placeholder={placeholder}
        label={label}
        byline={byline}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
      />
      {value.length > 1 && (
        <StyledSuggestionContainer>
          {isLoading && <BoxLoader tiny />}
          {!isLoading &&
            suggestions.map((suggestion) => (
              <Suggestion
                suggestion={suggestion}
                onAdd={() => addTag(suggestion.tagName, tagType)}
              />
            ))}
        </StyledSuggestionContainer>
      )}

      <StyledTagContainer>
        {state.page?.tags
          ?.filter((tag) => tag.type === tagType)
          .map((tag) => (
            <Tag
              key={tag.id}
              tag={tag}
              onRemove={() =>
                dispatch({
                  type: 'remove_tag',
                  payload: {
                    tagId: tag.id,
                    tagName: tag.tagName,
                    tagType: tagType,
                  },
                })
              }
            />
          ))}
      </StyledTagContainer>
    </div>
  );
};

export default TagField;
