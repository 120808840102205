import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components/macro';

const ApplicationDropAreaText = ({ actionKeys }) => {
  const [unlisting, setUnlisting] = useState(false);

  useEffect(() => {
    if (actionKeys.indexOf('unlist') > -1) {
      setUnlisting(true);
    }
  }, [actionKeys]);

  return (
    <StyledDropAreaText>
      {unlisting && (
        <span>
          <Trans i18nKey="component.droparea.remove_and">
            <strong>Remove</strong> from shortlist and
          </Trans>
        </span>
      )}
      <span>
        {actionKeys.indexOf('submitted') > -1 && (
          <Trans i18nKey="component.droparea.remove_from_list">
            remove from <strong>lists</strong>
          </Trans>
        )}
        {actionKeys.indexOf('shortlist') > -1 && (
          <Trans i18nKey="component.droparea.shortlist">
            <strong>shortlist</strong>
          </Trans>
        )}
        {actionKeys.indexOf('interview') > -1 && (
          <Trans i18nKey="component.droparea.interview">
            mark for <strong>interview</strong>
          </Trans>
        )}
        {actionKeys.indexOf('hired') > -1 && (
          <Trans i18nKey="component.droparea.hired">
            mark as <strong>hired</strong>
          </Trans>
        )}
        {actionKeys.indexOf('to_be_rejected') > -1 && (
          <Trans i18nKey="component.droparea.to_be_rejected">
            mark <strong>to be rejected</strong>
          </Trans>
        )}
        {actionKeys.indexOf('reject') > -1 && (
          <Trans i18nKey="component.droparea.reject">
            send <strong>rejection mail</strong> and mark as
            <strong> rejected</strong>
          </Trans>
        )}
      </span>
    </StyledDropAreaText>
  );
};

export default ApplicationDropAreaText;

const StyledDropAreaText = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.dgrey};
  span:first-child {
    display: block;
  }
  span:first-child:first-letter {
    text-transform: capitalize;
  }
`;
