// React
import React, { useState, useEffect, useContext } from 'react';

// Translations - Hooks - API endpoints
import { useTranslation } from 'react-i18next';
import {
  changeMainSubscription,
  getMainSubscriptionProducts,
} from '../../../../api/endpoints/subscription';
import { formatAmount } from '../../../../utils/various';

// Context
import { DispatchMessageContext } from '../../../../context/MessageContext';
import { DispatchUserContext } from '../../../../context/UserContext';
import { Trans } from 'react-i18next';

// Components
import {
  BoxLoader,
  // Button,
  Icon,
  PurchaseModal,
  SquareToggle,
} from '../../../../components';
import { Typography } from '../../../../components/Typography';
// import { StyledMainProductsContainer } from './style';
import { Grid } from '../../../../components/Grid';
import { PrimaryButton } from '../../../../components/Button';
import { RadioButton } from '../../../../components/Radio/RadioButton';
import { Flex } from '../../../style';

const ChangeSubscriptionForm = ({
  currentSubscription,
  onSubscriptionChange,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [chosenSubscription, setChosenSubscription] = useState(null);
  const messageDispatch = useContext(DispatchMessageContext);
  const userDispatch = useContext(DispatchUserContext);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await getMainSubscriptionProducts();
        if (!response || !response.success)
          throw new Error('Error getting products');

        // Sort options here...
        setOptions(
          response.data.sort((a, b) => {
            if (
              a.name === 'Pro' ||
              (a.name === 'Basic' && b.name !== 'Pro')
            ) {
              return -1;
            } else {
              return 1;
            }
          })
        );
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError(err.message);
      }
    })();
  }, []);

  /**
   * Open choose subscription modal, set the chosen subscription in state
   * @param {string} price
   */
  const chooseMainSubscription = (price) => {
    setChosenSubscription(price);
    setConfirmModalOpen(true);
  };

  /**
   * confirm purchase of new tier main subscription
   */
  const confirmMainSubscriptionChange = async () => {
    try {
      setConfirmModalOpen(false);
      setIsLoading(true);
      const response = await changeMainSubscription({
        params: { priceId: chosenSubscription.id },
      });
      if (!response || !response.success)
        throw new Error('Error changing main subscription');

      onSubscriptionChange();
      onClose();
      messageDispatch({
        type: 'set_message',
        payload: 'Subscription updated',
      });
      userDispatch({ type: 'reload' }); // this will reload main subscription and privileges
    } catch (err) {
      setError(err.message);
    }
  };

  const initialSelectedOption = currentSubscription?.product?.name === 'Pro' ? 'option2' : 'option1';
  const initialSelectedInterval = currentSubscription?.plan?.interval === 'month';

  // Store the initial selected package and interval
  const initialSelectedPackage = options.find(opt => initialSelectedOption === `option${opt.name === 'Pro' ? 2 : 1}`);
  const initialSelectedPriceInterval = initialSelectedInterval ? 'month' : 'year';
  const initialSelectedPrice = initialSelectedPackage?.prices.find(p => p.recurring?.interval === initialSelectedPriceInterval);

  // Selected states
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [selectedInterval, setSelectedInterval] = useState(initialSelectedInterval);
  const [selectedPrice, setSelectedPrice] = useState(initialSelectedPrice);


   // Fetch initial values after loading options
   useEffect(() => {
    if (options.length > 0) {
      const initialSelectedOption = currentSubscription?.product?.name === 'Pro' ? 'option2' : 'option1';
      const initialSelectedInterval = currentSubscription?.plan?.interval === 'month';

      setSelectedOption(initialSelectedOption);
      setSelectedInterval(initialSelectedInterval);

      const initialSelectedPackage = options.find(opt => initialSelectedOption === `option${opt.name === 'Pro' ? 2 : 1}`);
      const initialSelectedPriceInterval = initialSelectedInterval ? 'month' : 'year';
      const initialSelectedPrice = initialSelectedPackage?.prices.find(p => p.recurring?.interval === initialSelectedPriceInterval);

      setSelectedPrice(initialSelectedPrice);
    }
  }, [currentSubscription, options]);
  

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setSelectedPrice(null); // Reset selectedPrice when changing options

    // Find the selected package object based on the selectedOption
    const selectedPackage = options.find(
      (opt) => value === `option${opt.name === 'Pro' ? 2 : 1}`
    );

    // Find the price with the matching recurring interval
    const priceInterval = selectedInterval ? 'month' : 'year';
    const price = selectedPackage?.prices.find(
      (p) => p.recurring?.interval === priceInterval
    );

    if (price) {
      setSelectedPrice(price);
    }
  };

  const handleIntervalChange = (value) => {
    setSelectedInterval(value);
    setSelectedPrice(null); // Reset selectedPrice when changing interval

    // Find the selected package object based on the selectedOption
    const selectedPackage = options.find(
      (opt) => selectedOption === `option${opt.name === 'Pro' ? 2 : 1}`
    );

    // Find the price with the matching recurring interval
    const priceInterval = value ? 'month' : 'year';
    const price = selectedPackage?.prices.find(
      (p) => p.recurring?.interval === priceInterval
    );

    if (price) {
      setSelectedPrice(price);
    }
  };

  return (
   <>
      {error && <p>{error}</p>}
      {isLoading && <BoxLoader />}
      {!isLoading && !error && (
        <>
          {confirmModalOpen && chosenSubscription && (
            <PurchaseModal
              productTitle={chosenSubscription.nickname}
              type="changeSub"
              prices={[chosenSubscription]}
              onCancel={() => setConfirmModalOpen(false)}
              onConfirm={() => confirmMainSubscriptionChange()}
              title={t('common.changeSubConfirmation')}
            />
          )}
                <Grid padding="32" alignCenter borderBottom>
          <Typography tag="p" center nomargin>
           {t('common.changeSubMainDesc1')} <br /> <br />
           {t('common.changeSubMainDesc2')}
          </Typography>
          <Flex gap="40" justify="center" mt="25">
          <RadioButton
    value="option1"
    checked={selectedOption === 'option1'}
    onChange={handleOptionChange}
    text={options[1]?.name}
  />
  <RadioButton
    value="option2"
    checked={selectedOption === 'option2'}
    onChange={handleOptionChange}
    text={options[0]?.name}
  />
      </Flex>
          </Grid>
          <Grid padding="32" alignCenter borderBottom>
            <Typography tag="p">{t('common.billingPeriod')}:</Typography>
          <SquareToggle
              settings
              options={[
                {
                  text: t(
                    'common.monthly',
                    'Monthly'
                  ),
                  value: true,
                },
                {
                  text: t(
                    'common.yearly',
                    'Yearly'
                  ),
                  value: false,
                },
              ]}
              onChange={handleIntervalChange}
              on={selectedInterval}
            />
                      <div
                style={{
                  maxWidth: '485px',
                  marginTop: '25px'
                }}
              >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%', 
                }}
              >
                <span style={{ textTransform: 'capitalize', fontWeight: '500' }}>
                {selectedOption === 'option1' ? options[1]?.name : options[0]?.name} {t('common.subscription')}
                </span>
                    <>
                 
                    <span>
                    <span style={{fontWeight: '500'}}>
                      {formatAmount(
                        selectedPrice?.unit_amount, // Use selectedPrice instead of chosenSubscription
                         selectedPrice?.currency
                      )}</span>
                            /{selectedInterval ? t('common.mo') : t('common.yr')}
                      </span>
        
                      </>
              </div>
              <Typography tag="p" large width="100%" nomargin mt="10">
                <Trans i18nKey="component.purchase_modal.terms">
                  By making this purchase you are agreeing to our
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_TERMS}`}
                  >
                    terms and conditions
                  </a>
                </Trans>
              </Typography>
              </div>
          </Grid>
        </>
      )}
      <Grid padding="32" alignCenter>
      <PrimaryButton
  red
  disabled={
    selectedOption === initialSelectedOption &&
    selectedInterval === initialSelectedInterval
  }
  onClick={() => {
    // Find the selected package object based on the selectedOption
    const selectedPackage = options.find(
      (opt) => selectedOption === `option${opt.name === 'Pro' ? 2 : 1}`
    );

    // Find the price with the matching recurring interval
    const selectedPrice = selectedPackage?.prices.find(
      (price) =>
        (selectedInterval && price.recurring?.interval === 'month') ||
        (!selectedInterval && price.recurring?.interval === 'year')
    );

    if (selectedPrice) {
      chooseMainSubscription(selectedPrice);
    }
  }}
>
  {t('common.changeSubscription', 'Change subscription')}
  <Icon icon="edit_sub" mr="10" />
</PrimaryButton>
<PrimaryButton
        onClick={onClose}
        transparent
        style={{marginTop: 25}}
      >
        {t('common.cancel')}
        <Icon icon="cross" mr="10"/>
      </PrimaryButton>
      </Grid>
      </>
  );
};

export default ChangeSubscriptionForm;
