const prependUrl = ( url ) => {
    if (!url) return false;
    const prefixes  = ['http://', 'https://'];
    let prefixedUrl = url;

    //check if url contains either of the strings in prefixes
    let hasPrefix = false;
    prefixes.forEach( prefix => {
        if ( prefixedUrl.includes(prefix)) {
            hasPrefix = true;
        }
    });

    if (!hasPrefix)
    {
        prefixedUrl = prefixes[1] + url;
    }

    return prefixedUrl;
}

export default prependUrl;