import styled from 'styled-components/macro';

export const StyledBasicCardContainer = styled.div`
  display: flex;
  margin: 16px 0px;
  align-items: flex-start;
  & > input {
    margin: 36px 10px 10px 0;
  }
`;

export const StyledBasicCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledBasicCard = styled.div`
  position: relative;
  width: 100%;
  /* min-height: 140px; */
  border: 1px solid ${(props) => props.theme.colors.lgrey};
  ${(props) =>
    props.dropdownOff
      ? `
    opacity: ${props.selectedCard ? 1 : 0.5};
    box-shadow: ${
      props.selectedCard ? '0 3px 6px rgba(0, 0, 0, 0.16)' : 'none'
    };
  `
      : ``}
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  background: #fff;
  &:hover,
  &.expanded {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
  }
  &:hover {
    border-color: ${(props) => props.theme.colors.mgrey};
  }
  &:first-child {
    margin-top: 0px;
  }
`;

export const DropdownGroup = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  overflow: visible;
  > div {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
    white-space: nowrap;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    > div {
      right: 0;
      left: auto;
    }
  }
`;

export const CardBasicInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  /* word-break: break-all; */

  & > div:first-child {
    //avatar
    display: flex;
    margin-right: 10px;
  }

  & > div:last-child {
    //text
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: -16px;
    & > div {
      flex: 1;
      margin-top: 16px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
`;

export const BasicCardExtendInfo = styled.div`
  /* background: blueviolet; */
`;
