import React from "react";
import styled from "styled-components/macro";
import { Typography } from "../Typography";

const CVRating = ({ single }) => {
  //list of skills/languages
  return (
    <>
      {Object.entries(single).map((arr, idx) => (
        <CVRatingObject key={idx} array={arr} />
      ))}
    </>
  );
};

const CVRatingObject = ({ array }) => {
  return (
    <div className="rating">
      <Typography tag="p" nomargin>{array[0]}</Typography>
      <Dots className="dots">
      { [1, 2, 3, 4, 5].map((n) => (
          <Dot key={ n } marked={ n <= array[1] } />
      ) ) }
      </Dots>
    </div>
  );
};

const Dots = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    width: 80px; 
`;

const Dot = styled.span`
    display: inline-block;
    border: 1px solid ${props=>props.theme.colors.red};
    border-radius: 15px;
    width :11px; height: 11px;
    position: relative;
    &:last-child { 
        &:after {
            display: none;
        }
    }
    &:before { 
        content: "";
        display: ${props=>props.marked ? "block" : "none" };
        position: absolute;
        width: 5px; height: 5px; 
        top: 2px; left: 2px;
        background-color: ${props=>props.theme.colors.red};
        border-radius: 8px;
    }
`;

export default CVRating;
