import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

export const getApplicants = async (request = {}) => {
  const { selector, params } = parseRequest(request);
  try {
    const data = await axios.get(
      `/applicant/${selector ? selector : ""}${params ? "/" + params : ""}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const inviteApplicant = async (request = {}) => {
    const { selector, body, params } = parseRequest(request);
    try {
      const data = await axios.post(
        `/applicant/invite/${selector ? selector : ""}${params ? "/" + params : ""}`
      , body);
      return responseHandler(data);
    } catch (err) {
      return errorResponseHandler(err);
    }
  };
  