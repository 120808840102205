import styled from "styled-components";

export const StyledProgress = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${(props) => props.theme.colors.lgrey};
  position: relative;
  &:after {
    content: "";
    width: ${(props) => props.percentage || 0}%;
    height: 100%;
    position: absolute;
    background-color: ${(props) => props.theme.colors.dgrey};
    top: 0;
    left: 0;
    display: block;
  }
`;
