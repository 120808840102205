import React, { useState } from "react";
import styled from "styled-components/macro";
import Icon, { StyledIcon } from "../../../components/Icon/";
import { capitalize } from "../../../utils/various";

const StyledAccordion = styled.div`
  & > div:first-child {
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.mgrey};
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    ${StyledIcon} {
      width: 14px;
    }
  }
  margin-bottom: 1em;
`;

const Accordion = ({ children, title, initiallyOpen }) => {
  const [open, setOpen] = useState(initiallyOpen || false);
  return (
    <StyledAccordion>
      <div onClick={() => setOpen(!open)}>
        { capitalize(title)}       
        <Icon icon="arrow" rotate={open ? 180 : 0} />
      </div>
      {open && <div>{children}</div>}
    </StyledAccordion>
  );
};

export default Accordion;
