import styled from "styled-components";
import checkFilled from '../../../components/Icon/check_filled.svg';

export const RadioContainer = styled.div`
  display: flex;
`;

export const RadioButton = styled.div`
  position: relative;
  margin-right: 10px;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span {
    height: 16px;
    width: 16px;
    border: 2px solid red;
    border-radius: 40%;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      height: 8px;
      width: 8px;
      border-radius: 40%;
      background-color: red;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: ${(props) => (props.checked ? '1' : '0')};
      transition: opacity 0.3s ease;
    }
  }
`;

export const PositionWrapper = styled.div`
border: 1px solid rgba(24, 31, 56, 0.15);
border-radius: 5px; 
width: 100%; 
height: 100%; 
`

export const SearchPositionContainer = styled.div`
display: flex; 
border-bottom: 1px solid rgba(24, 31, 56, 0.15);
`

export const PositionTableHeader = styled.div`
display: flex;
padding: 14px 20px;
justify-content: space-between;
border-bottom: 1px solid rgba(24, 31, 56, 0.15);
box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15);
`

export const PositionList = styled.div`
max-height: calc(45px * 4);
overflow: scroll; 
min-height: calc(45px * 4);
`

export const List = styled.div`
padding: 12px 20px; 
border-bottom: 1px solid rgba(24, 31, 56, 0.15);
display: flex;  
justify-content: space-between;
`

export const CheckboxInput = styled.input`
appearance: none;
width: 16px;
height: 16px;
border: 1px solid ${props => props.theme.colors.red};
border-radius: 6px;
cursor: pointer;
outline: none; 
background-size: 100% 100%;
background-repeat: no-repeat;
position: relative; 
&::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${checkFilled}) no-repeat center center;
    background-size: cover;
    opacity: ${({ checked }) => (checked ? '1' : '0')};
    transition: opacity 0.2s ease-in-out;
  }

`;


export const SelectButtonContainer = styled.div`
display: flex; 
justify-content: flex-end;
width: 100%; 
margin-bottom: 10px; 
`