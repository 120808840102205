import styled from "styled-components/macro";

export const StyledBlockEditor = styled.div`
${props => props.isLoaded && `
opacity: 0;
animation: fadeIn .5s ease forwards; 
animation-delay: .5s;
@keyframes fadeIn {
  0% {
    opacity: 0; 
  }
  100% {
    opacity: 1; 
  }
}
`}
  & > p {
    //headline
    color: ${(props) => props.theme.colors.mgrey};
    margin-bottom: 20px;
  }
  @media (min-width: 1200px) {
    padding: 0 60px; 
  }
  @media (min-width: 1600px) {
    padding: 0 140px; 
  }
`;
