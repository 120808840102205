// React
import React from "react"

// Styled components
import { StyledLoader } from "./style";

// Lottie JSON
import splash from "../../assets/splash.json";
import Lottie from 'lottie-react'

const Splash = ({fadeOut}) => {
  return (
    <StyledLoader fadeOut={fadeOut}>
      <div>
      <Lottie animationData={splash} loop={false} />
      </div>
    </StyledLoader>
  );
};

export default Splash;
