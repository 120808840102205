import React from "react";
import { StyledMetaEditor } from "./MetaEditor.styled";

const MetaEditor = ({ children }) => {
  return (
    <StyledMetaEditor>
      <div>{children}</div>
    </StyledMetaEditor>
  );
};

export default MetaEditor;
