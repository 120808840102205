import axios from "axios";
import {errorResponseHandler, responseHandler} from "../utilities/response";
import { parseRequest } from "../utilities/queries";

export const getRecruiters = async ( request = {} ) => {
  let { selector } = parseRequest(request);
  try {
    if ( !selector ) selector = "";
    const data = await axios.get(`/recruiter/${selector}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const inviteRecruiter = async ( request = {} ) => {
  const { body } = parseRequest(request);
  try {
    const data = await axios.post("/recruiter/invite", body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};


export const updateRecruiter = async ( request = {} ) => {
  let { selector, body } = parseRequest(request);
  try {
    if ( !selector ) selector = "";
    const data = await axios.put(`/recruiter/${selector}`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
}

export const deleteRecruiter = async (request ={} ) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.delete("/recruiter/" + selector);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const recruiterExists = async(request = {}) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.get("/recruiter/check/" + params);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
}

