import React from 'react'
import styled from "styled-components/macro";
import { Icon } from '../../../components';

const StyledStat = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 16px;
    margin-top: 16px;
    background-color: ${props=>props.theme.colors.lgreytwo};
    border: 1px solid ${props=>props.theme.colors.lgrey};
    border-radius: 5px;
    & > div {
        display: flex;
       
    }
`;

const Stat = ({ icon, text, value }) => {
    return (
        <StyledStat>
            <div>
              <Icon icon={icon} mr={12}/>
              {text}
            </div>
            <div>
                {value}
            </div>  
              
               
        </StyledStat>
    )
}

export default Stat
