// React
import React from "react";

// Utilities - hooks - API endpoints
import { formatDate } from "../../../../utils/various";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

// Components & styled
import { Icon } from "../../../../components";
import { Typography } from "../../../../components/Typography";

const Share = ({
  id,
  firstName,
  lastName,
  email,
  phoneNumber,
  expires,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <StyledShareContainer>
      <Typography tag="p" nomargin bold>
        {firstName} {lastName}
      </Typography>
      <Typography tag="p" nomargin lowOpacity>
        {email}
      </Typography>
      <Typography tag="p" nomargin lowOpacity>
        {phoneNumber.slice(0, 3) +
          " " +
          phoneNumber.slice(3).replace(/(\d{2})/g, "$1 ")}
      </Typography>
      <Typography tag="p" nomargin lowOpacity>
        {t("common.expiresOn")} {formatDate(expires)}
      </Typography>
      <Icon icon="trash" onClick={(e) => onDelete(e, id)} />
    </StyledShareContainer>
  );
};

const StyledShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
  position: relative;
  padding-right: 24px;
  border: 1px solid #e7e8ea;
  box-shadow: 0 0 8px rgba(24, 31, 56, 0.06);
  border-radius: 5px;
  flex-wrap: wrap;
  gap: 10px;
`;

export default Share;
