import styled from 'styled-components/macro';
import { ResetButton } from '../Button';

export const StyledSquareToggle = styled.div`
  height: 36px;
  display: flex;
  width: 100%;
  align-items: center; 
  @media (min-width: 1200px) {
  width: fit-content; 
  }
  &.no-stretch {
    width: auto;
    display: inline-flex;
    justify-content: flex-start;

    & > button {
      padding: 0 20px;
      white-space: nowrap;
      flex: 0 1 auto;
    }
  }
  ${props => props.mt && `margin-top: ${props.mt}px;`}; 
  ${props => props.reverse && `
  flex-direction: row-reverse; 
  `}
  ${props => props.gap && `gap: ${props.gap}px`};
`;

export const StyledSideButton = styled(ResetButton)`
  height: 32px;
  border-radius: 10px; 
  background-color: ${props => props.theme.colors.white};
  color: ${(props) => props.theme.colors.faceliftBlueLight};
  flex: 1;
  font-size: 12px; 
  border: 1px solid ${props => props.theme.colors.mgrey}; 
  margin-left: 5px; 
  margin-right: 5px; 

  &:last-child {
    margin-right: 0; 
  }

  &.active {
    border: 1px solid ${props => props.theme.colors.red}; 
    color: #181F38;
    transition: .5s ease; 
  }
  @media (min-width: 1200px) {
    height: 40px; 
    width: fit-content; 
    padding: 0; 
    padding-left: 25px;
    padding-right: 25px; 
  }
`;

export const UnderlineToggleWrapper = styled.div`
width: 100%; 
display: flex; 
cursor: pointer;
`

export const UnderlineToggleButton = styled.div`
width: 100%;
text-align: center;
font-size: 14px; 
color: ${props => props.theme.colors.faceliftBlue}; 
padding-bottom: 9px; 
position: relative; 
margin-top: 32px; 
border-bottom: 1px solid #F0F0F3;

&:after {  
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  left: 50%;
  position: absolute;
  background: ${props => props.theme.colors.red};
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
&.active {
  &:after {
  width: 100%; 
  left: 0; 
  }
}
`
