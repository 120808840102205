import React, { useEffect, useState } from 'react';
import { StyledMainContent } from "./style";

const MainContent = ({ children }) => {

  // Initialize state to check if the pathname includes "position"
  const [includesPosition, setIncludesPosition] = useState(false);

  // We do not want translateY to mess up our kanban, so if /position/ then add prop to unset translateY. 
  useEffect(() => {
    // Check the current URL using window.location.href
    const currentUrl = window.location.href;

    // Check if the current URL includes "position" or is exactly "localhost:3000" or "recruiter.hitalento.com"
    if (currentUrl.includes("position")) {
      setIncludesPosition(true);
    } else {
      setIncludesPosition(false);
    }
  }, []);


  return (
    <StyledMainContent position={includesPosition}>
      {children}
    </StyledMainContent>
  );
};

export default MainContent;