import styled from "styled-components/macro";

export const  StyledMainContainer = styled.div`
    display: flex;
    width: 100%;
    height: ${props => props.height ? `${props.height}px;` : null}; 
    max-width: 100vw; 
    overflow: hidden; 
    overflow-y: scroll;
    position: relative; 
`;