import React from 'react'
import styled from "styled-components/macro";

const StyledVerticalDivider = styled.span`
    display: ${props=>props.expand ? "block" : "inline"};
    height: ${props=>props.expand ? "100%" : "auto"};
    position: relative;
    margin : ${props=>props.margin ? `0px ${props.margin}px` : `0px 10px` };
    &:after { 
        content : "";
        position: absolute;
        width: 1px; height: ${props=>props.expand ? "100%" : "15px"};
        background-color: ${props=>props.theme.colors.mgrey};
        top: 50%; left: 50%; transform: translate(50%, -50%);
    }
`;

const VerticalDivider = ({ margin, expand, collapse }) => {
    return (
        <StyledVerticalDivider margin={margin}  expand={expand} />
    )
}

export default VerticalDivider
