import styled from 'styled-components/macro';

export const StyledBigCheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 46px;
  margin: 10px 0;
  height: 36px;
  cursor: pointer;

  user-select: none;

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  > span {
    position: absolute;
    top: 0;
    left: 0;
    height: 36px;
    width: 36px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.mgrey};
    background-color: #fbfbfb;

    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      ${(props) =>
        !props.icon &&
        `left: 13px;
      top: 7px;
      width: 8px;
      height: 15px;
      border: solid ${props.theme.colors.mgrey};
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);`}
      background-position: center;
      background-image: ${(props) =>
        props.icon ? `url(${props.icon})` : 'transparent'};
    }
  }

  input:checked {
    & ~ span {
      background-color: #f5f9ff;
      &:after {
        display: block;
      }
    }
  }

  &:hover {
    > span {
      background-color: #efefef;
    }
  }
`;
