import styled from 'styled-components/macro';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as CheckmarkSmall } from './checkmark_small.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Smartphone } from './smartphone.svg';
// import { ReactComponent as Pin } from './pin.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Globe } from './globe.svg';
import { ReactComponent as LogoWithName } from './logo_with_name.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as LinkIcon } from './link.svg';
import { ReactComponent as LinkRotated } from './link_rotated.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as ArrowRight } from './arrow_right.svg';
import { ReactComponent as ArrowLarge } from './arrow_large.svg';
import { ReactComponent as Wrench } from './wrench.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Avatar } from './avatar.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Suitcase } from './suitcase.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as AddPerson } from './add_person.svg';
import { ReactComponent as Reject } from './reject.svg';
import { ReactComponent as EmailReject } from './email_rejection.svg';
import { ReactComponent as Notice } from './notice.svg';
import { ReactComponent as DoubleArrow } from './double_arrow.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as EyeClosed } from './eye_closed.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as Unchecked } from './unchecked.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as ClosedForApplications } from './closed_for_applications.svg';
import { ReactComponent as ApplicationSubmitted } from './application_submitted.svg';
import { ReactComponent as Window } from './window.svg';
import { ReactComponent as BlockParagraph } from './block_paragraph.svg';
import { ReactComponent as BlockList } from './block_list.svg';
import { ReactComponent as BlockHeader } from './block_header.svg';
import { ReactComponent as BlockFooter } from './block_footer.svg';
import { ReactComponent as BlockParagraphWithImage } from './block_paragraph_with_image.svg';
import { ReactComponent as GenericIcon } from './generic_icon.svg';
import { ReactComponent as Interview } from './interview.svg';
import { ReactComponent as Hired } from './hired.svg';
import { ReactComponent as Page } from './page.svg';
import { ReactComponent as WhiteBrand } from './new_white_brand.svg';
import { ReactComponent as CirclePlus } from './circle-plus.svg';
import { ReactComponent as NavPlus } from './navPlus.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as Pinned } from './pinned.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Burger } from './burger.svg';
import { ReactComponent as Collapse } from './collapse.svg';
import { ReactComponent as Preview } from './preview.svg';
import { ReactComponent as Rocket } from './rocket.svg';
import { ReactComponent as ArrowUp } from './arrow_up.svg';
import { ReactComponent as SendMessage } from './send_message.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Deactivate } from './deactivate.svg';
import { ReactComponent as ArrowLeft } from './arrow_left.svg';
import { ReactComponent as NextPrev } from './next_prev.svg';
import { ReactComponent as Attachment } from './attachment.svg';
import { ReactComponent as AttachmentPlus } from './attachment_plus.svg';
import { ReactComponent as SavePlus } from './saveplus.svg';
import { ReactComponent as Marker } from './marker.svg';
import { ReactComponent as ThickPlus } from './thick_plus.svg';
import { ReactComponent as CircleCross } from './circle_cross.svg';
import { ReactComponent as Cart } from './cart.svg';
import { ReactComponent as ArrowLeftSmall } from './arrow_left_small.svg';
import { ReactComponent as Update } from './update.svg';
import { ReactComponent as Some } from './block_some.svg';
import { ReactComponent as Apply } from './block_apply.svg';
import { ReactComponent as Clip } from './clip.svg';
import { ReactComponent as AddRecruiter } from './add_recruiter.svg';
import { ReactComponent as Thumbsup } from './thumbsup.svg';
import { ReactComponent as OutlineSuitcase } from './outline_suitcase.svg';
import { ReactComponent as Recruiters } from './recruiters.svg';
import { ReactComponent as Change } from './change.svg';
import { ReactComponent as EditSub } from './editSub.svg';
import { ReactComponent as CreditCard } from './creditcard.svg';
import { ReactComponent as EditUser } from './edit_user.svg';
import { ReactComponent as Socials } from './socials.svg';
import { ReactComponent as CheckFilled } from './check_filled.svg';
import { ReactComponent as SelectAllCheck } from './selectAllCheck.svg';
import { ReactComponent as DeSelectAllCheck } from './deSelectAllCheck.svg';
import { ReactComponent as PaperPlane } from './paperplane.svg';
import { ReactComponent as Connect } from './connect.svg';
import { ReactComponent as Info } from './info.svg';

const Icon = ({ active, inactive, icon, isOpen, ...rest }) => {
  const selectedIcon = (selector) => {
    switch (selector) {
      case 'dots':
        return <Dots />;
      case 'email':
        return <Email />;
      case 'phone':
        return <Smartphone />;
      case 'socials':
        return <Socials />;
      case 'check_filled':
        return <CheckFilled />;
      case 'paperplane':
        return <PaperPlane />;
      case 'select_all_check':
        return <SelectAllCheck />;
      case 'deselect_all_check':
        return <DeSelectAllCheck />;
      case 'connect':
        return <Connect />;
      // case 'location':
      //   return <Pin />;
      case 'pinned':
        return <Pinned />;
      case 'linkedin':
        return <Linkedin />;
      case 'new_white_brand':
        return <WhiteBrand />;
      case 'website':
        return <Globe />;
      case 'logo_with_name':
        return <LogoWithName />;
      case 'collapse':
        return <Collapse />;
      case 'marker':
        return <Marker />;
      case 'checkmark':
        return <Checkmark />;
      case 'clip':
        return <Clip />;
      case 'checkmark_small':
        return <CheckmarkSmall />;
      case 'arrow_left_small':
        return <ArrowLeftSmall />;
      case 'plus':
        return <Plus />;
      case 'circle_plus':
        return <CirclePlus />;
      case 'navPlus':
        return <NavPlus />;
      case 'minus':
        return <Minus />;
      case 'edit_sub':
        return <EditSub />;
      case 'save':
        return <Save />;
      case 'cross':
        return <Cross />;
      case 'sort':
        return <Sort />;
      case 'arrow_up':
        return <ArrowUp />;
      case 'next_prev':
        return <NextPrev />;
      case 'link':
        return <LinkIcon />;
      case 'arrow':
        return <Arrow />;
      case 'send_message':
        return <SendMessage />;
      case 'page':
        return <Page />;
      case 'arrow_right':
        return <ArrowRight />;
      case 'arrow_large':
        return <ArrowLarge />;
      case 'preview':
        return <Preview />;
      case 'wrench':
        return <Wrench />;
      case 'attachment':
        return <Attachment />;
      case 'recruiters':
        return <Recruiters />;
      case 'attachment_plus':
        return <AttachmentPlus />;
      case 'arrow_left':
        return <ArrowLeft />;
      case 'save_plus':
        return <SavePlus />;
      case 'trash':
        return <Trash />;
      case 'deactivate':
        return <Deactivate />;
      case 'lock':
        return <Lock />;
      case 'edit_user':
        return <EditUser />;
      case 'update':
        return <Update />;
      case 'edit':
        return <Edit />;
      case 'link_rotated':
        return <LinkRotated />;
      case 'avatar':
        return <Avatar />;
      case 'add':
        return <Add />;
      case 'cart':
        return <Cart />;
      case 'change':
        return <Change />;
      case 'send':
        return <Send />;
      case 'add_recruiter':
        return <AddRecruiter />;
      case 'user':
        return <User />;
      case 'credit_card':
        return <CreditCard />;
      case 'users':
        return <Users />;
      case 'suitcase':
        return <Suitcase />;
      case 'outline_suitcase':
        return <OutlineSuitcase />;
      case 'search':
        return <Search />;
      case 'upload':
        return <Upload />;
      case 'settings':
        return <Settings />;
      case 'file':
        return <File />;
      case 'add_person':
        return <AddPerson />;
      case 'to_be_rejected':
        return <Reject />;
      case 'interview':
        return <Interview />;
      case 'thick_plus':
        return <ThickPlus />;
      case 'hired':
        return <Hired />;
      case 'reject':
        return <EmailReject />;
      case 'notice':
        return <Notice />;
      case 'circle_cross':
        return <CircleCross />;
      case 'double_arrow':
        return <DoubleArrow />;
      case 'eye':
        return <Eye />;
      case 'eye_closed':
        return <EyeClosed />;
      case 'checked':
        return <Checked />;
      case 'unchecked':
        return <Unchecked />;
      case 'folder':
        return <Folder />;
      case 'burger':
        return <Burger />;
      case 'filter':
        return <Filter />;
      case 'closed_for_applications':
        return <ClosedForApplications />;
      case 'application_submitted':
        return <ApplicationSubmitted />;
      case 'window':
        return <Window />;
      case 'rocket':
        return <Rocket />;
      case 'thumbs_up':
        return <Thumbsup />;
      case 'block_paragraph':
        return <BlockParagraph />;
      case 'block_list':
        return <BlockList />;
      case 'block_header':
        return <BlockHeader />;
      case 'block_footer':
        return <BlockFooter />;
      case 'block_some':
        return <Some />;
      case 'block_apply':
        return <Apply />;
      case 'block_paragraph_with_image':
        return <BlockParagraphWithImage />;
      case 'info':
        return <Info />;
      default:
        return <GenericIcon />;
      //none found
    }
  };

  return <StyledIcon active={active} inactive={inactive} isOpen={isOpen} {...rest}>{selectedIcon(icon)}</StyledIcon>;
};

export const StyledIcon = styled.div`
${(props) => props.active ? 'animation: 1s active;' : props.inactive ? 'opacity: 0.4; transition: 1s ease;' : 'opacity: 1;'}
@keyframes active {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1; 
  }
}
${props => props.white ? 'filter: brightness(0) invert(1);' : null}
${props => props.invert ? `filter: invert(1)` : null}; 
${props => props.pointer ? 'cursor: pointer;' : null};
${props => props.p && `padding: ${props.p}px !important;`};
  ${(props) => {
    if (props.width) {
      return `width: ${props.width}px;`;
    }
  }}
  ${(props) => {
    if (props.height) {
      return `height: ${props.height}px;`;
    }
  }}
  margin-right: ${(props) =>
    props.nomargin ? '0' : props.mr ? props.mr : '10'}px;
  margin-left: ${(props) => (!props.ml ? '0px' : props.ml + 'px')};
  display: ${(props) => (props.forceInline ? 'inline' : 'flex')};
  justify-content: ${(props) => {
    if (props.justifyStart) {
      return 'flex-start';
    } else if (props.justifyEnd) {
      return 'flex-end';
    } else {
      return 'center';
    }
  }};
  align-items: ${(props) => {
    if (props.alignStart) {
      return 'flex-start';
    } else if (props.alignEnd) {
      return 'flex-end';
    } else {
      return 'center';
    }
  }};
  & > svg {
    ${(props) => (props.small ? 'width:16px;' : '')}
    & * {
      ${(props) =>
    props.highlightStroke
      ? `stroke : ${props.theme.colors.highlight};`
      : ''}
    }
    & * {
      ${(props) =>
    props.highlightFill
      ? `fill : ${props.theme.colors.highlight};`
      : ''}
    }
  }
  &:last-child {
    margin-right: ${(props) =>
    props.nomargin ? '0' : props.mr ? props.mr : '0'}px;
  }
  ${(props) =>
    props.color
      ? `
      & > svg * {
          fill : ${props.color} !important;
        }
    `
      : ``}
  ${(props) =>
    props.useStyleVars
      ? `
      & > svg * {
          fill : var(--primary-color) !important;

        }
    `
      : ``}
      ${props => props.disabled ? 'opacity: 0.3;' : 'opacity: 1;'};
      ${props => props.lowOpacity ? 'opacity: 0.6 !important;' : 'opacity: 1;'};
      ${props => props.lowestOpacity ? 'opacity: 0.15;' : 'opacity: 1;'};
  ${(props) =>
    props.colorStroke
      ? `
      & > svg * {
          stroke : ${props.colorStroke} !important;
        }

    `
      : ``}
  ${(props) =>
    props.rotate
      ? `
      transform: rotate(${props.rotate}deg);
    `
      : ``}

${(props) =>
    props.rotate && props.scale
      ? `
      transform: rotate(${props.rotate}deg) scale(${props.scale});
    `
      : ``}
      ${props => props.isOpen ? `
      transform: scale(1) rotate(180deg) !important;
      transition: .5 ease;
      transition-delay: 0;
      ` : null}
${(props) =>
    props.scale
      ? `
      transform: scale(${props.scale}) !important;
    `
      : ``}
      ${(props) => props.pr ? `padding-right: ${props.pr}px;` : null}
      ${(props) => props.pb ? `padding-bottom: ${props.pr}px;` : null}
      ${(props) => props.$filter ? 'filter: invert(1)' : null}; 
      ${props => props.collapsed ? `
      transform: scaleX(-1);
      transition: .5s ease;
      ` : `
      transform: scaleX(1);
      transition: .5s ease; `};
      ${props => props.collapsed ? 'transition-delay: .4s;' : null}
      @media (max-width: 991px) {
        ${props => props.hideOnMobile ? 'display: none !important;' : null}; 
      }
      @media (min-width: 992px) {
        ${props => props.hideOnDesktop ? 'display: none !important;' : null}; 
      }
      ${props => props.lightInverted && `
      filter: brightness(0) saturate(100%) invert(48%) sepia(35%) saturate(163%) hue-rotate(188deg) brightness(91%) contrast(80%);
      `}
      ${props => props.clip && `
      position: absolute; 
      right: -60px;
      `}
`;

export default Icon;
