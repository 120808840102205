import React from "react";
import { Icon } from "../../../components";
import { StyledP } from "../../../components/Typography";
import prependUrl from "../../../utils/prependUrl";
import { StyledCompanyLogoContainer, StyledPWithIcon } from "./style";

const CompanyLogo = ({ path, name, logoPath }) => {
  const Content = (
    <>
      {logoPath ? (
        <img src={logoPath} alt="company logo" />
      ) : (
        <>
          {path ? (
            <StyledPWithIcon>
              <Icon icon="link" />
              {name}
            </StyledPWithIcon>
          ) : (
            <StyledP nomargin light>
              {name}
            </StyledP>
          )}
        </>
      )}
    </>
  );
  return (
    <StyledCompanyLogoContainer>
      <>
        {path ? (
          <a rel="noreferrer" target="_blank" href={prependUrl(path)}>
            {Content}
          </a>
        ) : (
          Content
        )}
      </>
    </StyledCompanyLogoContainer>
  );
};

export default CompanyLogo;
