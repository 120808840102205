import styled from "styled-components/macro";
import { Button } from "../../../../../../../components";

export const StyledActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  > ${Button} {
    &.non-button {
      text-transform: uppercase;
      padding-left: 6px;
      padding-right: 6px;
      &:active {
        background: transparent;
      }
    }

    &:first-child {
      padding-left: 0;
    }
  }
`;

export const StyledGotoUrl = styled.a`
  position: absolute;
  ${props => props.position ? `position: ${props.position};` : null}; 
  top: 0;
  right: 0;
  display: flex;

  font-size: 10px;
  text-transform: unset;
  ${(props) =>
    !props.publish
      ? `color: ${props.theme.colors.mgrey};`
      : `color:${props.theme.colors.dgrey};`}
  svg * {
    fill: ${(props) =>
      !props.publish ? props.theme.colors.mgrey : props.theme.colors.dgrey};
  }
`;
