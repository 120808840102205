import styled from "styled-components/macro";

export const AnonymousHiringWrapper = styled.div`
position: absolute;
right: 0; 
top: 8px;
width: fit-content; 
@media (max-width: 991px) {
    top: 28px; 
}
`