// React
import React from 'react';

// Quill JS
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Components
import { StyledQuill } from './QuillEditor.styled';
import CustomToolbar from './CustomToolbar';
import { InputLabel, LabelByline } from '../../../../../components/Input/Input';
import { Typography } from '../../../../../components/Typography';

// Translation
import { useTranslation } from 'react-i18next';

// Dynamic fields function - Listen to dynamic field options, and insert text into the editor.
function dynamicFields(args) {
  const value = args;
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

const QuillEditor = ({
  label,
  byline,
  value,
  changeHandler,
  toolbar,
  mb,
  mbLG,
  title, 
  noText,
  subDescription,
  description, 
  hasDynamicFields,
}) => {
  const { t } = useTranslation();
  const modules = hasDynamicFields
    ? {
        toolbar: {
          container: '#toolbar',
          handlers: {
            dynamicFields: dynamicFields,
          },
        },
      }
    : {
        toolbar: toolbar
          ? toolbar
          : [
              // [{ font: [] }],
              [{ header: [1, 2, 3, false] }],
              ['bold', 'italic', 'underline'],
              // [{ color: [] }, { background: [] }],
              // [{ script: "sub" }, { script: "super" }],
              // ["blockquote", "code-block"],
              [{ list: 'ordered' }, { list: 'bullet' }],
              //   [{ indent: "-1" }, { indent: "+1" },
              [{ align: [] }],
              ['link', 'video'], //"image", "video"],
              ['clean'],
            ],
      };

  return (
    <StyledQuill>
      {label && (
        <InputLabel byline={byline} fontSize="16px">
          {label}
          {byline && <LabelByline mb={mb} mbLG={mbLG} fontSize="12px">{byline}</LabelByline>}
        </InputLabel>
      )}
      {!noText && (
        <>
        <Typography tag="p" mt="25" mb="10" center>{description} <strong style={{fontSize: 14}}>{title}</strong></Typography>
        <Typography tag="p" center mb="25">{subDescription}</Typography>
        </>
      )}
      {hasDynamicFields ? <CustomToolbar /> : null}
      <ReactQuill
        modules={modules}
        value={value}
        theme="snow"
        onChange={changeHandler}
        placeholder={t(
          'component.block.section_quill_placeholder',  
          'Content goes here...'
        )}
      />
    </StyledQuill>
  );
};

export default QuillEditor;
