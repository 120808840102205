// React
import React, {useState} from 'react'

// Translations and hooks
import { useTranslation } from 'react-i18next'

// Components
import { Icon } from '../../../../components'
import { Modal } from '../../../../components'
import { PrimaryButton } from '../../../../components/Button'
import { Grid } from '../../../../components/Grid'
import Shares from '../Shares/Shares'

// Styled components
import { ShareholdersButton } from './ManageShareholders.styled'
import { Typography } from '../../../../components/Typography'


const ManageShareholders = ({positionId, title}) => {

    const [showModal, setShowModal] = useState(false);
    const [confirmation, setConfirmation] = useState(false); 
    const {t} = useTranslation();

    const updateParentState = (newValue) => {
        setShowModal(newValue);
      };

      const updateParentConfirmation = (newValue) => {
        setConfirmation(newValue);
      };

  return (
    <>
   <ShareholdersButton>
    <PrimaryButton transparent noPadding onClick={() => setShowModal(true)}>
    {t('common.manageShareholders')}
    <Icon icon="edit_user" mr="8" />
    </PrimaryButton>
   </ShareholdersButton>
    {showModal && (
        <>
        <Modal title={t('common.manageShareholders')} onClose={() => setShowModal(false)}>
            <Shares updateParentConfirmation={updateParentConfirmation} updateParentState={updateParentState} positionId={positionId} title={title} />
        </Modal>
        </>
    )}
                {confirmation && (
              <>
              <Modal title={t('common.shareholderAdded')} onClose={() => setConfirmation(false)}>
                <Grid padding={25} fluid alignCenter>
                  <Typography tag="p" nomargin mb="20">{t('common.shareholderAddedDesc_1')}</Typography>
                  <Typography tag="p" nomargin mb="10">{t('common.shareholderAddedDesc_2')}</Typography>
                  <PrimaryButton red mt="20" onClick={() => setConfirmation(false)}> 
                {t('common.great')}
                <Icon icon="thumbs_up" mr="8" scale='1' />
                </PrimaryButton>
                </Grid>
              </Modal>
              </>
            )}
   </>

  )
}

export default ManageShareholders