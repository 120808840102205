// React
import React, { useContext, useState } from "react";

// Translations, hooks, and API endpoints
import { useTranslation } from "react-i18next";
import QuillEditor from "../../QuillEditor/QuillEditor";

// Components
import { InputFieldWithLabel } from "../../../../../../components";
import { InputGroup } from "../../../../../../components/Input/Input";
import CommonBlockSettings from "./CommonBlockSettings";
import { ColorPicker } from "../Block.styled";
import { Typography } from "../../../../../../components/Typography";

// State management
import { PageContext } from "../../../../../context/PageContext";
import { Flex } from "../../../../../style";
import { StateBrandingContext } from "../../../../../context/BrandingContext";

export const ParagraphContent = ({ block }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  return (
    <>
      <InputGroup block>
        <InputFieldWithLabel
          tiny
          label={t("common.headline", "Headline")}
          byline={t(
            "common.headlineDesc",
            "Add a headline for this paragraph."
          )}
          onChange={(e) => {
            dispatch({
              type: "update_block",
              payload: { id: block.id, name: "title", value: e.target.value },
            });
          }}
          name="title"
          mb="20"
          mbLG="10"
          type="text"
          value={block.title || ""}
        />
      </InputGroup>
      <InputGroup block noBorderBottom>
        <QuillEditor
          noText
          label={t("common.mainText", "Text")}
          byline={t("common.mainTextDesc", "Write your main text here.")}
          value={block.body}
          mb="20"
          mbLG="10"
          changeHandler={(data) =>
            dispatch({
              type: "update_block",
              payload: { id: block.id, name: "body", value: data },
            })
          }
        />
      </InputGroup>
    </>
  );
};

export const ParagraphSettings = ({ block }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);
  const [selectedColor, setSelectedColor] = useState(
    block.backgroundColor || ""
  );
  const { branding } = useContext(StateBrandingContext);

  const colorOptions = [
    { name: "Black", value: "#000000" },
    { name: "White", value: "#FFFFFF" },
    ...[
      branding.brandingColorOne,
      branding.brandingColorTwo,
      branding.brandingColorThree,
      branding.brandingColorFour,
    ]
      .filter((color) => color !== null || false) // Filter out "false" values
      .map((color, index) => ({ key: index, name: color, value: color })),
  ];

  const handleColorChange = (e) => {
    const color = e.target.value;
    setSelectedColor(color);
    dispatch({
      type: "update_block",
      payload: {
        id: block.id,
        name: "backgroundColor",
        value: color,
      },
    });
  };

  return (
    <>
      <InputGroup block pt="0">
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          flexDirectionLG="row"
          alignItemsLG="center"
          justifyLG="space-between"
        >
          <div>
            <Typography tag="p" mb="4" mbXL="10" bold>
              {t("component.block.background_color_label", "Background color")}
            </Typography>
            <Typography tag="p" lowOpacity mb="22" mbXL="0">
              {t("common.backgroundDesc")}
            </Typography>
          </div>
          <div>
            <Flex plLG="50">
              {colorOptions.map((color, index) => (
                <ColorPicker
                  key={index}
                  type="radio"
                  name="backgroundColor"
                  value={color.value}
                  checked={selectedColor === color.value}
                  onChange={handleColorChange}
                  className={selectedColor === color.value ? "active" : ""}
                  pLabel={color.name}
                />
              ))}
            </Flex>
          </div>
        </Flex>
      </InputGroup>
      <CommonBlockSettings block={block} />
    </>
  );
};
