// React
import React, { useState, useCallback, useEffect } from "react";

// Styled components
import { ApplicantContactInfoWrapper } from "./ApplicantContactInfo.styled";
import { Flex } from "../../../style";

// Components
import { Icon } from "../../../../components";
import { Typography } from "../../../../components/Typography";
import { FileDownload } from "../../../../components";

export const NON_ATTACHED_DOC_TYPES = [
  "profileImage",
  "personalityTest",
  "skillTest",
];

const parseApplicationData = (data) => {
  let returnData;

  if (data.applicant) {
    returnData = { ...data, ...data.applicant.candidate };
  } else {
    returnData = data;
  }
  return returnData;
};

export const ApplicantContactInfo = ({
  single,
  hideOnDesktop,
  isAnonymous,
  onUpdateState,
}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 992);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 992);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { files } = parseApplicationData(single);
  const [documents, setDocuments] = useState([]);

  /**
   * Find files of attached document type and set in local state "documents"
   * @param {array} files
   */
  const getDocumentsArray = useCallback((files) => {
    if (files && files.length > 0) {
      const addedFiles = files
        .filter((file) => NON_ATTACHED_DOC_TYPES.indexOf(file.docType) === -1) //filter out files
        .map((file) => {
          return { name: file.filename, url: file.signedRequest };
        });
      setDocuments(addedFiles);
    } else {
      setDocuments([]);
    }
  }, []);

  useEffect(() => {
    getDocumentsArray(files);
  }, [single, files, getDocumentsArray]);

  const copyConfirmation = () => {
    onUpdateState(true);
    setTimeout(() => {
      onUpdateState(false);
    }, 3500);
  };

  return (
    <>
      <ApplicantContactInfoWrapper
        hideOnDesktop={hideOnDesktop}
        isAnonymous={isAnonymous}
      >
        {single.applicant.candidate.email && (
          <>
            <button
              className="btn"
              onClick={() =>
                (window.location = `mailto:${single.applicant.candidate.email}`)
              }
            >
              <Flex
                flexDirection="row"
                align="center"
                justify="flex-start"
                ml="-2"
              >
                <Icon width="40" mr="0" ml="-10" lowOpacity icon="email" />
                <Typography tag="p" lowOpacity nomargin large pointer>
                  {isLargeScreen && single.applicant.candidate.email.length > 15
                    ? `${single.applicant.candidate.email.slice(0, 15)}....`
                    : single.applicant.candidate.email}
                </Typography>
              </Flex>
            </button>
            <Icon
              icon="clip"
              clip="true"
              scale="0.2"
              lowOpacity
              pointer
              onClick={() => {
                copyConfirmation();
                // Logic to copy the email to clipboard
                navigator.clipboard.writeText(single.applicant.candidate.email);
              }}
            />
          </>
        )}
        {single.applicant.candidate.phoneNumber && (
          <button
            className="btn"
            onClick={() =>
              (window.location = `tel:${single.applicant.candidate.phoneNumber}`)
            }
          >
            <Flex
              flexDirection="row"
              align="center"
              justify="flex-start"
              ml="-2"
            >
              <Icon width="40" ml="-10" mr="0" lowOpacity icon="phone" />
              <Typography tag="p" lowOpacity nomargin large pointer>
                {single.applicant.candidate.phoneNumber}
              </Typography>
            </Flex>
          </button>
        )}

        {single.applicant.candidate.city && (
          <Flex
            flexDirection="row"
            align="center"
            justify="flex-start"
            ml="-2"
            mb="10"
          >
            <Icon width="40" mr="0" ml="-10" lowOpacity icon="marker" />
            <Typography
              tag="p"
              lowOpacity
              nomargin
              large
            >{`${single.applicant.candidate.city}, ${single.applicant.candidate.country}`}</Typography>
          </Flex>
        )}
        {single.links.linkedin && (
          <>
            <button
              className="btn"
              onClick={() => (window.location = `${single.links.linkedin}`)}
            >
              <Flex
                flexDirection="row"
                align="center"
                justify="flex-start"
                ml="-2"
              >
                <Icon width="40" ml="-10" mr="0" lowOpacity icon="socials" />
                <Typography tag="p" lowOpacity nomargin large pointer>
                  {single.links.linkedin}
                </Typography>
              </Flex>
            </button>
          </>
        )}

        {single.links.other && (
          <>
            <button
              className="btn"
              onClick={() => (window.location = `${single.links.other}`)}
            >
              <Flex
                flexDirection="row"
                align="center"
                justify="flex-start"
                ml="-2"
              >
                <Icon width="40" mr="0" ml="-10" lowOpacity icon="website" />
                <Typography tag="p" lowOpacity nomargin large pointer>
                  {single.links.other}
                </Typography>
              </Flex>
            </button>
          </>
        )}

        {documents.length > 0 && (
          <>
            {/* <CVHeadline>{at('component.cv.files')}</CVHeadline> */}
            {documents.map((doc, index) => (
              <FileDownload
                cv
                includeIcon
                key={index}
                url={doc.url}
                name={doc.name}
              />
            ))}
          </>
        )}
      </ApplicantContactInfoWrapper>
    </>
  );
};
