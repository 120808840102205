import styled from 'styled-components/macro';
import { ResetButton } from '../../../../components/Button';

export const ProductContainer = styled.div`
  position: relative;
  padding: ${(props) =>
    props.type === 'basic'
      ? '24px 8px'
      : props.type === 'availableAddon'
      ? '6px 8px'
      : '16px 8px'};
  border-radius: 5px;
  border: 1px solid #eee;
  &:first-of-type {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;

  & > a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ProductWrapper = styled.div``;

export const DescriptionBar = styled.div`
  padding: 20px 20px;
  background-color: #efefef;
  border: 1px solid #eee;
  margin-top: -5px;
  z-index: 0;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CancelSub = styled(ResetButton)`
  & span {
    color: ${(props) =>
      props.disabled
        ? props.theme.colors.lgrey
        : props.theme.colors.red};
  }
`;

export const Box = styled.div`
  padding: ${(props) => (props.noMargin ? '0' : '4px 10px')};
  margin: 4px 4px;
  /* white-space: nowrap; */
  display: inline-block;
  border-radius: 20px;
  background-color: ${(props) => {
    switch (props.color) {
      case 'blue':
        return props.theme.colors.blue;
      case 'grey':
        return props.theme.colors.lgrey;
      case 'red':
        return props.theme.colors.red;
      case 'green':
        return props.theme.colors.green;
      case 'gray':
        return props.theme.colors.lgrey;
      default:
        return 'transparent';
    }
  }};

  &:last-child {
    align-self: flex-end;
    justify-self: flex-end;
  }
  color: ${(props) => {
    switch (props.color) {
      case 'green':
        return '#fff';
      case 'red':
        return '#fff';
      case 'grey':
        return props.theme.colors.grey;
      case 'gray':
        return props.theme.colors.mgrey;
      default:
        return props.theme.colors.dgrey;
    }
  }};
  ${(props) =>
    props.mute ? `color : ${props.theme.colors.mgrey}` : ``}

  ${(props) =>
    props.bold &&
    `text-transform: uppercase;
      font-weight: bold;`}
`;
