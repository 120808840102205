import React from "react";
import { NotEditable } from "./CommonBlockSettings";

export const DefaultContent = ({ block }) => {
  return (
    <>
      <NotEditable />
    </>
  );
};

export const DefaultSettings = () => {
  return (
    <>
      <NotEditable />
    </>
  );
};
