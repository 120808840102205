// React
import React, { useContext, useEffect } from "react";

// Components
import { Icon } from "../../../components";

// Styled components
import { StyledMegaHeader } from "./style";
import { MobileOnly, DesktopOnly } from "../../style";

// State management
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../context/PositionContext";

const MegaHeader = ({ text, noIcon }) => {
  // Update state step on back. - Back to AllPositions (mobile)
  const positionDispatch = useContext(DispatchPositionContext);
  const { collapsed } = useContext(StatePositionContext);

  const handleViewCount = () => {
    positionDispatch({ type: "view_count", payload: 0 });
  };

  const handleCollapse = () => {
    if (!collapsed) {
      positionDispatch({ type: "positions_collapsed", payload: true });
    } else {
      positionDispatch({ type: "positions_collapsed", payload: false });
    }
  };

  // Add useEffect to handle cleanup when the component is unmounted
  useEffect(() => {
    return () => {
      positionDispatch({ type: "positions_collapsed", payload: false });
    };
  }, [positionDispatch]);

  return (
    <div>
      <StyledMegaHeader>
        <MobileOnly>
          {!noIcon ? (
            <Icon onClick={handleViewCount} icon="burger" mr="16" />
          ) : null}
        </MobileOnly>
        <DesktopOnly>
          {!noIcon ? (
            <Icon
              onClick={handleCollapse}
              icon="collapse"
              pointer
              mr="16"
              collapsed={collapsed}
            />
          ) : null}
        </DesktopOnly>
        <h1>{text}</h1>
        {/* {editFunction ? (
          <EditButton
            onClick={editFunction}
            disabled={editFunctionDisabled}
          >
            {t('common.edit')}
            <Icon icon="edit" />
          </EditButton>
        ) : (
          ''
        )} */}
      </StyledMegaHeader>
    </div>
  );
};

export default MegaHeader;
