import React from 'react'
import styled from 'styled-components/macro'
import { Icon } from '..';

const StyledTBA = styled.div`
    margin: 40px 0px;
    color: ${props=>props.theme.colors.mgrey};
    padding: 40px;
    border-radius: 15px;
    background-color: ${props=>props.theme.colors.lgrey};
    div:first-child { 
        width :20px;
        height: 20px;
        margin-right: 20px;
    }

    display: flex;
    align-items: center;
`;

const TBA = () => {
    return (
        <StyledTBA>
            <Icon icon="wrench" />
            <div>
                <h3>
                    TBA
                </h3>
                <p>This feature is planned and will be added at a later stage</p>

            </div>
        </StyledTBA>
    )
}

export default TBA
