export const recruiterTheme = {
  colors: {
    primary: "#FF385C",
    secondary: "#F5F9FF",
    blue: "#F5F9FF",
    darkblue: "#EBF3FF",
    highlight: "#FF385C",
    red: "#FF385C",
    green: "#98C269",
    lgrey: "#E7E7E7", //"#EFEFEF",
    lgreytwo: "#FBFBFB",
    mgrey: "#E7E8EA",
    dgrey: "#555555",
    vdgrey: "#333",
    primaryButtonColor: "#fff",
    mgreyBolder: '#B7B7B7',
    faceliftBlue: '#181F38',
    faceliftBlueLight: 'rgba(24, 31, 56, 0.6)',
    faceliftBlueLighter: 'rgba(24, 31, 56, 0.15)',
    white: '#ffffff',
    almostWhite: '#FEFEFE',
    lightGray: '#F3F3F5',
    mediumGray: '#E8E9EB',
    slateGray: '#52576A', 
    input: '#8B8E9B',  
  },
  breakpoints: {
    xs: "320px",
    sm: "568px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    xxxl: "1680px",
  },
  grid: {
    gutter: "16px",
  },
  fonts: {
    fontFamily: '"Work Sans", Arial, Helvetica, sans-serif',
    smallSize: "10px",
    defaultSize: "14px",
    largerSize: "16px", 
    labelSize: "12px",
    bylineSize: "9px",
    buttonStandardSize: "12px",
  },
  inputs: {
    borderSize: "1px",
  },
  effects: {
    shadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
  },
};
