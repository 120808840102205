import styled from "styled-components/macro";

export const KanbanContainer = styled.div`
  padding-bottom: 20px;
  display: flex; 
  height: calc(100% - 180px); 
  width: ${(props) =>
    props.width
      ? props.width + 'px'
      : 'auto'};
  overflow-x: scroll;
  overflow-y: hidden; 
  width: 100%;
  opacity: 0; 
  ${props => !props.$loading && `
  animation: opacity .5s ease;
  opacity: 1; 
  `}
  h2 {
    color: ${props => props.theme.colors.faceliftBlue}; 
    font-size: 16px; 
    font-weight: 500; 
    margin-bottom: 20px; 
    margin-top: 5px;
  }
  button {
    width: 100%;
    border: none;
    background-color: transparent;
  }
  .kbc {
    margin-right: 20px;
    position: relative; 
    &:first-of-type {
      margin-left: 20px; 
      .anonymous-hiring {
        display: flex; 
      }
    }
  }
  div[role=button] {
    width: 100%; 
  }
  @media (max-width: 992px) {
    height: calc(100% - 125px);
    .kbc {
    margin-right: 24px;
    &:first-of-type {
      margin-left: 48px; 
    }
  }
    h2 {
      margin-top: 24px;
      margin-bottom: 16px; 
    }
  }
`;

export const KanbanDroppable = styled.div`
      min-height: 100%; 
      height: 100%;
      display: flex;
    width: 360px; 
    flex-direction: column;
    ${props => props.isEmpty && `
background: rgb(24,31,56);
background: linear-gradient(0deg, rgba(24,31,56,0) 0%, rgba(24,31,56,0.03) 100%);
animation: animate .5s ease; 
@keyframes animate {
  0% {
    opacity: 0; 
  }
  100% {
    opacity: 1; 
  }
}
    `}
      .body-item-scrollable {
            display: flex;
            overflow-y: scroll;
            align-items: center;
            flex-direction: column;
            padding-bottom: 50px;
            &::-webkit-scrollbar {
              display: none;
              width: 0 !important
            }
            -ms-overflow-style: none;
  scrollbar-width: none;
          }
          @media (max-width: 992px) {
            padding-bottom: 75px;
        width: 280px; 
        height: calc(100% - 125px);
          }
`