import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
// import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: "en-US",
    debug: false,
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    react: {
      useSuspense: true,
      // wait: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b"], // don't convert to <1></1> if simple react elements
    },
  });

export default i18n;
