import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

function useTimeAgo(dateString) {
  const { t } = useTranslation();
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.round(Math.abs(now.getTime() - date.getTime()) / 1000);

    let interval = 0;
    let unit = '';

    if (seconds < 60) {
      interval = Math.floor(seconds);
      if (interval > 1) {
        unit = t("common.seconds", "seconds");
      } else {
        unit = t("common.second", "second");
      }
    } else if (seconds < 3600) {
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        unit = t("common.minutes", "minutes");
      } else {
        unit = t("common.minute", "minute");
      }
    } else if (seconds < 86400) {
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        unit = t("common.hours", "hours");
      } else {
        unit = t("common.hour", "hour");
      }
    } else if (seconds < 604800) {
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        unit = t("common.days", "days");
      } else {
        unit = t("common.day", "day");
      }
    } else if (seconds < 2592000) {
      interval = Math.floor(seconds / 604800);
      if (interval > 1) {
        unit = t("common.weeks", "weeks");
      } else {
        unit = t("common.week", "week");
      }
    } else if (seconds < 31536000) {
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        unit = t("common.months", "months");
      } else {
        unit = t("common.month", "month");
      }
    } else {
      interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        unit = t("common.years", "years");
      } else {
        unit = t("common.year", "year");
      }
    }


    setTimeAgo(`${t("common.applied", "Applied")} ${interval} ${unit} ${t("common.ago", "ago")}`);
  }, [dateString]);

  return timeAgo;
}

export default useTimeAgo; 