// React
import React, { useState, useRef, useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

// Beautiful-dnd
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Utility functions, API calls, and hooks
import { useGetResponsiveDimensions } from "../../../hooks/useGetResponsiveDimensions";
import { updateApplication } from "../../../../api/endpoints/application";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// Components
import {
  KanbanContainer,
  KanbanDroppable,
  KanbanVerticalScroll,
} from "./Kanban.styled";
import KanbanCard from "./KanbanCard/KanbanCard";
import ManageShareholders from "../../../components/Shortlist/ManageShareholders/ManageShareholders";

// State management, utilities, and hooks
import { useFetchApplications } from "../../../components/ApplicationList/useFetchApplications";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../../context/PositionContext";
import { ApplicationParamsContext } from "../../../components/ApplicationList/ApplicationContextWrapper";
import AnonymousHiring from "../AnonymousHiring/AnonymousHiring";
import Loading from "../../../../components/Loader/Loading";
import RejectAll from "../RejectAll/RejectAll";

const Kanban = ({ title, onFetchData, onDragEndCallback }) => {
  // Context and state setup
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ApplicationParamsContext);
  const { error, data, status, refetch } = useFetchApplications(state.params);
  const positionDispatch = useContext(DispatchPositionContext);
  const [activePositionId, setActivePositionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(true);
  const [rejectedArray, setRejectedArray] = useState([]);
  const [flattenedList, setFlattenedList] = useState([]);

  let { id } = useParams();

  const { single } = useContext(StatePositionContext);

  useEffect(() => {
    // Call the parent callback with the fetched data
    onFetchData({ data });
  }, [data]);

  // Object destructuring and state initialization
  const [isEmpty, setIsEmpty] = useState({
    applied: false,
    shortlisted: false,
    interview: false,
    hired: false,
    to_be_rejected: false,
  });

  const [columns, setColumns] = useState({
    applied: [],
    shortlisted: [],
    interview: [],
    hired: [],
    to_be_rejected: [],
    rejected: [],
  });

  // Helper function to recursively find substatus in an object
  const findSubstatus = (obj) => {
    if (obj && obj.substatus) {
      return obj.substatus;
    } else if (Array.isArray(obj) && obj.length > 0) {
      for (let i = 0; i < obj.length; i++) {
        const substatus = findSubstatus(obj[i]);
        if (substatus) {
          return substatus;
        }
      }
    } else if (typeof obj === "object" && obj !== null) {
      for (const key in obj) {
        const substatus = findSubstatus(obj[key]);
        if (substatus) {
          return substatus;
        }
      }
    }
    return undefined;
  };

  // Function to move rejected elements into the 'rejected' column
  const updateStatusAndMoveToRejectedColumn = () => {
    setColumns((prevColumns) => {
      const updatedColumns = { ...prevColumns };
      const rejectedPositions = [];

      for (const column of Object.keys(updatedColumns)) {
        const positions = updatedColumns[column];
        const updatedPositions = [];

        for (const position of positions) {
          if (position.status === "rejected") {
            // Set substatus to "rejected" for positions with status "rejected"
            position.substatus = "rejected";
            rejectedPositions.push(position);
          } else {
            updatedPositions.push(position);
          }
        }

        updatedColumns[column] = updatedPositions;
      }

      // Move positions with "status: rejected" to the "rejected" column
      updatedColumns["rejected"] =
        updatedColumns["rejected"].concat(rejectedPositions);

      return updatedColumns;
    });
  };

  // Function to set shortlist to true for applications with substatus 'shortlisted'
  const setShortlistForShortlistedApplications = async () => {
    // Iterate through all columns and positions
    for (const column of Object.keys(columns)) {
      const positions = columns[column];
      // Check if the current column is 'shortlisted'
      const isShortlistedColumn = column === "shortlisted";

      // Iterate through positions in the current column
      for (const position of positions) {
        // Check if the position has substatus 'shortlisted'
        const isShortlisted = position.substatus === "shortlisted";

        // If it's the 'shortlisted' column or the shortlist status has changed
        if (isShortlistedColumn || position.shortlist !== isShortlisted) {
          // Update the application in the database
          await updateApplication({
            selector: position.id,
            body: { shortlist: isShortlisted },
          });
          // Update the local shortlist status
          position.shortlist = isShortlisted;
        }
      }
    }
  };

  // Function to update the "to_be_rejected" array
  const updateRejectedArray = () => {
    const toBeRejectedColumn = columns["to_be_rejected"];
    const newRejectedArray = toBeRejectedColumn.map((position) => position);
    setRejectedArray(newRejectedArray);
  };

  useEffect(() => {
    if (status === "success" && data?.pages) {
      const flattened = data.pages.flatMap((page) => page.data);

      const newColumns = {
        applied: [],
        shortlisted: [],
        interview: [],
        hired: [],
        to_be_rejected: [],
        rejected: [],
      };

      flattened.forEach((card) => {
        const substatus = card.substatus;
        // const shortlisted = card.shortlist;
        if (substatus === "shortlisted") {
          newColumns.shortlisted.push(card);
        } else if (
          ["interview", "hired", "to_be_rejected", "rejected"].includes(
            substatus
          )
        ) {
          newColumns[substatus].push(card);
        } else {
          newColumns.applied.push(card);
        }
      });

      const newIsEmpty = {
        applied: newColumns.applied.length === 0,
        shortlisted: newColumns.shortlisted.length === 0,
        interview: newColumns.interview.length === 0,
        hired: newColumns.hired.length === 0,
        to_be_rejected: newColumns["to_be_rejected"].length === 0,
        rejected: newColumns.rejected.length === 0,
      };

      setFlattenedList(flattened);
      setColumns(newColumns);
      setIsEmpty(newIsEmpty);
      dispatch({ type: "update_list", payload: flattened });
      // console.log("flattened", flattened);

      // Automatically set shortlist for invited applications and update the "to_be_rejected" array
      updateStatusAndMoveToRejectedColumn();
      autoMoveInvitedToShortlist();
      setNullSubstatusToApplied();

      setLoading(false);
    } else {
      // setFlattenedList([]);
      setColumns({
        applied: [],
        shortlisted: [],
        interview: [],
        hired: [],
        to_be_rejected: [],
        rejected: [],
      });

      setIsEmpty({
        applied: true,
        shortlisted: true,
        interview: true,
        Hired: true,
        to_be_rejected: true,
        rejected: true,
      });
    }

    // Update columns based on substatus
    const substatus = findSubstatus(data);
    if (substatus) {
      const card = flattenedList.find((card) => card.id === substatus.cardId);
      if (card) {
        setColumns((prevColumns) => {
          const updatedColumns = { ...prevColumns };

          // Remove card from its current column
          const prevColumn = Object.entries(updatedColumns).find(
            ([column, cards]) => cards.some((c) => c.id === card.id)
          );
          if (prevColumn) {
            const [prevColumnName, prevColumnCards] = prevColumn;
            updatedColumns[prevColumnName] = prevColumnCards.filter(
              (c) => c.id !== card.id
            );
          }

          // Add card to the new column
          if (substatus.value === "Shortlisted") {
            updatedColumns.Shortlisted.push(card);
          } else if (
            ["interview", "hired", "to_be_rejected", "rejected"].includes(
              substatus.value
            )
          ) {
            updatedColumns[substatus.value].push(card);
          } else {
            updatedColumns.Applied.push(card);
          }

          // Update isEmpty object
          const newIsEmpty = {
            ...isEmpty,
            [card.substatus]: updatedColumns[card.substatus].length === 0,
            [substatus.value]: updatedColumns[substatus.value].length === 0,
          };

          return {
            ...updatedColumns,
            ...newIsEmpty,
          };
        });
      }
    }
  }, [data, dispatch, status, activePositionId, single]);

  useEffect(() => {
    setShortlistForShortlistedApplications();
    // Update the "to_be_rejected" array
    updateRejectedArray();
  }, [columns]);

  // Drag and drop handler
  const onDragEnd = async (result) => {
    if (data?.pages) {
      // const flattened = data.pages.flatMap((page) => page.data);
      // console.log("flattened on drag", flattened);
      // dispatch({ type: "update_list", payload: flattened });
      // console.log("data test", data.pages);
      onFetchData({ data });
    }

    const { source, destination } = result;
    if (!destination) return;
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const [draggedCard] = sourceColumn.splice(source.index, 1);
    // Check if the dragged card has status "invited"
    if (draggedCard.status === "invited") {
      // If the destination is not "shortlisted," return the card to "shortlisted" column
      if (destination.droppableId !== "shortlisted") {
        setColumns({
          ...columns,
          [source.droppableId]: [...sourceColumn, draggedCard],
        });
        return;
      }
      // Set the substatus to "shortlisted"
      draggedCard.substatus = "shortlisted";
    } else {
      // Handle other cases where status is not "invited"
      draggedCard.substatus = destination.droppableId;
    }

    if (destination.droppableId === "shortlisted") {
      draggedCard.shortlisted = true;
    } else {
      draggedCard.shortlisted = false;
    }

    destColumn.splice(destination.index, 0, draggedCard);

    setIsEmpty({
      ...isEmpty,
      [source.droppableId]: sourceColumn.length === 0,
      [destination.droppableId]: destColumn.length === 0,
    });

    const shortlist = destination.droppableId === "Shortlisted";

    try {
      await updateApplication({
        selector: draggedCard.id,
        body: { substatus: destination.droppableId, shortlist: shortlist },
      });
      setColumns({
        ...columns,
        [source.droppableId]: sourceColumn,
        [destination.droppableId]: destColumn,
      });
    } catch (error) {
      console.error("Failed to update application:", error);
      // Optionally, you can revert the changes in the UI if the update fails
      setColumns({
        ...columns,
        [source.droppableId]: [...sourceColumn, draggedCard],
      });
    }

    // Invoke the callback function
    if (onDragEndCallback) {
      onDragEndCallback(result);
    }

    // console.log("draggedCard", draggedCard);
  };

  const dimRef = useRef();
  const { elX, elWidth } = useGetResponsiveDimensions(dimRef);

  // Function to format column names
  const formatColumnName = (columnName) => {
    // if (columnName === "to_be_rejected") {
    //   return "To be rejected";
    // }

    // // Capitalize the first letter without replacing underscores
    // return columnName.charAt(0).toUpperCase() + columnName.slice(1);

    return t(
      "kanban.titles." + columnName,
      columnName.charAt(0).toUpperCase() + columnName.slice(1)
    );
  };

  // Function to update columns after rejecting applications
  const updateColumnsAfterReject = (rejectedApplications) => {
    setColumns((prevColumns) => {
      const updatedColumns = { ...prevColumns };

      // Remove rejected applications from their current columns
      for (const column of Object.keys(updatedColumns)) {
        updatedColumns[column] = updatedColumns[column].filter(
          (app) =>
            !rejectedApplications.some(
              (rejectedApp) => rejectedApp.id === app.id
            )
        );
      }

      // Add rejected applications to the 'rejected' column
      updatedColumns["rejected"] =
        updatedColumns["rejected"].concat(rejectedApplications);

      return updatedColumns;
    });
  };

  // Auto-move invited applications to the shortlisted column
  const autoMoveInvitedToShortlist = () => {
    setColumns((prevColumns) => {
      const updatedColumns = { ...prevColumns };
      const invitedColumn = updatedColumns["applied"]; // assuming 'applied' is the initial column

      const shortlistedColumn = updatedColumns["shortlisted"];

      const invitedPositions = invitedColumn.filter(
        (position) => position.status === "invited"
      );

      // Move positions with "status: invited" to the "shortlisted" column
      updatedColumns["applied"] = invitedColumn.filter(
        (position) => position.status !== "invited"
      );
      updatedColumns["shortlisted"] =
        shortlistedColumn.concat(invitedPositions);

      return updatedColumns;
    });
  };

  // Function to set substatus to 'applied' for cards with substatus: null
  const setNullSubstatusToApplied = () => {
    setColumns((prevColumns) => {
      const updatedColumns = { ...prevColumns };

      Object.keys(updatedColumns).forEach((column) => {
        updatedColumns[column] = updatedColumns[column].map((position) => {
          if (position.substatus === null || position.substatus === undefined) {
            return { ...position, substatus: "applied" };
          }
          return position;
        });
      });

      return updatedColumns;
    });

    setIsEmpty((prevIsEmpty) => ({
      ...prevIsEmpty,
      applied: false, // Assuming 'applied' should not be considered empty after this update
    }));
  };

  return (
    <>
      <Loading loading={loading} />
      <DragDropContext onDragEnd={onDragEnd}>
        <KanbanContainer
          ref={dimRef}
          width={window.outerWidth - elX}
          $loading={loading}
        >
          {Object.keys(columns).map((column, columnIndex) => (
            <div key={column} className="kbc">
              <h2>{formatColumnName(column)}</h2>
              {columnIndex === 0 && <AnonymousHiring />}
              {columnIndex === 1 && (
                <ManageShareholders title={title} positionId={id} />
              )}
              {columnIndex === 4 && (
                <RejectAll
                  positionId={id}
                  toBeRejected={rejectedArray}
                  onUpdateParent={updateColumnsAfterReject}
                />
              )}
              <Droppable droppableId={column}>
                {(provided, snapshot) => (
                  <KanbanDroppable
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    isEmpty={isEmpty[column]}
                  >
                    <div className={"body-item-scrollable"}>
                      {columns[column].map((card, index) => (
                        <Draggable
                          key={card.id}
                          draggableId={String(card.id)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <KanbanCard
                                application={card}
                                activePositionId={activePositionId}
                                setActivePositionId={setActivePositionId}
                                initial={initial}
                                setInitial={setInitial}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </KanbanDroppable>
                )}
              </Droppable>
            </div>
          ))}
        </KanbanContainer>
      </DragDropContext>
    </>
  );
};

export default Kanban;
