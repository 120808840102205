import React from "react";
import { StyledSuggestion } from "./style";

const Suggestion = ({ suggestion, onAdd }) => {
  return (
    <StyledSuggestion onClick={onAdd}>{suggestion.tagName}</StyledSuggestion>
  );
};

export default Suggestion;
