export const DEFAULT_APPLICATION_CARD_ACTIONS = [
  'delete',
  'reject',
  'shortlisted',
  'unshortlist',
  'interview',
  'hired',
  'to_be_rejected',
];

export const SUBSTATUS_APPLICATION_CARD_ACTIONS = [
  'shortlisted',
  'interview',
  'hired',
  'to_be_rejected',
];

export const APPLICATION_CARD_ACTIONS = [
  {
    name: 'interview',
    icon: 'interview',
    i18nKey: [
      'component.application_status_list.interview',
      'Interview',
    ],
    bulk: true,
    skipModal: true,
  },
  {
    name: 'hired',
    icon: 'hired',
    i18nKey: ['component.application_status_list.hired', 'Hired'],
    bulk: true,
    skipModal: true,
  },
  {
    name: 'to_be_rejected',
    icon: 'to_be_rejected',
    i18nKey: [
      'component.application_status_list.to_be_rejected',
      'To be rejected',
    ],
    bulk: true,
    skipModal: true,
  },
  {
    name: 'delete',
    icon: 'trash',
    i18nKey: ['common.delete', 'delete'],
    bulk: true,
    modal: {
      header: [
        'component.card.application.confirm_delete_popup',
        'Delete application?',
      ],
      description: [
        'component.card.application.confirm_reject_candidate_description',
        '',
      ],
      button: ['common.delete', 'Delete'],
    },
  },
  {
    name: 'reject',
    icon: 'reject',
    i18nKey: ['component.card.application.dropdown_reject', 'reject'],
    bulk: true, // Can handle bulk updates
    modal: {
      header: [
        'component.card.application.confirm_reject_candidate',
        'Send rejection email to candidate?',
      ],
      button: [
        'component.card.application.confirm_reject_button_label',
        'Send rejection',
      ],
    },
  },
  {
    //Objects with no 'modal' property skips confirmation
    name: 'shortlisted',
    icon: 'add',
    skipModal: true,
    i18nKey: ['common.shortlist', 'shortlist'],
    bulk: true,
  },
  {
    name: 'unshortlist',
    icon: 'minus',
    skipModal: true,
    i18nKey: ['common.unlist', 'Unlist'],
    bulk: true,
  },
  {
    name: 'add_applicants',
    icon: 'add_person',
    i18nKey: ['page.position.add_candidates', 'Add candidates'],
    bulk: true,
    modal: {
      header: ['page.position.add_candidates', 'Add candidates'],
      description: ['page.position.confirm_add_applicant', ''],
    },
  },
];
