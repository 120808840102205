import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Icon from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { ApplicationFilter } from '../ApplicationList';

const StyledSearchContainerWithFilter = styled.div`
  display: flex;
  position: relative;
  width: 100%; 
    border-bottom: 1px solid ${props => props.theme.colors.mgrey};
    height: 70px; 
  > button {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
  }
  @media (max-width: 992px) {
  width: 100%; 
  }
`;

const StyledSearchContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 16px; 
  ${(props) => props.borderBottom ? `border-bottom: 1px solid #E7E8EA;` : null};
  ${(props) => props.borderBottom ? `padding-bottom: 16px;` : null};
  form {
    width: 100%; 
    padding-right: 16px; 
  }
  & > div:last-child {
    height: 100%;
    width: 60px;
    position: absolute;
    right: 32px;
    top: 0;
    /* background: blue; */
    display: flex;
    justify-content: center;
    flex-direction: row; 
    cursor: pointer;
  }
`;
const StyledSearch = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  border: 1px solid ${(props) => props.theme.colors.mgrey};
  width: 100%;
  padding: 12px 16px; 
  border-radius: 8px;
  font-size: 12px;
  @media (min-width: 992px) {
    border-radius: 5px; 
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.input};
    opacity: 1; 
    font-weight: 400; 
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.dgrey};
  }
`;

const Search = ({
  onSearchAction,
  searchOnChange,
  filterIncluded,
  disableSearchSubmit,
  placeholder,
  borderBottom
}) => {
  const [query, setQuery] = useState('');
  const [clear, setClear] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const changeHandler = (e) => {
    e.preventDefault();
    setClear(false);
    setQuery(e.target.value);
    if (searchOnChange) onSearch(e);
  };

  const onClear = (e) => {
    setClear(false);
    setQuery('');
    onSearchAction('');
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (query.length > 3) {
      setClear(true);
    } else {
      setClear(false);
    }
    onSearchAction(query);
  };

  return (
    <StyledSearchContainerWithFilter>
      <StyledSearchContainer borderBottom={borderBottom}>
        {/* <Link to={'/position/create'}><Icon icon="plus" /></Link> */}
        <form onSubmit={onSearch}>
          <StyledSearch
            onKeyPress={(e) => {
              if (disableSearchSubmit) {
                e.key === 'Enter' && e.preventDefault();
              }
            }}
            onChange={changeHandler}
            value={query}
            placeholder={placeholder}
          />
        </form>
        <div onClick={clear ? onClear : onSearch}>
          {!clear ? (
            <Icon icon="search" />
          ) : (
            <Icon icon="plus" rotate={45} />
          )}
        </div>
      </StyledSearchContainer>
      {filterIncluded && (
        <Button
          onClick={() => {
            setFilterOpen(!filterOpen);
          }}
          transparent
        >
          {filterOpen ? (
            <Icon icon="plus" rotate={45} width={18} />
          ) : (
            <Icon icon="filter" rotate={0} />
          )}
        </Button>
      )}
      {filterOpen && filterIncluded && <ApplicationFilter />}
    </StyledSearchContainerWithFilter>
  );
};

export default Search;
