import React from "react";
import styled from "styled-components/macro";
import { RemoveButton } from "../../../components";

const RemovableInputField = ({ children, removeFunction }) => {
  return (
    <StyledRemoveContainer>
      {children}
      <RemoveButton type="button" onClick={removeFunction} />
    </StyledRemoveContainer>
  );
};

const StyledRemoveContainer = styled.div`
  padding-right: 40px;
  position: relative;
  margin: 16px 0px;
  *:first-child input {
    margin-top: 0;
  }
`;

export default RemovableInputField;
