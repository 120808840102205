import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { getShareApplications } from '../../../api/endpoints/share';
import { responseHandler } from '../../../api/utilities/response';
import { useQuery } from 'react-query';

/**
 * Fetch applications with react query and axios
 * @param {object} params
 * {
 *  positionId : number,
 *  excludePositionId: number,
 *  excludeApplicants: boolean, ( exclude candidates that have already applied to this positionId )
 *  positionArray : array, ( position ids to include in call )
 *  applicantId : number, ( not used atm )
 *  shortlist : number (1 / 0)
 *  sort : string ( DESC / ASC )
 *  sortby : string ( firstName / createdAt )
 *  size : number ( page size )
 *  page : number ( page number )
 *  s : string ( search string )
 *  skills : { string : string,  }
 *  status : string ( submitted, rejected, etc.
 *  hasFiles : number (1/0)
 *  isAvailable : number (1/0)
 *  isNew : number (1/0)
 * }
 */

const removeNullValues = (object) => {
  const copy = { ...object };
  for (let propName in copy) {
    const value = copy[propName];
    if (value === null || value === undefined) delete copy[propName];
  }
  return copy;
};

// export const useFetchApplications = (state) => {
//   return useInfiniteQuery(
//     ['applications', removeNullValues(state)],
//     state.uuid ? fetchApplicationsExternal : fetchApplications, //if uuid provided, then external user is accessing list
//     {
//       enabled: !state?.isLoading,

//       // getNextPageParam: (lastPage, pages) => {
//       //   //find next page number
//       //   return lastPage.nextPage;
//       // },
//     }
//   );
// };

export const useFetchApplications = (state) => {
  const queryKey = ['applications', removeNullValues(state)];

  const queryResult = useInfiniteQuery(
    queryKey,
    state.uuid ? fetchApplicationsExternal : fetchApplications,
    {
      enabled: !state?.isLoading,
      // Add other query options as needed
    }
  );

  return {
    ...queryResult,
    refetch: queryResult.refetch,
  };
};

// export const useFetchApplications = (state) => {
//   return useQuery(
//     ['applications', removeNullValues(state)],
//     state.uuid ? fetchApplicationsExternal : fetchApplications
//   );
// };


const fetchApplicationsExternal = async ({ queryKey }) => {
  //Use external access point!
  if (queryKey[1].uuid) {
    return getShareApplications({
      selector: queryKey[1].uuid,
      params: { includeSignedRequest: true, size: 1000 },
    });
  }
};

// const fetchApplications = async ({
//   pageParam = 0,
//   // meta,
//   queryKey,
// }) => {
//   //Internal endpoint for getting applications
//   const serialized = new URLSearchParams(queryKey[1]).toString();
//   const response = await axios.get(
//     `/application/?${serialized}&page=${pageParam}`
//   );

//   return responseHandler(response);
// };

const fetchApplications = async ({
  // meta,
  queryKey,
}) => {
  //Internal endpoint for getting applications
  const serialized = new URLSearchParams(queryKey[1]).toString();
  // console.log(serialized, 'serialized');
  const response = await axios.get(
    `/application/?${serialized}`
  );

  return responseHandler(response);
};