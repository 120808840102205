// React
import React, {useState, useEffect, memo} from 'react'

// Utility functions - API endpoints - hooks
import { useTranslation } from 'react-i18next'
import { rejectApplication } from '../../../../api/endpoints/application'
import useApplicationActions from '../../../hooks/useApplicationActions'

// Styled components
import { RejectAllButton } from './RejectAll.styled'

// Components
import { PrimaryButton } from '../../../../components/Button'
import { Icon } from '../../../../components'
import {Modal} from '../../../../components'
import { Typography } from '../../../../components/Typography'
import { Grid } from '../../../../components/Grid'
import EditRejectionEmail from '../../../components/EditRejectionEmail/EditRejectionEmail'

const RejectAll = ({positionId, toBeRejected, onUpdateParent}) => {
    
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(false); 
    const { handleActionTaken } = useApplicationActions();

    const handleClose = () => {
        setOpen(false); 
      }

      async function handleRejectApplications(applications) {
        try {  
          const response = await rejectApplication({
            params: {
              applicationIds: applications.map((app) => app.id),
            },
          });
             // Update the parent columns after rejecting applications
      onUpdateParent(applications);
          setShowModal(false)
          setConfirmation(true);

        } catch (error) {
          // Handle the error
          console.error("An error occurred while rejecting applications:", error);
        }
      }    

  return (
    <>
    <RejectAllButton>
         <PrimaryButton transparent noPadding onClick={() => setShowModal(true)}>
    {t('common.rejectAll')}
    <Icon icon="edit_user" mr="8" />
    </PrimaryButton>
    </RejectAllButton>
        {showModal && (
            <>
            <Modal title={t('common.rejectAllModalTitle')} onClose={() => setShowModal(false)}>
            <Grid padding={25} fluid alignCenter>
            {toBeRejected.length !== 0 ? (
  <>
    <Typography tag="p" nomargin mb="20">
      {t('common.confirmRejectAll')}
    </Typography>
    <PrimaryButton white onClick={() => setOpen(true)} mb="25">
      {t(
        'component.metaeditor.edit_rejection_email',
        'Edit rejection email'
      )}
      <Icon icon="edit" ml="0" mr="8" scale={1.2} lowOpacity />
    </PrimaryButton>
    <PrimaryButton red mt="0" onClick={() => handleRejectApplications(toBeRejected)}>
      {t('common.yesValidation')}
      <Icon icon="checkmark" mr="12" scale='2' />
    </PrimaryButton>
  </>
) : (
  <Typography tag="p" nomargin>{t('common.noCandidatesToBeRejected')}</Typography>
)}
                    <PrimaryButton transparent mt="20" onClick={() => setShowModal(false)}> 
                    {t('common.cancel')}
                    <Icon icon="cross" mr="12" scale='1' />
                    </PrimaryButton>
                    </Grid>
            </Modal>
            </>
        )}
                    {confirmation && (
                  <>
                  <Modal title={t('common.rejectedCandidates')} onClose={() => setConfirmation(false)}>
                    <Grid padding={25} fluid alignCenter>
                      <Typography tag="p" nomargin mb="20">{t('common.rejectedCandidatesDesc')}</Typography>
                      <PrimaryButton red mt="20" onClick={() => setConfirmation(false)}> 
                    {t('common.great')}
                    <Icon icon="thumbs_up" mr="8" scale='1' />
                    </PrimaryButton>
                    </Grid>
                  </Modal>
                  </>
                )}
<EditRejectionEmail positionId={positionId} ml="16" mb="25" open={open} onClose={handleClose} />
                </>
  )
};

export default RejectAll