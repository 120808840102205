// React
import React, { useEffect, useContext, useState } from "react";

// Api endpoints, translations, and hooks
import {
  makePage,
  getPage,
  getPositionPage,
} from "../../../../api/endpoints/cp";
import { hasPrivilege } from "../../../../utils/various";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// State management
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { PageContext } from "../../../context/PageContext";
import { useQueryClient } from "react-query";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { DispatchPositionContext } from "../../../context/PositionContext";

// Components
import { Col, Row } from "../../../../components/Grid";
import BlockEditor from "./BlockEditor/BlockEditor";
import Loading from "../../../../components/Loader/Loading";
// import SiteRelationDropdown from './SiteRelationDropdown';
/**
 * Renders a single career page (blockeditor & metasettings )
 * Creates new page if pageId is "new"
 * @param {object} props { pageId: 0 | "new", context: "page"|"position" }
 * @returns
 */
const SinglePage = ({ pageId, context, cp, sites }) => {
  const location = useLocation();
  const { dispatch } = useContext(PageContext);
  const queryClient = useQueryClient();
  let { id } = useParams();
  const positionDispatch = useContext(DispatchPositionContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const [loading, setLoading] = useState(true);

  const {
    state: {
      main: { subscription },
      privileges: { privileges, activeItems },
    },
    isLoading: subscriptionIsLoading,
  } = useContext(SubscriptionContext);

  const { state: pageState, dispatch: pageDispatch } = useContext(PageContext);

  const hasPositionInPathname = location.pathname.includes("position");
  const hasSiteInPathname = location.pathname.includes("site");

  // Position management - Create and GET position.
  useEffect(() => {
    let isMounted = true;

    if (hasPositionInPathname) {
      const handleCreateOrGetPage = async () => {
        try {
          pageDispatch({ type: "init_page" });
          const response = await getPositionPage({ selector: id });
          if (isMounted) {
            if (response.success) {
              dispatch({ type: "page_loaded", payload: response.data });

              positionDispatch({
                type: "add_page",
                payload: response.data,
              });
              setLoading(false);
            }
          }
        } catch (err) {
          if (isMounted) {
            // Check if the component is still mounted
            pageDispatch({ type: "error" });
            errorDispatch({ type: "set_error", payload: err.message });
          }
        }
      };

      handleCreateOrGetPage();
    }

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [hasPositionInPathname, id, pageDispatch, positionDispatch]);

  // Loading page
  useEffect(() => {
    if (hasSiteInPathname) {
      let isMounted = true;
      const initPage = async () => {
        try {
          dispatch({ type: "init_page" });
          if (hasPrivilege("create_cp", privileges, activeItems)) {
            //creating a new page
            const request = {
              params: {},
              body: {},
            };
            const response = await makePage(request);
            if (response.success && isMounted) {
              queryClient.invalidateQueries(["pages"]);
              dispatch({ type: "page_loaded", payload: response.data });

              setTimeout(function () {
                setLoading(false);
              }, 1000);
            } else if (!isMounted) {
              console.log("Component unmounted, aborting state update.");
            } else {
              throw new Error(response.data?.data);
            }
          } else {
            //getting an existing page
            if (pageId) {
              const response = await getPage({ selector: pageId });

              if (response.success && isMounted) {
                dispatch({ type: "page_loaded", payload: response.data });

                setTimeout(function () {
                  setLoading(false);
                }, 0);
              } else if (!isMounted) {
                console.log("Component unmounted, aborting state update.");
              } else {
                throw new Error(response.data.data);
              }
            } else {
              // console.log("pageId is not defined. Skipping getPage call.");
            }
          }
        } catch (err) {
          if (isMounted) {
            // Check if the component is still mounted
            dispatch({ type: "error", payload: err.message });
          } else {
            console.log("Component unmounted, aborting error handling.");
          }
        }
      };

      initPage();

      // Cleanup function to cancel asynchronous tasks when component unmounts
      return () => {
        isMounted = false;
      };
    }
  }, [
    errorDispatch,
    pageId,
    dispatch,
    hasSiteInPathname,
    hasPrivilege,
    queryClient,
  ]);

  //Clearing page state/context when unmounting. To prevent data being shown in wrong places
  useEffect(() => {
    return () => {
      dispatch({ type: "clear_state" });
    };
  }, [dispatch]);

  return (
    <>
      <Loading loading={loading} />
      <Row mt={30} flexLG="1" scrollable cp ptLG="100" pbLG="200" mh>
        <Col>
          <BlockEditor loading={loading} cp={cp} />
        </Col>
      </Row>
    </>
  );
};

export default SinglePage;
