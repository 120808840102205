import styled from "styled-components/macro";

export const StyledClosed = styled.div`
  min-height: 100vh;
  background: grey;
  display: flex;
  @media (max-width: 760px) {
    flex-direction: column;
    min-height: auto;
  }
`;
export const StyledLeftContainer = styled.div`
  background: ${(props) => props.theme.colors.vdgrey};
  min-height: 100%;
  padding: 150px;
  width: 50%;
  @media (max-width: 760px) {
    min-height: auto;
    width: 100%;
    padding: 80px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledRightContainer = styled.div`
  min-height: 100%;
  width: 50%;
  @media (max-width: 760px) {
    min-height: auto;
    width: 100%;
    padding: 100px 50px 100px 50px;
  }
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: center;
  align-items: center;
  position: relative;
  & > div:first-child() {
  }
  & > div:nth-child(2) {
    opacity: 0.2;
    position: absolute;
    right: 40px;
    bottom: 40px;
  }
`;
