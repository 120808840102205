// React
import React, { useContext } from "react";

// Translations, hooks, plugins, and API endpoints
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';

// Components
import { InputGroup } from "../../../../../../components/Input/Input";
import { Typography } from "../../../../../../components/Typography";

// State management
import { PageContext } from "../../../../../context/PageContext";
import { PADDING_VERTICAL } from "../blockConstants";

const CommonBlockSettings = ({ block, pt }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  return (
    <>
      {/* {JSON.stringify(block)} */}
      <InputGroup block={block} pt={pt}>
      <Typography tag="p" bold mb="4" mbXL="10">{t("component.block.padding_label", "Padding")}</Typography>
        <Typography tag="p" lowOpacity mb="10">{t(
          "component.block.padding_byline",
          "Choose the vertical padding for this section"
        )}</Typography>
      <Dropdown
  options={PADDING_VERTICAL.map((p) => ({ value: p.value, label: p.label }))}
  onChange={(selectedOption) => {
    dispatch({
      type: 'update_block',
      payload: {
        id: block.id,
        name: 'paddingVertical',
        value: selectedOption.value,
      },
    });
  }}
  value={block.paddingVertical || ''}
/>
      </InputGroup>
    </>
  );
};

export default CommonBlockSettings;

export const NotEditable = () => {
  const { t } = useTranslation();
  return (
    <p style={{ opacity: 0.5 }}>
      {t("component.block.not_editable", "Not editable")}
    </p>
  );
};
