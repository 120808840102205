import { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { responseHandler } from '../../../api/utilities/response';
import { parseRequest } from '../../../api/utilities/queries';

/**
 * Fetch pages from API using react query
 * Return error, status, isLoading, data, pages ( unnested data )
 * Takes one argument "queryOptions", which is an object like this
 * { siteId: siteId, fromMain: true } which is query params sent to api
 */
export const useFetchPages = (queryOptions) => {
  const [pages, setPages] = useState([]);
  const queryKey = ['pages', queryOptions];
  const { error, data, status, isLoading } = useQuery(
    queryKey,
    getPage
  );

  useEffect(() => {
    /**
     * Our api always has success property ( bool ) in response
     */
    if (data && data.success) {
      setPages(data.data);
    }
  }, [data]);

  return { error, status, isLoading, data, pages, queryKey };
};

const getPage = async ({ queryKey }) => {
  // console.log('getPage queryKey', queryKey);
  const incoming_params = queryKey[1];
  const { params } = parseRequest({ params: incoming_params });
  const route = `/cp/page/${params}`;
  const data = await axios.get(route);
  return responseHandler(data);
};
