import { useContext } from "react";
import KanbanCard from "../../views/Position/Kanban/KanbanCard/KanbanCard";
import { ApplicationParamsContext } from "../ApplicationList/ApplicationContextWrapper";

/**
 * Application card.
 * Used in applicationList, shortlist, allApplications, single position
 */
const ApplicationCard = ({ application }) => {
  const { dispatch } = useContext(ApplicationParamsContext);

  const handleCardClick = () => {
    // Dispatch an action to set the index to the app.id
    dispatch({ type: "set_index", payload: application.id });
  };

  return (
    <div onClick={handleCardClick}>
      <KanbanCard application={application} manageShareholder />
    </div>
  );
};

export default ApplicationCard;
