import styled from "styled-components/macro";


export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.colors.faceliftBlueLighter}; 
  border-radius: 5px; 
  background-color: ${props => props.theme.colors.white};
  @media (max-width: 1200px) {
    margin-bottom: 25px;
  }
`;

export const TableHeader = styled.div`
padding: 12px 12px; 
box-shadow: 0 3px 6px ${props => props.theme.colors.faceliftBlueLighter};
@media (min-width: 1200px) {
    padding: 12px 25px; 
}
`

export const TableHeaderElements = styled.div`
display: flex; 
justify-content: space-between;
width: calc(100% - 80px); 
h2 {
    font-weight: 400; 
}
p {
    &:last-of-type {
    margin-left: 25px;
    }
    @media (max-width: 768px) {
        display: none; 
    } 
}

`

export const TableContent = styled.div`
padding: 24px 12px;
display: flex;
justify-content: space-between;
width: calc(100% - 80px); 
h2 {
    font-weight: 500; 
    &:last-of-type {
        font-weight: 400; 
    }
}
p {
    &:last-of-type {
    margin-left: 25px; 
    }

}
h3 {
    font-size: 12px;
    color: ${props => props.theme.colors.faceliftBlueLight}; 
    text-transform: uppercase;
    margin-right: 25px; 
    font-weight: 400; 
    @media (max-width: 1199px) {
        display: none;
    }
}
@media (min-width: 1200px) {
    padding: 25px; 
}
`

export const TableActions = styled.div`
display: flex; 
justify-content: space-between;
width: 80px;
    padding-right: 24px;
    padding-left: 12px;
    padding-top: 26px;
`

export const PositionGreyList = styled.div`
padding: 2px 9px; 
background-color: #F3F3F5; 
border: 1px solid rgba(24, 31, 56, 0.15);
margin-top: 10px; 
border-radius: 10px; 
margin-right: 10px;
`


export const SelectedPositionsWrapper = styled.div`
@media (max-width: 768px) {
    display: none; 
}
`