import styled from "styled-components/macro";
import { ResetButton } from "../../../../../components/Button";

export const StyledBlockOuter = styled.div`
  padding: 8px 0px;
  width: 100%;
  padding: 0 19px; 
  margin-bottom: 10px;
  &.is-dropping {
    ${(props) =>
      props.dropOnTop ? "padding-top: 32px;" : "padding-bottom:32px;"};
    position: relative;
    &:after {
      content: "Drop to insert block here";
      vertical-align: middle;
      text-align: center;
      padding: 14px 16px;
      font-size: 9px;
      height: 40px;
      width: calc(100% - 60px);
      display: block;
      position: absolute;
      top: ${(props) => (props.dropOnTop ? "-8px" : "unset")};
      left: 0;
      color: ${(props) => props.theme.colors.mgrey};
      background: ${(props) => props.theme.colors.lgreytwo};
    }
  }
  &:first-of-type {
    padding-top: 0px;
  }
`;

export const StyledBlockContainer = styled.div`
  /* padding: 16px; */
width: 100%; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: -5px;
  position: relative; 
  z-index: 9; 
  border: 1px solid ${(props) => props.theme.colors.mgrey};
  background-color: ${props => props.theme.colors.white}; 
  border-top: none;
  transform: scaleY(0);
		transform-origin: top;
    max-height: 0;
  /* overflow: hidden; */
  /* Add transition for height property */
  transition: .5s; 
  &.open {
    transform: scaleY(1); 
    max-height: 5000px;
  }
`;

export const StyledBlockInner = styled.div``;

export const StyledBlockHeaderOuter = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  & > div {
    //icon and title
    height: 100%;
    display: flex;
    align-items: center;

    & > div,
    & > div svg {
      width: 18px;
    }
    & > p {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  border-radius: 5px;
  border: 1px solid
    ${(props) =>
      props.openAccordion
        ? props.theme.colors.mgrey
        : props.theme.colors.lgrey};
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px; 
  font-weight: 400;
  color: ${(props) => props.theme.colors.dgrey};
      background-color: ${props => props.theme.colors.white};
  height: 80px;
  border: 1px solid ${props => props.theme.colors.faceliftBlueLighter};
  ${(props) =>
    props.openAccordion &&
    `border-bottom: 1px solid ${props.theme.colors.mgrey};`}
`;

export const StyledBlockContent = styled.div`
  padding: 16px;
  padding-top: 32px; 
  border-top: 1px solid ${props => props.theme.colors.faceliftBlueLighter};
`;

export const StyledBlockTabRow = styled.div`
  display: flex;
  margin-top: 5px;
  background-color: ${(props) => props.theme.colors.lgreytwo};
  > div:last-child {
    flex: 1;
    border-bottom: 1px solid ${(props) => props.theme.colors.mgrey};
  }
`;
export const StyledBlockTab = styled(ResetButton)`
  /* background: red; */
  padding: 16px;
  position: relative;
  height: 100%;
  background: ${(props) => props.open && "#fff"};

  &:first-of-type {
    ${(props) =>
      props.open
        ? `
        border-bottom: 1px solid #fff;
        
        `
        : ` 
        border-right: 1px solid ${props.theme.colors.mgrey};
        border-bottom: 1px solid ${props.theme.colors.mgrey};
        border-bottom-right-radius: 5px;
    `};
  }
  &:last-of-type {
    ${(props) =>
      props.open
        ? `
        border-bottom: 1px solid #fff;
        `
        : ` 
        border-left: 1px solid ${props.theme.colors.mgrey};
        border-bottom-left-radius: 5px;
        border-bottom: 1px solid ${props.theme.colors.mgrey};
        `};

    & + div {
      ${(props) =>
        props.open &&
        `
      
      border-left: 1px solid ${props.theme.colors.mgrey};
      border-bottom-left-radius: 5px;
      `};
    }
    /* border-bottom: 1px solid ${(props) => props.theme.colors.mgrey}; */
    /* border-bottom-left-radius: 5px; */
  }
`;

export const ColorPicker = styled.input`
            display: inline-block;
          width: 30px;
          height: 30px;
          background-color: ${props=> props.pLabel ? props.pLabel : null}; 
          border: 1px solid ${props => props.theme.colors.faceliftBlueLighter};
          margin-right: 10px;
          position: relative; 
          cursor: pointer;
          appearance: none;
          border-radius: 5px; 
          z-index: 999; 
          transition: .5s ease; 

          &.active {
            ::before {
              content: '';
              position: absolute;
              top: calc(50% - 19px);
              left: calc(50% - 19px);
              border-radius: 5px;  
              width: 38px; 
              height: 38px; 
              border: 2px solid ${props => props.theme.colors.red}; 
              animation: beforeAnim .5s ease; 
            }
            @keyframes beforeAnim {
              0% {
                opacity: 0; 
              }
              100% {
                opacity: 100%; 
              }
            }
          }
`

export const ImagePositionButton = styled.button`
position: relative;
  padding-left: 25px; 
  padding-right: 25px; 
  display: flex; 
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.faceliftBlueLight}; 
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.mgrey}; 
  height: 40px; 
  margin-right: 10px; 
  background-color: white; 
  &.active {
    border: 1px solid ${props => props.theme.colors.red}; 
    transition: .5s ease; 
  }

`