import styled from "styled-components/macro";

export const StyledBlockAdder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;

`;
