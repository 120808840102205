import styled from "styled-components/macro";
import { InputGroup } from "../../../components/Input/Input";

export const StyledTitleBar = styled.div`
width: 100%;
margin-right: 16px; 
  ${InputGroup} {
    label {
      margin-right: 16px;
      flex-shrink: 0;
    }
    display: flex;
  }
`;

export const StyledPageControl = styled.div`
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 16px;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
