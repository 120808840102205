import React, { useEffect, useReducer } from "react";
import SingleField from "./SingleField/SingleField";
import { fieldsReducer, initialFieldsState } from "./reducer";
import { IsLoading } from "../../recruiter/style";
import { StyledFileField } from "./style";
import { InputLabelWithByline } from "../Input/Input";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Button";

const FileField = ({
  initialFiles,
  onUploadStart,
  onUploadEnd,
  onUpload,
  onRemove,
  notes,
  dynamicAddRemove,
  maxFields = 5,
  type,
  applicant,
  attachment,
  docType,
  label,
  boldLabel,
  largeButton,
  byline,
  isLoading,
  overrideButton,
  disabled,
  darkBackground,
  required,
  useStyleVars,
  transparentButton,
  uploadBtn,
  headerImg,
  mt,
  mb,
  noBorder,
  mtLG,
  pt,
  pbLG,
  page,
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(fieldsReducer, {
    ...initialFieldsState,
    dynamicAddRemove: dynamicAddRemove,
  });

  const onFileUploadCompleted = (data) => {
    onUpload(data);
  };

  const addNewField = () => {
    dispatch({ type: "add_field" });
  };

  const handleOnUploadStarted = () => {
    if (typeof onUploadStart === "function") onUploadStart();
  };
  const handleOnUploadEnded = () => {
    if (typeof onUploadEnd === "function") onUploadEnd();
  };

  const onFileRemove = (data) => {
    onRemove(data);
  };

  useEffect(() => {
    if (initialFiles && !state.anyIsUploading) {
      dispatch({ type: "initial_files", payload: initialFiles });
    }
  }, [initialFiles]);

  if (isLoading) return <IsLoading />;
  return (
    <StyledFileField
      attachment={attachment}
      mt={mt}
      mb={mb}
      mtLG={mtLG}
      block
      noBorder={noBorder}
      pbLG={pbLG}
      pt={pt}
      notes={notes}
      applicant={applicant}
    >
      {label && (
        <InputLabelWithByline
          label={label}
          boldLabel={boldLabel}
          byline={byline}
          required={required}
        />
      )}

      {state.fields.map((field) => (
        <SingleField
          applicant={applicant}
          page={page}
          headerImg={headerImg}
          type={type || "other"}
          docType={docType || "other"}
          key={field.id}
          id={field.id}
          transparentButton={transparentButton}
          onFileUploadStarted={handleOnUploadStarted}
          onFileUploadEnded={handleOnUploadEnded}
          onFileUploadCompleted={onFileUploadCompleted}
          onFileRemove={onFileRemove}
          fieldDispatch={dispatch}
          fieldState={state}
          overrideButton={overrideButton}
          disabled={disabled}
          darkBackground={darkBackground}
          useStyleVars={useStyleVars}
          attachment={attachment}
          uploadBtn={uploadBtn}
          largeButton={largeButton}
        />
      ))}

      {dynamicAddRemove &&
        state.fields[state.fields.length - 1].hasFile &&
        state.fields.length < maxFields && (
          <PrimaryButton
            useStyleVars={useStyleVars}
            onClick={addNewField}
            applicant
            mt="10"
            mb="50"
            largeButton={largeButton}
          >
            {t("application_form.add_more", "Add more")}
          </PrimaryButton>
        )}
    </StyledFileField>
  );
};

export default FileField;
