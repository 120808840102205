import { useState } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components/macro';
import ApplicationDropArea from './ApplicationDropArea';

const ApplicationDropContainer = ({ primary, secondary }) => {
  const [itemShortlisted, setItemShortlisted] = useState(false); //To determine whether to show secondary drop area
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    canDrop: () => true,
    hover: (item) => {
      setItemShortlisted(item.shortlist);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  return (
    <>
      <StyledDropcontainer ref={drop}>
        {primary && (
          <ApplicationDropArea
            actionKeys={primary}
            visible={isOver}
          />
        )}
        {/* used as optionally unlisting shortlisted item */}
        {secondary && itemShortlisted && (
          <ApplicationDropArea
            actionKeys={secondary}
            visible={isOver}
          />
        )}
      </StyledDropcontainer>
    </>
  );
};

export default ApplicationDropContainer;

const StyledDropcontainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  height: 100%;
  width: 100%;
overflow-y: scroll;
  & > div:first-child {
    flex: 2 1 auto;
  }
`;
