// React
import React, { useRef, useEffect } from 'react';

// Utility functions and hooks
import { useOffClick } from '../../recruiter/hooks/useOffClick';

// Icon
import Icon from '../Icon';

// Styled components 
import { StyledModal, StyledModalContent, ModalHeader, CloseModal, ModalWidthContainer } from './Modal.styled';

const Modal = ({
  tiny,
  blind,
  children,
  noBorder,
  onClickOutside,
  title,
  openAnimation,
  onClose,
  containWidth,
  noClose
}) => {
  const ref = useRef();
  useOffClick(ref, () => {
    if (onClickOutside) {
      onClickOutside();
    }
  });

  useEffect(() => {

  })

  return (
    <StyledModal blind={blind}>
      <StyledModalContent tiny={tiny} noBorder={noBorder} ref={ref}>
        <ModalHeader>
            <h2>{title}</h2>
        </ModalHeader>
        {!noClose && (
        <CloseModal>
        <Icon icon="cross" pointer onClick={onClose} />
      </CloseModal>
        )}
        <ModalWidthContainer containWidth={containWidth}>
        {children}
        </ModalWidthContainer>
      </StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
