import styled from "styled-components/macro";

export const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181F38;
  z-index: 99999999;
  pointer-events: none; 
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    text-align: center;
  }
  svg {
    max-width: 250px; 
    animation: fading 4s infinite;
    animation-delay: 2.5s;  
  }
  ${props => props.fadeOut && `
  animation: fadingLoader .5s ease forwards;
  `}
  @keyframes fadingLoader {
    0% {
      opacity: 1;
    }
    99% {
      display: flex; 
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  @keyframes fading {
    0% {
      opacity: 1; 
    }
    50% {
      opacity: 0.1; 
    }
    100% {
      opacity: 1; 
    }
  }
`;


export const StyledLoadingContainer = styled.div`
position: fixed; 
top: 0; 
right: 0; 
height: 100vh; 
width: 100vw; 
background-color: #F3F3F5; 
z-index: 9999999999; 
display: flex; 
align-items: center;
justify-content: center;
opacity: 1;
pointer-events: none;
svg {
  max-width: 300px; 
}
@media (min-width: 1200px) {
  width: calc(100vw - 240px); 
}
${props => !props.$loading && `
animation: fadeOut .5s ease forwards;
`}
@keyframes fadeOut {
  0% {
    opacity: 1; 
  }
  99% {
    display: flex;
  }
  100% {
    display: none;
    pointer-events: none;
    opacity: 0; 
  }
}
`

export const ButtonLoader = styled.div`
position: fixed; 
right: 0; 
top: 0; 
height: 100vh; 
width: calc(100vw);
/* background-color: rgba(0, 0, 0, 0.1);  */
background-color: rgba(0, 0, 0, 0.2); 
${props => props.nobg && `background-color: transparent;`}
z-index: 99999999; 
animation: fadeIn .5s ease forwards; 
pointer-events: none; 
@keyframes fadeIn {
  0% {
    opacity: 0; 
  }
  100% {
    opacity: 1; 
  }
}
@media (min-width: 1200px) {
  width: calc(100vw - 240px);
  ${props => props.fw && `width: 100vw;`};
}
`

export const UpdateLoading = styled.div`
width: 336px; 
height: 50px; 
position: fixed; 
left: calc(50% - 336px/2); 
bottom: 40px;
${props => props.success && `background-color: #181F38`};
${props => props.error && `background-color: #FF385C`};
border: 1px solid rgba(24, 31, 56, 0.06);
color: #FEFEFF;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px; 
border-radius: 5px; 
font-weight: 500; 
z-index: 99999999; 
animation: fadeInOut 3s ease forwards;
opacity: 0; 
@keyframes fadeInOut {
  0% {
    opacity: 0; 
  }
  30% {
    opacity: 1; 
  }
  80% {
    opacity: 1; 
  }
  100% {
    opacity: 0; 
  }
}
`