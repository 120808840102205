import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router";
import {
  deleteRecruiter,
  getRecruiters,
  updateRecruiter,
} from "../../../api/endpoints/recruiter";
import {
  BoxLoader,
  InputFieldWithLabel,
  Button,
  VerticalDivider,
} from "../../../components";
import { Typography } from "../../../components/Typography";
import { ContextMenu, MegaHeader } from "../../components";
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { Grid, Row, Col } from "../../../components/Grid";
import { StateUserContext } from "../../../context/UserContext";
import { DispatchMessageContext } from "../../../context/MessageContext";
import { fullName } from "../../../utils/various";
import { useTranslation } from "react-i18next";

const User = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const { user } = useContext(StateUserContext);
  const [single, setSingle] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);

  useEffect(() => {
    setIsLoading(true);
    //fetch single user
    const fetchSingleUser = async (id) => {
      try {
        const request = { selector: id };
        const response = await getRecruiters(request);
        if (response.success) {
          setSingle(response.data);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        errorDispatch({ type: "set_error", payload: JSON.stringify(err) });
      }
    };
    fetchSingleUser(id);
  }, [id, errorDispatch]);

  const handleDelete = async (e) => {
    e.preventDefault();
    const confirmedDelete = window.confirm(
      t("page.user.confirm_delete_user", {
        first_name: single.firstName,
        last_name: single.lastName,
      })
    );
    if (!confirmedDelete) return false;
    if (confirmedDelete) {
      setIsSubmitting(true);
      try {
        const request = { selector: single.id };
        const response = await deleteRecruiter(request);
        if (response.success) {
          messageDispatch({
            type: "set_message",
            payload: t("message.user_deleted"),
          });
          history.push("/user");
        } else {
          errorDispatch({
            type: "set_error",
            payload: t("error.user_deletion_error"),
          });
        }
      } catch (err) {
        errorDispatch({ type: "set_error", payload: JSON.stringify(err) });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const request = {
        selector: single.id,
      };
      const response = await updateRecruiter(request);
      if (response.success) {
        console.log('success')
      } else {
        console.log('error')
      }
      setIsSubmitting(false);
    } catch (err) {
      errorDispatch({ type: "set_error", payload: JSON.stringify(err) });
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <ContextMenu goBack>
        <div>
          <Button
            uppercase
            urlLike
            transparent
            faded
            onClick={() => history.goBack()}
          >
            {t("common.cancel")}
          </Button>
          <VerticalDivider />

          <Button
            urlLike
            transparent
            highlight
            bold
            onClick={handleSubmit}
            disabled={isSubmitting}
            uppercase
          >
            {t("common.save")}
          </Button>
        </div>
      </ContextMenu>
      {!isLoading && single ? (
        <>
          <MegaHeader
            text={fullName(single.firstName, single.lastName)}
            lineThrough
          />
          <Grid fluid>
            <Row>
              <Col>
                <Typography tag="buttonLike">{t("common.role")}</Typography>
                {single && (
                  <Typography tag="span" bold>
                    {single.role}
                  </Typography>
                )}
              </Col>
            </Row>
            <Row>
              <Col size={1} collapse="xl" />
              <Col size={10} break="lg">
                <form>
                  <Row>
                    <Col>
                      <Typography bold tag="h3" lineThrough>
                        {t("page.user.user_info")}
                      </Typography>
                    </Col>
                  </Row>
                  <Row smallerInnerGutter>
                    <Col break="sm">
                      <InputFieldWithLabel
                        label={false}
                        disabled
                        tiny
                        placeholder={t("common.first_name")}
                        value={single.firstName}
                      />
                    </Col>
                    <Col>
                      <InputFieldWithLabel
                        label={false}
                        disabled
                        tiny
                        placeholder={t("common.last_name")}
                        value={single.lastName}
                      />
                    </Col>
                  </Row>
                  <Row smallerInnerGutter>
                    <Col break="sm">
                      <InputFieldWithLabel
                        label={false}
                        disabled
                        tiny
                        placeholder={t("common.phone_number")}
                        value={single.phoneNumber}
                      />
                    </Col>
                    <Col break="sm">
                      <InputFieldWithLabel
                        label={false}
                        disabled
                        tiny
                        placeholder={t("common.email")}
                        value={single.email}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Typography bold tag="h3" lineThrough>
                        {t("page.user.account_options")}
                      </Typography>
                      {user.id === single.id && (
                        <Button
                          secondary
                          buttonColorDark
                          onClick={() => history.push("/configure/account")}
                        >
                          {t("page.user.go_to_account")}
                        </Button>
                      )}
                      {user.id !== single.id && (
                        <Button
                          secondary
                          buttonColorDark
                          onClick={(e) => handleDelete(e)}
                        >
                          {t("page.account.delete_account")}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Grid>
        </>
      ) : (
        <BoxLoader />
      )}
    </div>
  );
};

export default User;
