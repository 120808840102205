import styled from 'styled-components/macro';

export const StyledMegaHeader = styled.div`
  display: flex;
  align-items: center;
  > h1 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.faceliftBlue};
    font-weight: medium;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    > h1 {
      font-size: 18px;
      font-weight: 500; 
      width: 100%;
    }
  }
`;
