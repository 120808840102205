import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InputFieldWithLabel } from "../../../components";
import { Col, Grid, Row } from "../../../components/Grid";
import { PageContext } from "../../context/PageContext";

import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../context/PositionContext";
import { StyledTitleBar } from "./style";
import { makePage } from "../../../api/endpoints/cp";
import { DispatchMessageContext } from "../../../context/MessageContext";
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";

// Can be type page or position. Type page uses PageContext, type position does not
const TitleBar = ({ type }) => {
  const { t } = useTranslation();

  const {
    state: { privileges },
  } = useContext(SubscriptionContext);
  const { state: pageState, dispatch: pageDispatch } = useContext(PageContext);
  const { single, isLoading: positionIsLoading } =
    useContext(StatePositionContext);
  const positionDispatch = useContext(DispatchPositionContext); //update position state

  const messageDispatch = useContext(DispatchMessageContext);
  const errorDispatch = useContext(DispatchErrorContext);

  /**
   * Create a new page for this position
   */
  const handleCreateNewPage = async () => {
    try {
      if (!single.id) {
        throw new Error("Save position before creating page");
      }
      pageDispatch({ type: "init_page" });
      const request = { params: { positionId: single.id } };
      const response = await makePage(request);
      if (response.success) {
        pageDispatch({ type: "page_loaded", payload: response.data });
        //update position with this page object
        positionDispatch({
          type: "add_page",
          payload: response.data,
        });
        messageDispatch({
          type: "set_message",
          payload: "Created a new page",
        });
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      pageDispatch({ type: "error" });
      errorDispatch({ type: "set_error", payload: err.message });
    }
  };

  // singlePageReducer
  return (
    <StyledTitleBar>
      <Grid fluid>
        <Row>
          <Col break="sm">
            {/* page title  */}
            <InputFieldWithLabel
              type="text"
              name="title"
              tiny
              label={false}
              required
              positionTitle
              editPositionTitle
              placeholder={
                type === "page"
                  ? t("page.single_page.page_title_label", "Page title")
                  : t("page.position.position_title")
              }
              disabled={
                type === "page"
                  ? pageState?.page?.frontpage ||
                    pageState.page.positionId ||
                    pageState.isLoading
                  : single.unsolicited
              }
              onChange={(e) => {
                if (type === "page") {
                  pageDispatch({
                    type: "update_page",
                    payload: { name: "title", value: e.target.value },
                  });
                } else if (type === "position") {
                  //update position title
                  positionDispatch({
                    type: "update_position_field",
                    payload: [e.target.name, e.target.value],
                  });
                }
              }}
              value={type === "page" ? pageState.page.title : single.title}
            />
          </Col>
        </Row>
      </Grid>
    </StyledTitleBar>
  );
};

export default TitleBar;
