// Styled components
import styled from "styled-components/macro";

export const PromoteHiBoostWrapper = styled.div`
height: 100%; 
width: 100%; 
display: flex;
flex-direction: column;
align-items: center;
.label {
    font-size: 14px;
    display: flex;
    align-items: center;
    &:last-of-type {
    margin-left: 25px;
    } 
    input {
          /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  height: 15px; 
  width: 15px; 
  cursor: pointer;
  border-radius: 5px; 
  border: 1px solid ${props => props.theme.colors.red};
          margin-right: 5px; 
          position: relative; 
          &::before {
            content: '';
            position: absolute;
            top: calc(50% - 5px); 
            left: calc(50% - 5px);
            height: 10px; 
            width: 10px; 
            background-color: ${props => props.theme.colors.red};
            border-radius: 3px; 
              transform: scale(0);
  transition: 420ms transform ease-in-out;
          }
    }
    input:checked::before {
        transform: scale(1);
    }
}
a {
    font-size: 14px; 
}
`

export const HiBoostDescription = styled.div`
padding: 32px; `

export const HiBoostSolution = styled.div`
padding: 32px; 
border-top: 1px solid #F0F0F3;
border-bottom: 1px solid #F0F0F3;
width: 100%; 
`