import styled from "styled-components/macro";

export const StyledGenericList = styled.div`
  text-align: right;
  ${(props) =>
    props.background
      ? `
    padding: 25px;    
    background: ${props.theme.colors.lgreytwo};
    border-radius: 5px;
    // @media ( max-width: ${props.theme.breakpoints.lg} ) {
    //   margin-left: -25px;margin-right: -25px;
    // }
    `
      : ``}
`;
