// React
import React, { useContext } from "react";

// Translations, libraries, API endpoints, and custom hooks
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { destroyPage } from "../../../../../../../api/endpoints/cp";
import { formatDate } from "../../../../../../../utils/various";

// React Router
import { useHistory } from "react-router";

// Components
import MetaEditorGroup from "../../../../../MetaEditor/MetaEditorGroup/MetaEditorGroup";
import StatusItem from "./StatusItem";

// State management
import { DispatchErrorContext } from "../../../../../../../context/ErrorContext";
import { PageContext } from "../../../../../../context/PageContext";
import { DispatchPositionContext } from "../../../../../../context/PositionContext";

const PublishMG = ({ context }) => {
  const { t } = useTranslation();

  const positionDispatch = useContext(DispatchPositionContext);
  const { state, dispatch } = useContext(PageContext);
  const queryClient = useQueryClient();
  const errorDispatch = useContext(DispatchErrorContext);
  const history = useHistory();
  //Deleting page
  const onDeleteHandler = async () => {
    const confirm = window.confirm("Delete page?");
    if (confirm) {
      dispatch({ type: "init_page" });
      try {
        const request = { selector: state.page.id };
        const response = await destroyPage(request);
        if (response.success) {
          queryClient.invalidateQueries(["pages"]);
          if (context === "page") {
            history.push(`/cp/site/${state.page.siteId}`);
          } else if (context === "position") {
            dispatch({ type: "clear_state" });
            positionDispatch({ type: "add_page", payload: null });
          }
        } else {
          throw Error(response.data?.data || "Error");
        }
      } catch (err) {
        console.log(err);
        errorDispatch({ type: "set_error", payload: err.message });
      }
    }
  };

  return (
    <MetaEditorGroup hideOnMobile name={t("common.status", "Status")}>
      <div>
        {state.page.position && (
          <StatusItem
            label={t("common.applicationFormStatusLabel", "State")}
            value={
              state.page.position.status === "active"
                ? t("common.active", "Active")
                : t("common.inactive", "Inactive")
            }
          />
        )}
        <StatusItem
          label={t("common.publishedStatusLabel", "Status")}
          value={
            state.page.status === "publish"
              ? t("common.is_published", "Published")
              : t("common.draft", "Draft")
          }
        />

        <StatusItem
          label={t("common.dateCreated", "Date created")}
          value={
            state.page.publishedAt ? formatDate(state.page.publishedAt) : "N/A"
          }
        />
      </div>
    </MetaEditorGroup>
  );
};

export default PublishMG;
