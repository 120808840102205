// React
import React from 'react'

// Styled
import { LargeBlockStyled } from '../ViewStats.styled'
import { Typography } from '../../../../components/Typography'

const LargeBlock = ({count, title}) => {
  return (
    <LargeBlockStyled>
        <Typography tag="p" nomargin fontSize="16" bold mb="10">{count}</Typography>
        <Typography tag="p" nomargin fontSize="12">{title}</Typography>
    </LargeBlockStyled>
  )
}

export default LargeBlock