// React
import { useEffect, useState } from "react";

// Api endpoints
import { getPositions } from "../../api/endpoints/position";
import { getApplications } from "../../api/endpoints/application";
import { getRecruiters } from "../../api/endpoints/recruiter";
import { getPrivileges } from "../../api/endpoints/subscription";
import { getPage } from "../../api/endpoints/cp";

const useDashboardData = () => {
  const [positionsCount, setPositionsCount] = useState(0);
  const [activePositionsCount, setActivePositionsCount] = useState(0);
  const [recruiters, setRecruiters] = useState();
  const [recruitersCount, setRecruitersCount] = useState();

  const [pageCount, setPageCount] = useState();
  const [privileges, setPrivileges] = useState(null);
  const [shortlistedCandidatesCount, setShortlistedCandidatesCount] =
    useState(0);
  const [hiredCandidatesCount, setHiredCandidatesCount] = useState(0);
  const [totalCandidatesCount, setTotalCandidatesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get all positions
        const positionsResponse = await getPositions();
        const positions = positionsResponse.data;
        setPositionsCount(positions.length);

        // Filter active positions
        const activePositions = positions.filter(
          (position) => position.status === "active"
        );
        setActivePositionsCount(activePositions.length);

        // Get all recruiters
        const recruitersResponse = await getRecruiters();
        const recruiters = recruitersResponse.data;
        setRecruiters(recruiters);
        setRecruitersCount(recruiters.length);

        // Get all applications
        const applicationsResponse = await getApplications();
        const applications = applicationsResponse.data;

        // Filter hired candidates
        const hiredApplications = applications.filter(
          (application) =>
            application.status === "hired" || application.substatus === "hired"
        );
        setHiredCandidatesCount(hiredApplications.length);

        // Filter shortlisted candidates
        const shortlistedApplications = applications.filter(
          (application) =>
            application.status === "shortlisted" ||
            application.substatus === "shortlisted" ||
            application.shortlist === true
        );

        setShortlistedCandidatesCount(shortlistedApplications.length);

        // Total candidates count
        setTotalCandidatesCount(applications.length);

        // Get all pages
        const pageResponse = await getPage();
        const pageRes = pageResponse.data;
        setPageCount(pageRes.length);

        // Get privileges
        const privilegeResponse = await getPrivileges();
        setPrivileges(privilegeResponse.data.privileges);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchData();
  }, []);

  return {
    positionsCount,
    activePositionsCount,
    hiredCandidatesCount,
    shortlistedCandidatesCount,
    totalCandidatesCount,
    pageCount,
    recruiters,
    recruitersCount,
    privileges,
    isLoading,
    error,
  };
};

export default useDashboardData;
