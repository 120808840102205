export const errorResponseHandler = (err) => {
  console.log(err);
  if (err.response) {
    // Request made and server responded
    console.log({
      data: err.response.data,
      status: err.response.status,
      headers: err.response.headers,
    });
    return err.response;
  } else if (err.request) {
    // The request was made but no response was received
    console.log(err.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", err.message);
  }
};

export const responseHandler = (response) => {
  if (response.data) {
    return response.data;
  }

  return response;
};
