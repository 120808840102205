import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getSite } from '../../../api/endpoints/cp';

export const useFetchSites = (siteId) => {
  const [activeSite, setActiveSite] = useState(null);
  const [sites, setSites] = useState([]);

  const { isLoading, isError, data } = useQuery(['sites'], getSite);

  useEffect(() => {
    /**
     * This handles data from response "getSite" and sets in state
     */
    if (data && data.success) {
      const { data: fetched_sites } = data;
      setSites(fetched_sites);
      if (!siteId) {
        setActiveSite(
          fetched_sites.find((site) => {
            return site.main;
          })
        );
      } else {
        setActiveSite(
          fetched_sites.find((site) => {
            return site.id == siteId;
          })
        );
      }
    }
  }, [data, siteId]);

  return { activeSite, sites, isLoading, isError };
};
