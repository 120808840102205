// React
import React from 'react'

// Styled components
import { SmallerBlockStyled, SmallerBlockLeft, SmallerBlockRight } from '../ViewStats.styled'

const SmallerBlock = ({count, title}) => {
  return (
    <SmallerBlockStyled>
        <SmallerBlockLeft>
            {count}
        </SmallerBlockLeft>
        <SmallerBlockRight>
            {title}
        </SmallerBlockRight>
    </SmallerBlockStyled>
  )
}

export default SmallerBlock