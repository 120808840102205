// React
import React, { useState, useContext, useRef, useEffect } from "react";

// Components
import { Col, Grid, Row } from "../../../../components/Grid";
import { Typography } from "../../../../components/Typography";
import {InputFieldWithLabel,} from "../../../../components";
import { DropdownContainer, InviteCandidateFormContainer } from "./InviteCandidate.styled";
import { PrimaryButton } from "../../../../components/Button";
import { Icon } from "../../../../components";
import {Modal} from "../../../../components";

// External imports
import { Formik, Field } from "formik";

// API 
import { inviteApplicant } from "../../../../api/endpoints/applicant";
import ReactDropdown from "react-dropdown";

// State management
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { DispatchMessageContext } from "../../../../context/MessageContext";
import { StatePositionContext } from "../../../context/PositionContext";
import { ApplicationParamsContext } from "../../../components/ApplicationList/ApplicationContextWrapper";

// Router and translations
import { useTranslation } from "react-i18next";

const InviteCandidate = ({onClose, openConfirmationModal}) => {
  const { t } = useTranslation();
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { state, dispatch } = useContext(ApplicationParamsContext);

  //Formik external form handling
  const formRef = useRef();
  const handleSubmit = async () => {
    if (formRef.current) {
      const formikProps = formRef.current;
      await formikProps.submitForm();

      if (!formikProps.isSubmitting && formikProps.isValid) {
        try {
          const request = {
            body: { ...formikProps.values, positionId: positionId },
          };
          const response = await inviteApplicant(request);
          if (response.success) {
            dispatch({ type: 'update_list', payload: [...state.list, response.data[0]] });
            // dispatch({ type: 'update_list', payload: [...state.list, ...response.data[0]] });
            formikProps.resetForm({});
            messageDispatch({ type: "set_message", payload: t('message.succesful_invite') });
            openConfirmationModal(); // Open the confirmation modal
            onClose();

          } else {
            errorDispatch({
              type: "set_error",
              payload: response.data.data,
            });
          }
        } catch (err) {
          errorDispatch({
            type: "set_error",
            payload: JSON.stringify(err),
          });
        }
      }
    }
  };  

  // Fetch positions and create dropdown
  const { positions } = useContext(StatePositionContext);
  const [activePositions, setActivePositions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [positionId, setPositionId] = useState(null);

  useEffect(() => {
    // Filter active positions
    const activePositions = positions.filter(position => position.status === 'active');
    // Map positions to options for react-dropdown
    const options = activePositions.map(position => ({ value: position.id, label: position.title }));
    setActivePositions(options);
    // Set default option to the first active position, if any
    if (options.length > 0) {
      setDefaultOption(options[0]);
      setPositionId(options[0].value);
    }
  }, [positions]);

  const handleChange = option => {
    setPositionId(option.value);
  };

  return (
    <div>
      {showConfirmationModal && (
        <>
        <Modal title="test">

        </Modal>
        </>
      )}
      <Grid padding={32} fluid >
        <Typography tag="p" center>
          {t('common.inviteCandidateDescription')}
        </Typography>
        <DropdownContainer>
      <ReactDropdown options={activePositions} onChange={handleChange} value={defaultOption} />
            <Formik
              initialValues={{
                email: "",
                firstName: "",
                lastName: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                try {
                  const request = {
                    body: { ...values, positionId: positionId },
                  };
                  const response = await inviteApplicant(request);
                  if (response.success) {
                    resetForm({});
                    messageDispatch({type:"set_message", payload: t('message.succesful_invite')});
                  } else {
                    errorDispatch({
                      type: "set_error",
                      payload: response.data.data,
                    });
                  }
                } catch (err) {
                  errorDispatch({
                    type: "set_error",
                    payload: JSON.stringify(err),
                  });
                }
              }}
              innerRef={formRef}
            >
              {(props) => {
                const { handleSubmit } = props;
                return (
                  <InviteCandidateFormContainer>
                  <form onSubmit={handleSubmit}>
                    <Row smallerInnerGutter>
                      <Col>
                        <Field
                          tiny
                          label={false}
                          as={InputFieldWithLabel}
                          placeholder={t('common.first_name')}
                          name="firstName"
                        />
                      </Col>
                      <Col>
                        <Field
                          tiny
                          label={false}
                          as={InputFieldWithLabel}
                          placeholder={t('common.last_name')}
                          name="lastName"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          as={InputFieldWithLabel}
                          tiny
                          label={false}
                          placeholder={t('common.email')}
                          name="email"
                        />
                      </Col>
                    </Row>
                  </form>
                  </InviteCandidateFormContainer>
                );
              }}
            </Formik>
            <PrimaryButton
            onClick={handleSubmit}
            red
          >
            {t('common.sendInvitation')}
            <Icon icon="send_message" mr="10" />
          </PrimaryButton>
          <PrimaryButton transparent mt="18" onClick={onClose}>
            {t('common.cancel')}
            <Icon icon="cross" mr="10" invert />
          </PrimaryButton>
          </DropdownContainer>
      </Grid>
    </div>
  );
};

export default InviteCandidate;
