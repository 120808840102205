/**
 * Go through every key in object and remove / delete empty things
 * @param {object} obj
 * @param {object} options { remove : ["optionName"]} // property remove is an arr of strings property names to remove from object ( regardless of empty or not )
 * @returns clean object
 */
const cleanObject = (obj, options = {}) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      (Array.isArray(obj[propName]) && obj[propName].length === 0) ||
      isEmpty(obj[propName]) ||
      (options["remove"] && options["remove"].indexOf(propName) > -1)
    ) {
      delete obj[propName];
    }
  }
  return obj;
};
export default cleanObject;

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
