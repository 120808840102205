import styled from 'styled-components';

export const StyledShortlistContainer = styled.div``;

export const StyledShortlist = styled.div`
  margin-top: 40px;
  background-color: ${(props) => props.theme.colors.lgreytwo};
  border: 1px solid ${(props) => props.theme.colors.lgrey};
  border-radius: 5px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: -${(props) => props.theme.grid.gutter};
    margin-right: -${(props) => props.theme.grid.gutter};
  }
`;

export const StyledInviteSection = styled.div`
  & > div:first-child {
    display: flex;
    align-items: flex-end;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      align-items: center;
    }
    justify-content: space-between;
    & > div {
      //headline
      font-size: 28px;
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 16px;
      }
      margin: 0;
    }
    & > button {
      margin-right: -16px;
    }
  }
  padding: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 16px;
    h3 {
      font-size: 16px;
    }
  }
  border-bottom: 1px solid ${(props) => props.theme.colors.lgrey};
`;

export const StyledCandidateSection = styled.div`
  padding: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 16px;
    h3 {
      font-size: 16px;
      margin-top: 8px;
    }
  }
`;

export const SharedContainer = styled.div`
width: 100%; 
border: 1px solid #E7E8EA; 
border-radius: 5px; 
`