// React
import React, { useEffect, useState } from "react";
// import React, { useContext, useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

// Utility functions, hooks, and API endpoints
import useUserData from "../../../utils/hooks/useUserData";
import useDashboardData from "../../../utils/hooks/useDashboardData";

// Components
import { TopBarTop, TopBarBottom } from "../../components/TopBar/style";
import {
  BlockWrapper,
  LargeBlockContainer,
  ViewStatsWrapper,
  SmallBlockContainer,
} from "./ViewStats.styled";
import { Typography } from "../../../components/Typography";
import LargeBlock from "./LargeBlock/LargeBlock";
import SmallerBlock from "./SmallerBlock/SmallerBlock";
import { Flex } from "../../style";

const ViewStats = ({ stats, isHidden }) => {
  const { t, i18n } = useTranslation();

  // Get todays date
  const today = new Date();

  // Get user data
  const { userData } = useUserData();

  // Get and format todays date
  const formatDate = (date) => {
    // initial formatted date string
    let formattedDate = "";

    // Get language
    const language = i18n.language;

    // Get day of the week
    const dayOfWeek = date.toLocaleDateString(undefined, { weekday: "long" });
    const dayOfWeekTranslated = t(
      "time.day." + dayOfWeek.toLowerCase(),
      dayOfWeek
    );
    // Make dayOfWeekString first letter uppercase
    formattedDate +=
      dayOfWeekTranslated.charAt(0).toUpperCase() +
      dayOfWeekTranslated.slice(1);
    formattedDate += ", ";

    // Get day of the month
    const dayOfMonth = date.toLocaleDateString(undefined, { day: "numeric" });
    formattedDate += dayOfMonth;

    let suffix = "";
    if (language === "en-US") {
      suffix = "th";
      if (dayOfMonth === "1" || dayOfMonth === "21" || dayOfMonth === "31") {
        suffix = "st";
      } else if (dayOfMonth === "2" || dayOfMonth === "22") {
        suffix = "nd";
      } else if (dayOfMonth === "3" || dayOfMonth === "23") {
        suffix = "rd";
      }
    }

    // Add suffix to dayOfMonth
    formattedDate += suffix;
    formattedDate += " ";

    // Get month
    const month = date.toLocaleDateString(undefined, { month: "long" });
    formattedDate += t("time.month." + month.toLowerCase(), month);

    // return formatted date
    return formattedDate;
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  // useDashboardDataHook
  const {
    positionsCount,
    activePositionsCount,
    hiredCandidatesCount,
    totalCandidatesCount,
    shortlistedCandidatesCount,
    // isLoading,
    // error,
  } = useDashboardData(isMounted);

  const applicants = stats?.avgApplicants ?? 0; // Provide a default value if avgApplicants is undefined
  const applicantsDisplay =
    applicants % 1 === 0
      ? applicants.toFixed(0)
      : Math.round(applicants * 100) / 100;

  return (
    <ViewStatsWrapper isHidden={isHidden}>
      <TopBarTop />
      <TopBarBottom />
      <BlockWrapper>
        <Typography center tag="p" lowOpacity large nomargin mb="20">
          {formatDate(today)}
        </Typography>
        {userData && userData.firstName && (
          <Typography
            center
            tag="p"
            fontSize="14"
            fontSizeXL="20"
            lineHeight="20"
            lineHeightXL="40"
            bold
          >
            {t("common.hi", "Hi")} {userData.firstName} {userData.lastName}!
            <br />
            {t("common.welcomeBack", "Welcome back")}
          </Typography>
        )}
        <LargeBlockContainer>
          {/* <LargeBlock title={t("common.newCandidates","New candidates")} count="Coming soon" /> */}
          <LargeBlock
            title={t("page.all_positions.active_positions", "Active positions")}
            count={activePositionsCount}
          />
          <LargeBlock
            title={t(
              "page.client_view.shortlisted_candidates",
              "Shortlisted candidates"
            )}
            count={shortlistedCandidatesCount}
          />
        </LargeBlockContainer>
        <SmallBlockContainer>
          <Flex gap="20" flexDirectionXL="column">
            <SmallerBlock
              title={t("common.hires", "Hires")}
              count={hiredCandidatesCount}
            />
            <SmallerBlock
              title={t("common.positionsInTotal", "Positions in total")}
              count={positionsCount}
            />
          </Flex>
          <Flex gap="20" flexDirectionXL="column">
            <SmallerBlock
              title={t("common.candidatesInTotal", "Candidates in total")}
              count={totalCandidatesCount}
            />
            <SmallerBlock
              title={t(
                "common.candidatesPerPosition",
                "Candidates per position"
              )}
              count={applicantsDisplay}
            />
          </Flex>
        </SmallBlockContainer>
        <Typography tag="p" lowOpacity nomargin mt="20" mtXL="40" large>
          {t("common.noPositionsSelected", "No position selected")}
        </Typography>
      </BlockWrapper>
    </ViewStatsWrapper>
  );
};

export default ViewStats;
