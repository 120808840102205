export const redirectAfterLogout = {
  returnTo: window.location.protocol + "//" + window.location.host,
};

/**
 * Return page url based on page and org object
 * @param {object} page a page with a 'site' property with a 'main' property and 'slug' property
 * @param {object} organization
 * @returns {string|null} page url
 */
export const pageUrl = (page, organization) => {
  if (!organization.slug) return null;
  if (!page || !page.site) return `${process.env.REACT_APP_CP_URL}/${organization.slug}/`;

  let url = page.site.main ? `${process.env.REACT_APP_CP_URL}/${organization.slug}/` : `${process.env.REACT_APP_CP_URL}/${organization.slug}/${page.site.slug}/`;
  if (page.frontpage) {
    return url;
  } else if (page.positionId) {
    return url + "job/" + page.slug;
  } else {
    return url + page.slug;
  }
};
