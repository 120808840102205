import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { deleteAccount } from '../../../api/endpoints/me';
import { Button, Icon, Modal } from '../../../components';
import { Typography } from '../../../components/Typography';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import { StateUserContext } from '../../../context/UserContext';
import LogoutButton from '../../../components/LogoutButton';
import { Grid } from '../../../components/Grid';
import { PrimaryButton } from '../../../components/Button';

const SubscriptionNotifications = () => {
  const { t } = useTranslation();
  const {
    state: {
      main,
      privileges: { privileges, activeItems },
    },
  } = useContext(SubscriptionContext);
  const [showModal, setShowModal] = useState(true);

  const { user } = useContext(StateUserContext);

  //handle showing modal
  useEffect(() => {
    //subscription actions
    if (
      main.subscription_action === 'get_payment_method' ||
      main.subscription_action === 'nudge_payment' ||
      main.subscription_action === 'subscription_cancelled' ||
      main.subscription_action === 'confirm_payment'
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [main]);

  //handle delete user
  const { logout } = useAuth0();
  const handleDelete = async () => {
    let confirm = window.confirm(
      t('component.subscription.modal.delete_confirmation')
    );
    if (confirm) {
      try {
        const deleted = await deleteAccount();
        if (deleted.success) logout();
      } catch (err) { }
    }
  };

  return (
    <div>
      {activeItems.recruiter > privileges.recruiter &&
        user.role !== 'owner' && (
          <Modal blind>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <Icon icon="lock" />
              <Typography tag="h3" nomargin>
                {t(
                  'component.subscription.modal.lockout_header',
                  'You have been locked out'
                )}
              </Typography>
            </div>

            <Typography tag="p">
              {t(
                'component.subscription.modal.lockout_paragraph',
                'The current subscription does not allow the number of recruiters this organization currently has. Contact the organization owner to delete some recruiters or purchase additional seats.'
              )}
            </Typography>
            <LogoutButton red>{t('common.logout')}</LogoutButton>
          </Modal>
        )}
      {showModal && main.subscription_action === 'get_payment_method' && (
        <Modal tiny title={t('component.subscription.modal.add_a_payment_method')} onClose={() => setShowModal(false)} noClose>
          <Grid padding="32" alignCenter>
            <Typography tag="p" center>
              {t('component.subscription.modal.description_continue')}
            </Typography>
            <PrimaryButton
              red
              onClick={() =>
              (window.location.href =
                main.subscription_action_payload)
              }
            >
              {' '}
              {t(
                'component.subscription.modal.add_a_payment_method'
              )}{' '}
              <Icon icon="edit" mr={12} />
            </PrimaryButton>
          </Grid>
        </Modal>
      )}
      {showModal && main.subscription_action === 'nudge_payment' && (
        <Modal tiny blind>
          <Typography tag="h3" mb={10} nomargin>
            {t('component.subscription.modal.add_a_payment_method')}
          </Typography>
          <Typography tag="p">
            {t('component.subscription.modal.description_continue')}
          </Typography>
          <Button
            urlLike
            transparent
            onClick={() => setShowModal(false)}
          >
            Remind me later
          </Button>
          <Button
            blue
            buttonColorDark
            onClick={() =>
            (window.location.href =
              main.subscription_action_payload)
            }
            bold
          >
            {' '}
            {t('component.subscription.modal.add_a_payment_method')}
            <Icon icon="edit" ml={12} />
          </Button>
        </Modal>
      )}
      {showModal &&
        main.subscription_action === 'subscription_cancelled' && (
          <Modal tiny blind>
            <Typography tag="h3" mb={10}>
              {t(
                'component.subscription.modal.subscription_cancelled'
              )}
            </Typography>
            <Typography tag="p">
              {t(
                'component.subscription.modal.description_cancelled'
              )}
            </Typography>
            <Button primaryColor onClick={handleDelete} bold>
              {t('common.delete_account')}
              <Icon colorStroke="#fff" icon="trash" ml={12} />
            </Button>
            <Button
              blue
              buttonColorDark
              onClick={() => {
                window.open(
                  `mailto:${main.subscription_action_payload}`,
                  '_blank'
                );
              }}
            >
              {' '}
              {t('component.subscription.modal.contact')}{' '}
              <Icon icon="email" ml={12} />
            </Button>
          </Modal>
        )}
    </div>
  );
};

export default SubscriptionNotifications;
