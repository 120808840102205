// React
import React, { useRef, useContext, useEffect, useState } from 'react';

// Components 
import { Icon } from '../../../components';

// Styled components
import { StyledCard } from './style';

// Utility functions and hooks
import { useGetResponsiveDimensions } from '../../hooks/useGetResponsiveDimensions';
import { GenericCardHoverOptions, GenericCardHoverOptionsDivider } from '../GenericList/GenericCard/GenericCard.styled';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Typography } from '../../../components/Typography';

// State management
import { DispatchPositionContext } from '../../context/PositionContext';

/**
 * icon : Icon (Component) | null
 * title : string | null
 * meta : object | null [ "Status", "published", "Property", "value"]
 * arrow : boolean
 */
const InnerCard = React.forwardRef(
  ({ icon, title, meta, arrow, hoverOptions, id, baseUrl }, ref) => {
    const innerRef = useRef();
    const { elWidth } = useGetResponsiveDimensions(ref || innerRef);

    const positionDispatch = useContext(DispatchPositionContext);

    
    // HandleClick - go live
    const handleClick = () => {
      setTimeout(function() {
        positionDispatch({ type: 'edit_position_view', payload: true });
      }, 500)
    };



    return (
      <StyledCard ref={ref || innerRef} elWidth={elWidth} allPositionsCard>
        <div className="card-main-container">
          <div className="card-upper-container">
            {title && <div className="card-title">{title}</div>}
                        {icon && <div className="card-icon">{icon}</div>}
          </div>
{/* 
            <div className="card-lower-container">
            {Array.isArray(meta) && meta.length > 0 && (
  <div className="card-meta">
    {meta.map((value) => {
      const newVal = value[1]?.replace(/\D/g,'');
      return (
        <span className="card-meta-item">{newVal}</span>
      );
    })}
  </div>
)}
            </div> */}
          

        </div>
        {/* <div className="card-arrow">
          {arrow && <Icon icon="arrow" rotate={-90} />}
        </div> */}
        {hoverOptions &&
        <GenericCardHoverOptions>
      <Typography tag="p" fontSize="12" nomargin lowOpacity hover height="16" mr="10" onClick={handleClick}>Edit</Typography>
        {/* <GenericCardHoverOptionsDivider /> */}
        </GenericCardHoverOptions>
        }
      </StyledCard>
    );
  }
);

export default InnerCard;
