export const initialField = {
  id: 0,
  hasFile: false,
  isUploading: false,
  isError: false,
  progress: 0,
  savedFile: null,
};

export const initialFieldsState = {
  fields: [initialField],
  increment: 0,
  dynamicAddRemove: false,
  anyIsUploading: false,
};

export const fieldsReducer = (state, action) => {
  switch (action.type) {
    case "initial_files": {
      const newFields =
        action.payload.length > 0 ? action.payload : [initialField];
      const increment = action.payload.length - 1;
      return {
        ...state,
        increment: increment,
        fields: newFields,
      };
    }
    case "upload_start": {
      return {
        ...state,
        anyIsUploading: true,
        fields: state.fields.map((field) =>
          field.id === action.payload
            ? { ...field, hasFile: true, isUploading: true, isError: false }
            : field
        ),
      };
    }
    case "upload_finished": {
      const { fieldId, savedFile } = action.payload;
      const newFieldsState = state.fields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              hasFile: true,
              isUploading: false,
              savedFile: savedFile,
            }
          : field
      );
      return {
        ...state,
        anyIsUploading: checkIfAnyFieldsAreUploading(newFieldsState),
        fields: newFieldsState,
      };
    }
    case "upload_error": {
      const newFieldsState = state.fields.map((field) =>
        field.id === action.payload
          ? { ...field, hasFile: false, isUploading: false, isError: true }
          : field
      );
      return {
        ...state,
        anyIsUploading: checkIfAnyFieldsAreUploading(newFieldsState),
        fields: newFieldsState,
      };
    }
    case "update_progress": {
      const { fieldId, progress } = action.payload;

      return {
        ...state,
        fields: state.fields.map((field) =>
          field.id === fieldId ? { ...field, progress: progress } : field
        ),
      };
    }
    case "upload_cancel": {
      return {
        ...state,
        fields: state.fields.map((field) =>
          field.id === action.payload
            ? { ...field, hasFile: false, isUploading: false, progress: 0 }
            : field
        ),
      };
    }
    case "file_remove": {
      //remove field if more than one field!
      if (state.fields.length === 1 || !state.dynamicAddRemove) {
        //reset field
        return {
          ...state,
          fields: state.fields.map((field) =>
            field.id === action.payload
              ? {
                  ...field,
                  hasFile: false,
                  isUploading: false,
                  isError: false,
                  progress: 0,
                  savedFile: null,
                }
              : field
          ),
        };
      } else if (state.dynamicAddRemove) {
        //remove field
        return {
          ...state,
          fields: state.fields.filter((field) => field.id !== action.payload),
        };
      }
    }
    case "add_field": {
      const increment = state.increment + 1;
      return {
        ...state,
        increment: increment,
        fields: [...state.fields, { ...initialField, id: increment }],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

/**
 *
 * @param {array} fields
 * @returns {boolean} true if any field in list has property  isUploading true
 */
const checkIfAnyFieldsAreUploading = (fields) => {
  let anyIsUploading = false;

  fields.forEach((field) => {
    if (field.isUploading) anyIsUploading = true;
  });

  return anyIsUploading;
};
