export const STATIC_BLOCK_TYPES = ["header", "footer"];
export const NO_CONTENT_BLOCKS = ["list"];
export const ALLOWED_COMMON_BACKGROUND_COLORS = [
  { value: "transparent", label: "White" },
  { value: "#F0F0F0", label: "Grey" },
];
export const LIST_TYPES = [
  { value: "job_ad", label: "Job ads" },
  { value: "sub_page", label: "Pages" },
];
export const PADDING_VERTICAL = [
  { value: "sm", label: "Small" },
  { value: "md", label: "Medium" },
  { value: "lg", label: "Large" },
  { value: "xl", label: "Extra Large" },
];
export const BLOCK_SIZES = [
  { value: "sm", label: "Small" },
  { value: "lg", label: "Large" },
];
export const IMAGE_POS = [
  { value: true, label: "Left" },
  { value: false, label: "Right" },
];
export const BLOCK_TEMPLATES = {
  header: {
    label: "Header",
  },
  footer: {
    label: "Footer",
  },
  paragraph: {
    label: "Paragraph",
    type: "paragraph",
    title: "My new paragraph section",
  },
  list: {
    label: "List",
    type: "list",
    title: "My new list section",
  },
  some: {
    label: "Social media links",
    type: "some",
    title: "My new share on social media section",
  },
  paragraph_with_image: {
    label: "Image and paragraph section",
    type: "paragraph_with_image",
    title: "A paragraph with an image",
  },
  image: {
    label: "Single image",
    type: "image",
    title: "An image",
  },
  apply: {
    label: "Apply section",
    type: "apply",
    title: "Apply section",
  },
};
export const HEADER_BUTTON_OPTIONS = [
  {
    action: "scroll_to_open_positions",
    option: "Scroll to open position",
    page: "frontpage",
  },
  {
    action: "go_to_unsolicited_application_form",
    option: "Go to unsolicited application",
    page: "frontpage",
  },
  {
    action: "go_to_application_form",
    option: "Go to application form",
    page: "job_ad",
  },
  {
    action: "mail_to_contact_person",
    option: "Send a mail to contact person",
    page: "job_ad",
  },
];
