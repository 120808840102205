// React
import React from 'react'

const CustomToolbar = () => {

  const firstName = '{{firstName}}';
  const lastName = '{{lastName}}'
  const fullName = '{{fullName}}';
  const positionName = '{{positionName}}';
  const organizationName = '{{organizationName}}';

  return (
    <div id="toolbar">
          <select className="ql-header" defaultValue="normal">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option value="3">Heading 3</option>
      <option value="normal">normal</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <button className="ql-link" value="bullet"></button>    
    <button className="ql-clean" />
    <select className="ql-dynamicFields">
        <option value={firstName}>First name</option>
        <option value={lastName}>Last name</option>
        <option value={fullName}>Full name</option>
        <option value={positionName}>Position name</option>
        <option value={organizationName}>Organization name</option>
    </select>
  </div>
  )
}

export default CustomToolbar