import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationParamsContext,
  filterableParams,
} from '../ApplicationContextWrapper';
import { Typography } from '../../../../components/Typography';

const hasAppliedFilter = (params) => {
  const filterable = filterableParams(params);
  const {
    hasFiles,
    isAvailable,
    isNew,
    isNotRejected,
    isRejected,
    isSubmitted,
    languages,
    skills,
    positionIds,
    sortby,
    status,
    substatus,
    shortlist,
  } = filterable;
  if (
    hasFiles ||
    isNew ||
    isAvailable ||
    isNotRejected ||
    isRejected ||
    isSubmitted ||
    languages !== '{}' ||
    skills !== '{}' ||
    positionIds.length !== 0 ||
    sortby !== 'createdAt' ||
    status ||
    substatus ||
    shortlist
  )
    return true;

  return false;
};

const ApplicationFilterHeader = () => {
  const { t } = useTranslation();
  const {
    state: { params },
    dispatch,
  } = useContext(ApplicationParamsContext);
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography tag="h3" nomargin>
        {t('component.filter.plural')}
        {hasAppliedFilter(params) && (
          <Typography
            tag="span"
            highlight
            style={{ fontSize: '12px' }}
          >
            {' '}
            ({t('common.active')})
          </Typography>
        )}
      </Typography>
      <p
        style={{ cursor: 'pointer' }}
        onClick={() => dispatch({ type: 'clear_all' })}
      >
        {t('common.reset')}
      </p>
    </div>
  );
};

export default ApplicationFilterHeader;
