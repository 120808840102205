import styled from 'styled-components/macro';

export const StyledApplicationListContainer = styled.div`
  margin-top: 0;
  min-height: 300px;
  height: 100%;
  position: relative;
  margin-top: -14px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => props.hideCardsOnMobile && 'display:none;'};
  }
  & > div:first-child {
    margin-top: 0;
  }
  ${(props) =>
    props.scrollList
      ? `
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 600px;
    `
      : ``}

  ${(props) =>
    props.isOver &&
    `
        & > .application-card {
            pointer-events:none;
        }
    `}
`;
