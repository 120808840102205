export const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

/**
 * stringifies values in params ( ie "?type=somethpy&otherparams=32")
 * @param {object} request
 * @returns object
 */
export const parseRequest = (request) => {
    const baseRequest = {
        selector : "",
        body : "",
        params : ""
    }

    //stringify arrays in params
    if ( request.params ) {
      for (const [key, value] of Object.entries(request.params)) {
        if ( Array.isArray(value)) {
          request.params[key] = JSON.stringify(value)
        }
      }
    }

    if ( request.params ) {
        request.params = '?' + serialize(request.params);
    }

    return { ...baseRequest, ...request };
}