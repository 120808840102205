import styled from "styled-components/macro";
import { ResetButton } from "../../../../../../../components/Button";

export const StyledSuggestion = styled(ResetButton)`
  padding: 12px;
  width: 100%;
  text-align: left;
  border-radius: 5px;
  &:hover {
    color: #fff;
    background: ${(props) => props.theme.colors.red};
  }
`;
