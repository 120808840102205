import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';
import { StateUserContext } from '../context/UserContext';
import Onboarding from './components/Onboarding';
import { OrganizationProvider } from './context/OrganizationContext';
import { PositionProvider } from './context/PositionContext';
import { BrandingProvider } from './context/BrandingContext';
import { SiteProvider } from './context/SiteContext';
import { AdministrationProvider } from './context/AdministrationContext';
import Admin from './Admin';
import { Share } from './views';
import { NoMatch } from '../components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Signup from './Signup';
import { PageProvider } from './context/PageContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';

const Layout = () => {
  const { user, isLoading, isAuthenticated } =
    useContext(StateUserContext);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // ✅ globally default to 20 seconds
        staleTime: 1000 * 20,
      },
    },
  }); //REACT QUERY

  // GTM initialize
  useEffect(() => { TagManager.initialize({ gtmId: 'GTM-P6PDD3J' }); }, []);


  return (
    <>
      {/* show onboarding if user has no organization associated */}
      {!user.organizationId &&
        isAuthenticated &&
        !isLoading &&
        user.email ? (
        <Onboarding />
      ) : (
        <QueryClientProvider client={queryClient}>
          <AdministrationProvider>
            <OrganizationProvider>
              <SubscriptionProvider>
                <BrandingProvider>
                  <SiteProvider>
                    <PositionProvider>
                      <PageProvider>
                        <DndProvider backend={HTML5Backend}>
                          <Router>
                            <Switch>
                              <Route
                                path="/share/:orgName/:uuid"
                                component={Share}
                              />
                              <Route
                                path="/signup"
                                component={Signup}
                              />
                              <Route path="/404" component={NoMatch} />
                              <ProtectedRoute
                                path="/"
                                component={Admin}
                              />
                            </Switch>
                          </Router>
                        </DndProvider>
                      </PageProvider>
                    </PositionProvider>
                  </SiteProvider>
                </BrandingProvider>
              </SubscriptionProvider>
            </OrganizationProvider>
          </AdministrationProvider>
        </QueryClientProvider>
      )}
    </>
  );
};

export default Layout;
