import React from 'react';
import styled from 'styled-components/macro';

const StyledDropdownItem = styled.div`
    padding: 4px 0px;
    display: flex;
    ${(props) => (props.disabled ? 'opacity: 0.4' : 'opacity: 1')};
    svg {
        margin-right: 10px;
    }
    &:hover {
        color: ${(props) => props.theme.colors.highlight};
        .stroke {
            svg, svg * {
                stroke: ${(props) =>
                  props.theme.colors.highlight} !important;
            }
        }
        .fill {
            svg, svg * {
                fill: ${(props) =>
                  props.theme.colors.highlight} !important;
            }
        }

        }
    }
`;

const DropdownItem = ({ handleClick, children, disabled }) => {
  return (
    <StyledDropdownItem
      disabled={disabled}
      onClick={disabled ? null : handleClick}
    >
      {children}
    </StyledDropdownItem>
  );
};

export default DropdownItem;
