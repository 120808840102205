import styled from "styled-components/macro";

export const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(24, 31, 56, 0.5); 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999999; 
  display: flex;
  align-items: center;
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important; 
    }
    -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledModalContent = styled.div`
  width: ${(props) => (props.tiny ? '500px' : '1000px')};
  height: auto;
  position: relative;
  margin: 0 ${(props) => props.theme.grid.gutter};
  background-color: #fff;
  max-height: 100vh; 

  animation: animIn .5s ease;
  @keyframes animIn {
    0% {
        opacity: 0; 
    }
    100% {
        opacity: 1; 
    }
  }
  @media (max-width: 768px) {
    /* padding: 15px; */
    width: calc(100% - 30px);
  }
  border-radius: 10px;
  border: 1px solid #F0F0F3;
  box-shadow: 0px 3px 6px rgba(24, 31, 56, .15);
    padding: 0;
`;

export const ModalWidthContainer = styled.div`
max-height: 75vh; 
overflow: scroll; 
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important; 
    }
    -ms-overflow-style: none;
  scrollbar-width: none;
    ${props => props.containWidth && `
padding-left: 25px; 
padding-right: 25px;
@media (min-width: 1200px) {
  padding-right: 250px; 
  padding-left: 250px; 
}
`}
display: flex;
flex-direction: column;
align-items: center; 
`

export const CloseModal = styled.div`
position: absolute; 
right: 28px; 
top: 22px;
`

export const ModalHeader = styled.div`
display: flex; 
justify-content: center;
width: 100%; 
padding: 25px 0; 
border-bottom: 1px solid #F0F0F3; 
h2 {
    font-size: 20px; 
    font-weight: 500; 
    color: ${props => props.theme.colors.faceliftBlue}; 
    margin: 0 !important;
}
`