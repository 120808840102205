import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

const BackgroundVideo = ({ video }) => {
  const [videoId, setVideoId] = useState(null);

  //Handle video_id
  useEffect(() => {
    setVideoId(getIdFromUrl(video));
  }, [video]);

  // could be https://vimeo.com/{#####} or vimeo.com/{#####} or {######} https://vimeo.com/{#####}?test=test
  const getIdFromUrl = (id) => {
    const arr = id.split('/');
    const last = arr[arr.length - 1];
    return last.split('?')[0];
  };

  if (!video) return null;
  return (
    <StyledIframeVideoWrapper>
      {videoId && (
        <iframe
          title="vimeo header video"
          src={`https://player.vimeo.com/video/${videoId}?background=1&autoplay=1&loop=1&byline=0&title=0&portrait=0&muted=1`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>
      )}
    </StyledIframeVideoWrapper>
  );
};

export default BackgroundVideo;

const StyledIframeVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  &:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
