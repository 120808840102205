import axios from 'axios';

import { parseRequest } from '../utilities/queries';
import {
  errorResponseHandler,
  responseHandler,
} from '../utilities/response';

export const getApplications = async (request = {}) => {
  const { selector, params } = parseRequest(request);
  try {
    const url = `/application/${selector ? selector : ''}${
      params ? '/' + params : ''
    }`;

    //Files disappear when using std axios json conversion
    const call = await axios.get(url);

    return responseHandler(call);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deleteApplication = async (request = {}) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.delete(
      `/application/${selector ? selector : ''}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const bulkDeleteApplications = async (request = {}) => {
  const { body } = parseRequest(request);
  if (!body) return;
  try {
    const data = await axios.delete('/application', { data: body });
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const rejectApplication = async (request = {}) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.put(`/application/reject/${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Recruiter updating application. summary, shortlisting, adding files / removing files
 * @param {object} request
 * @returns response from server
 */
export const updateApplication = async (request = {}) => {
  const { selector, body } = parseRequest(request);
  try {
    const data = await axios.put(
      `/application/${selector ? selector : ''}`,
      body
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const duplicateApplicationsToPosition = async (
  request = {}
) => {
  const { params } = parseRequest(request);
  try {
    if (!params) throw new Error('No params provided');
    const data = await axios.post(
      `/application/duplicate${params ? '/' + params : ''}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};
