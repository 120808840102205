import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../../components";

const LoginButton = ({ children, ...rest }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        loginWithRedirect({ screen_hint: "login", display: "popup" })
      }
      }
      {...rest}
    >
      {children}
    </Button>
  );
};

export default LoginButton;
