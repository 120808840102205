import React from "react";
import { Typography } from "../Typography";
import { Grid, Row, Col } from "../Grid";

const NoMatch = () => {
  return (
    <Grid>
      <Row>
        <Col size={4} >
        <Typography tag="h3">404 - We seem to have misplaced that particular thing.</Typography>
          <Typography tag="p">Sorry about that!</Typography>
        </Col>
       
      </Row>
    </Grid>
  );
};

export default NoMatch;