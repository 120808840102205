import React, { createContext, useReducer } from "react";

export const PageContext = createContext();

export const initialPage = {
  isLoading: false,
  isError: false,
  isSaved: true,
  page: {
  },
};

export const singlePageReducer = (state, action) => {
  switch (action.type) {
    case "init_page": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "page_loaded": {
      return {
        ...state,
        isLoading: false,
        isSaved: true,
        page: action.payload,
      };
    }
    case "new_page_loaded": {
      return {
        ...state,
        isLoading: false,
        isSaved: false,
        page: action.payload,
      };
    }
    case "update_page": {
      const { name, value } = action.payload;
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          [name]: value,
        },
      };
    }
    case 'update_page_site_id': {
      const { site, siteId } = action.payload;

      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          site: site,
          siteId: siteId,
        },
      };
    }
    case "add_block": {
      const { template, index } = action.payload;
      const blocksAdded = [...state.page.blocks];
      blocksAdded.splice(index, 0, template);
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          blocks: blocksAdded,
        },
      };
    }
    case "delete_block": {
      const { block } = action.payload;
      const blockSpliced = [...state.page.blocks];
      const blockIndex = blockSpliced.map((b) => b.id).indexOf(block.id);
      blockSpliced.splice(blockIndex, 1);
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          blocks: blockSpliced,
        },
      };
    }
    case "move_block": {
      const { block, direction } = action.payload;
      const blocksOrdered = [...state.page.blocks];
      //Swap position with prev or next depending on direction
      const thisBlockIndex = blocksOrdered.map((b) => b.id).indexOf(block.id);
      if (direction === "up") {
        const otherBlock = blocksOrdered[thisBlockIndex - 1];
        blocksOrdered[thisBlockIndex - 1] = block;
        blocksOrdered[thisBlockIndex] = otherBlock;
      } else if (direction === "down") {
        const otherBlock = blocksOrdered[thisBlockIndex + 1];
        blocksOrdered[thisBlockIndex + 1] = block;
        blocksOrdered[thisBlockIndex] = otherBlock;
      }
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          blocks: blocksOrdered,
        },
      };
    }
    case "drag_block": {
      const [dragIndex, hoverIndex] = action.payload;
      //Swap places
      let blocksOrdered = [...state.page.blocks];
      const draggedBlock = blocksOrdered[dragIndex];
      blocksOrdered[dragIndex] = blocksOrdered[hoverIndex];
      blocksOrdered[hoverIndex] = draggedBlock;

      return {
        ...state,
        page: {
          ...state.page,
          blocks: blocksOrdered,
        },
        isSaved: false,
      };
    }
    case "update_block": {
      const { id, name, value } = action.payload;
      return {
        ...state,
        page: {
          ...state.page,
          blocks: state.page.blocks.map((block) => {
            if (id === block.id) {
              return { ...block, [name]: value };
            } else {
              return block;
            }
          }),
        },
        isSaved: false,
      };
    }
    case "add_block_file": {
      const { id, file } = action.payload;
      //Update block addFiles array
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          blocks: state.page.blocks.map((block) => {
            if (block.id === id) {
              const addFiles = block.addFiles || [];
              return { ...block, addFiles: [...addFiles, file.uuid] };
            } else {
              return block;
            }
          }),
        },
      };
    }
    case "remove_block_file": {
      const { id, file } = action.payload;
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          blocks: state.page.blocks.map((block) => {
            if (block.id === id) {
              // if in block.files add to removeFiles
              let removeFiles = block.removeFiles || [];
              removeFiles = [...removeFiles, file.uuid];
              removeFiles = removeFiles.filter(
                (uuid) => block.files.map((f) => f.uuid).indexOf(uuid) > -1
              );

              // remove from files
              let newFiles = block.files || [];
              newFiles = newFiles.filter((f) => f.uuid !== file.uuid);

              // if in addFiles, remove from add files
              let addFiles = block.addFiles || [];
              addFiles = addFiles.filter((uuid) => uuid !== file.uuid);
              return {
                ...block,
                files: newFiles,
                addFiles: addFiles,
                removeFiles: removeFiles,
              };
            } else {
              return block;
            }
          }),
        },
      };
    }
    case "add_tag": {
      const { tagName, type } = action.payload;
      const addTags =
        state.page?.addTags?.filter((t) => t.tagName !== tagName) || [];
      const currentTags =
        state.page.tags.filter((t) => t.tagName !== tagName) || [];
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          addTags: [...addTags, { tagName: tagName, type: type }],
          tags: [...currentTags, { tagName: tagName, type: type }],
        },
      };
    }
    case "remove_tag": {
      const { tagId, tagName } = action.payload;
      const removeTags = state.page.removeTags || [];
      return {
        ...state,
        isSaved: false,
        page: {
          ...state.page,
          removeTags: [...removeTags, tagId],
          tags: state.page.tags.filter((tag) => {
            if (tagId) {
              return tag.id !== tagId;
            } else {
              return tag.tagName !== tagName;
            }
          }),
        },
      };
    }

    case "error": {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case "clear_state": {
      return initialPage;
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export const PageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(singlePageReducer, initialPage);

  return (
    <PageContext.Provider value={{ state, dispatch }}>
      {children}
    </PageContext.Provider>
  );
};
