import styled from "styled-components/macro";

export const StyledBrandingSettings = styled.div`
  & > div:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .Dropdown-control {
    padding: 0; 
    border: none; 
    width: 100%;
    cursor: pointer; 
  }
  .Dropdown-arrow-wrapper {
    opacity: 0; 
  }
`;
