// React
import React, { useContext, useReducer } from "react";

// API endpoints - custom hooks - utilities
import { createShare } from "../../../../api/endpoints/share";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

// Components & Styled
import { InputFieldWithLabel, Icon } from "../../../../components";
import { Row, Col } from "../../../../components/Grid";

// Context
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { PrimaryButton } from "../../../../components/Button";
import { Flex } from "../../../style";

const shareFormReducer = (state, action) => {
  switch (action.type) {
    case "change":
      const { name, value } = action.payload;
      let countryCode = state.countryCode;
      if (name === "countryCode") {
        countryCode = value;
      }
      let phoneNumber = state.phoneNumber;
      if (name === "phoneNumber") {
        phoneNumber = value;
      }
      return {
        ...state,
        [name]: value,
        phone: countryCode + phoneNumber,
      };
    case "submit":
      return {
        ...state,
        isSubmitting: true,
      };
    case "submitted":
      return {
        ...state,
        ...initialState,
      };
    case "error":
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return {
        ...state,
      };
  }
};

const phoneRegex =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  email: yup.string().email().required(),
  phone: yup.string().matches(phoneRegex, "Phone number is not valid"),
});

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  countryCode: "+45",
  phone: "+45",
  isSubmitting: false,
};

const ShareShortlistForm = ({
  positionId,
  addedNewShare,
  updateParentState,
  updateParentConfirmation,
}) => {
  const { t } = useTranslation();
  const errorDispatch = useContext(DispatchErrorContext);
  const [shareFormState, dispatchShareForm] = useReducer(
    shareFormReducer,
    initialState
  );
  const handleChange = (e) => {
    dispatchShareForm({
      type: "change",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  // const handleChange = (selectedOption) => {
  //   dispatchShareForm({
  //     type: 'change',
  //     payload: { name: 'countryCode', value: selectedOption.value },
  //   });
  // };

  const handleButtonClick = () => {
    // Call the callback function to update the parent's state
    updateParentState(false);
  };

  const submitShareForm = async (e) => {
    e.preventDefault();
    dispatchShareForm({ type: "submit" });
    try {
      //validate
      await schema.validate(shareFormState);

      //send request
      const request = { selector: positionId, body: shareFormState };
      const response = await createShare(request);
      if (response.success) {
        dispatchShareForm({ type: "submitted" });
        addedNewShare(response.data);
        updateParentState(false);
        updateParentConfirmation(true);
      } else {
        errorDispatch({
          type: "set_error",
          payload: t("error.an_error_happened"),
        });
        dispatchShareForm({ type: "error" });
      }
    } catch (err) {
      errorDispatch({ type: "set_error", payload: err.message });
      dispatchShareForm({ type: "error" });
    }
  };

  return (
    <form style={{ width: "100%" }}>
      <Row smallerInnerGutter>
        <Col>
          <InputFieldWithLabel
            tiny
            value={shareFormState.firstName}
            name="firstName"
            onChange={handleChange}
            type="text"
            required
            label={false}
            id="firstName"
            placeholder={t("common.first_name")}
          />
        </Col>
        <Col break="sm">
          <InputFieldWithLabel
            tiny
            value={shareFormState.lastName}
            name="lastName"
            label={false}
            onChange={handleChange}
            type="text"
            id="lastName"
            placeholder={t("common.last_name")}
          />
        </Col>
      </Row>
      <Row smallerInnerGutter>
        <Col>
          <InputFieldWithLabel
            tiny
            value={shareFormState.email}
            name="email"
            onChange={handleChange}
            label={false}
            type="email"
            required
            id="email"
            placeholder={t("common.email")}
          />
        </Col>
        <Col break="sm">
          <InputFieldWithLabel
            tiny
            value={shareFormState.phoneNumber}
            name="phoneNumber"
            onChange={handleChange}
            label={false}
            type="tel"
            required
            id="phoneNumber"
            placeholder={t("common.phone_number")}
            countryCode={shareFormState.countryCode}
            countryCodeChange={handleChange}
          />
        </Col>
      </Row>
      <Row mt={40}>
        <Flex flexDirection="column" alignItems="center" justify="center">
          <PrimaryButton
            red
            disabled={shareFormState.isSubmitting}
            onClick={submitShareForm}
            mb="20"
          >
            {t("page.position.share_shortlist_form.share_button_label")}
            <Icon icon="send" mr="8" />
          </PrimaryButton>
          <PrimaryButton
            onClick={handleButtonClick}
            uppercase
            transparent
            urlLike
          >
            <span>{t("common.cancel")}</span>
            <Icon icon="cross" rotate={45} mr={8} />
          </PrimaryButton>
        </Flex>
      </Row>
    </form>
  );
};

export default ShareShortlistForm;
