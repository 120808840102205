import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Button, Icon } from '../../../components';
import { Typography } from '../../../components/Typography';
import { ApplicationParamsContext } from './ApplicationContextWrapper';

/**
 * Set active application index with this ui element  (dec) <-- 1 / 1 --> (inc)
 */
const ApplicationArrowNavigation = () => {
  const {
    state: { list, index },
    dispatch,
  } = useContext(ApplicationParamsContext);
  const handleChange = (e) => {
    if (e.target.value === 'next') {
      dispatch({ type: 'inc_index' });
    } else if (e.target.value === 'prev') {
      dispatch({ type: 'dec_index' });
    }
  };
  if (!list || list.length === 0) return null;
  return (
    <StyledArrowContainer>
      <Button buttonColorDark value="prev" onClick={handleChange}>
        <Icon icon="arrow_large" />
      </Button>
      <Typography tag="h3" nomargin>
        <strong>{index + 1}</strong> of <strong>{list.length}</strong>
      </Typography>
      <Button buttonColorDark value="next" onClick={handleChange}>
        <Icon icon="arrow_large" rotate={180} />
      </Button>
    </StyledArrowContainer>
  );
};

export default ApplicationArrowNavigation;

const StyledArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: auto;
    margin-right: auto;
  }
  margin-bottom: 24px;
  /* max-width: 400px; */
  /* margin-top: 16px; */
`;
