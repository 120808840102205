import { useEffect } from 'react';
import { useFormikContext } from 'formik';

/**
 * Read values from formik form
 */
const Observer = ({ setValues, trigger }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values,trigger]);

  return null;
};

export default Observer;
