// React
import React from "react";

// Tranlsation - Supported languages etc
import { useTranslation } from "react-i18next";
import supportedLanguages from "../../i18n/supportedLanguages.json";
import ISO6391 from "iso-639-1";

// Components
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const LanguageSelect = ({
  value,
  onChange,
  allowedLanguages,
  disabled,
  useISO,
  cp,
  label,
}) => {
  const { t } = useTranslation();

  const handleLanguageChange = (selectedOption) => {
    if (cp) {
      onChange({ target: { value: selectedOption.value } });
    } else {
      // onChange({ target: { value: selectedOption.value } });
      onChange(selectedOption.value);
    }
  };

  const availableLanguages = allowedLanguages
    ? supportedLanguages.filter((lng) => allowedLanguages.indexOf(lng) > -1)
    : supportedLanguages;

  // Get the first two languages from the availableLanguages array
  const firstTwoLanguages = availableLanguages.slice(0, 2);

  const dropdownOptions = useISO
    ? ISO6391.getAllCodes()
        .filter((language) => firstTwoLanguages.includes(language)) // Filter by the first two languages
        .map((language) => ({
          value: language,
          label: ISO6391.getName(language),
        }))
    : firstTwoLanguages.map((language) => ({
        value: language,
        label: t(`component.language.${language}`),
      }));

  return (
    <Dropdown
      options={dropdownOptions}
      onChange={handleLanguageChange}
      value={value}
      disabled={disabled}
    />
  );
};

export default LanguageSelect;
