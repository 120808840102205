// React
import React, { useContext, useState, useEffect } from "react";

// Translations, API calls, and hooks
import { useTranslation } from "react-i18next";

// Components
import { InputFieldWithLabel, FileField } from "../../../../../components";
import BigCheckBox from "../../../../../components/BigCheckbox";
import { initialField } from "../../../../../components/FileField/reducer";
import { SelectFieldWithLabel } from "../../../../../components/Input/Input";
import SquareToggle from "../../../../../components/SquareToggle/SquareToggle";
import EditRejectionEmail from "../../../../components/EditRejectionEmail/EditRejectionEmail";
import MetaEditor from "../../../../components/MetaEditor/MetaEditor";
import MetaEditorGroup from "../../../../components/MetaEditor/MetaEditorGroup/MetaEditorGroup";
import EditPositionAccordion from "../EditPositionAccordion/EditPositionAccordion";
import FieldList from "../FieldList/FieldList";
import PageMG from "../../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PageMG";
import { PrimaryButton } from "../../../../../components/Button";
import { Icon } from "../../../../../components";
import TagField from "../../../../components/cp/SinglePage/PageMetaEditor/TagField";

// Assets
import anonIcon from "../../../../../assets/icons/anon.svg";

// State management
import { StateUserContext } from "../../../../../context/UserContext";
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../../../context/PositionContext";
import { PageContext } from "../../../../context/PageContext";
import { Flex } from "../../../../style";
import { Typography } from "../../../../../components/Typography";
import PublishMG from "../../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PublishMG/PublishMG";
import PositionSiteSelect from "../../../../../components/PositionSiteSelect/PositionSiteSelect";
import { useFetchSites } from "../../../../components/cp/useFetchSites";

const PositionMetaEditor = () => {
  const { t, i18n } = useTranslation();
  const { single, isLoading } = useContext(StatePositionContext);
  const positionDispatch = useContext(DispatchPositionContext);
  const [open, setOpen] = useState(false);
  const { sites } = useFetchSites();
  const { state, dispatch } = useContext(PageContext);
  const [positionPageSite, setpositionPageSite] = useState();
  const [positionPageSiteId, setpositionPageSiteId] = useState();
  const [positionPageSiteLanguage, setPositionPageSiteLanguage] = useState();

  useEffect(() => {
    if (single.page && single.page.site) {
      const lang =
        single.page.site.slug === "en"
          ? t("component.language.en-US", "English")
          : t("component.language.da-DK", "Danish");
      setPositionPageSiteLanguage(lang);
      setpositionPageSiteId(single.page.siteId);
      setpositionPageSite(sites.find((site) => site.id === single.page.siteId));
    }
  }, [single.page]);

  useEffect(() => {
    const mysitesSite = sites.find((site) => site.id === positionPageSiteId);

    dispatch({
      type: "update_page_site_id",
      payload: {
        site: mysitesSite,
        siteId: positionPageSiteId,
      },
    });
  }, [positionPageSiteId]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MetaEditor>
      <PublishMG />
      <EditPositionAccordion
        metaEditor
        title={t("component.metaeditor.application_form", "Application form")}
      >
        <MetaEditorGroup
          name={t("component.metaeditor.application_form", "Application form")}
        >
          <>
            <Flex
              mb="16"
              mbLG="25"
              alignItemsLG="flex-start"
              justifyLG="space-between"
            >
              <SquareToggle
                settings
                options={[
                  {
                    text: t("component.metaeditor.optional", "Optional"),
                    value: false,
                  },
                  {
                    text: t("component.metaeditor.mandatory", "Mandatory"),
                    value: true,
                  },
                ]}
                on={single?.mandatoryFields?.indexOf("upload_cv") > -1}
                label={t("application_form.upload_cv_label", "Upload resume")}
                onChange={(value) => {
                  positionDispatch({
                    type: value ? "add_mandatory" : "remove_mandatory",
                    payload: "upload_cv",
                  });
                }}
              />
            </Flex>
            <Flex alignItemsLG="flex-start" justifyLG="space-between">
              <SquareToggle
                settings
                options={[
                  {
                    text: t("component.metaeditor.optional", "Optional"),
                    value: false,
                  },
                  {
                    text: t("component.metaeditor.mandatory", "Mandatory"),
                    value: true,
                  },
                ]}
                on={single?.mandatoryFields?.indexOf("about") > -1}
                label={t("application_form.cover_letter_label", "Cover letter")}
                onChange={(value) => {
                  positionDispatch({
                    type: value ? "add_mandatory" : "remove_mandatory",
                    payload: "about",
                  });
                }}
              />
            </Flex>
          </>

          <FieldList
            name="competencies"
            list={single.competencies || []}
            label={t("common.skills")}
            max={10}
            addText={t("page.position.add_new_skill")}
            placeholder={t("common.requiredSkill")}
            description={t("common.requiredSkillDesc")}
          />

          <FieldList
            name="languages"
            list={single.languages || []}
            label={t("common.languages")}
            max={10}
            addText={t("page.position.add_new_language")}
            placeholder={t("common.addLanguage")}
            description={t("common.languageDesc")}
          />

          <Typography tag="p" bold nomargin mb="10" mt="24">
            {t("component.tagfield.locationLabel", "Location")}
          </Typography>
          <Typography tag="p" nomargin lowOpacity>
            {t(
              "component.tagfield.locationDescription",
              "Where is the workplace located"
            )}
          </Typography>
          <TagField
            label={false}
            placeholder={t(
              "component.tagfield.locationPlaceholder",
              "Add a location..."
            )}
            tagType="location"
          />

          <Typography tag="p" bold nomargin mb="10" mt="24">
            {t("common.upload_pdf", "Upload PDF")}
          </Typography>
          <Typography tag="p" nomargin lowOpacity>
            {t(
              "page.position.pdf_description",
              "The PDF will be hosted at your HiTalento site as long as this position exists"
            )}
          </Typography>
          <FileField
            transparentButton
            type="document"
            docType="positionDescription" //positionDescription
            // isLoading={isLoading}
            uploadBtn
            mt={0}
            pt={5}
            pbLG="0"
            noBorder
            // overrideButton={<p>{t("common.upload_pdf_label")}</p>}
            initialFiles={
              !isLoading &&
              single?.files?.length > 0 &&
              single?.files.map((file, index) => {
                return {
                  ...initialField,
                  id: index,
                  hasFile: true,
                  savedFile: file,
                };
              })
            }
            onUpload={async (data) => {
              //Add files to state
              positionDispatch({
                type: "update_position_field",
                payload: ["addFiles", [data.uuid]],
              });
            }}
            onRemove={async (data) => {
              //Add files to state
              positionDispatch({
                type: "update_position_field",
                payload: ["files", []],
              });
              positionDispatch({
                type: "update_position_field",
                payload: ["addFiles", []],
              });
              positionDispatch({
                type: "update_position_field",
                payload: ["removeFiles", [data.uuid]],
              });
            }}
          />
        </MetaEditorGroup>
      </EditPositionAccordion>
      <EditPositionAccordion
        metaEditor
        title={t("common.settings", "Settings")}
      >
        <PageMG />
        <MetaEditorGroup name="Other" pt="0" pb="0">
          <Typography tag="p" bold nomargin mb="4">
            {t("component.tagfield.rejectionEmailLabel", "Rejection email")}
          </Typography>
          <Typography tag="p" color="rgba(24, 31, 56, 0.6)">
            {t(
              "component.tagfield.rejectionEmailDescription",
              "Edit the email that will be sent to rejected candidates."
            )}
          </Typography>
          <PrimaryButton white onClick={() => setOpen(true)} mb="25">
            {t(
              "component.metaeditor.edit_rejection_email",
              "Edit rejection email"
            )}
            <Icon icon="edit" ml="0" mr="8" scale={1.2} lowOpacity />
          </PrimaryButton>
        </MetaEditorGroup>
      </EditPositionAccordion>
      <EditPositionAccordion
        metaEditor
        title={t("common.careerPage", "Career page")}
      >
        <MetaEditorGroup>
          <Typography tag="p" bold nomargin mb="4">
            {t(
              "component.tagfield.selectedCareerPageLabel",
              "Select a career site"
            )}
          </Typography>
          <Typography tag="p" nomargin mb="-5" color="rgba(24, 31, 56, 0.6)">
            {t(
              "component.tagfield.selectedCareerPageDescription",
              "Select the career site to which the position belongs to and should be displayed on."
            )}
          </Typography>
          <PositionSiteSelect
            name="positionPageSiteId"
            options={sites}
            value={positionPageSiteLanguage}
            onChange={(e) => {
              setpositionPageSiteId(e);
            }}
          />
        </MetaEditorGroup>
      </EditPositionAccordion>
      <EditRejectionEmail
        positionId={single.id}
        ml="16"
        mb="25"
        open={open}
        onClose={handleClose}
      />
    </MetaEditor>
  );
};

export default PositionMetaEditor;
