import styled, { css } from 'styled-components';

const BaseStatusIcon = css`
  background-color: ${(props) => {
    if (props.status === 'submitted') return '#98C269';
    if (props.status === 'draft') return '#F8C929';
    if (props.status === 'invited') return 'grey';
    if (props.status === 'rejected') return '#FF385C';
  }};
  border: 1px solid grey;
  border-color: ${(props) => {
    if (props.status === 'submitted') return '#98C269';
    if (props.status === 'draft') return '#F8C929';
    if (props.status === 'invited') return 'grey';
    if (props.status === 'rejected') return '#FF385C';
  }};
`;

export const StyledAvatar = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.lgrey};

  border-radius: 50px;
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-container {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const StyledStatusIcon = styled.div`
  position: absolute;
  border-radius: 15px;
  ${BaseStatusIcon};

  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  display: ${(props) => (props.status === '' ? 'none' : 'block')};
`;

//This is used in single application view
export const StyledStatusIconInline = styled.span`
  ${BaseStatusIcon};
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
`;
