// React
import React, {useEffect, useState} from 'react'

// Translations
import { useTranslation } from 'react-i18next';

// Styled components
import { ButtonLoader, UpdateLoading } from './style';

const ButtonLoading = ({ loading, success, error, nobg, fw }) => {

    const [shouldShowModal, setShouldShowModal] = useState(false);
    const {t} = useTranslation(); 

    useEffect(() => {
        if (!loading) {
          // When loading is complete, show the modal
          setShouldShowModal(true);

          // Automatically close the modal after 2 seconds
          const modalTimeout = setTimeout(() => {
            setShouldShowModal(false);
          }, 4000);
    
          // Cleanup the timeout when the component unmounts
          return () => clearTimeout(modalTimeout);
        }
      }, [loading]);

  return (
    <>
    {loading && (
        <ButtonLoader nobg={nobg} fw={fw} />
    )}
    {shouldShowModal && success && (
    <UpdateLoading success>{t('common.successfullyUpdated')}</UpdateLoading>
    )}
      {shouldShowModal && error && (
    <UpdateLoading error>{t('common.errorUpdating')}</UpdateLoading>
    )}
    </>
  )
}

export default ButtonLoading