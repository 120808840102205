import axios from 'axios';
import { errorResponseHandler, responseHandler } from '../utilities/response';

export const purchasePromotion = async (body) => {
  try {
    const data = await axios.post("/promotion/purchase", body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getPromotion = async (body) => {
    try {
      const data = await axios.get("/promotion/products");
      return responseHandler(data);
    } catch (err) {
      return errorResponseHandler(err);
    }
  };
  
