import styled from "styled-components/macro";

export const StyledHeadlineWithAction = styled.div`
    display :flex;
    align-items:  center;
    justify-content: space-between;
    
    ${props=>props.mt && `margin-top: ${props.mt}px;`}
    ${props=>props.mb && `margin-bottom: ${props.mb}px;`}

    hr {
        margin-left: 20px;
        margin-right: 20px;
        flex: 1;
        color: transparent;
        background: transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top: 1px solid ${props=>props.theme.colors.lgrey} ;
    
    }
`;