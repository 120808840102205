import styled from "styled-components/macro";

export const PageViewWrapper = styled.div`
display: flex;
padding: 0 16px; 
flex-direction: column; 
@media (min-width: 1200px) {
    padding: 0; 
}
`

export const PageViewSettings = styled.div`
background-color: white; 
width: 100%; 
padding: 50px 0; 
@media (min-width: 1200px) {
    z-index: 1;
    ${props => props.hide && `
    z-index: -1;
    `}
    width: 394px; 
    padding: 25px; 
    height: 100vh;
    overflow: scroll; 
    padding-bottom: 150px; 
             &::-webkit-scrollbar {
              display: none;
              width: 0 !important
            }
            -ms-overflow-style: none;
  scrollbar-width: none;
}
`

export const PageLeftColumn = styled.div`
@media (min-width: 1200px) {
    width: calc(100% - 394px);
    background-color: #F3F3F5;
}
`