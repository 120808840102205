// React
import React, { useContext, useState } from "react";

// API endpoints - hooks - utility functions
import { createPosition } from "../../../../api/endpoints/position";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { makePage } from "../../../../api/endpoints/cp";

// State management
import { DispatchPositionContext } from "../../../context/PositionContext";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { StatePositionContext } from "../../../context/PositionContext";

// Components
import { Modal, BoxLoader } from "../../../../components";
import { Typography } from "../../../../components/Typography";
import { Icon } from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { Grid } from "../../../../components/Grid";
import { InputFieldWithLabel } from "../../../../components/Input/Input";

const CreatePosition = () => {
  const positionDispatch = useContext(DispatchPositionContext);
  const { createPositionModal } = useContext(StatePositionContext);
  const errorDispatch = useContext(DispatchErrorContext);
  let history = useHistory();
  let { t } = useTranslation();

  const [positionTitle, setPositionTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setPositionTitle(newValue);
  };

  const handleCreatePosition = async () => {
    try {
      setIsLoading(true);

      // Create a modified_single object with predefined properties
      const modified_single = {
        title: positionTitle,
      };

      // Create a request with the modified_single object
      const request = {
        body: modified_single,
      };

      // Call the createPosition API endpoint
      const response = await createPosition(request);

      if (!response.success) {
        throw new Error("Error creating position");
      }

      if (response.success) {
        const createPageRequest = {
          params: { positionId: response?.data?.id },
        };

        const createPageResponse = await makePage(createPageRequest);
        if (createPageResponse.success) {
          await history.push(`/position/${response?.data?.id}`);
        }
      }

      setIsLoading(false);

      await positionDispatch({ type: "create_position_modal", payload: false });
      await positionDispatch({ type: "edit_position_view", payload: true });
    } catch (err) {
      console.log(err);
      errorDispatch({ type: "set_error", payload: err.message });
    }
  };

  return (
    <>
      {createPositionModal && (
        <Modal
          onClose={() =>
            positionDispatch({ type: "create_position_modal", payload: false })
          }
          title={t("common.addNewPosition")}
        >
          <Grid padding="32" alignCenter>
            <Typography>{t("common.addPositionDesc_1")}</Typography>
            <InputFieldWithLabel
              label={false}
              name="linkedinUrl"
              value={positionTitle}
              tiny
              placeholder={t("page.position.position_title")}
              onChange={handleInputChange}
              mw
            />
            <PrimaryButton
              red
              mt="24"
              onClick={isLoading ? null : handleCreatePosition}
              disabled={isLoading}
            >
              {isLoading ? (
                <BoxLoader tiny />
              ) : (
                <>
                  {t("common.addPosition")}
                  <Icon icon="checkmark" mr="10" scale="2" />
                </>
              )}
            </PrimaryButton>
            <PrimaryButton
              transparent
              mb="0"
              mt="8"
              onClick={() =>
                positionDispatch({
                  type: "create_position_modal",
                  payload: false,
                })
              }
            >
              {t("common.cancel")}
              <Icon icon="cross" mr="10" invert />
            </PrimaryButton>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default CreatePosition;
