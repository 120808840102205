import { createGlobalStyle } from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        font-family: 'Work Sans', Arial, Helvetica, sans-serif;
        font-size: 12px;
        box-sizing: border-box;
        text-decoration: none;
        list-style: none;
    }
    pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }


    @page {
    size: A4 portrait;
    margin: 0cm;
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    a {
      color: ${props=>props.theme.colors.highlight};
      &:hover {
        color: ${props=>props.theme.colors.mgrey};
      }
    }

`;
