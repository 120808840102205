import React, { createContext, useReducer } from 'react';
// import { StateUserContext } from "../../context/UserContext";

export const StateAdministrationContext = createContext();
export const DispatchAdministrationContext = createContext();


const administrationReducer = (state, action, t) => {
  switch (action.type) {
    case 'modal_confirmation': {
      return {
        ...state,
        modalConfirmation: action.payload
      };
    }
    case 'delete_success': {
      return {
        ...state,
        deleteSuccess: action.payload
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const AdministrationProvider = ({ children }) => {
  // const { isAuthenticated } = useContext(StateUserContext);

  const administrationInitialState = {
    modalConfirmation: false,
    deleteSuccess: false,
  };

  const [state, dispatch] = useReducer(
    administrationReducer,
    administrationInitialState
  );

  return (
    <DispatchAdministrationContext.Provider value={dispatch}>
      <StateAdministrationContext.Provider value={state}>
        {children}
      </StateAdministrationContext.Provider>
    </DispatchAdministrationContext.Provider>
  );
};
