import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '..';
import { redirectAfterLogout } from '../../utils/redirectUrls';
import { localClearAll } from '../../utils/localSave';

export const logoutFunction = (logout) => {
  localClearAll();
  logout(redirectAfterLogout);
};

const LogoutButton = ({ children, ...rest }) => {
  const { logout } = useAuth0();
  return (
    <Button
      onClick={() => {
        //clear all local state after logout
        logoutFunction(logout);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default LogoutButton;
