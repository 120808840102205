import React, { useContext, useEffect, forwardRef } from "react";

// Custom hooks - API endpoints - libraries
import { useTranslation } from "react-i18next";
import { capitalize } from "../../../../utils/various";
import { titleValidation } from "../../../../utils/validation";
import { Formik, Field } from "formik";
import { updateOrganization } from "../../../../api/endpoints/organization";

// Components
import { InputFieldWithLabel } from "../../../../components";
import { Row, Col, Grid } from "../../../../components/Grid";
import { Button } from "../../../../components";
import { Icon } from "../../../../components";

// State management
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import {
  DispatchOrganizationContext,
  StateOrganizationContext,
} from "../../../context/OrganizationContext";
import { DispatchMessageContext } from "../../../../context/MessageContext";

// Styled
import {
  SettingsBlockWrapper,
  SettingsBlockHeading,
  SettingsBlockContent,
} from "../Settings.styled";
import { Typography } from "../../../../components/Typography";

const Company = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { organization } = useContext(StateOrganizationContext);

  const organizationDispatch = useContext(DispatchOrganizationContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);

  return (
    <SettingsBlockWrapper>
      <SettingsBlockHeading>
        <Typography tag="p" nomargin mb="5" bold>
          {t("common.company")}
        </Typography>
        <Typography tag="p" lowOpacity nomargin large>
          {t("common.companyDesc")}
          <br />
          <br />
          {t("common.companyDescTwo")}
        </Typography>
      </SettingsBlockHeading>
      <SettingsBlockContent>
        {organization && (
          <Formik
            initialValues={{
              ...organization,
              name: organization.name || "",
              organizationUrl: organization.organizationUrl || "",
              privacyPolicyUrl: organization.privacyPolicyUrl || "",
              primaryContactFirstName:
                organization.primaryContactFirstName || "",
              primaryContactLastName: organization.primaryContactLastName || "",
              primaryContactEmail: organization.primaryContactEmail || "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                organizationDispatch({
                  type: "init_update_organization",
                });
                if (!titleValidation(values.name))
                  throw new Error(
                    t(
                      "page.onboarding.error_org_name_characters",
                      "Please remove special characters from organization name"
                    )
                  );
                const submit = await updateOrganization(values);
                if (submit.success) {
                  organizationDispatch({
                    type: "finished_updating_organization",
                    payload: submit.data,
                  });
                } else {
                  errorDispatch({
                    type: "set_error",
                    payload: submit.data.data,
                  });
                }
              } catch (err) {
                console.log(err);
                errorDispatch({
                  type: "set_error",
                  payload: err.message,
                });
                setSubmitting(false);
              }
            }}
            enableReinitialize={true}
            initialErrors={true}
            innerRef={ref} // Add this line to forward the ref to Formik
          >
            {(props) => {
              const {
                // errors, values,
                isSubmitting,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Row widthSM="100%">
                    <Col
                      size={12}
                      break="sm"
                      smallerInnerGutter
                      minWidthSM="100%"
                    >
                      <Field
                        as={InputFieldWithLabel}
                        tiny
                        label={false}
                        placeholder={t("page.branding.organization_name")}
                        name="name"
                      />
                    </Col>
                    <Col
                      size={12}
                      break="sm"
                      smallerInnerGutter
                      minWidthSM="100%"
                    >
                      <Field
                        tiny
                        label={false}
                        as={InputFieldWithLabel}
                        placeholder={t("common.first_name")}
                        name="primaryContactFirstName"
                      />
                    </Col>
                  </Row>
                  <Row widthSM="100%">
                    <Col
                      size={12}
                      break="sm"
                      smallerInnerGutter
                      minWidthSM="100%"
                    >
                      <Field
                        as={InputFieldWithLabel}
                        tiny
                        label={false}
                        placeholder={t("page.branding.organization_url")}
                        name="organizationUrl"
                      />
                    </Col>
                    <Col
                      size={12}
                      break="sm"
                      smallerInnerGutter
                      minWidthSM="100%"
                    >
                      <Field
                        tiny
                        label={false}
                        as={InputFieldWithLabel}
                        placeholder={t("common.last_name")}
                        name="primaryContactLastName"
                      />
                    </Col>
                  </Row>
                  <Row widthSM="100%">
                    <Col
                      size={12}
                      break="sm"
                      smallerInnerGutter
                      minWidthSM="100%"
                    >
                      <Field
                        as={InputFieldWithLabel}
                        tiny
                        label={false}
                        placeholder={t(
                          "page.branding.organization_privacy_url"
                        )}
                        name="privacyPolicyUrl"
                      />
                    </Col>
                    <Col size={12} smallerInnerGutter minWidthSM="100%">
                      <Field
                        label={false}
                        tiny
                        as={InputFieldWithLabel}
                        placeholder={t("common.email")}
                        name="primaryContactEmail"
                      />
                    </Col>
                  </Row>
                  <Row mt={10} widthSM="100%">
                    <Col right minWidthSM="100%">
                      {/* <Button
                        transparent
                        urlLike
                        bold
                        highlight
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        <span
                          style={{ textTransform: 'uppercase' }}
                        >
                          {t('common.save')}
                        </span>
                        {!isSubmitting && (
                          <Icon
                            icon="checkmark"
                            small
                            highlightFill
                            ml={6}
                          />
                        )}
                      </Button> */}
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        )}
      </SettingsBlockContent>
    </SettingsBlockWrapper>
  );
});

export default Company;
