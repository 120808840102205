import styled from 'styled-components/macro';

export const RadioContainer = styled.label`
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const RadioMark = styled.span`
opacity: 1; 
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid ${props => props.theme.colors.red};
  border-radius: 40%;
    background-color: transparent; 
    transition: .5s ease;
  ${RadioInput}:checked ~ & {
    position: relative; 
    opacity: 1; 
    background-color: transparent; 
    &::after {
        content: ''; 
        position: absolute;
        height: 8px;
        width: 8px;
        left: calc(50% - 4px); 
        top: calc(50% - 4px); 
        border-radius: 30%; 
        background-color: ${props => props.theme.colors.red}; 
        animation: trans .5s ease;
        @keyframes trans {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1; 
            }
        }
    }
  }
`;