import styled from "styled-components/macro";

export const StyledFileField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${props => props.mb ? `margin-bottom: ${props.mb}px` : null}; 
  ${props => props.mt ? `margin-top: ${props.mt}px` : null}; 
  ${props => props.pt ? `padding-top: ${props.pt}px !important` : null}; 
  label {
    align-self: flex-start;
    color: ${(props) => props.theme.colors.dgrey};
    h3 {
      font-size: ${(props) => props.theme.fonts.labelSize};
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      font-size: ${(props) => props.theme.fonts.bylineSize};
      font-weight: 300;
    }

    margin-bottom: 10px;
  }
  ${props => props.attachment ? `
  display: flex; 
  align-items: center;
  flex-direction: row; 
  `: null}
  ${props => props.block && `
  padding: 26px 0;
  border-bottom: 1px solid #F0F0F3;
  `}
    ${props => props.noBorder ? `border: none` : null}; 
    @media (min-width: 992px) {
      ${props => props.notes && `
      padding: 5px 0; 
      `}
    }
  @media (min-width: 1200px) {
    ${props => props.mtLG ? `margin-top: ${props.mtLG}px` : null}; 
  }
  @media (min-width: 1200px) {
    ${props => props.pbLG ? `padding-bottom: ${props.pbLG}px` : null}; 
  }
  @media (min-width: 1400px) {
      ${props => props.notes && `
      padding: 10px 0; 
      `}
  }
  ${props => props.applicant && `
  border-bottom: 0;
  padding: 0;
  `}
`;
