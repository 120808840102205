import * as Yup from "yup";
import { testUrl } from "../../../utils/validation";

export const validation = async (objectToValidate, t) => {
  const shape = Yup.object().shape({
    accentColor: Yup.string(),
    brandColor: Yup.string(),
    linkedinUrl: Yup.string()
      .nullable()
      .test("validUrl", "Not a valid url", (value) => testUrl(value, true)),
    twitterUrl: Yup.string()
      .nullable()
      .test("validUrl", "Not a valid url", (value) => testUrl(value, true)),
    facebookUrl: Yup.string()
      .nullable()
      .test("validUrl", "Not a valid url", (value) => testUrl(value, true)),
    instagramUrl: Yup.string()
      .nullable()
      .test("validUrl", "Not a valid url", (value) => testUrl(value, true)),
  });
  return await shape.validate(objectToValidate);
};
