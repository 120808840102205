import * as Yup from 'yup';
import { phoneRegExp } from '../../../candidate/views/Application/validationSchema';
import { titleValidation } from '../../../utils/validation';

export const validation = async (objectToValidate, t) => {
  const shape = Yup.object().shape({
    language: Yup.string().required(
      t('page.onboarding.error_lang_required')
    ),
    terms: Yup.boolean()
      .required(t('page.onboarding.error_terms_required'))
      .oneOf([true], t('page.onboarding.error_terms_required')),
    phone: Yup.string()
      .required(t('page.onboarding.error_phone_required'))
      .matches(
        phoneRegExp,
        t('page.onboarding.error_phone_required')
      ),
    name: Yup.string()
      .required(t('page.onboarding.error_org_required'))
      .min(2, t('page.onboarding.error_org_name_too_short'))
      .max(50, t('page.onboarding.error_org_name_too_long'))
      .test(
        'organization name',
        t(
          'page.onboarding.error_org_name_characters',
          'Please remove special characters from organization name'
        ),
        (value) => titleValidation(value)
      ),
  });
  return await shape.validate(objectToValidate);
};
