// React
import React, { useState, useContext, useEffect } from "react";

// Translation
import { useTranslation } from "react-i18next";

// API endpoints
import { getPositions } from "../../../api/endpoints/position";

// Components
import { Col, Grid } from "../../../components/Grid";
import { Typography } from "../../../components/Typography";
import PositionAccessList from "./PositionAccessList/PositionAccessList";

// Styled
import { RadioButton, RadioContainer } from "./ManageAccess.styled";

// State management
import { StatePositionContext } from "../../context/PositionContext";
import { DispatchPositionContext } from "../../context/PositionContext";
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { DispatchOrganizationContext } from "../../context/OrganizationContext";

const ManageAccess = ({ user }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(
    user?.positionsAccess[0] === "all"
  );
  const positionDispatch = useContext(DispatchPositionContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const dispatchOrganization = useContext(DispatchOrganizationContext);

  useEffect(() => {
    const fetchPositions = async () => {
      positionDispatch({ type: "init_positions" });
      let request = { params: { sort: "DESC" } };
      try {
        const response = await getPositions(request);
        if (response.success) {
          positionDispatch({
            type: "loaded_positions",
            payload: response.data,
          });
        } else {
          errorDispatch({
            type: "set_error",
            payload: JSON.stringify(response.data),
          });
        }
      } catch (err) {
        console.log(err);
        errorDispatch({
          type: "set_error",
          payload: JSON.stringify(err),
        });
      }
    };
    fetchPositions();
  }, [errorDispatch, positionDispatch]);

  useEffect(() => {
    if (user?.positionsAccess.length < 0) {
      setIsChecked(false);
    }
  }, [user]);

  const { positions } = useContext(StatePositionContext);

  const handleRadioChange = (value) => {
    setIsChecked(value);
  };

  useEffect(() => {
    dispatchOrganization({
      type: "position_access",
      payload: isChecked === true && ["all"],
    });
  }, [isChecked]);

  return (
    <>
      <Grid padding={32} fluid borderBottom alignCenter>
        <Col mw>
          <Typography tag="p">
            {t(
              "common.manageRecruiterAccess",
              "Manage access for your new recruiter."
            )}
          </Typography>
          <RadioContainer>
            <RadioButton
              checked={isChecked}
              onClick={() => handleRadioChange(true)}
            >
              <input
                type="radio"
                name="customRadio"
                checked={isChecked}
                onChange={() => {}}
              />
              <Typography
                tag="p"
                nomargin
                ml="10"
                mr="40"
                lowOpacity={!isChecked}
              >
                {t("common.position.all_positions", "All positions")}
              </Typography>
              <span />
            </RadioButton>
            <RadioButton
              checked={!isChecked}
              onClick={() => handleRadioChange(false)}
            >
              <input
                type="radio"
                name="customRadio"
                checked={!isChecked}
                onChange={() => {}}
              />
              <Typography tag="p" nomargin ml="10" lowOpacity={isChecked}>
                {t("common.selectedPositions", "Selected positions")}
              </Typography>
              <span />
            </RadioButton>
          </RadioContainer>
        </Col>
      </Grid>
      <Grid padding={32} fluid alignCenter>
        <PositionAccessList
          positions={positions}
          allPositions={isChecked}
          user={user}
        />
      </Grid>
    </>
  );
};

export default ManageAccess;
