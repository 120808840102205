// React
import React from "react";

// Styled
import { RadioContainer, RadioInput, RadioMark } from "./radioButton.styled";
import { Typography } from "../Typography";
import { Flex } from "../../recruiter/style";

export const RadioButton = ({ value, checked, onChange, text }) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <RadioContainer>
      <Flex>
        <RadioInput value={value} checked={checked} onChange={handleChange} />
        <RadioMark />
        <Typography tag="p" nomargin ml="10">
          {text}
        </Typography>
      </Flex>
    </RadioContainer>
  );
};
