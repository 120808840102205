// React
import React, { useEffect, useState } from "react";

// Utility functions, hooks, api endpoints
import { useTranslation } from "react-i18next";
import { Icon } from "../..";
import { tmpFileUpload } from "../../../api/endpoints/files";
import { PrimaryButton, StopButton } from "../../Button";

import { DOC_TYPES, IMAGE_MIME_TYPES } from "./filter";
import {
  SmallButton,
  StyledButtonContainer,
  StyledSingleFileContainer,
  StyledValueContainer,
  StyledProgressContainer,
} from "./style";

import Progress from "./Progress/Progress";

const SingleField = ({
  id,
  fieldState,
  fieldDispatch,
  onFileUploadStarted,
  onFileUploadEnded,
  onFileUploadCompleted,
  onFileRemove,
  applicant,
  type,
  attachment,
  headerImg,
  docType,
  disabled,
  darkBackground,
  useStyleVars,
  page,
  transparentButton,
  uploadBtn,
  largeButton,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(Date.now()); //use state to force a rerender of input to clear the field
  const [controller, setController] = useState(null); //used to handle cancel if uploads
  const [field, setField] = useState(null);
  useEffect(() => {
    const _field = fieldState.fields.find((field) => field.id === id);
    setField(_field);
  }, [id, fieldState]);

  const onRemove = () => {
    fieldDispatch({ type: "file_remove", payload: field.id }); // update state
    setKey(Date.now()); // clear input field
    onFileRemove(field.savedFile);
  };

  const MAX_FILE_SIZE = 52428800; //50Mb

  const onUploadStart = async (e) => {
    //upload file on add
    const file = e.target.files[0];

    if (file) {
      fieldDispatch({ type: "upload_start", payload: field.id });

      //Make a controller so user can cancel request
      const _controller = new AbortController();
      setController(_controller); //set in state for later use

      try {
        if (file.size > MAX_FILE_SIZE)
          throw new Error("Max file size (50mb) exceeded!");
        const request = {
          file: file,
          type: type,
          docType: docType,
        };
        onFileUploadStarted();

        const response = await tmpFileUpload(
          request,
          (p) =>
            fieldDispatch({
              type: "update_progress",
              payload: { fieldId: field.id, progress: p },
            }),
          _controller
        );
        if (response.success) {
          console.log("success");
          fieldDispatch({
            type: "upload_finished",
            payload: { fieldId: field.id, savedFile: response.data },
          });
          onFileUploadCompleted(response.data);
          onFileUploadEnded();
        } else {
          throw new Error(response.data);
        }
      } catch (err) {
        console.log(err);
        onFileUploadEnded();
        if (err.message === "canceled") {
          fieldDispatch({ type: "upload_cancel", payload: field.id }); // update state
        } else {
          fieldDispatch({ type: "upload_error", payload: field.id });
        }
      }
    }
  };

  return (
    <>
      {field && (
        <StyledSingleFileContainer
          applicant={applicant}
          darkBackground={darkBackground}
          open={!field.hasFile}
          disabled={disabled}
          attachment={attachment}
          uploadBtn={uploadBtn}
          page={page}
        >
          <>
            {!field.hasFile ? (
              <>
                <StyledButtonContainer
                  attachment={attachment}
                  applicant={applicant}
                  largeButton={largeButton}
                >
                  <>
                    {attachment ? (
                      <Icon icon="attachment" />
                    ) : !page && !applicant ? (
                      <>
                        <SmallButton
                          useStyleVars={useStyleVars}
                          transparentButton={transparentButton}
                        >
                          <Icon icon="upload" lightInverted mr="6" ml="-6" />
                          {t(
                            "component.filefield.upload_file_label",
                            "Upload file"
                          )}{" "}
                        </SmallButton>
                      </>
                    ) : null}
                    {page ||
                      (applicant && (
                        <>
                          <PrimaryButton
                            transparent
                            largeButton={largeButton}
                            applicant={applicant}
                          >
                            {largeButton && <Icon icon="upload" mr="12" />}
                            {t(
                              "component.filefield.upload_file_label",
                              "Upload file"
                            )}{" "}
                            {!largeButton && <Icon icon="upload" mr="12" />}
                          </PrimaryButton>
                        </>
                      ))}
                  </>
                  {/* has error */}
                  {field.isError && (
                    <p style={{ marginTop: 20 }} className="error-text">
                      {t("component.filefield.upload_file_error", "≈")}
                    </p>
                  )}
                </StyledButtonContainer>
              </>
            ) : null}
            <input
              key={key}
              id={`upload-field-${field.id}`}
              type="file"
              name="file"
              value=""
              accept={
                type === "image" ? IMAGE_MIME_TYPES.join() : DOC_TYPES.join()
              }
              onChange={(e) => {
                if (e.target.value) onUploadStart(e);
              }}
              // disabled={field.hasFile || field.savedFile || disabled}
              capture={docType === "profileImage" ? "user" : false} //use camera when adding profileImages
            />
          </>

          {/* is uploading */}
          {field.isUploading && (
            <StyledProgressContainer>
              <Progress percentage={field.progress} />
            </StyledProgressContainer>
          )}

          {/* // has a file */}
          {field.hasFile && !field.isUploading && (
            <StyledValueContainer
              attachment={attachment}
              uploadBtn={uploadBtn}
              headerImg={headerImg}
              applicant={applicant}
            >
              {field.savedFile && field.savedFile.signedRequest && (
                <>
                  {IMAGE_MIME_TYPES.indexOf(field.savedFile.mimeType) > -1 ? (
                    <img src={field.savedFile.signedRequest} alt="" />
                  ) : (
                    <a
                      href={field.savedFile.signedRequest}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {field.savedFile.filename.length > 28
                        ? field.savedFile.filename.substring(0, 25) +
                          "..." +
                          field.savedFile.filename.substring(
                            field.savedFile.filename.lastIndexOf(".")
                          )
                        : field.savedFile.filename}
                    </a>
                  )}
                </>
              )}
              {field.savedFile && !field.savedFile.signedRequest && (
                <p>{field.savedFile.filename}</p>
              )}

              {(field.hasFile || field.savedFile) && !field.isUploading && (
                <StopButton onClick={onRemove} attachment />
              )}
            </StyledValueContainer>
          )}
        </StyledSingleFileContainer>
      )}
    </>
  );
};

export default SingleField;
