import { useEffect } from 'react';

/**
 * Hook for clicking outside event
 * @param {ref} ref 
 * @param {function} onOutside code to run when a click outside happens
 */
export const useOutsideAlerter = (ref, onOutside) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && typeof onOutside === "function") {
        onOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}