import styled from 'styled-components/macro';
import { StyledP } from '../Typography';

export const CVAbout = styled(StyledP)`
  position: relative;
  word-break: normal; 
  font-size: 14px; 
  opacity: 0.75; 
  color: ${props => props.theme.colors.faceliftBlue}; 
  line-height: 22px; 
  @media (min-width: 992px) {
    margin-top: 40px; 
  }
  @media (min-width: 1480px) {
    margin-top: 82px; 
  }
`;

export const CVExperienceWrapper = styled.div`
margin-bottom: 40px;
&:last-of-type {
  padding-bottom: 100px; 
}
`
