// React
import React, { useContext } from "react";

// API endpoints - custom hooks - utility functions
import { useTranslation } from "react-i18next";

// Components
import { Modal } from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { Icon } from "../../../../components";

// State management
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { DispatchUserContext } from "../../../../context/UserContext";
import { Typography } from "../../../../components/Typography";
import { Grid } from "../../../../components/Grid";

const DeleteRecruiter = ({ user, onCancel, onDelete }) => {
  const { t } = useTranslation();
  const errorDispatch = useContext(DispatchErrorContext);
  const userDispatch = useContext(DispatchUserContext);

  const handleConfirmDelete = async () => {
    try {
      await onDelete();
      userDispatch({ type: "reload" });
    } catch (err) {
      errorDispatch({ type: "set_error", payload: err });
    }
  };

  return (
    <Modal title={t("common.deleteRecruiterModalTitle")} onClose={onCancel}>
      <Grid padding={25} fluid alignCenter>
        <Typography tag="p" center>
          {t("common.deleteRecruiterConfirm_1")} {user.firstName}{" "}
          {user.lastName} {t("common.deleteRecruiterConfirm_2")}
        </Typography>
        <PrimaryButton red mt="20" onClick={handleConfirmDelete}>
          {t("common.yesValidation")}
          <Icon icon="checkmark" mr="10" scale="2.5" />
        </PrimaryButton>
        <PrimaryButton transparent onClick={() => onCancel()} mt="20">
          {t("common.cancel")}
          <Icon icon="cross" mr="10" />
        </PrimaryButton>
      </Grid>
    </Modal>
  );
};

export default DeleteRecruiter;
