import styled from "styled-components/macro";

export const ToolbarContainer = styled.div`
width: 100%; 
padding: 0 16px; 
padding-left: calc(16px + 5px);
border-bottom: 1px solid #E7E8EA;
display: flex; 
align-items: center;
height: 56px; 
position: absolute; 
top: 70px;
z-index: 9999;
background-color: white; 
@media (min-width: 992px) {
    padding: 0 16px; 
    padding-left: calc(16px + 5px);
    height: 40px;
}
`

export const CheckBoxContainer = styled.div`
position: relative; 
margin-bottom: -3px;
&::after {
    content: '';
    position: absolute;
    top: calc(50% - 7.5px);  
    right: 0; 
    width: 1px; 
    height: 15px; 
    background-color: ${(props) => props.theme.colors.mediumGray};
}
`

export const SortAndFilter = styled.div`
padding-left: 16px; 
display: flex;
@media (min-width: 992px) {
    opacity: 0.6; 
}
`

export const Sort = styled.div`
display: flex; 
font-size: 12px; 
color: ${props => props.theme.colors.faceliftBlue}; 
font-weight: 500;
@media (min-width: 992px) {
    font-weight: 400; 
    font-size: 12px; 
} 
> div {
    padding-right: 5px;
}
`

export const Filter = styled.div`
display: flex; 
font-size: 12px; 
padding-left: 25px; 
color: ${props => props.theme.colors.faceliftBlue}; 
font-weight: 500;
@media (min-width: 992px) {
    font-weight: 400; 
    font-size: 12px;
} 
> div {
    padding-right: 5px;
}
`

export const PositionStats = styled.div`
display: none;
@media (min-width: 992px) {

}
`

export const PositionStatsHeader = styled.div`
@media (max-width: 992px) {
    display: none;
}
display: flex; 
opacity: 0.6; 
color: ${props => props.theme.colors.faceliftBlue};
    width: 150px;
    div {
        width: 75px; 
        @media (min-width: 992px) {
            font-size: 12px;
        }
    }
`

export const SwitchContainer = styled.div`
width: 260px; 
background-color: ${props => props.theme.colors.faceliftBlue}; 
height: 50px; 
position: absolute; 
bottom: calc(-100vh + 140px); 
left: calc(50% - 130px);
z-index: 9999; 
${(props) => props.disabled ? 'opacity: 0;' : 'opacity: 1;'}; 
${(props) => props.disabled ? 'pointer-events: none;' : 'pointer-events: all;'}; 
transition: .5s ease;
border-radius: 25px; 
display: flex; 
align-items: center;
padding: 0 25px; 
justify-content: space-between;
@media (min-width: 992px) {
  width: 300px; 
  left: calc(50% - 150px);
}
@media (min-width: 1480px) {
  width: 380px; 
  left: calc(50% - 190px);
}
`

export const SwitchText = styled.div`
font-size: 12px; 
color: ${props => props.theme.colors.white}; 
font-weight: 400; 
transition: .5s ease;
`