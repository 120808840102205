// React
import React, { useEffect, useState, useContext } from 'react';

// API endpoints - Custom hooks - utilities
import {
  deleteShare,
  getShares,
} from '../../../../api/endpoints/share';
import { useTranslation } from 'react-i18next';

// Components
import Share from './Share';
import ShareShortlistForm from '../ShareShortlistForm/ShareShortlistForm';
import { SharedContainer } from '../style';
import { Grid } from '../../../../components/Grid';
import { Typography } from '../../../../components/Typography';

// State management
import { DispatchMessageContext } from '../../../../context/MessageContext';
import { DispatchErrorContext } from '../../../../context/ErrorContext';

const Shares = ({ positionId, title, updateParentState, updateParentConfirmation }) => {
  const { t } = useTranslation();
  const messageDispatch = useContext(DispatchMessageContext);
  const dispatchError = useContext(DispatchErrorContext);
  const [shares, setShares] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  //get list of shares
  useEffect(() => {
    //get list of shares
    const initShares = async () => {
      setIsLoading(true);
      try {
        const request = { selector: positionId };
        const response = await getShares(request);
        if (response.success) {
          setIsLoading(false);
          setShares(response.data);
          setIsError(false);
        } else {
          setIsError(true);
          setIsLoading(false);
        }
      } catch (err) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    if (positionId) initShares();
  }, [positionId]);

  const addedNewShare = (newShare) => {
    setShares([...shares, newShare]);
  };

  const handleDelete = async (e, id) => {
    try {
      const request = { selector: id };
      const response = await deleteShare(request);
      if (response.success) {
        messageDispatch({
          type: 'set_message',
          payload: t('message.deleted_share'),
        });
        setShares([...shares.filter((share) => share.id !== id)]);
      } else {
        dispatchError({
          type: 'set_error',
          payload: t('error.delete_share_failed'),
        });
      }
    } catch (err) {
      dispatchError({
        type: 'set_error',
        payload: t('error.delete_share_failed'),
      });
    }
  };


  return (
      <SharedContainer>
        <Grid padding="25" fluid alignCenter borderBottom>
        {!isLoading && shares && shares.length > 0 && (
        <Typography tag="p" nomargin mb="24">
          {t('common.shortlistCurrentlySharedWith')}
        </Typography>
      )}
      {!isLoading &&
        shares &&
        shares.map((share) => (
          <Share
            firstName={share.firstName}
            lastName={share.lastName}
            email={share.email}
            phoneNumber={share.phone}
            expires={share.expires}
            key={share.id}
            id={share.id}
            onDelete={handleDelete}
          />
        ))}
        </Grid>
        <Grid padding="25" fluid alignCenter>
          <Typography tag="p">{t('common.shareTheShortlistOf')} <b>{title}</b> {t('common.withANewShareHolder')}</Typography>
      <ShareShortlistForm
        addedNewShare={addedNewShare}
        positionId={positionId}
        updateParentState={updateParentState}
        updateParentConfirmation={updateParentConfirmation}
      />
      </Grid>
    </SharedContainer>
  );
};

export default Shares;
