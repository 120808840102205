import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

export const getSubscription = async () => {
  try {
    const data = await axios.get("/subscription/");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getPrivileges = async () => {
  try {
    const data = await axios.get("/subscription/privileges");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getMainSubscription = async () => {
  try {
    const data = await axios.get("/subscription/main");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getMainSubscriptionProducts = async () => {
  try {
    const data = await axios.get("/subscription/main/products");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const changeMainSubscription = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.put(`/subscription/main/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getPortalSession = async (request = {}) => {
  try {
    const data = await axios.get(`/subscription/portal/`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getCheckoutSession = async (request = {}) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.get(`/subscription/checkout/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getAvailableAddons = async () => {
  try {
    const data = await axios.get("/addon");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getUserAddons = async () => {
  try {
    const data = await axios.get("/addon/active");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const purchaseAddon = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.post(`/addon/purchase/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const cancelAddon = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.post(`/addon/cancel/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const reactivateAddon = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.post(`/addon/reactivate/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};
