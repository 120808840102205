import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationParamsContext } from '../ApplicationContextWrapper';
import Accordion from '../../Accordion';

const CONDITION_KEYS = [
  ['isSubmitted', 'component.filter.is_approved_by_candidate'],
  ['isNew', 'component.filter.is_new'],
  ['isAvailable', 'component.filter.is_available'],
  ['isNotAvailable', 'component.filter.is_not_available'],
  ['hasFiles', 'component.filter.has_files'],
  ['isRejected', 'component.filter.is_rejected'],
  ['isNotRejected', 'component.filter.is_not_rejected'],
];

const ApplicationFilterConditions = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ApplicationParamsContext);
  const handleClick = (e) => {
    dispatch({
      type: 'update_params',
      payload: { [e.target.name]: e.target.checked },
    });
  };
  return (
    <Accordion
      title={t("component.filter.conditions")}
      // initiallyOpen
    >
      {CONDITION_KEYS.map(([key, t_key]) => {
        return (
          <label key={key} htmlFor={key}>
            <input
              type="checkbox"
              checked={state.params[key]}
              id={key}
              name={key}
              onChange={handleClick}
            />
            <span>{t(t_key)}</span>
          </label>
        );
      })}
    </Accordion>
  );
};

export default ApplicationFilterConditions;
