import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

export const getPage = async (request = {}) => {
  const { selector, params } = parseRequest(request);
  const route = `/cp/page/${selector && selector}${params && params}`;
  try {
    const data = await axios.get(route);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getPositionPage = async (request = {}) => {
  const { selector } = parseRequest(request);
  const route = `/cp/page/positionPage/${selector}`;
  try {
    const data = await axios.get(route);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Update a page. Provide selector ( page id ) and body in request object
 * @param {object} request
 */
export const updatePage = async (request = {}) => {
  try {
    const { selector, body } = parseRequest(request);
    const data = await axios.put(`/cp/page/${selector}`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Make a new page object
 * axios post request
 * supply params siteId ( default to create a page for main site )
 * supply body with pageId to make a relational page
 */
export const makePage = async (request = {}) => {
  try {
    const { params, body } = parseRequest(request);
    const data = await axios.post(`/cp/page/${params && params}`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const destroyPage = async (request = {}) => {
  try {
    const { selector } = parseRequest(request);
    const data = await axios.delete(`/cp/page/${selector}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Get site (provide site id as param ) or sites
 */
export const getSite = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.get(`/cp/site/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Get site (provide site id as param ) or sites
 */
export const deleteSite = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.delete(`/cp/site/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Update with axios put request
 * uses params and body in request object { params: { id : siteId}, body : { language: "es-ES" }}
 */
export const updateSite = async (request = {}) => {
  try {
    const { selector: { id }, body } = parseRequest(request);
    const data = await axios.put(`/cp/site/${id && id}`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getTags = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.get(`/cp/tag/${params && params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * sort pages
 * @param {object} request with body object containing /  { body : { siteId: 30, orderedPages: [{ id: 1, ...etc }, { id : 2, ...etc}] }}
 * @returns
 */
export const sortPages = async (request = {}) => {
  try {
    const { body } = parseRequest(request);
    const data = await axios.put(`/cp/page/sort`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
}
