import React from "react";
import { StyledMetaEditorGroup } from "./MetaEditorGroup.styled";

const MetaEditorGroup = ({ name, children, pt, pb, hideOnMobile, page }) => {
  return (
    <StyledMetaEditorGroup
      pt={pt}
      pb={pb}
      hideOnMobile={hideOnMobile}
      page={page}
    >
      {/* <p>{name || t("common.settings", "settings")}</p> */}
      <div>{children}</div>
    </StyledMetaEditorGroup>
  );
};

export default MetaEditorGroup;
