// React
import React, { useEffect, useState } from "react";

// Utility functions - hooks - API endpoints - and ext. plugins
import { getPositions } from "../../../../../api/endpoints/position";
import { Typography } from "../../../../../components/Typography";
import { PositionGreyList, SelectedPositionsWrapper } from "./UsersCard.styled";
import { Flex } from "../../../../style";

const SelectedPositions = ({ user }) => {
  const [positions, setPositions] = useState();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await getPositions();
        if (response.success) {
          setPositions(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchPositions();
  }, [user]);

  // Filter positions based on user.positionsAccess
  const filteredPositions = positions?.filter((position) =>
    user.positionsAccess.includes(position.id.toString())
  );

  return (
    <SelectedPositionsWrapper>
      <Flex flexWrap>
        {filteredPositions?.map((filteredPosition) => (
          <React.Fragment key={filteredPosition.id}>
            <PositionGreyList>
              <Typography tag="p" nomargin ml="0" lowOpacity large>
                {filteredPosition.title}
              </Typography>
            </PositionGreyList>
          </React.Fragment>
        ))}
      </Flex>
    </SelectedPositionsWrapper>
  );
};

export default SelectedPositions;
