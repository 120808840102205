import React from 'react';
// import * as p from '../../../package.json';
// import packageInfo from '../../package.json';
import styled from 'styled-components/macro';

const Version = () => {
    return (
        <StyledVersion>
        {/* hitalento, version : { p.version } */}
        </StyledVersion>
        )
    }
    // <div style={{ fontSize: 9, position: 'absolute', bottom: 10, right: 10, color: "lightgrey" }}>

const StyledVersion = styled.div`
    font-size: 9px;
    margin-top: 20px;
    color: lightgrey;
    padding: 40px 20px 20px 20px;
    text-align: right;
    display: none;
`;

export default Version
