// React
import React from "react";
import { ButtonContainer } from "./SiteButton.styled";

/**
 * A button for each site.
 * Ordered by creation data
 * Main site contains a "home" icon and the title
 */
const SiteButton = ({ title, selected, onClick }) => {
  return (
    <ButtonContainer disabled={selected} selected={selected}>
      <button disabled={selected} onClick={onClick}>
        {title}
      </button>
    </ButtonContainer>
  );
};

export default SiteButton;
