
/**
 * Modify body
 * Wrap iframe in responsive div wrapper
 * Strip non vimeo or youtube embeds
 * @param {string} body
 */
export const modifyBody = (body) => {
  let newBody = body;
  const reIframe = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
  const iframeMatches = body.match(reIframe);
  // console.log(iframeMatches);

  if ( iframeMatches && iframeMatches?.length > 0 ) {

    iframeMatches.forEach( (iframeText) => {
      if ( iframeText.includes('class="ifr"') ) return; //Ignore already wrapped iframes!
      const replacedIframe = iframeText.replace('class="ql-video"', 'class="ql-video ifr" ');
      newBody = newBody.replace(iframeText, `<div class="responsive-wrapper">${replacedIframe}</div>`)
    })
  }
  return newBody;

}


/**
 * Find blocks with body and iframes in them modify the body before submit
 * @param {object} page
 * @returns
 */
export const modifyPage = (page) => {
  const newPage = {...page};
  newPage.blocks.forEach(block => {
    if ( !block.body) return;
    block.body = modifyBody(block.body);
  })

  return newPage;
}