import React from 'react';
import { InputLabelWithByline } from '../Input/Input';
import { StyledSideButton, StyledSquareToggle, UnderlineToggleWrapper, UnderlineToggleButton } from './SquareToggle.styled.js';

const SquareToggle = ({
  options,
  on,
  label,
  byline,
  onChange,
  noStretch,
  settings,
  mt, 
  reverse,
  gap
}) => {
  return (
    <>
      {label && (
        <InputLabelWithByline label={label} byline={byline} settings={settings} />
      )}
      <StyledSquareToggle className={noStretch ? 'no-stretch' : ''} mt={mt} reverse={reverse} gap={gap}>
        {options.map((opt) => (
          <StyledSideButton
            key={opt.value}
            className={on === opt.value && 'active'}
            onClick={() => {
              if (on !== opt.value) {
                onChange(opt.value);
              }
            }}
          >
            {opt.text}
          </StyledSideButton>
        ))}
      </StyledSquareToggle>
    </>
  );
};

export default SquareToggle;


export const UnderlineToggle = ({
  options,
  on,
  label,
  byline,
  onChange,
  settings,
  bold
}) => {
  return (
    <>
      {label && (
        <InputLabelWithByline label={label} byline={byline} settings={settings} bold={bold} />
      )}
      <UnderlineToggleWrapper>
        {options.map((opt) => (
          <UnderlineToggleButton
            key={opt.value}
            className={on === opt.value && 'active'}
            onClick={() => {
              if (on !== opt.value) {
                onChange(opt.value);
              }
            }}
          >
            {opt.text}
          </UnderlineToggleButton>
        ))}
      </UnderlineToggleWrapper>
    </>
  );
};