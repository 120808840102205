// React
import React, { useState, useEffect } from "react";

// API endpoints - custom hooks - utility functions
import { getCheckoutSession } from "../../api/endpoints/subscription";
import { useTranslation } from "react-i18next";

// Components
import { PrimaryButton } from "../Button";
import Icon from "../Icon";
import Modal from "../Modal/Modal";
import { Typography } from "../Typography";
import { Grid } from "../Grid";

const PaymentModal = ({ onCancel, returnUrl }) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const fetchUrl = async () => {
      const request = { params: { returnUrl: returnUrl } };
      const response = await getCheckoutSession(request);
      if (response.success) {
        setUrl(response.data.url);
      }
    };
    fetchUrl();
  }, [returnUrl]);
  return (
    <Modal
      tiny
      blind
      title={t("component.add_payment_popup.header")}
      onClose={onCancel}
    >
      {
        <Grid padding={25} alignCenter>
          <Typography tag="p" center>
            {t("component.add_payment_popup.text")}
          </Typography>
          <PrimaryButton red onClick={() => (window.location.href = url)}>
            {" "}
            {t("component.add_payment_popup.cta")} <Icon icon="edit" mr={12} />
          </PrimaryButton>
          <PrimaryButton transparent onClick={onCancel} mt="10">
            {t("common.cancel")}
          </PrimaryButton>
        </Grid>
      }
    </Modal>
  );
};

export default PaymentModal;
