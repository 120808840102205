import React from 'react';
import styled from 'styled-components/macro';
import { BoxLoader } from '../../../components';
import { ResetButton } from '../../../components/Button';
import { useTranslation } from 'react-i18next';

const LoadMore = ({ getMore, isGetting }) => {
  const { t } = useTranslation();
  // const remaining = 10;
  return (
    <StyledLoadMore onClick={() => getMore()}>
      <span></span>
      <StyledLoadMoreButton disabled={isGetting}>
        {!isGetting ? (
          `${t('component.list.load_more')}` // (${remaining})
        ) : (
          <BoxLoader tiny />
        )}
      </StyledLoadMoreButton>
      <span></span>
    </StyledLoadMore>
  );
};

const StyledLoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  & > span {
    flex: 1;
    height: 1px;
    background: ${(props) => props.theme.colors.lgrey};
  }
`;

const StyledLoadMoreButton = styled(ResetButton)`
  text-transform: uppercase;
  margin: 10px 10px;
  color: ${(props) => props.theme.colors.dgrey};
  /* position: absolute; */
  &:disabled {
    opacity: 0.6;
  }
`;

export default LoadMore;
