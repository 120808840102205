import styled from "styled-components/macro";
import arrow from '../../../components/Icon/arrow_up.svg';

export const StyledOnboarding = styled.div`
    position: fixed;
    left: 0; top: 0; z-index: 10;
    width: 100%; height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.faceliftBlue};
    color: #fff;
    .Dropdown-root {
    width: 340px; 
    z-index: 9999; 
    border-radius: 5px; 
    cursor: pointer;
    position: relative; 
    .Dropdown-arrow {
        display: none;
    }
    &::after {
        content: '';
        position: absolute;
        background-image: url(${arrow});
        width: 11px; 
        height: 8px; 
        background-repeat: no-repeat;
        background-size: contain; 
        top: calc(50% - 6px);
        right: 22px; 
        transform: rotate(180deg);
        pointer-events: none;
        transition: .3s ease;

    }
    &.is-open {
        .Dropdown-placeholder {
            opacity: 0.6; 
            transition: .3s ease;
        }
    }
    .Dropdown-control {
        padding: 0;
        border-radius: 5px; 
    }
    .Dropdown-placeholder, .Dropdown-option {
        padding: 10.5px 20px; 
        display: flex;
        align-items: center;
        font-size: 14px; 
        font-weight: 400; 
        color: ${props => props.theme.colors.faceliftBlue}; 
        transition: .3s ease;
    }
    .Dropdown-menu {
        position: absolute; 
        top: 50px; 
        background-color: white; 
        transition: .5s ease;
        width: 340px; 
        border: 1px solid rgba(24, 31, 56, 0.15); 
        border-radius: 5px; 
        box-shadow: 0px 3px 6px rgba(24, 31, 56, 0.15);

    .Dropdown-option {
        border-bottom: 1px solid ${props => props.theme.colors.mgrey};
        transition: .5s ease; 

        &:hover {
            background-color: ${props => props.theme.colors.lightGray}; 
            transition: .5s ease; 
        }
        &:last-of-type {
            border-bottom: none; 
        }
    }
    }
}
`;