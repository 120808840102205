// React
import React, { useState, useRef, useContext } from "react";

// Translations, hooks, and API endpoints
import { isSafari } from "react-device-detect";
import { useDrag, useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

// State management
import { PageContext } from "../../../../context/PageContext";
import { BLOCK_TEMPLATES, STATIC_BLOCK_TYPES } from "./blockConstants";

// Components
import BlockAdder from "./BlockAdder/BlockAdder";
import BlockControl from "./BlockControl/BlockControl";
import { DefaultContent, DefaultSettings } from "./BlockTypes/Default";
import { FooterContent, FooterSettings } from "./BlockTypes/Footer";
import { HeaderContent, HeaderSettings } from "./BlockTypes/Header";
import { ImageContent, ImageSettings } from "./BlockTypes/Image";
import { ListContent, ListSettings } from "./BlockTypes/List";
import { ParagraphContent, ParagraphSettings } from "./BlockTypes/Paragraph";
import {
  ParagraphWithImageContent,
  ParagraphWithImageSettings,
} from "./BlockTypes/ParagraphWithImage.jsx";
import {
  StyledBlockInner,
  StyledBlockOuter,
  StyledBlockHeader,
  StyledBlockContainer,
  StyledBlockHeaderOuter,
  StyledBlockContent,
} from "./Block.styled";
import { Icon } from "../../../../../components";

const Block = ({ block, moveBlock, index }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  //DRAG AND DROP
  const [dropOnTop, setDropOnTop] = useState(true);
  const [newBlockDrop, setNewBlockDrop] = useState(false);
  const dragRef = useRef(null);
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ["BLOCK", "BLOCK_ITEM"],
    collect(monitor) {
      return {
        isOver: !!monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: (item) => {
      if (item.source === "BLOCK_ITEM") {
        const hoverIndex = dropOnTop ? index : index + 1;

        //Determine before or after depending on drop position
        const template = { ...BLOCK_TEMPLATES[item.type], id: uuidv4() };
        dispatch({
          type: "add_block",
          payload: { index: hoverIndex, template: template },
        });
      }
    },
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      if (item.source === "BLOCK") {
        setNewBlockDrop(false);
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveBlock(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      } else if (item.source === "BLOCK_ITEM") {
        setNewBlockDrop(true);

        const clientOffset = monitor.getClientOffset();
        const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
        const middleOfHovered =
          hoverBoundingRect.top + hoverBoundingRect.height / 2;

        if (clientOffset.y < middleOfHovered) {
          setDropOnTop(true);
        } else {
          setDropOnTop(false);
        }
      }
    },
  });

  const [openAccordion, setOpenAccordion] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    type: "BLOCK",
    item: {
      id: block.id,
      source: "BLOCK",
      blockOrder: block.blockOrder,
      index: index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !openAccordion,
  });

  if (STATIC_BLOCK_TYPES.indexOf(block.type) === -1 && !isSafari) {
    //Drag and drop makes quill editor unusable in safari - therefore disabled in safari
    drag(drop(dragRef));
  }

  //Initially open settings tab if no content
  // const [openTab, setOpenTab] = useState(
  //   NO_CONTENT_BLOCKS.indexOf(block.type) > -1 ? "settings" : "content"
  // );

  const renderBlock = (block) => {
    switch (block.type) {
      case "header": {
        return [
          <HeaderContent block={block} />,
          <HeaderSettings block={block} />,
        ];
      }
      case "paragraph": {
        return [
          <ParagraphContent block={block} />,
          <ParagraphSettings block={block} />,
        ];
      }
      case "paragraph_with_image": {
        return [
          <ParagraphWithImageContent block={block} />,
          <ParagraphWithImageSettings block={block} />,
        ];
      }
      case "image": {
        return [
          <ImageContent block={block} />,
          <ImageSettings block={block} />,
        ];
      }
      case "list": {
        return [<ListContent block={block} />, <ListSettings block={block} />];
      }
      case "footer": {
        return [
          <FooterContent block={block} />,
          <FooterSettings block={block} />,
        ];
      }
      default: {
        return [
          <DefaultContent block={block} />,
          <DefaultSettings block={block} />,
        ];
      }
    }
  };

  return (
    <>
      <StyledBlockOuter
        dropOnTop={dropOnTop}
        className={`${isOver && newBlockDrop && "is-dropping"}`}
        style={{ opacity: isDragging ? 0 : 1 }}
        data-handler-id={handlerId}
        // ref={preview}
        ref={dragRef}
      >
        <StyledBlockInner>
          <StyledBlockHeaderOuter className="handle">
            <StyledBlockHeader
              openAccordion={openAccordion}
              nonMovable={STATIC_BLOCK_TYPES.indexOf(block.type) > -1}
              onClick={() => setOpenAccordion(!openAccordion)}
            >
              <div>
                <Icon icon={`block_${block.type}`} nomargin />
                {/* <p>{BLOCK_TEMPLATES[block.type]?.label}</p> */}
                <p>
                  {t(
                    "block.label." + block.type,
                    BLOCK_TEMPLATES[block.type]?.label
                  )}
                </p>
              </div>
              <Icon icon="arrow" width={18} rotate={openAccordion ? 180 : 0} />
            </StyledBlockHeader>
            <BlockControl block={block} />
          </StyledBlockHeaderOuter>
          <StyledBlockContainer className={openAccordion ? "open" : "closed"}>
            <StyledBlockContent>
              <>{renderBlock(block)[1]}</>
              <>{renderBlock(block)[0]}</>
            </StyledBlockContent>
          </StyledBlockContainer>
        </StyledBlockInner>
      </StyledBlockOuter>
      <BlockAdder block={block} />
    </>
  );
};

export default Block;
