// React
import React, { forwardRef } from "react";

// Components
import Company from "./Company";
import AccountSettings from "./AccountSettings";
import { SettingsFlexBlocks } from "../Settings.styled";

const Account = forwardRef((props, ref) => {
  return (
    <SettingsFlexBlocks>
      <Company ref={ref.companyFormRef} />
      <AccountSettings ref={ref.accountSettingsFormRef} />
    </SettingsFlexBlocks>
  );
});

export default Account;
