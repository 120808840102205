import { createGlobalStyle } from "styled-components";
import { brightnessByColor } from "../../../../utils/various";

export const StyledButtonVars = createGlobalStyle`
:root {
    --primary-background-color: ${(props) =>
      props.accentColor ? props.accentColor : props.theme.colors.primary};
    --primary-color: ${(props) =>
      props.accentColor
        ? brightnessByColor(props.accentColor) < 125
          ? "white"
          : "black"
        : props.theme.colors.dgrey};
    --secondary-background-color: ${(props) =>
      props.brandColor ? props.brandColor : props.theme.colors.primary};
    --secondary-color: ${(props) =>
      props.brandColor
        ? brightnessByColor(props.brandColor) < 125
          ? "white"
          : "black"
        : props.theme.colors.dgrey};
}
`;
