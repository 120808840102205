/**
 * use cpid, fallback to main site
 * if header is null, then dont use CP header
 */
export const selectPageWithHeader = (pages, cpid = null) => {
  if (!pages) return { page: null, header: null};
  let found_page;
  if (cpid) {
    pages.forEach((page) => {
      if (cpid === page.id) found_page = page;
    });
  }
  if (!found_page) {
    pages.forEach((page) => {
      if (page.site.main && page.status === 'publish')
        found_page = page;
    });
  }
  return {
    page: found_page,
    header: found_page
      ? found_page.blocks.find((block) => block.type === 'header')
      : null,
  };
};