// import Cookies from 'universal-cookie';
// const cookies = new Cookies();
const localKey = 'hitalento.applications';


export const localGetAndParseApplication = () => {
    return JSON.parse( sessionStorage.getItem(localKey) );
}

export const setRecentVisitedApplication = (uuid) => {
    sessionStorage.setItem(`${localKey}.latest_visited`, uuid);
}

export const getRecentVisitedApplication = () => {
    // return cookies.get(`${localKey}.latest_visited`);
    return sessionStorage.getItem(`${localKey}.latest_visited`);
}

export const clearRecentVisitedApplication = () => {
    sessionStorage.removeItem(`${localKey}.latest_visited`);
}

export const localSaveApplication = (uuid, data) => {
    let existingItem = localGetAndParseApplication();
    if ( existingItem ) {
        existingItem[uuid] = data;
    } else {
        existingItem = {};
        existingItem[uuid] = data;
    }   
    // cookies.set(localKey, JSON.stringify( existingItem ), { path: "/" });
    sessionStorage.setItem(localKey, JSON.stringify( existingItem ));
}

export const localRemoveFromApplication  = (uuid) => {
    let existingItem = localGetAndParseApplication();
    if ( existingItem ) { 
        delete existingItem.uuid;
    }
}

export const localClearAll = () => {
    //clear all session storage
    sessionStorage.clear();
}

export const getShortlistToken = () => {
    return sessionStorage.getItem('hitalento-shortlist-token')
}

export const saveShortlistToken = ( token ) => {
    sessionStorage.setItem('hitalento-shortlist-token', token);
}

export const setGenericSessionStorage = ( name, value ) => {
    sessionStorage.setItem(`${localKey}.${name}`, value)
}
export const getGenericSessionStorage = ( name ) => {
    return sessionStorage.getItem(`${localKey}.${name}`)
}
export const clearGenericSessionStorage = ( name ) => {
    sessionStorage.removeItem(`${localKey}.${name}`);
}

export const setGenericLocalStorage = (name, value, stringify ) => {
    localStorage.setItem(`${localKey}.${name}`, stringify ? JSON.stringify(value) : value);
}

export const getGenericLocalStorage = (name, parse) => {
    return parse ? JSON.parse(localStorage.getItem(`${localKey}.${name}`)) : localStorage.getItem(`${localKey}.${name}`);
}
