import { useState, useContext } from 'react';
import { DispatchMessageContext } from '../../context/MessageContext';
import { useQueryClient } from 'react-query';
import {
  bulkDeleteApplications,
  duplicateApplicationsToPosition,
  rejectApplication,
  updateApplication,
} from '../../api/endpoints/application';
import { useTranslation } from 'react-i18next';
import { DispatchErrorContext } from '../../context/ErrorContext';

/**
 * Perform an action on applications in applications array
 * Such as
 * delete, reject, shortlist
 * @param {string|null} action
 * @param {function} setModalAction
 * @param {array} applications
 * @param {object} payload values
 * @param {boolean} invalidate react query cache after action
 * @param {boolean} messageAfter dispatch a message after actrion
 * @returns
 */
const useApplicationActions = (
  action,
  setModalAction,
  applications,
  payload,
  invalidate = true,
  messageAfter = true
) => {
  const { t } = useTranslation();
  const messageDispatch = useContext(DispatchMessageContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const SET_SUBSTATUS = ['interview', 'hired', 'to_be_rejected'];

  //Actions delete, reject, changes status
  const takeApplicationAction = async (requestOptions = {}) => {
    if (
      !apiCalls(applications)[action] &&
      SET_SUBSTATUS.indexOf(action) === -1
    ) {
      setModalAction(null);
      return;
    }
    requestOptions = { ...payload, ...requestOptions };

    try {
      setLoading(true);
      // Then actual mutation
      let actionCall = action;
      if (SET_SUBSTATUS.indexOf(action) > -1) {
        actionCall = 'set_substatus';
        requestOptions = { ...requestOptions, substatus: action };
      }
      await apiCalls(applications, requestOptions)[actionCall]();

      // Feedback
      setLoading(false);

      if (messageAfter) {
        messageDispatch({
          type: 'set_message',
          payload: MESSAGES[action]
            ? t(MESSAGES[action])
            : t('message.status_changed'),
        });
      }

      //Clear cache, then refetch after delete
      if (invalidate) {
        queryClient.invalidateQueries();
        if (action === 'delete') {
          setTimeout(() => {
            //THE MOTHER OF ALL HACKS!
            queryClient.invalidateQueries();
          }, 600);
        }
      }

      //Close modal
      setModalAction(null);
    } catch (err) {
      setLoading(false);
      //Close modal
      setModalAction(null);
      errorDispatch({ type: 'set_error', payload: err.message });
      console.log(err);
    }
  };

  return {
    loading: loading,
    handleActionTaken: takeApplicationAction,
  };
};

export default useApplicationActions;

/**
 * Get a list of functions to perform mutations to a list of applications
 * @param {array} applications
 * @returns {object} a list of async function calls to the api
 */
const apiCalls = (applications, payload) => {
  return {
    delete: async () =>
      await bulkDeleteApplications({
        body: { applicationIds: applications.map((app) => app.id) },
      }),
    reject: async () =>
      await rejectApplication({
        params: {
          applicationIds: applications.map((app) => app.id),
        },
      }),
    shortlist: async () =>
      await Promise.all(
        applications.map(async (application) => {
          const request = {
            selector: application.id,
            body: { shortlist: true },
          };
          return await updateApplication(request);
        })
      ),
    unshortlist: async () =>
      await Promise.all(
        applications.map(async (application) => {
          const request = {
            selector: application.id,
            body: { shortlist: false },
          };
          return await updateApplication(request);
        })
      ),
    rate: async () => {
      if (payload?.rating === 'undefined')
        throw new Error('No rating');
      return await Promise.all(
        applications.map(async (application) => {
          const request = {
            selector: application.id,
            body: { stars: payload.rating },
          };
          return await updateApplication(request);
        })
      );
    },
    set_substatus: async () => {
      if (payload?.substatus === 'undefined')
        throw new Error('No substatus');
      return await Promise.all(
        applications.map(async (application) => {
          const request = {
            selector: application.id,
            body: { substatus: payload.substatus },
          };
          return await updateApplication(request);
        })
      );
    },
    add_applicants: async () => {
      if (!payload?.positionId) throw new Error('No position id');
      const request = {
        params: {
          positionId: payload.positionId,
          applicationIds: JSON.stringify(
            applications.map((app) => app.id)
          ),
        },
      };
      return await duplicateApplicationsToPosition(request);
    },
  };
};

const MESSAGES = {
  delete: 'message.application_deleted',
  reject: 'message.rejection_sent',
  shortlist: 'common.shortlisted',
  unshortlist: 'common.unlisted',
  add_applicants: 'message.added_candidates_to_position',
  interview: 'message.status_changed',
  hired: 'message.status_changed',
  to_be_rejected: 'message.status_changed',
};
