// React
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
// Components
import { ToggleSwitch } from "../../../components/Buttons/Buttons";

// API endpoint
import { updatePosition } from "../../../../api/endpoints/position";

// Styled component
import { AnonymousHiringWrapper } from "./AnonymousHiring.styled";

// Context
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../../context/PositionContext";

const AnonymousHiring = () => {
  const { t } = useTranslation();
  const positionDispatch = useContext(DispatchPositionContext);
  const { single } = useContext(StatePositionContext);

  const [prevAnon, setPrevAnon] = useState(single.anon);

  useEffect(() => {
    if (single.anon !== prevAnon) {
      // Save to the database when "anon" value changes
      const updateDatabase = async () => {
        try {
          // Make the API call to update the "anon" value in the database
          await updatePosition({
            selector: single.id,
            body: { anon: single.anon },
          });
          setPrevAnon(single.anon);
        } catch (error) {
          console.log('Error updating "anon" value in the database:', error);
        }
      };

      updateDatabase();
    }
  }, [single.anon, prevAnon, single.id]);

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  return (
    <AnonymousHiringWrapper>
      <ToggleSwitch
        text={t("common.anonymous.hiring", "Anonymous hiring")}
        active={single.anon}
        onClick={() => {
          positionDispatch({
            type: "update_position_field",
            payload: ["anon", !single.anon],
          });
        }}
      />
    </AnonymousHiringWrapper>
  );
};

export default AnonymousHiring;
