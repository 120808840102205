// React
import React, { useContext } from "react";

// Translations, hooks, and API endpoints
import { useTranslation } from "react-i18next";
import { BLOCK_SIZES, HEADER_BUTTON_OPTIONS } from "../blockConstants";

// React dropdown
import Dropdown from "react-dropdown";

// Components
import {
  FileField,
  InputFieldWithLabel,
  TextFieldWithLabel,
} from "../../../../../../components";
import { UnderlineToggle } from "../../../../../../components/SquareToggle/SquareToggle";
import { initialField } from "../../../../../../components/FileField/reducer";
import { Col, Row } from "../../../../../../components/Grid";

// State management
import { PageContext } from "../../../../../context/PageContext";
import { InputGroup } from "../../../../../../components/Input/Input";
import { Typography } from "../../../../../../components/Typography";
import { Flex } from "../../../../../style";

export const HeaderContent = ({ block }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(PageContext);
  return (
    <>
      <InputFieldWithLabel
        block
        tiny
        label={t("component.block.section_header_label", "Section header")}
        byline={t(
          "component.block.section_header_byline",
          "Choose a header for this section of text"
        )}
        onChange={(e) => {
          dispatch({
            type: "update_block",
            payload: { id: block.id, name: "title", value: e.target.value },
          });
        }}
        name="title"
        type="text"
        value={block.title || ""}
      />
      <TextFieldWithLabel
        nomargin
        block
        label={t("component.block.section_subheader_label", "Subheader")}
        byline={t(
          "component.block.section_subheader_label",
          "Short descriptive text"
        )}
        placeholder="Your text here..."
        value={block.subTitle || ""}
        trackValue={block.subTitle || ""}
        onChange={(e) => {
          dispatch({
            type: "update_block",
            payload: { id: block.id, name: "subTitle", value: e.target.value },
          });
        }}
        maxLength={200}
      />
      <Flex flexDirection="column" flexDirectionLG="row" gap="50">
        <InputGroup block noBorderBottom>
          <Typography tag="p" bold nomargin>
            {t("component.block.primary_button_label", "Primary button")}
          </Typography>
          <Typography tag="p" lowOpacity mt="4" mbXL="10">
            {t(
              "component.block.primary_button_byline",
              "Choose a primary button action"
            )}
          </Typography>
          <Dropdown
            options={[
              { value: "", label: t("component.block.no_button", "No button") },
              ...HEADER_BUTTON_OPTIONS.filter(
                (opt) =>
                  (opt.page === "job_ad" && state.page.positionId) ||
                  (!state.page.positionId && opt.page !== "job_ad")
              ).map((b) => ({ value: b.action, label: b.option })),
            ]}
            onChange={(selectedOption) => {
              dispatch({
                type: "update_block",
                payload: {
                  id: block.id,
                  name: "primaryButtonAction",
                  value: selectedOption.value,
                },
              });
            }}
            value={block.primaryButtonAction || ""}
          />
        </InputGroup>
        <InputGroup block noBorderBottom>
          <Typography tag="p" bold nomargin>
            {t("component.block.secondary_button_label", "Secondary button")}
          </Typography>
          <Typography tag="p" lowOpacity mt="4" mbXL="10">
            {t(
              "component.block.secondary_button_byline",
              "Choose a secondary button action"
            )}
          </Typography>
          <Dropdown
            options={[
              { value: "", label: t("component.block.no_button", "No button") },
              ...HEADER_BUTTON_OPTIONS.filter(
                (opt) =>
                  (opt.page === "job_ad" && state.page.positionId) ||
                  (!state.page.positionId && opt.page !== "job_ad")
              ).map((b) => ({ value: b.action, label: b.option })),
            ]}
            onChange={(selectedOption) => {
              dispatch({
                type: "update_block",
                payload: {
                  id: block.id,
                  name: "secondaryButtonAction",
                  value: selectedOption.value,
                },
              });
            }}
            value={block.secondaryButtonAction || ""}
          />
        </InputGroup>
      </Flex>
      {state.page.positionId &&
        (block.secondaryButtonAction === "mail_to_contact_person" ||
          block.primaryButtonAction === "mail_to_contact_person") && (
          <Row>
            <Col>
              <InputFieldWithLabel
                block
                name="contactName"
                value={block.contactName}
                label={t("component.block.contact_name_label", "Contact name")}
                tiny
                onChange={(e) => {
                  dispatch({
                    type: "update_block",
                    payload: {
                      id: block.id,
                      name: "contactName",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col>
              <InputFieldWithLabel
                block
                name="contactEmail"
                value={block.contactEmail}
                label={t(
                  "component.block.contact_email_label",
                  "Contact email"
                )}
                tiny
                onChange={(e) => {
                  dispatch({
                    type: "update_block",
                    payload: {
                      id: block.id,
                      name: "contactEmail",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
        )}
    </>
  );
};

export const HeaderSettings = ({ block }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  return (
    <>
      <UnderlineToggle
        bold
        options={[
          {
            text: t("component.block.background_type_option_1", "Solid Color"),
            value: "solid",
          },
          {
            text: t("component.block.background_type_option_2", "Image"),
            value: "image",
          },
          {
            text: t("component.block.background_type_option_3", "Video"),
            value: "video",
          },
        ]}
        label={t("common.headerType", "Header type")}
        byline={t("common.headerTypeDesc")}
        on={block.backgroundType || "Solid"}
        onChange={(value) => {
          dispatch({
            type: "update_block",
            payload: {
              id: block.id,
              name: "backgroundType",
              value: value,
            },
          });
        }}
      />
      {block.backgroundType === "video" && (
        <InputFieldWithLabel
          name="backgroundVideo"
          block
          value={block.backgroundVideo || ""}
          label={t(
            "component.block.background_video_label",
            "Background video"
          )}
          byline={t(
            "component.block.background_video_byline",
            "Insert vimeo video id. Go to your video on vimeo and find your id in the path: https://vimeo.com/YOUR_VIDEO_ID"
          )}
          tiny
          placeholder=""
          onChange={(e) => {
            dispatch({
              type: "update_block",
              payload: {
                id: block.id,
                name: "backgroundVideo",
                value: e.target.value,
              },
            });
          }}
        />
      )}
      {block.backgroundType === "image" && (
        <FileField
          type="image"
          docType="other"
          block
          transparentButton
          headerImg
          uploadBtn
          label={t(
            "component.block.background_image_upload_label",
            "Background Image"
          )}
          initialFiles={
            block.files?.length > 0 &&
            block?.files.map((file, index) => {
              return {
                ...initialField,
                id: index,
                hasFile: true,
                savedFile: file,
              };
            })
          }
          onUpload={(data) => {
            dispatch({
              type: "add_block_file",
              payload: { id: block.id, file: data },
            });
          }}
          onRemove={(data) => {
            dispatch({
              type: "remove_block_file",
              payload: { id: block.id, file: data },
            });
          }}
        />
      )}
      <InputGroup block>
        <Typography tag="p" bold nomargin mb="4" mbXL="10">
          {t("component.block.header_size_label")}
        </Typography>
        <Typography tag="p" lowOpacity mbXL="10">
          {t("component.block.header_size_byline")}
        </Typography>
        <Dropdown
          options={BLOCK_SIZES.map((p) => ({ value: p.value, label: p.label }))}
          value={block.blockSize || ""}
          onChange={(selectedOption) => {
            dispatch({
              type: "update_block",
              payload: {
                id: block.id,
                name: "blockSize",
                value: selectedOption.value,
              },
            });
          }}
        />
      </InputGroup>
      {/* <CommonBlockSettings block={block} /> */}
    </>
  );
};
