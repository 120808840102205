import styled from "styled-components/macro";

export const StyledIntegrationWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  padding: 0px;
  div.logo:first-child  {
    // Logo container
    padding: 0px 0 20px 0;
  }
`