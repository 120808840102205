import React from "react";

import { StyledTag } from "./style";
import { Icon } from "../../../../../../../components";

const Tag = ({ tag, onRemove }) => {
  return (
    <StyledTag>
      {tag.tagName} <Icon icon="cross" scale={0.8} ml="8" onClick={onRemove} />
    </StyledTag>
  );
};

export default Tag;
