// React
import React, { useState, useEffect, useContext } from "react";

// API endpoints - utility functions - hooks
import {
  activateIntegration,
  deactivateIntegration,
  readIntegration,
} from "../../../../api/endpoints/integration";
import { useTranslation } from "react-i18next";

// Components
import { BoxLoader, Icon, InputFieldWithLabel } from "../../../../components";
import { Typography } from "../../../../components/Typography";
import { Grid } from "../../../../components/Grid";

// Styled components
import { Flex } from "../../../style";

// State management
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { DispatchMessageContext } from "../../../../context/MessageContext";
import IntegrationWrapper from "../IntegrationWrapper";

// Assets
import { PrimaryButton } from "../../../../components/Button";

const BobIntegration = ({ closeModal }) => {
  const [integration, setIntegration] = useState({
    webhook_url: "",
    webhook_key: "",
  });
  const [status, setStatus] = useState("unloaded"); // unloaded / loading / loaded / error
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);

  const { t } = useTranslation();

  const handleIntegrationButtonClick = () => {
    closeModal();
  };

  useEffect(() => {
    const initForm = async () => {
      setStatus("loading");
      const response = await readIntegration({
        params: { type: "bob" },
      });
      if (response.success) {
        setStatus("loaded");
        if (response.data[0]?.webhook_url) {
          //Only set in state if response contains an actual integration
          setIntegration(response.data[0]);
        }
      }
    };

    initForm();
  }, []);

  const onChange = (e) => {
    setIntegration({
      ...integration,
      [e.target.name]: e.target.value,
    });
  };

  //Deactivate the integration
  const deactivate = async () => {
    const response = await deactivateIntegration({
      params: { type: "bob" },
    });
    if (response.success) {
      setIntegration(response.data);
    }
  };

  //Update or create integration
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setStatus("loading");
      const response = await activateIntegration({
        params: { type: "bob" },
        body: {
          webhook_url: integration.webhook_url,
          webhook_key: integration.webhook_key,
        },
      });
      if (response.success) {
        setIntegration(response.data);
        messageDispatch({
          type: "set_message",
          payload: "Integration updated and active!",
        });
      } else {
        errorDispatch({ type: "set_error", payload: response.data.data });
      }
      setStatus("loaded");
    } catch (err) {
      setStatus("error");
      console.log(err);
    }
  };
  return (
    <IntegrationWrapper>
      {status === "error" && <p>An error occurred</p>}
      {status === "loading" && <BoxLoader />}
      {status !== "loading" && (
        <>
          <Grid padding="32" fluid alignCenter borderBottom>
            <Typography tag="p">{t("common.integrate_desc_1")}</Typography>
            <Typography tag="p" nomargin>
              {t("common.integrate_desc_2")}
            </Typography>
            <form>
              <Flex gap="20">
                <InputFieldWithLabel
                  name="webhook_url"
                  onChange={onChange}
                  value={integration.webhook_url}
                  required
                  placeholder="HiBob URL"
                />
                <InputFieldWithLabel
                  name="webhook_key"
                  onChange={onChange}
                  value={integration.webhook_key}
                  required
                  placeholder="Secret API Key"
                />
              </Flex>
              <PrimaryButton red onClick={onSubmit} alignCenter mt="25">
                {t("common.connect")}
                <Icon icon="checkmark" scale="2.5" mr="10" />
              </PrimaryButton>
              <PrimaryButton
                transparent
                alignCenter
                mt="15"
                onClick={handleIntegrationButtonClick}
              >
                {t("common.cancel")}
                <Icon icon="cross" mr="10" />
              </PrimaryButton>
            </form>
          </Grid>
        </>
      )}
    </IntegrationWrapper>
  );
};

export default BobIntegration;
