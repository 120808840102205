import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FileField } from "../../../../../../components";
import { initialField } from "../../../../../../components/FileField/reducer";

import { PageContext } from "../../../../../context/PageContext";

import CommonBlockSettings from "./CommonBlockSettings";

export const ImageContent = ({ block }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  return (
    <>
      <FileField
        type="image"
        docType="other"
        transparentButton
        uploadBtn
        label={t("component.block.image_label", "Image")}
        initialFiles={
          block.files?.length > 0 &&
          block?.files.map((file, index) => {
            return {
              ...initialField,
              id: index,
              hasFile: true,
              savedFile: file,
            };
          })
        }
        onUpload={(data) => {
          dispatch({
            type: "add_block_file",
            payload: { id: block.id, file: data },
          });
        }}
        onRemove={(data) => {
          dispatch({
            type: "remove_block_file",
            payload: { id: block.id, file: data },
          });
        }}
      />
    </>
  );
};

export const ImageSettings = ({ block }) => {
  return (
    <>
      <CommonBlockSettings block={block} pt="0"/>
    </>
  );
};
