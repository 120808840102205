import styled from "styled-components/macro";

export const SmallCardWrapper = styled.div`
width: 100%; 
border: 1px solid rgba(24, 31, 56, 0.15); 
border-radius: 5px; 
margin-bottom: 24px; 
&:last-of-type {
    margin-bottom: 0; 
}
div {
    display: flex; 
}
@media (min-width: 1200px) {
    margin-top: 16px;
    &:last-of-type {
        margin-bottom: 24px;
    }
}
`

export const SmallCardHeader = styled.div`
width: 100%; 
display: flex; 
flex-direction: row;
align-items: center;
padding: 24px; 
background-color: white; 
border-radius: 5px 5px 0 0; 
box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
z-index: 9; 
border-bottom: 1px solid rgba(24, 31, 56, 0.15); 
justify-content: space-between;
position: relative;
z-index: 9; 
`

export const SmallCardContent = styled.div`
z-index: 0; 
position: relative; 
padding: 14px 24px; 
background-color: white; 
border-radius: 0 0 5px 5px; 
display: flex; 
justify-content: space-between;
`

export const SmallerCardContainer = styled.div`
padding: 24px; 
width: 100%; 
@media (min-width: 1200px) {
    width: 33.33%; 
    border-right: 1px solid rgba(213, 214, 219);
    &:last-of-type {
        border-right: 0; 
    }
}
`