import React, { useState, useContext, useEffect } from "react";
import { StyledGlobalErrorMessage, StyledDismissButton } from "./style";
import { DispatchErrorContext } from "../../context/ErrorContext";
import { DispatchMessageContext } from "../../context/MessageContext";
import Icon from "../Icon";
const GlobalErrorMessage = ({ children, message }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);
  const onDismiss = () => {
    if (message) {
      messageDispatch({ type: "clear_message" });
    } else {
      errorDispatch({ type: "clear_error" });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        if (message) {
          messageDispatch({ type: "clear_message" });
        } else {
          errorDispatch({ type: "clear_error" });
        }
      }, 1900);
    }, 5000);
  }, [messageDispatch, errorDispatch, message]);
  return (
    <StyledGlobalErrorMessage
      fadeOut={fadeOut}
      notError={message ? true : false}
    >
      <StyledDismissButton onClick={onDismiss} />

      <Icon icon="notice" width={20} height={20} color="#fff" mr={16} />
      {children}
    </StyledGlobalErrorMessage>
  );
};

export default GlobalErrorMessage;
