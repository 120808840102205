// React
import React, { useContext, useState } from "react";

// Translation
import { useTranslation } from "react-i18next";

// Backend
import { deactivatePositions } from "../../../api/endpoints/position";

// Components
import { Modal } from "../../../components";
import { Typography } from "../../../components/Typography";
import { PrimaryButton } from "../../../components/Button";
import { Icon } from "../../../components";
import { ToggleButton, ToggleSwitch } from "../Buttons/Buttons";

// Styled components 
import { ToolbarContainer, CheckBoxContainer, PositionStatsHeader, SwitchContainer, SwitchText } from "./Positionlist.styled";
import { Flex } from "../../style";

// State management
import { DispatchMessageContext } from "../../../context/MessageContext";
import { DispatchPositionContext } from "../../context/PositionContext";

// Utility functions and hooks
import { toggleCheckAll } from "../../../utils/various";

const PositionListOptions = ({ selected, setSelected, all, options }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const dispatchPosition = useContext(DispatchPositionContext);
  const messageDispatch = useContext(DispatchMessageContext);

  const handleDeactivateSelected = async () => {
    try {
      const request = { body: { positionIds: selected } };
      const response = await deactivatePositions(request);

      if (response.success) {
        //dispatch message
        messageDispatch({
          type: "set_message",
          payload: t("message.deactivated_positions"),
        });
        //refresh lists
        dispatchPosition({ type: "refresh_positions" });

        //clear selected
        setSelected([]);

        // Clear active return to active state
        setIsActive(true);
      }

      //close modal
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleModal = () => {
    setShowModal(prevState => !prevState);
    setIsActive(prevState => !prevState);
  }

  return (
    <>
      {showModal && (
        <Modal openAnimation={showModal} title="Deactivate positions">
          <Typography tag="p" larger center mt="25">
            {t('common.deactivateConfirmation')}
          </Typography>
          <Flex flexDirection="column">
            <PrimaryButton onClick={handleDeactivateSelected} red mt="20">
              {t("common.deactivate")}
              <Icon icon="deactivate" mr="10" />
            </PrimaryButton>
            <PrimaryButton transparent mt="18" mb="32" onClick={handleModal}>
              {t('common.cancel')}
              <Icon icon="cross" mr="10" invert />
            </PrimaryButton>
          </Flex>
        </Modal>
      )}

      <ToolbarContainer>
        <CheckBoxContainer>
          <ToggleButton
            toggleFunction={() => toggleCheckAll(selected, setSelected, all)}
            selected={selected || []}
          />
        </CheckBoxContainer>
        <Flex justify="flex-end">
          {/* <SortAndFilter>
        <Sort>
          <Icon icon="sort" />
          {t("common.sort")}
        </Sort>
        <Filter>
        <Icon icon="filter" />
          {t("common.filter")}
        </Filter>
        </SortAndFilter> */}
          <PositionStatsHeader>
            <div>
              {t("common.applied")}
            </div>
            <div>
              {t("common.shortlist")}
            </div>
          </PositionStatsHeader>
        </Flex>
        {options && options.indexOf("deactivate") > -1 && (
          <SwitchContainer disabled={selected.length === 0}>
            <SwitchText>{selected.length} {t("common.positionsSelected", "positions selected")}</SwitchText>
            <ToggleSwitch
              onClick={() => handleModal()}
              active={isActive}
              text={isActive ? t("common.active", "Active") : t("common.inactive", "Inactive")}
              white={true}
            />
          </SwitchContainer>
        )}
      </ToolbarContainer>
    </>
  );
};

export default PositionListOptions;
