import styled from "styled-components/macro";


export const EditPositionWrapper = styled.div`
padding: 12px 16px; 
overflow-x: hidden;
overflow-y: scroll; 
background-color: ${props => props.theme.colors.mgrey}; 
display: flex; 
flex-direction: column;
padding-bottom: 100px;
@media (min-width: 1200px) {
    flex-direction: row; 
    padding: 0; 
}
`

export const Bar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
${props => props.mtSM ? `margin-top: ${props.mtSM}px` : null};
${props => props.mbSM ? `margin-bottom: ${props.mbSM}px` : null};
z-index: 1;
    transition: .5s ease;
    ${props => props.hide && `
    opacity: 0; 
    transition: 0s ease;
    `}
@media (min-width: 1200px) {
        position: absolute;
    right: 0;
    width: calc(100vw - 640px); 
    box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
    background-color: #F3F3F5; 
    height: 70px; 
    padding: 0 25px; 
    ${props => props.cp && `
    top: 109px; 
    margin: 0; 
    left: 0;
    width: calc(100vw - 634px); 
    `}
}
`

export const EditPositionFixed = styled.div`
@media (min-width: 1200px) {
position: fixed; 
width: calc(100vw - 240px); 
}
`