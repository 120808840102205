import styled from "styled-components/macro";

export const ApplicationWrapper = styled.div`
position: absolute;
z-index: 999999; 
background-color: white; 
top: 0; 
left: 0; 
width: 100vw; 
height: 100%; 
overflow: hidden;
${props => props.applicantView ? 'transform: translateX(0);' : 'transform: translateX(100%);'};
${props => props.manageShareholder && `
z-index: 0;
position: relative; 
transform: translateX(0); 
width: 100% !important;
border: 1px solid #E7E8EA !important;
border-radius: 10px !important;
box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
@media (min-width: 1200px) {
    height: 100% !important;
    max-height: 100% !important;
}
`}
transition: .5s ease;
.btn_1 {
    background-color: transparent; 
    border: none;   
}
.btn {
    background-color: transparent; 
    border: none; 
    margin-bottom: 10px; 
    &:first-of-type {
        margin-top: 30px; 
    }
}
@media (min-width: 992px) {
    width: calc(100% - 400px);  
    right: 0; 
    left: unset; 
    top: unset; 
    bottom: 50px;
    height: calc(100% - 120px); 
    border-left: 1px solid rgba(231, 232, 234); 
    box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
}
@media (min-width: 1200px) {
    bottom: 0;
        height: calc(100% - 70px); 
}
@media (min-width: 1480px) {
    width: calc(100% - 570px); 
}
@media (min-width: 1740px) {
    width: 875px; 
}
@media (min-width: 2000px) {
    width: 1120px;
}
`

export const ApplicationTopBar = styled.div`
height: 54px; 
border-bottom: 1px solid ${props => props.theme.colors.mgrey}; 
box-shadow: 0 0 8px rgba(24, 31, 56, 0.06);
display: flex; 
align-items: center;
padding: 0 16px; 
justify-content: space-between;
div {
    display: flex;
    align-items: center;
}
@media (min-width: 992px) {
height: 40px; 
}
`

export const ApplicantMeta = styled.div`
display: flex;
align-items: center;
margin-top: 20px;
@media (min-width: 992px) {
    justify-content: space-between; 
}
`

export const ApplicantHeadings = styled.div`
margin-left: 24px; 
@media (min-width: 992px) {
margin-left: 0;  
margin-top: 15px; 
}
`

export const ApplicantAvatar = styled.div`
height: 96px; 
width: 96px;
background: ${props => `url(${props.avatar}) no-repeat top center`};
background-size: cover;
border-radius: 40px;
border: 2px solid ${props => props.theme.colors.mgrey}; 
@media (min-width: 992px) {
    ${props => props.hideOnDesktop ? 'display: none' : null}; 
    ${props => props.large && `
        width: 100%; 
        height: 155px; 
        border-radius: 65px; 
        display: flex;
        justify-content: center;
        flex-shrink: 0;
    `}
} 
@media (max-width: 991px) {
    ${props => props.hideOnMobile ? `display: none` : null}; 
}
`