// React
import React, { useEffect, useState } from "react";

// Translations
import { useTranslation } from "react-i18next";

// Components
import { BoxLoader, Button, Icon } from "../../../components";
import { Switch, SwitchWrapper, SwitchText } from "../../../components/Button";

// Styled components
import { Flex } from "../../style";
import { StyledRadioButton, StyledInput } from "./Buttons.styled";

export const ToggleButton = ({ toggleFunction, selected }) => {
  const { t } = useTranslation();
  return (
    <Button
      style={{ marginBottom: 0 }}
      urlLike
      transparent
      onClick={toggleFunction}
      uppercase
      smallModal={selected.length === 0}
    >
      <Icon icon={selected.length > 0 ? "checked" : "unchecked"} mr={16} />
    </Button>
  );
};

export const BulkButton = ({ bulkFunction, selected, label, icon }) => {
  return (
    <Button
      style={{ marginBottom: 16 }}
      urlLike
      transparent
      onClick={bulkFunction}
      uppercase
      disabled={selected.length === 0}
    >
      {label}
      {icon && <Icon icon={icon} ml={10} />}
    </Button>
  );
};

export const ToggleSwitch = ({
  onClick,
  selected,
  text,
  active,
  mr,
  ml,
  white,
  isToggling,
}) => {
  return (
    <>
      <Flex widthSM="fit-content">
        {isToggling ? (
          <BoxLoader tiny />
        ) : (
          <>
            <Switch onClick={onClick} active={active} ml={ml}></Switch>
            <SwitchText mr={mr} white={white}>
              {text ? text : null}
            </SwitchText>
          </>
        )}
      </Flex>
    </>
  );
};

export const RadioButton = ({
  name,
  value,
  label,
  selectedValue,
  onChange,
  mr,
  ml,
}) => {
  const isActive = selectedValue === value;

  const handleOnClick = () => {
    if (!isActive) {
      onChange(value);
    }
  };

  const className = isActive ? "active" : "";

  return (
    <StyledRadioButton className={className} mr={mr} ml={ml}>
      <StyledInput
        type="radio"
        name={name}
        value={value}
        checked={isActive}
        onChange={() => {}}
        onClick={handleOnClick}
      />
      {label}
    </StyledRadioButton>
  );
};
