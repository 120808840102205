import { useMemo } from "react";
import { useLocation } from "react-router";

/**
 * extract search (This is the query string (?) included in the URL.) from 'location' object
 * then use search to make a URLSearchParams objet
 * @returns URLSearchParams
 */
export const useQuerySearchParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}