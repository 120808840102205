import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

export const readIntegration = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.get(`/integration${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const activateIntegration = async (request = {}) => {
  try {
    const { params, body } = parseRequest(request);
    const data = await axios.post(`/integration${params}`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deactivateIntegration = async (request = {}) => {
  try {
    const { params } = parseRequest(request);
    const data = await axios.put(`/integration${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};
