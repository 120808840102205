import styled from "styled-components/macro";

export const StyledMetaEditorGroup = styled.div`
  position: relative;
  padding: 16px; 
  ${props => props.pt ? `padding-top: ${props.pt}px !important;` : null};
  ${props => props.pb ? `padding-bottom: ${props.pb}px !important;` : null};
  ${props => props.page && `
  padding: 0 !important;
  @media (min-width: 1200px) {
    &:last-of-type {
    padding-bottom: 75px !important;
    }
  }
  `}
  @media (max-width: 1200px) {
    ${props => props.hideOnMobile ? 'display: none' : null}; 
  }
  @media (min-width: 1200px) {
        padding-top: 25px;
        padding-bottom: 25px; 
  }
`;
