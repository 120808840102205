// React
import React, { useState, useEffect, useContext } from "react";

// Translations - hooks - API calls
import { useTranslation } from "react-i18next";
import SmallCard from "../../../../components/SmallCard/SmallCard";
import { getMainSubscription } from "../../../../api/endpoints/subscription";
import { getPortalSession } from "../../../../api/endpoints/subscription";
import { getCheckoutSession } from "../../../../api/endpoints/subscription";
import { getUserAddons } from "../../../../api/endpoints/subscription";
import { StateUserContext } from "../../../../context/UserContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import useDashboardData from "../../../../utils/hooks/useDashboardData";
import { useFetchPages } from "../../../components/cp/useFetchPages";

// Components
import { Modal } from "../../../../components";
import ChangeSubscriptionForm from "../../Subscription/ChangeSubscriptionForm/ChangeSubscriptionForm";
import Product from "../../Subscription/Product/Product";

// Styled components
import {
  SettingsFlexBlocks,
  SettingsBlockWrapper,
  SettingsBlockHeading,
} from "../Settings.styled";
import { Typography } from "../../../../components/Typography";
import { Flex } from "../../../style";
import PaymentModal from "../../../../components/PaymentModal/PaymentModal";

const Subscription = () => {
  const { t } = useTranslation();
  const [mainSubscription, setMainSubscription] = useState(null);
  const [portalUrl, setPortalUrl] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [normalAddons, setNormalAddons] = useState([]);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
  const [changedSubscription, setChangedSubscription] = useState(false);
  const [changeSubscriptionModalOpen, setChangeSubscriptionModalOpen] =
    useState(false);
  const { user } = useContext(StateUserContext);
  const [isLoadingMainSubscrition, setIsLoadingMainSubscrition] =
    useState(false);
  const [activePositionAddons, setActivePositionAddons] = useState(0);
  const [activeRecruiterAddons, setActiveRecruiterAddons] = useState(0);

  const {
    state: {
      main: { subscription_action },
      privileges: { subscriptions: activeAddons },
      available_addons,
      hasPayment,
    },
  } = useContext(SubscriptionContext);

  useEffect(() => {
    setActivePositionAddons(
      activeAddons.filter((addon) => addon === "position").length
    );

    setActiveRecruiterAddons(
      activeAddons.filter((addon) => addon === "recruiter").length
    );
  }, [activeAddons]);

  //get subscription info and portal sessions
  useEffect(() => {
    const getPortal = async () => {
      try {
        const session = await getPortalSession();
        if (session.success) {
          setPortalUrl(session.data.url);
          return;
        }
      } catch (err) {}
    };

    const getCheckout = async () => {
      try {
        const session = await getCheckoutSession();
        if (session.success) {
          setCheckoutUrl(session.data.url);
          return;
        }
      } catch (err) {}
    };

    const getNormalAddons = async () => {
      try {
        const response = await getUserAddons(); //await listProducts();

        if (response.success) {
          setNormalAddons(response.data);
          return;
        }
      } catch (err) {}
    };

    const getMain = async () => {
      try {
        setIsLoadingMainSubscrition(true);
        const response = await getMainSubscription();
        setIsLoadingMainSubscrition(false);
        if (response.success) {
          setMainSubscription(response.data);
          return;
        }
      } catch (err) {
        setIsLoadingMainSubscrition(false);
      }
    };

    (async () => {
      if (showPaymentButton(subscription_action)) {
        await Promise.all([
          getMain(),
          getNormalAddons(),
          getPortal(),
          getCheckout(),
        ]);
      } else {
        await Promise.all([getMain(), getNormalAddons(), getPortal()]);
      }
    })();
  }, [subscription_action, changedSubscription]);

  const showPaymentButton = (subscription_action) => {
    return (
      subscription_action === "has_no_payment" ||
      subscription_action === "nudge_payment" ||
      subscription_action === "get_payment_method"
    );
  };

  const gotoPortal = () => {
    window.open(portalUrl, "_blank");
  };
  const gotoCheckout = () => {
    window.open(checkoutUrl, "_self");
  };

  // Create array of new titles and descriptions for the available addons map
  const addonTitles = [
    t("common.careerSites"),
    t("common.position.plural"),
    t("common.recruiter.plural"),
  ];
  const addonAdds = [
    t("common.addCareerSite"),
    t("common.addPosition"),
    t("common.addRecruiter"),
  ];
  const addonDescriptions = [
    t("common.careerSiteDesc"),
    t("common.positionModalDesc"),
    t("common.recruiterModalDesc"),
  ];
  const [mainPages, setMainPages] = useState([]);
  const { pages } = useFetchPages({});

  useEffect(() => {
    const frontPages = pages.filter((fpage) => fpage.frontpage);
    setMainPages(frontPages); // Update frontPages state
  }, [pages]);

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  // Get data for total number of recruiters etc
  const {
    positionsCount,
    activePositionsCount,
    recruitersCount,
    pageCount,
    privileges,
    isLoading,
    error,
  } = useDashboardData(isMounted);

  return (
    <>
      {!hasPaymentMethod && (
        <PaymentModal onCancel={() => setHasPaymentMethod(true)} />
      )}
      {changeSubscriptionModalOpen && (
        <Modal
          title={t("common.changeSubscription")}
          onClose={() => setChangeSubscriptionModalOpen(false)}
        >
          <ChangeSubscriptionForm
            currentSubscription={mainSubscription}
            onClose={() => setChangeSubscriptionModalOpen(false)}
            onSubscriptionChange={() =>
              setChangedSubscription(!changedSubscription)
            }
          />
        </Modal>
      )}

      <SettingsFlexBlocks paddingTop="15">
        <Flex flexDirection="column" flexDirectionLG="row" gap="24">
          <SmallCard
            icon="outline_suitcase"
            heading={t("common.totalPositions")}
            headingCount={privileges?.position}
            content={t("common.currentlyInUse")}
            contentCount={activePositionsCount}
            isLoading={isLoading}
          />
          <SmallCard
            icon="recruiters"
            heading={t("common.totalRecruiters")}
            headingCount={privileges?.recruiter}
            content={t("common.currentlyInUse")}
            contentCount={recruitersCount ? recruitersCount - 1 : ""} // -1 to subtract the owner
            isLoading={isLoading}
          />
          <SmallCard
            icon="file"
            heading={t("common.totalCareerSites")}
            headingCount={privileges?.career_page}
            content={t("common.currentlyInUse")}
            contentCount={mainPages.length}
            invert
            isLoading={isLoading}
          />
        </Flex>

        <SettingsBlockWrapper marginTopSM="16">
          <SettingsBlockHeading>
            <Typography tag="p" nomargin mb="5" bold>
              {t("common.company")}
            </Typography>
            <Typography tag="p" lowOpacity nomargin large>
              {t("common.companyDesc")}
            </Typography>
          </SettingsBlockHeading>
          <Product
            type="main"
            subscription={mainSubscription}
            isLoading={isLoadingMainSubscrition}
            onButtonClick={() => {
              if (!hasPayment) {
                setHasPaymentMethod(false);
                return;
              }
              setChangeSubscriptionModalOpen(true);
            }}
            isMissingPaymentMethod={showPaymentButton(subscription_action)}
          />
        </SettingsBlockWrapper>
        <SettingsBlockWrapper>
          <SettingsBlockHeading>
            <Typography tag="p" nomargin mb="5" bold>
              {t("common.addOns")}
            </Typography>
            <Typography tag="p" lowOpacity nomargin large>
              {t("common.addOnsDesc")}
            </Typography>
          </SettingsBlockHeading>
          <Flex
            flexDirection="column"
            flexDirectionLG="row"
            justify="space-between"
          >
            {available_addons &&
              available_addons.length > 0 &&
              available_addons.map((availableAddon, index) => (
                <Product
                  key={availableAddon.id}
                  index={index}
                  type="availableAddon"
                  subscription={availableAddon}
                  isLoading={isLoading}
                  title={addonTitles[index]}
                  adds={addonAdds[index]}
                  cp={index === 0 ? true : false}
                  count={
                    index === 0
                      ? mainPages.length // Set count for the first addon
                      : index === 1
                      ? activePositionAddons // Set count for the second addon
                      : activeRecruiterAddons // Set count for the third addon
                  }
                  description={addonDescriptions[index]}
                  isPurchased={
                    activeAddons &&
                    activeAddons.includes(availableAddon?.metadata?.allows)
                  }
                  isMissingPaymentMethod={showPaymentButton(
                    subscription_action
                  )}
                />
              ))}
          </Flex>
        </SettingsBlockWrapper>
      </SettingsFlexBlocks>
    </>
  );
};

export default Subscription;
