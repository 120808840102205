// React
import React, { useEffect, useContext, useState } from "react";

// Router

// API endpoints, custom hooks, and utility functions
import { getRecruiters } from "../../../api/endpoints/recruiter";
import { useTranslation } from "react-i18next";

// Components
import { MegaHeader } from "../../components";
import { TopBarTop, TopBarBottom } from "../../components/TopBar/style";
import ManageUsers from "./ManageUsers/ManageUsers";

// State management
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { DispatchOrganizationContext } from "../../context/OrganizationContext";
import { AdministrationWrapper } from "./Administration.styled";
import { MobileMenuButtons, MobileMenuButton } from "../../style";

const Administration = () => {
  const { t } = useTranslation();
  const organizationDispatch = useContext(DispatchOrganizationContext);

  const errorDispatch = useContext(DispatchErrorContext);

  const [activeLink, setActiveLink] = useState("manage");

  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        organizationDispatch({ type: "users_init" });
        const response = await getRecruiters();
        if (response.success) {
          organizationDispatch({
            type: "loaded_users",
            payload: response.data,
          });
        } else {
          errorDispatch({ type: "set_error", payload: response.data });
        }
      } catch (err) {
        errorDispatch({ type: "set_error", payload: err });
      }
    };
    fetchRecruiters();
  }, [errorDispatch, organizationDispatch]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <AdministrationWrapper>
      <TopBarTop>
        <MegaHeader text={t("common.administration")} noIcon />
      </TopBarTop>
      <TopBarBottom>
        <MobileMenuButtons>
          <MobileMenuButton
            onClick={() => handleLinkClick("manage")}
            isActive={activeLink === "manage"}
          >
            {t("common.manageUsers")}
          </MobileMenuButton>
        </MobileMenuButtons>
      </TopBarBottom>
      {activeLink === "manage" && <ManageUsers />}
    </AdministrationWrapper>
  );
};

export default Administration;
