// React
import React from 'react'

// Lottie - Lottie assets JSON 
import loadingSpinner from '../../assets/loading-spinner.json'
import Lottie from 'lottie-react'

// LoadingContainer
import { StyledLoadingContainer } from './style'

const Loading = ({loading}) => {
  return (
    <StyledLoadingContainer $loading={loading}>
      <Lottie animationData={loadingSpinner} loop={true} />
    </StyledLoadingContainer>
  )
}

export default Loading