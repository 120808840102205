import React, { createContext, useReducer } from "react";
import { GlobalErrorMessage } from "../components";

export const StateMessageContext = createContext();
export const DispatchMessageContext = createContext();

const messageReducer = (state, action) => {
  switch (action.type) {
    case "set_message": {
      return {
        ...state,
        showMessage: true,
        message: action.payload,
      };
    }
    case "show_modal": {
      return {
        ...state,
        showModal: action.payload,
      };
    }
    case "clear_message": {
      return {
        ...state,
        showMessage: false,
        message: "",
      }
    }
    default : {
      return { ...state }
    }
  }
};

export const MessageProvider = ({ children }) => {
  const messageInitialState = {
    showMessage: false,
    message: "",
    showModal: false, 
  };


  const [state, dispatch] = useReducer(messageReducer, messageInitialState);
  
  return (
    <DispatchMessageContext.Provider value={dispatch}>
      <StateMessageContext.Provider value={state}>
        { state.showMessage && <GlobalErrorMessage message={true} >{state.message || "An error happened."}</GlobalErrorMessage> }
        {children}
      </StateMessageContext.Provider>
    </DispatchMessageContext.Provider>
  );
};
