import React from "react";
import { NotEditable } from "./CommonBlockSettings";

export const FooterContent = ({ block }) => {
  return (
    <>
      <NotEditable />
    </>
  );
};

export const FooterSettings = () => {
  return (
    <>
      <NotEditable />
    </>
  );
};
