import React, { useEffect, useState, useContext } from "react";
import { STATIC_BLOCK_TYPES } from "../blockConstants";

import { PageContext } from "../../../../../context/PageContext";
import {
  MoveBlockButton,
  StyledBlockControl,
  DeleteBlockButton,
} from "./style";

const BlockControl = ({ block }) => {
  const { state, dispatch } = useContext(PageContext);

  //is of movable type and there are more than 1 movable blocks on the page
  const canDelete = STATIC_BLOCK_TYPES.indexOf(block.type) === -1;

  const [canMoveUp, setCanMoveUp] = useState(false);
  const [canMoveDown, setCanMoveDown] = useState(false);

  useEffect(() => {
    setCanMoveUp(canMove("up", block, state.page.blocks));
    setCanMoveDown(canMove("down", block, state.page.blocks));
  }, [block, state.page.blocks]);

  const onMoveHandler = (direction) => {
    dispatch({
      type: "move_block",
      payload: { block: block, direction: direction },
    });
  };
  const onDeleteHandler = () => {
    dispatch({ type: "delete_block", payload: { block: block } });
  };

  return (
    <>
      {canDelete ? (
        <>
          <StyledBlockControl>
            <MoveBlockButton
              direction="up"
              disabled={!canMoveUp}
              onClick={() => onMoveHandler("up")}
            />
            <DeleteBlockButton onClick={onDeleteHandler} />
            <MoveBlockButton
              direction="down"
              disabled={!canMoveDown}
              onClick={() => onMoveHandler("down")}
            />
          </StyledBlockControl>
        </>
      ) : (
        <StyledBlockControl />
      )}
    </>
  );
};

export default BlockControl;

/**
 * check if things can move up or down
 * @param {string} direction
 * @param {object} block
 * @param {array} blocks
 * @returns {boolean}
 */
export const canMove = (direction, block, blocks) => {
  let canMove =
    STATIC_BLOCK_TYPES.indexOf(block.type) === -1 &&
    blocks.filter((b) => STATIC_BLOCK_TYPES.indexOf(b.type) === -1).length > 1;
  if (!canMove) return canMove; //Block is not movable

  if (block.type === "footer") {
    console.log(direction, block, blocks);
  }
  //check if can move up. Is there a block not of dynamic type before it.
  let prevBlock = null;
  for (let i = 0; i < blocks.length; i++) {
    const b = blocks[i];
    if (b.id === block.id) {
      //this block
      if (
        STATIC_BLOCK_TYPES.indexOf(prevBlock?.type) > -1 &&
        direction === "up"
      ) {
        canMove = false;
        break;
      }
    }
    if (
      STATIC_BLOCK_TYPES.indexOf(b.type) > -1 &&
      direction === "down" &&
      prevBlock?.id === block.id
    ) {
      canMove = false;
      break;
    }

    prevBlock = b;
  }
  return canMove;
};
