import { useState, useContext } from 'react';
import { purchaseAddon } from '../../api/endpoints/subscription';
import { DispatchErrorContext } from '../../context/ErrorContext';
import { SubscriptionContext } from '../context/SubscriptionContext';

/**
 * Purchase addon hook, returns the addon function
 * @returns [loading, purchaseFunction]
 */
const usePurchaseAddon = (onSuccess = () => {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(SubscriptionContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const handlePurchase = async (priceId, key) => {
    try {
      setIsLoading(true);
      //The API purchase call
      const request = { params: { priceId: priceId } };
      const response = await purchaseAddon(request);
      if (response.success) {
        dispatch({
          type: 'update_privilege',
          payload: {
            key: key,
            value: 1,
          },
        });
        //Callback on success
        onSuccess();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      errorDispatch({ type: 'set_error', payload: err.message });
      console.log(err);
    }
  };
  return { handlePurchase, isLoading };
};

export default usePurchaseAddon;
