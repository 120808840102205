import styled from "styled-components/macro";
import React, { useState, useEffect } from "react";

const StyledAttention = styled.div`
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.colors.dgrey};
  text-align: center;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%; //auto;
  z-index: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
`;

const StyledAttentionContainer = styled.div`
  position: relative;
  width: auto;
  height: 0px;
`;

const Attention = ({ trigger, show, children }) => {
  const [triggerShow, setTriggerShow] = useState(false);
  useEffect(() => {
    if (!trigger) {
      setTimeout( () => {
        if(!trigger) {
          setTriggerShow(false)
        }
      }, 200);
    } else {
      setTriggerShow(true)
    }
  }, [trigger])

  return (
    <>
      { show && triggerShow ? (
        <StyledAttentionContainer>
          <StyledAttention>
            {children}
          </StyledAttention>
        </StyledAttentionContainer>
      ) : "" }
    </>
  );
};

export default Attention;
