import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Icon } from "../../../components";

const BackToLink = ({ to, label }) => {
  let history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      {to ? (
        <Link to={to}>
          <StyledBackLink>
            <Icon icon="arrow_right" />
            <p>{label || t('common.back')}</p>
          </StyledBackLink>
        </Link>
      ) : (
        <div onClick={ ()=> {history.goBack()}} >
          <StyledBackLink>
            <Icon icon="arrow_right" />
            <p>{label || t('common.back')}</p>
          </StyledBackLink>
        </div>
      )}
    </>
  );
};
export default BackToLink;

const StyledBackLink = styled.div`
  display: flex;
  * {
    color: ${(props) => props.theme.colors.dgrey};
    fill: ${(props) => props.theme.colors.dgrey};
  }
  :hover {
    * {
      color: ${(props) => props.theme.colors.highlight};
      stroke: ${(props) => props.theme.colors.highlight};
    }
  }
`;
