import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../Button";
import SquareToggle from "../SquareToggle/SquareToggle";
import { Grid } from "../Grid";
import Modal from "../Modal/Modal";
import { Typography } from "../Typography";
import { Trans, useTranslation } from "react-i18next";
import { formatAmount } from "../../utils/various";

import BoxLoader from "../BoxLoader";
import Icon from "../Icon";

const getPriceFromId = (priceId, prices) => {
  return prices.find((price) => price.id === priceId);
};

/**
 * onCancel - function that runs when user hits cancel
 * onConfirm - function that runs when user confirms purchase
 * prices - array of price objects - if multiple the user can select mth / yearly recurring price
 * if prices contain multiple prices, then
 */
const PurchaseModal = ({
  onCancel,
  onConfirm,
  prices,
  type,
  productTitle,
  title,
  desc,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [chosenPrice, setChosenPrice] = useState(null);

  useEffect(() => {
    if (prices && prices.length > 1) {
      setChosenPrice(prices[0]);
    } else if (prices) {
      setChosenPrice(prices[0]);
    }
  }, [prices]);

  return (
    <Modal
      blind
      title={title ? title : t("common.outOfRecruiters")}
      onClose={onCancel}
    >
      {type !== "changeSub" ? (
        <>
          <Grid padding={32} fluid borderBottom>
            <Typography tag="p" nomargin center>
              {desc ? (
                desc
              ) : (
                <>
                  {t("common.outOfRecruitersDesc_1")}
                  <br />
                  {t("common.outOfRecruitersDesc_2")}
                </>
              )}
            </Typography>
            {type === "generic" && (
              <Typography tag="h3">
                {`You are purchasing ${productTitle}`}
              </Typography>
            )}
          </Grid>
          <Grid padding={32} fluid alignCenter borderBottom>
            {prices && prices.length > 1 && chosenPrice && (
              <SquareToggle
                options={prices.map((price) => {
                  return {
                    text: t("common." + price.nickname.toLowerCase()),
                    value: price.id,
                  };
                })}
                onChange={(priceId) => {
                  setChosenPrice(getPriceFromId(priceId, prices));
                }}
                on={chosenPrice.id}
                label={t("common.billingMethod")}
                mt="25"
                reverse
                gap="15"
              />
            )}
            <div
              style={{
                maxWidth: "485px",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span
                  style={{ textTransform: "capitalize", fontWeight: "500" }}
                >
                  {productTitle}
                </span>
                {chosenPrice && (
                  <>
                    <span>
                      <span style={{ fontWeight: "500" }}>
                        {formatAmount(
                          chosenPrice.unit_amount,
                          chosenPrice.currency
                        )}
                      </span>
                      /{t("common." + chosenPrice?.recurring?.interval)}
                    </span>
                  </>
                )}
              </div>
              <Typography tag="p" large width="100%" nomargin mt="10">
                <Trans i18nKey="component.purchase_modal.terms">
                  By making this purchase you are agreeing to our
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${process.env.REACT_APP_TERMS}`}
                  >
                    terms and conditions
                  </a>
                </Trans>
              </Typography>
            </div>
          </Grid>
          <Grid padding={32} fluid alignCenter>
            {chosenPrice && (
              <PrimaryButton
                red
                onClick={isLoading ? null : () => onConfirm(chosenPrice.id)}
                noflex
                disabled={isLoading}
              >
                {isLoading ? <BoxLoader tiny /> : t("common.purchase")}
                <Icon icon="cart" mr="10" />
              </PrimaryButton>
            )}
            <PrimaryButton transparent onClick={() => onCancel()} mt="20">
              {t("common.cancel")}
              <Icon icon="cross" mr="10" />
            </PrimaryButton>
          </Grid>
        </>
      ) : (
        <>
          <Grid padding={32} paddingBottom="0" fluid alignCenter>
            {t("common.changeSubDesc1")} {productTitle.split(" ")[0]}{" "}
            {t("common.changeSubDesc2")} {productTitle.split(" ")[2]}{" "}
            {t("common.changeSubDesc3")}
          </Grid>

          <Grid padding={32} fluid alignCenter>
            {chosenPrice && (
              <PrimaryButton
                red
                onClick={() => onConfirm(chosenPrice.id)}
                noflex
              >
                {t("common.yesValidation")}
                <Icon icon="checkmark" mr="10" scale="2" />
              </PrimaryButton>
            )}
            <PrimaryButton transparent onClick={() => onCancel()} mt="20">
              {t("common.cancel")}
              <Icon icon="cross" mr="10" />
            </PrimaryButton>
          </Grid>
        </>
      )}
    </Modal>
  );
};

export default PurchaseModal;
