// React
import React, {useContext, useEffect} from 'react';

// React router
import { Link, useLocation } from 'react-router-dom';

// State management
import { DispatchPositionContext, StatePositionContext } from '../../../context/PositionContext';
import { ApplicationParamsContext } from '../../ApplicationList/ApplicationContextWrapper';

// Components
import { Icon } from '../../../../components';
import { Typography } from '../../../../components/Typography';
import InnerCard from '../../Card/card';

// Styled components
import {
  StyledGenericCard,
  StyledGenericCardContainer,
  StyledGenericCardInfoContainer,
  StyledCardBorder,
  StyledGenericCardAction,
  GenericCardHoverOptions,
} from './GenericCard.styled';

const GenericCard = ({
  baseUrl = '/',
  id,
  title,
  pin,
  onSelect,
  onCheck,
  data,
  initiallyOn,
  disabled,
  useLabel,
  genericIcon,
  inactive
}) => {
  const checkInitiallyOnArray = (arr, _id) => {
    return arr.map((n) => parseInt(n)).indexOf(_id) > -1;
  };

  const { pathname } = useLocation();
  const positionDispatch = useContext(DispatchPositionContext)
  const { state, dispatch } = useContext(ApplicationParamsContext);
  const { single } = useContext(StatePositionContext)

  const isActive = single && single.id === id;


  const card = (
    <InnerCard
      icon={(pin && <Icon icon="pinned" />)}
      title={title}
      meta={data}
      arrow
      hoverOptions
      id={id}
      baseUrl={baseUrl}
    />
  );

  return (
    <StyledCardBorder>
    <StyledGenericCardContainer inactive={inactive} isActive={isActive} onClick={() => positionDispatch({ type: 'candidate_view', payload: false })}>
      <StyledGenericCardAction>
        {onCheck && !onSelect ? (
          <>
            {initiallyOn ? (
              <input
                type="checkbox"
                id={`position-selector-${id}`}
                name={`position-selector`}
                onChange={(event) => {
                  onCheck(event, id);
                }}
                disabled={disabled}
                checked={checkInitiallyOnArray(initiallyOn, id)}
                value={id}
              />
            ) : (
              <input
                type="checkbox"
                id={`position-selector-${id}`}
                name={`position-selector`}
                onChange={(event) => {
                  onCheck(event, id);
                }}
                value={id}
                disabled={disabled}
              />
            )}
          </>
        ) : (
          ' '
        )}
        {onSelect && !onCheck ? (
          <>
            {initiallyOn ? (
              <input
                type="radio"
                id={`position-selector-${id}`}
                name={`position-selector`}
                checked={checkInitiallyOnArray(initiallyOn, id)}
                onChange={(event) => {
                  onSelect(event, id);
                }}
                value={id}
              />
            ) : (
              <input
                type="radio"
                id={`position-selector-${id}`}
                name={`position-selector`}
                onChange={(event) => {
                  onSelect(event, id);
                }}
                value={id}
              />
            )}
          </>
        ) : (
          ' '
        )}
      </StyledGenericCardAction>
      {useLabel && (onSelect || onCheck) ? (
        <label htmlFor={`position-selector-${id}`}>{card}</label>
      ) : (
        <>
        <Link to={`/${baseUrl}/${id}`}>{card}</Link>
        </>
      )}
    </StyledGenericCardContainer>
    </StyledCardBorder>
  );
};

export default GenericCard;
