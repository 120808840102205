// React
import React, { useState, useContext, useEffect } from 'react';

// Endpoint
import { getPositions } from '../../../api/endpoints/position';

// State management
import {
  DispatchPositionContext,
  StatePositionContext,
} from '../../context/PositionContext';
import { DispatchErrorContext } from '../../../context/ErrorContext';

// Components 
import { GenericList } from '..';
import PositionListOptions from './PositionListOptions';

// Translations and utilities 
import { useTranslation } from 'react-i18next';
import {
  filterListByQuery,
  handleSelectApplications,
} from '../../../utils/various';

const PositionList = ({
  active,
  header,
  onCheck,
  onSelect,
  includeOptions,
  searchable,
  useLabel,
  inactive,
  searchQuery
}) => {
  const { t } = useTranslation();
  const { refresher, positions, single, refetch } = useContext(
    StatePositionContext
  );
  const positionDispatch = useContext(DispatchPositionContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);

  useEffect(() => {
    const fetchPositions = async () => {
      positionDispatch({ type: 'init_positions' });
      let request = { params: { sort: 'DESC' } };
      try {
        const response = await getPositions(request);
        if (response.success) {
          positionDispatch({
            type: 'loaded_positions',
            payload: response.data,
          });
        } else {
          errorDispatch({
            type: 'set_error',
            payload: JSON.stringify(response.data),
          });
        }
      } catch (err) {
        console.log(err);
        errorDispatch({
          type: 'set_error',
          payload: JSON.stringify(err),
        });
      }
    };
    fetchPositions()
  }, [errorDispatch, positionDispatch, refresher, single, refetch]);



  const [clearSearch, setClearSearch] = useState(0);


  useEffect(() => {
    const filteredPositions = positions.filter((position) => {
      if (active) {
        return position.status === 'active';
      } else {
        return position.status === 'inactive';
      }
    })
      .filter((position) => {
        if (searchQuery) {
          const positionTitle = position.title.toLowerCase();
          const query = searchQuery.toLowerCase();
          return positionTitle.includes(query);
        } else {
          return true;
        }
      });

    const sortedPositions = [...filteredPositions].sort((a, b) => {
      if (a.title === 'Unsolicited applications') {
        return -1;
      } else if (b.title === 'Unsolicited applications') {
        return 1;
      }
      return a.title.localeCompare(b.title);
    });

    setFilteredList(sortedPositions);
  }, [positions, active, clearSearch, searchQuery, single]);

  return (
    <>
      <GenericList
        useLabel={useLabel}
        inactive={!active ? true : false}

        onSearchAction={
          searchable
            ? (query) =>
              filterListByQuery(
                filteredList,
                setFilteredList,
                query,
                setClearSearch
              )
            : null
        }
        options={
          includeOptions ? (
            <PositionListOptions
              selected={selectedPositions}
              setSelected={setSelectedPositions}
              all={filteredList}
              options={['deactivate']}
            />
          ) : null
        }
        checkedState={includeOptions ? selectedPositions : null}
        baseUrl="position"
        header={header}
        onCheck={
          onCheck
            ? onCheck
            : includeOptions
              ? (e, id) => {
                handleSelectApplications(
                  e,
                  id,
                  selectedPositions,
                  setSelectedPositions
                );
              }
              : null
        }
        onSelect={onSelect || null}
        isPinned={filteredList.find((position) => {
          return position.unsolicited === true;
        })}
        list={filteredList.map((position) => [
          [position.id, position.title],
          [
            `${t('common.applicant.plural')} : ${position.applications
              ? position.applications.length
              : 'n/a'
            }`,
            `${t('common.shortlist')}: ${position.applications
              ? position.applications.filter(
                (app) => app.shortlist
              ).length
              : 'n/a'
            }`,
          ],
        ])}
      />
    </>
  );
};

export default PositionList;
