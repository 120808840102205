import styled from "styled-components/macro";

export const ApplicantContactInfoWrapper = styled.div`
display: flex;
flex-direction: column;
position: relative; 
${props => props.isAnonymous && `
button {
    filter: blur(4px); 
}
`}
@media (min-width: 992px) {
${props => props.hideOnDesktop && 'display: none;'}
}
`