import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components/macro';
import {
  rejectApplication,
  updateApplication,
} from '../../../api/endpoints/application';
import ApplicationDropAreaText from './ApplicationDropAreaText';

const SUBSTATUS_KEYS = ['interview', 'hired', 'to_be_rejected'];

const ApplicationDropArea = ({ actionKeys, visible }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  //HANDLE DRAG AND DROP HERE ( DROPPING APPLICATION CARD )
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'CARD',
    canDrop: (item) => {
      return (
        (actionKeys.indexOf('reject') > -1 &&
          item.status === 'submitted') ||
        actionKeys.indexOf('reject') === -1
      );
    },
    drop: async (item) => {
      if (!item?.id) return;

      if (actionKeys.indexOf('reject') > -1) {
        const confirmed = window.confirm(
          t(
            'component.card.application.confirm_reject_candidate',
            'Send rejection email to candidate?'
          )
        );
        if (confirmed) {
          await rejectApplication({
            params: {
              applicationIds: [item.id],
            },
          });
          return;
        } else {
          return;
        }
      }

      //Do nothing if trying to shortlist already shortlisted applciation
      const substatus = actionKeys.filter(
        (key) => SUBSTATUS_KEYS.indexOf(key) > -1
      );

      const request = {
        selector: item.id,
        body: {},
      };

      if (actionKeys.indexOf('shortlist') > -1) {
        request.body.shortlist = true;
        // Clear substatus if it came from a list with substatus filter
        if (
          item.dndCameFrom.filter(
            (cf) => SUBSTATUS_KEYS.indexOf(cf) > -1
          ).length > 0
        ) {
          request.body.substatus = null;
        }
      }
      if (actionKeys.indexOf('unlist') > -1) {
        request.body.shortlist = false;
      }

      if (actionKeys.indexOf('submitted') > -1) {
        if (
          item.shortlist
          // &&
          // Array.isArray(item.dndCameFrom) &&
          // item.dndCameFrom.indexOf('shortlist') > -1
        ) {
          request.body.shortlist = false;
        }
        if (item.substatus) {
          request.body.substatus = null;
        }
      }

      if (substatus.length > 0) {
        request.body.substatus = substatus[0];
      }

      await updateApplication(request); //Actually shortlisting on the server!

      //Invalidate queries (react query)
      queryClient.invalidateQueries('applications');
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <StyledApplicationDropArea
      ref={drop}
      visible={visible}
      isOver={isOver}
      canDrop={canDrop}
    >
      {canDrop && <ApplicationDropAreaText actionKeys={actionKeys} />}
    </StyledApplicationDropArea>
  );
};

export default ApplicationDropArea;

const StyledApplicationDropArea = styled.div`
  flex: 1;
  opacity: ${(props) => (props.visible ? '1' : '0')};
    ${(props) =>
      props.isOver
        ? props.theme.colors.dgrey
        : props.theme.colors.mgrey};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
