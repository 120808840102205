// React
import { useState, useEffect } from 'react';

// Utility functions and hooks
import useApplicationActions from '../../../hooks/useApplicationActions';

// Components and styled components
import { ReactComponent as StarIcon } from './star.svg';
import { SingleStar, KanbanStars } from './ApplicationStars.styled';

/**
 * This component can be disabled ( shared shortlist ), unset ( no star rating given ) or has stars 1 - 5
 * @returns jsx
 */
const ApplicationStars = ({ id, disabled }) => {
  const [rating, setRating] = useState(id.stars || 0);
  const [tempRating, setTempRating] = useState(
    id.stars || 0
  );

  useEffect(() => {
    setTempRating(id.stars);
  }, [id]);

  const { handleActionTaken } = useApplicationActions(
    'rate',
    () => {},
    [{ id: id.id }],
    {},
    true,
    false
  );

  return (
    <KanbanStars>
      <span>
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <Star
              key={i}
              starNum={i}
              rating={rating}
              isOn={i <= tempRating}
              setRate={setRating}
              setTempRate={setTempRating}
              persistRate={handleActionTaken}
              disabled={disabled}
            />
          );
        })}
      </span>
    </KanbanStars>
  );
};

export default ApplicationStars;

const Star = ({
  starNum,
  isOn,
  rating,
  setRate,
  setTempRate,
  disabled, 
  persistRate,
}) => {
  const handleMouseOver = () => {
    if (disabled) {
      return;
    }
    setTempRate(starNum);
  };
  const handleMouseClick = () => {
    if (disabled) {
      return;
    }
      const newRate = rating === starNum ? 0 : starNum;
      setRate(newRate);
      persistRate({ rating: newRate });
  };
  const handleMouseOut = () => {
   setTempRate(rating);
  };
  return (
    <SingleStar
      isOn={isOn}
      onClick={handleMouseClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <StarIcon />
    </SingleStar>
  );
};
