// React
import React from "react";

// Tranlsation - Supported languages etc
import { useTranslation } from "react-i18next";

// Components
import { StyledSelectLanguage } from "./PositionSiteSelect.styled";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const PositionSiteSelect = ({
  options,
  value,
  onChange,
  allowedLanguages,
  disabled,
  useISO,
  cp,
  label,
}) => {
  const { t } = useTranslation();

  // console.log("options", options);

  const handleSiteChange = (selectedOption) => {
    // onChange({ target: { value: selectedOption.value } });
    // console.log("selectedOption", selectedOption);
    onChange(selectedOption.value);
  };

  const dropdownOptions = options.map((item) => ({
    value: item.id,
    label:
      item.slug === "en"
        ? t("component.language.en-US", "English")
        : t("component.language.da-DK", "Danish"),
  }));

  return (
    <StyledSelectLanguage>
      <Dropdown
        options={dropdownOptions}
        value={value}
        onChange={handleSiteChange}
        disabled={disabled}
      />
    </StyledSelectLanguage>
  );
};

export default PositionSiteSelect;
