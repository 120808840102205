// React
import React from "react";

// Styled components
import {
  SmallCardContent,
  SmallCardHeader,
  SmallCardWrapper,
} from "./SmallCard.styled";
import Icon from "../Icon";
import { Typography } from "../Typography";
import { BoxLoader } from "../../components";

const SmallCard = ({
  icon,
  heading,
  headingCount,
  isLoading,
  content,
  contentCount,
  invert,
}) => {
  return (
    <SmallCardWrapper>
      <SmallCardHeader>
        <div>
          <Icon mr="12" icon={icon} $filter={invert} />
          <Typography nomargin tag="p" bold>
            {heading}
          </Typography>
        </div>
        <div>
          {isLoading ? (
            <BoxLoader tiny />
          ) : (
            <Typography tag="p" lowOpacity nomargin>
              {headingCount}
            </Typography>
          )}
        </div>
      </SmallCardHeader>
      <SmallCardContent>
        <Typography tag="p" large lowOpacity nomargin>
          {content}
        </Typography>
        <Typography tag="p" lowOpacity nomargin>
          {contentCount}
        </Typography>
      </SmallCardContent>
    </SmallCardWrapper>
  );
};

export default SmallCard;
