//all positions
import { ContextMenu, MegaHeader } from '../../components';
import { Typography } from '../../../components/Typography';
import { Grid, Row, Col } from '../../../components/Grid';
import { useTranslation } from 'react-i18next';

import {
  ApplicationContextWrapper,
  ApplicationFilter,
  ApplicationList,
} from '../../components/ApplicationList';

const AllApplications = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContextMenu></ContextMenu>
      <MegaHeader text={t('common.candidate.all_candidates')} />
      <ApplicationContextWrapper>
        <Grid fluid>
          <Row>
            <Col size={16} break="md">
              <Typography
                tag="h3"
                lineThrough
                nomargin
                style={{ marginBottom: 20 }}
              >
                {t('page.all_applications.search_candidates')}
              </Typography>

              <ApplicationList useSearch={true} useSelect={true} />
            </Col>
            <Col size={8} collapse="md">
              <ApplicationFilter />
            </Col>
          </Row>
        </Grid>
      </ApplicationContextWrapper>
    </>
  );
};

export default AllApplications;
