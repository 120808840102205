// React
import React, { useState, useContext, useEffect, useRef } from "react";

// Components
import { Typography } from "../../../../components/Typography";
import { Grid } from "../../../../components/Grid";
import { Field, Formik } from "formik";
import Observer from "./Observer";
import { Row, Col } from "../../../../components/Grid";
import { InputFieldWithLabel } from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { Icon } from "../../../../components";
import { PurchaseModal } from "../../../../components";
import PaymentModal from "../../../../components/PaymentModal/PaymentModal";

// State management
import { DispatchMessageContext } from "../../../../context/MessageContext";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { DispatchAdministrationContext } from "../../../context/AdministrationContext";
import { DispatchUserContext } from "../../../../context/UserContext";

// API Endpoints - custom hooks - utility functions - translations
import useGetAddon from "../../../hooks/useGetAddon";
import usePurchaseAddon from "../../../hooks/usePurchaseAddon";
import { recruiterExists } from "../../../../api/endpoints/recruiter";
import { inviteRecruiter } from "../../../../api/endpoints/recruiter";
import { useTranslation } from "react-i18next";
import { hasPrivilege } from "../../../../utils/various";
import ManageAccess from "../../../components/ManageAccess/ManageAccess";
import { StateOrganizationContext } from "../../../context/OrganizationContext";

const AddRecruiter = ({ onClose, setFetchTrigger }) => {
  const { t } = useTranslation();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);
  const administrationDispatch = useContext(DispatchAdministrationContext);
  const userDispatch = useContext(DispatchUserContext);

  //Subscription context
  const {
    state: {
      main: { subscription },
      privileges: { privileges, activeItems },
      hasPayment,
      isLoading: subscriptionIsLoading,
    },
  } = useContext(SubscriptionContext);

  const { positionAccess } = useContext(StateOrganizationContext);

  //Formik external form handling, use Observer to read values
  const formRef = useRef();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  // Product to purchase
  const [product] = useGetAddon("Recruiter");

  // Handle purchase of addon
  const { handlePurchase: purchaseRecruiter, isLoading } = usePurchaseAddon();

  /**
   * is Recruiter invite form valid
   * @param {object} values { email : "", firstName : "", lastName : "", directories : []}
   * @returns
   */
  const formIsValid = (values) => {
    return values.email && values.firstName;
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  return (
    <>
      <Grid padding={32} fluid borderBottom>
        <Col mw>
          <Typography tag="p" center>
            {t("common.addRecruiterDescription")}
          </Typography>
          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              directories: [],
            }}
            onSubmit={async (values, { resetForm }) => {
              try {
                setFormSubmitting(true);

                //check if recruiter exists
                const res = await recruiterExists({
                  params: { email: values.email },
                });
                if (res.data) throw new Error("Recruiter exists already");

                //empty directorires array means assigned to all existing directories.
                const request = {
                  body: { ...values, positionsAccess: positionAccess },
                };
                const response = await inviteRecruiter(request);
                if (response.success) {
                  setFetchTrigger((prev) => !prev);
                  resetForm({});
                  messageDispatch({
                    type: "set_message",
                    payload: t("message.succesful_invite"),
                  });
                  setFormSubmitting(false);
                } else {
                  errorDispatch({
                    type: "set_error",
                    payload: response.data.data || response.data.message,
                  });
                  setFormSubmitting(false);
                }
              } catch (err) {
                console.log(err);
                errorDispatch({
                  type: "set_error",
                  payload: err.message,
                });
                setFormSubmitting(false);
              }
            }}
            innerRef={formRef}
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Observer setValues={setFormValues} />
                  <Row smallerInnerGutter>
                    <Col>
                      <Field
                        tiny
                        label={false}
                        as={InputFieldWithLabel}
                        placeholder={t("common.first_name")}
                        name="firstName"
                      />
                    </Col>
                    <Col>
                      <Field
                        tiny
                        label={false}
                        as={InputFieldWithLabel}
                        placeholder={t("common.last_name")}
                        name="lastName"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        as={InputFieldWithLabel}
                        tiny
                        label={false}
                        placeholder={t("common.email")}
                        name="email"
                      />
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        </Col>
      </Grid>
      <ManageAccess />
      <Grid padding={32} fluid alignCenter>
        <PrimaryButton
          red
          onClick={async () => {
            try {
              const res = await recruiterExists({
                params: { email: formValues.email },
              });
              if (res.data) {
                throw new Error("Recruiter exists already");
              }
              if (!hasPrivilege("invite_recruiter", privileges, activeItems)) {
                setShowModal(true);
              } else {
                handleSubmit();
                userDispatch({ type: "reload" });
                administrationDispatch({
                  type: "modal_confirmation",
                  payload: true,
                });
              }
            } catch (err) {
              errorDispatch({ type: "set_error", payload: err.message });
            }
          }}
        >
          {t("common.sendInvitation")}
          <Icon icon="send_message" mr="10" />
        </PrimaryButton>
        <PrimaryButton transparent mt="18" onClick={onClose}>
          {t("common.cancel")}
          <Icon icon="cross" mr="10" invert />
        </PrimaryButton>
        {showModal && (
          <>
            {hasPayment ? (
              <PurchaseModal
                title={t("common.outOfRecruiters")}
                onCancel={() => setShowModal(false)}
                onConfirm={async (priceId) => {
                  await purchaseRecruiter(priceId);
                  handleSubmit();
                  setShowModal(false);
                  administrationDispatch({
                    type: "modal_confirmation",
                    payload: true,
                  });
                }}
                prices={product.prices}
                productTitle={product.name}
                isLoading={isLoading}
              />
            ) : (
              <PaymentModal
                onCancel={() => setShowModal(false)}
                returnUrl={"/"}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default AddRecruiter;
