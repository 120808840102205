import React from "react";

import countryCodes from "country-codes-list";
import styled from "styled-components/macro";

const CountryCodesObject = countryCodes.customList(
  "countryCallingCode",
  "+{countryCallingCode}"
);
const CountryCode = ( { selectedValue, onChange } ) => {
  const codes = Object.entries(CountryCodesObject);
  return (
    <StyledCountryCode>
      <select name="countryCode" onChange={onChange} value={ selectedValue }>
        {codes.map((code) => {
          return <option key={code[1]} value={code[1]}>{code[1]}</option>;
        })}
      </select>
    </StyledCountryCode>
  );
};



const StyledCountryCode = styled.div`
top: 16px;
position: absolute; 
select {
  appearance: none;
    border: none;
    width: 61px;
    margin-left: 2px;
    height: 100%;
    height: 38px;
    margin-top: 2px;
    color: #181F38;
    padding-left: 20px;
    font-size: 14px;
    opacity: 0.6;
}
/* &::after {
  content: '';
  position: absolute; 
  right: 0; 
  height: 10px; 
  width: 1px; 
  background-color: rgba(24,31,56,0.15); 
  top: 16px;
} */

input:focus, textarea:focus, select:focus{
        outline: none;
    }
    
.Dropdown-control {
  border: none; 
  border: 100px;
    height: 40px;
    margin-left: 2px;
  
}
.Dropdown-placeholder {
  border: none !important;
  margin-top: -2px;
  &::before {
    display: none;
  }
}
`;

export default CountryCode;