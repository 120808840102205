import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPositions } from '../../../../api/endpoints/position';
import Accordion from '../../Accordion';
import { ApplicationParamsContext } from '../ApplicationContextWrapper';

/**
 * Get all positions belonging to organization to filter by application list by positionId
 */
const ApplicationFilterPosition = () => {
  const { status, data } = useQuery('positions', getPositions);
  const { state, dispatch } = useContext(ApplicationParamsContext);
  const { t } = useTranslation();

  const handleClick = (e) => {
    //Add to array if checked - Remove from array if checked
    const index = state.params.positionIds.indexOf(e.target.value);
    const arr = [...state.params.positionIds];
    if (e.target.checked) {
      if (index < 0) arr.push(e.target.value);
    } else {
      arr.splice(index, 1);
    }
    dispatch({
      type: 'update_params',
      payload: { [e.target.name]: arr },
    });
  };

  return (
    <Accordion
      title={t('common.position.singular')}
      initiallyOpen={false}
    >
      {status === 'success' &&
        data?.data.map((pos) => (
          <label htmlFor={pos.id} key={pos.id}>
            <input
              type="checkbox"
              checked={state.params['positionIds'].includes(
                pos.id.toString()
              )}
              id={pos.id}
              value={pos.id}
              name="positionIds"
              onChange={handleClick}
            />
            <span>{pos.title}</span>
          </label>
        ))}
    </Accordion>
  );
};

export default ApplicationFilterPosition;
