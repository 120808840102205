import styled from "styled-components/macro";

export const AddNotesContainer = styled.div`
position: fixed; 
bottom: 0;
width: 100%;
height: auto;
min-height: 70px; 
border-top: 1px solid ${props => props.theme.colors.mgrey}; 
padding: 13px 16px; 
background-color: ${props => props.theme.colors.white};
display: flex; 
align-items: center;
z-index: 999;
@media (min-width: 992px) {
    background-color: ${props => props.theme.colors.lightGray};  
    padding: 28px 25px; 
    flex-direction: column; 
}
`

export const Notes = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column; 
background-color: white; 
border-radius: 5px; 
@media (min-width: 992px) {
padding: 12px 22px; 
}
`

export const ButtonGroupContainer = styled.div`
display: none; 
@media (min-width: 992px) {
display: flex;
width: 100%; 
justify-content: space-between;
margin-top: 12px; 
}
`