import styled from "styled-components/macro";
import { ResetButton } from "../../Button";

export const StyledColorPicker = styled.div`
    position:relative;
    > div.picker {
        /* border :5px solid red; */
    }
`;

//the label
export const StyledColorInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center;
  margin: 15px 0px 10px 0px;
  label {
      margin :0;
  }
`;

//the button
export const StyledColorButton = styled(ResetButton)`
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.mgrey};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    background-color: ${props=>props.color};
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;
