import React from 'react';
import styled, { css } from 'styled-components';

const Thumb = () => css`
  width: 10px;
  height: 10px;
  border: 1px solid ${(props) => props.theme.colors.red};
  border-radius: 10px;
  background: #fff;
`;

const StyledSlider = styled.input`
  display: block;
  cursor: pointer;
  width: 100%;
  margin: 1em auto;
  padding: 0;
  background: ${(props) => props.theme.colors.red};
  font-size: 1em;
  position: relative;
  height: 1px;

  &,
  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-ms-track {
    border: none;
    color: transparent;
  }
  &::-webkit-slider-thumb {
    ${Thumb()}
  }
  &::-moz-range-thumb {
    ${Thumb()}
  }
  &::-ms-thumb {
    ${Thumb()}
  }
  &::-ms-tooltip {
    display: none;
  }
  &:focus {
    outline: none;
  }
`;

const SliderLabel = styled.div`
  display: flex;
  justify-content: space-between;
  & > span:nth-child(${(props) => props.value}) {
    color: ${(props) => props.theme.colors.red};
  }
`;

const ApplicationFilterSkillSlider = ({
  type,
  name,
  value,
  on,
  onCheckChange,
  onSliderChange,
}) => {
  return (
    <div>
      <label htmlFor={name}>
        <input
          type="checkbox"
          checked={on}
          id={name}
          name={name}
          onChange={(e) => onCheckChange(e, type)}
        />
        <span>{name}</span>
      </label>
      {}
      {on && (
        <>
          <StyledSlider
            name={name}
            type="range"
            min="1"
            max="5"
            value={value}
            step="1"
            onChange={(e) => onSliderChange(e, type)}
          />
          <SliderLabel value={value}>
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </SliderLabel>
        </>
      )}
    </div>
  );
};

export default ApplicationFilterSkillSlider;
