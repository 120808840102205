
import { createGlobalStyle } from "styled-components";
import styled from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        font-family: 'Work Sans', Arial, Helvetica, sans-serif;
        font-size: 12px;
        box-sizing: border-box;
        text-decoration: none;
        list-style: none;
        -webkit-font-smoothing: antialiased;
    }
    pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

    .chrome-picker {
      position: absolute !important;
      z-index: 9999;
      animation: animate .5s ease;
      @keyframes animate {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    @page {
    size: A4 portrait;
    margin: 0cm;
    }

    @media print {
      html, body { 
       visibility: hidden;
      }
      .for-print {
        visibility: visible;
      } 
    }

    a {
      color: ${props=>props.theme.colors.highlight};
      &:hover {
        color: ${props=>props.theme.colors.mgrey};
      }
      &.active, &.active * {
        color: ${props=>props.theme.colors.highlight};
      }
    }

    *,
*:before,
*:after {
  box-sizing: border-box;
}
`;

// ExpandableSidebar
export const ExpandableSidebar = styled.div`
width: 100%; 
height: 100%; 
position: relative;
background-color: white;
@media (min-width: 992px) {
  width: 400px;
  ${props => props.collapsed ? 'margin-left: -400px;' : 'margin-left: 0;'}; 
  transition: .5s ease;
  &::before {
      content: '';
      position: absolute; 
      bottom: 0; 
      right: 0; 
      height: calc(100% - 109px);
      width: 65px; 
      background: linear-gradient(90deg, rgba(24,31,56,0) 0%, rgba(24,31,56,0.02) 100%);
      pointer-events: none;
    }
}
@media (min-width: 1480px) {
  width: 480px; 
  ${props => props.collapsed ? 'margin-left: -480px;' : 'margin-left: 0;'}; 
}
@media (max-width: 992px) {
  overflow-x: scroll;
  padding-bottom: 75px; 
  ${props => props.count > 0 ? (`
    position: absolute; 
    left: -100%; 
    transition: .5s ease;
  `) : 'left: 0%; transition: .5s ease;'}
}
`
export const Flex = styled.div`
display: flex; 
align-items: center; 
width: ${props => props.widthSM ? props.widthSM : '100%'}; 
height: ${props => props.height ? props.height : null};  
justify-content: ${(props) => props.justify ? props.justify : null};
${props => props.flexWrap && `flex-wrap: wrap;`}; 
${props => props.position && `position: ${props.position};`}
${props => props.align ? `align-items: ${props.align};` : null};
${props => props.flexDirection ? `flex-direction: ${props.flexDirection};` : null};
${props => props.ml ? `margin-left: ${props.ml}px;` : null};
${props => props.mt ? `margin-top: ${props.mt}px` : null}; 
${props => props.mb ? `margin-bottom: ${props.mb}px;` : null}; 
${props => props.gap ? `column-gap: ${props.gap}px` : null}; 
${props => props.gapAll && `gap: ${props.gapAll}px`}; 
${props => props.paddingSM && `padding: ${props.paddingSM}px`};
${props => props.pointer ? 'cursor: pointer' : null}; 
  ${props => props.alignItems ? `align-items: ${props.alignItems}` : null}; 
${props => props.borderBottom ? `border-bottom: 1px solid rgb(240, 240, 243);` : null};
@media (min-width: 1200px) {
  width: ${props => props.widthLG ? props.widthLG : null};
  ${props => props.mbLG ? `margin-bottom: ${props.mbLG}px` : null};
  justify-content: ${props => props.justifyLG ? props.justifyLG : null};
  ${props => props.alignItemsLG ? `align-items: ${props.alignItemsLG}` : null}; 
  ${props => props.flexDirectionLG ? `flex-direction: ${props.flexDirectionLG}` : null};
  ${props => props.plLG ? `padding-left: ${props.plLG}px`: null};
  ${props => props.widthLG ? `width: ${props.widthLG}` : null}; 
}
@media (min-width: 1600px) {
  ${props => props.flexDirectionXL ? `flex-direction: ${props.flexDirectionXL};` : null}; 
}
`

export const IsLoading = styled.div`
height: 100vh; 
width: 100vw; 
position: fixed; 
top: 0; 
left: 0; 
background-color: rgba(24, 31, 56, 1); 
z-index: 999999; 
pointer-events: none; 
transition: .5s ease; 
`

export const MobileOnly = styled.div`
  ${props => props.fullWidth ? 'width: 100%;' : null};
@media (min-width: 992px) {
  display: none;
}
`

export const DesktopOnly = styled.div`
  ${props => props.fullWidth ? 'width: 100%;' : null};
@media (max-width: 992px) {
  display: none;
} 
`

export const Div = styled.div`

`

export const MobileMenuButtons = styled.div`
display: flex; 
align-items: center;
padding-left: 16px; 
height: 100%; 
`

export const MobileMenuButton = styled.div`
padding: 0 10px; 
font-size: 14px; 
margin-right: 10px; 
height: 100%; 
display: flex; 
align-items: center;
position: relative; 
cursor: pointer; 
&::after {
    content: ''; 
    position: absolute;
    bottom: 0; 
    left: 0; 
    height: 4px; 
    width: 0;   
    background-color: ${props => props.theme.colors.red}; 
    transition: .5s ease; 
}
${props => props.isActive && `
&::after {
    width: 100%; 
    transition: .5s ease; 
}
`}
`