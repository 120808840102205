import { useContext } from 'react';
import styled from 'styled-components/macro';
import { ApplicationParamsContext } from './ApplicationContextWrapper';
import {
  ApplicationFilterConditions,
  ApplicationFilterSortBy,
  ApplicationFilterPosition,
} from './ApplicationFilterComponents';
import ApplicationFilterComps from './ApplicationFilterComponents/ApplicationFilterComps';
import ApplicationFilterHeader from './ApplicationFilterComponents/ApplicationFilterHeader';
import ApplicationFilterStatus from './ApplicationFilterComponents/ApplicationFilterStatus';

const ApplicationFilter = () => {
  const { state } = useContext(ApplicationParamsContext);
  return (
    <StyledFilter className="application-filter">
      <ApplicationFilterHeader />
      <form onSubmit={(e) => e.preventDefault()}>
        <ApplicationFilterSortBy />
        <ApplicationFilterStatus />
        {(!state.params.positionId ||
          state.params.excludePositionId) && (
          <ApplicationFilterPosition />
        )}
        <ApplicationFilterComps type="skills" />
        <ApplicationFilterComps type="languages" />
        <ApplicationFilterConditions />
      </form>
    </StyledFilter>
  );
};

export default ApplicationFilter;

const StyledFilter = styled.div`
  label {
    width: 100%;
    display: flex;
    align-items: center;
    color: #555;
    margin: 10px 15px 10px 0px;
    input {
      border: 1px solid blue;
      &:checked + span {
        color: ${(props) => props.theme.colors.red};
      }
    }
    span {
      margin-left: 10px;
    }
  }
`;
