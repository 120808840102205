import styled from "styled-components/macro";

export const StyledPWithIcon = styled.p`
  display: flex;
  color: ${(props) => props.theme.colors.lgrey};
  /* font-weight: 600; */
  font-size: ${(props) => props.theme.fonts.buttonStandardSize};

  & > div {
    padding-right: 8px;
  }
`;

export const StyledCompanyLogoContainer = styled.div`
  height: 57px;
  display: flex;
  align-items: center;
  img {
    max-width: 160px;
    max-height: 34px;
    width: auto;
    height: auto;
  }
`;
