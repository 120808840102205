import styled from "styled-components/macro";
export const StyledFooter = styled.footer`
  margin-top: 40px;
  opacity: 0.2;
  padding: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`;
