import styled from "styled-components/macro";

export const SettingsMainWrapper = styled.div`
display: flex; 
flex-direction: column;
width: 100%; 
`

export const SettingsContentWrapper = styled.div`
display: flex; 
width: 100%; 
background-color: #F3F3F5; 
height: 100vh; 
padding-top: 75px; 
padding-bottom: 25px; 
@media (min-width: 1200px) {
    padding-top: 0; 
}
`

export const SettingsMenuWrapper = styled.div`
@media (max-width: 1199px) {
    display: none; 
}
display: block; 
width: 340px; 
height: 100%; 
background-color: ${props => props.theme.colors.white}; 
position: relative;
&::after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 65px;
    top: 0;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(24,31,56,0) 0%, rgba(24,31,56,0.03125) 100%);

}
@media (min-width: 1600px) {
    width: 480px; 
}
`

export const SettingsMenuItem = styled.div`
height: 85px; 
display: flex; 
flex-direction: column;
justify-content: center;
padding: 0 30px; 
border-bottom: 1px solid #D5D6DB; 
cursor: pointer;
transition: .5s ease; 
&::after {
    content: '';
    position: absolute;
    height: 0%;
    width: 4px; 
    background-color: #FF385C; 
    left: 0;
    top: 0;
}
${props => props.isActive && `
background-color: #F3F3F5;
transition: .5s ease; 
position: relative; 
&::after {
    transition: .5s ease;
    height: 100%; 
}
`}
`

export const SettingsBlockWrapper = styled.div`
border-radius: 5px; 
border: 1px solid rgba(24, 31, 56, 0.15); 
width: 100%; 
background-color: white;
margin-bottom: 16px; 
&:first-of-type {
    margin-top: 16px; 
}
&:last-of-type {
    margin-bottom: 185px; 
    @media (min-width: 1200px) {
        margin-bottom: 125px; 
    }
}
@media (max-width: 1200px) {
    ${props => props.marginTopSM ? `margin-top: ${props.marginTopSM}px` : null};
}
`

export const SettingsBlockHeading = styled.div`
padding: 20px; 
box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
@media (min-width: 1200px) {
    padding: 25px; 
}
`

export const SettingsBlockContent = styled.div`
padding: 20px; 
${props => props.flex ? `
display: flex; 
align-items: center; 
` : null}; 
${props => props.spaceBetween ? `justify-content: space-between`: null}; 
@media (min-width: 1200px) {
    padding: 25px; 
}

@media (max-width: 1200px) {
    ${props => props.flex ? `
    flex-direction: column; 
    flex-wrap: wrap; 
    align-items: flex-start; 
    ` : null}
}
`

export const SettingsFlexBlocks = styled.div`
display: flex; 
flex-direction: column;
width: 100%; 
padding: 16px; 
padding-top: 0;
overflow-x: scroll; 
animation: fadeIn .5s ease forwards; 
&::-webkit-scrollbar {
              display: none;
              width: 0 !important
            }
            -ms-overflow-style: none;
  scrollbar-width: none;
@keyframes fadeIn {
    0% {
        opacity: 0; 
    }
    100% {
        opacity: 1; 
    }
}
@media (min-width: 1200px) {
    width: calc(100% - 340px); 
    padding-top: 75px;
}
@media (min-width: 1600px) {
    width: calc(100% - 480px); 
}
@media (max-width: 1200px) {
    ${props => props.paddingTop ? `padding-top: ${props.paddingTop}px` : null}; 
}
`

export const SettingsButtonContainer = styled.div`
    position: absolute;
    height: 70px;
    top: 126px;
    background-color: #F3F3F5;
    right: 0;
    box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
    display: flex; 
    align-items: center;
    justify-content: flex-end;
    width: 100%; 
    padding-right: 16px; 
    z-index: 9;
    transition: .5s ease;
    ${props => props.hide && `
    opacity: 0; 
    transition: 0s ease;
    `}
    @media (min-width: 991px) {
        top: 110px; 
    }
    @media (min-width: 1200px) {
        width: calc(100% - 340px); 
    }
    @media (min-width: 1600px) {
        width: calc(100% - 480px);
    }
`