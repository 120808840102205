import styled from "styled-components/macro";

export const StyledPrivilegesContainer = styled.div`
  display: flex;
  margin-top: 1em;
  > div {
    flex: 1;
    background-color: #efefef;
    padding: 1em 1.5em;
    border-radius: 5px;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
    b {
      margin-right: 0.5em;
      font-size: 20px;
    }
  }
`;
