// React
import React from "react";

// Styled
import StyledFileDownload from "./style";

// Components
import { Icon } from "..";
import { Typography } from "../Typography";

const FileDownload = ({ url, name, cv, includeIcon, notype, ...rest }) => {
  return (
    <StyledFileDownload
      cv={cv}
      {...rest}
      includeIcon={includeIcon}
      href={url}
      target="_blank"
      download
    >
      {!notype && !cv && <span>Doc</span>}
      <Typography tag="p" lowOpacity nomargin large>{name}</Typography>
      {includeIcon && (
        <span>
          <Icon icon="file" width={38} ml={cv && -10} lowOpacity={cv} scale="0.7" invert />
        </span>
      )}
    </StyledFileDownload>
  );
};

export default FileDownload;
