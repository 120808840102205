import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "../../../components/Typography";
import { StyledAccountMenu } from "./style";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutFunction } from "../../../components/LogoutButton";
import { Button, Icon } from "../../../components";
import { getPortalSession } from "../../../api/endpoints/subscription";

const TopBar = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const [portalUrl, setPortalUrl] = useState();

  //get subscription info and portal sessions
  useEffect(() => {
    const getPortal = async () => {
      try {
        const session = await getPortalSession();
        if (session.success) {
          setPortalUrl(session.data.url);
          return;
        }
      } catch (err) {}
    };

    (async () => {
      await Promise.all([getPortal()]);
    })();
  }, []);

  const gotoPortal = () => {
    window.open(portalUrl, "_blank");
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  return (
    <>
      <div>
        <StyledAccountMenu>
          <div>
            <div>
              <Icon icon="avatar" width={15} />
            </div>
            <div>
              <Typography tag="p" nomargin>
                {t("common.account")}
              </Typography>
              <ul>
                <li>
                  <Link to="/settings">
                    <Typography tag="p" nomargin>
                      {t("common.settings")}
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={gotoPortal}>
                    <Typography tag="p" nomargin>
                      {t("common.manage_subscription")}
                    </Typography>
                  </Link>
                </li>
                <li>
                  <Button
                    transparent
                    urlLike
                    onClick={() => {
                      logoutFunction(logout);
                    }}
                  >
                    {t("common.logout")}
                  </Button>
                </li>
              </ul>
            </div>
            <Icon icon="arrow" ml={10} />
          </div>
        </StyledAccountMenu>
      </div>
    </>
  );
};

export default TopBar;
