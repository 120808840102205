import styled from 'styled-components/macro';

export const StyledDropdown = styled.div`
    background: #fff;
    box-shadow: ${props=>props.theme.effects.shadow};
    border-radius: 5px;
    /* width: auto; */
    display: inline-block;
    padding: 15px;
    padding-right: 40px;
    color: ${props=>props.theme.colors.dgray};

`;