// React
import React, { useState, useRef, useEffect, useContext } from "react";

// Custom hooks - utility functions - API endpoints
import { useTranslation } from "react-i18next";
import { getPortalSession } from "../../../api/endpoints/subscription";

// Components
import { TopBarTop, TopBarBottom } from "../../components/TopBar/style";
import { MegaHeader } from "../../components";
import Account from "./Account/Account";
import Subscription from "./Subscription/Subscription";
import ButtonLoading from "../../../components/Loader/ButtonLoading";
import Integrations from "./Integrations/Integrations";

// Styled components
import {
  SettingsMainWrapper,
  SettingsContentWrapper,
  SettingsMenuWrapper,
  SettingsMenuItem,
  SettingsButtonContainer,
} from "./Settings.styled";
import { MobileMenuButton, MobileMenuButtons } from "../../style";
import { Typography } from "../../../components/Typography";
import { PrimaryButton } from "../../../components/Button";
import { Icon } from "../../../components";

// State management
import { StateMessageContext } from "../../../context/MessageContext";

const Settings = () => {
  const [portalUrl, setPortalUrl] = useState(null);
  const [activeLink, setActiveLink] = useState("account");
  const { t } = useTranslation();

  const companyFormRef = useRef();
  const accountSettingsFormRef = useRef();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [successfullyUpdated, setSuccesfullyUpdated] = useState(false);

  const modalState = useContext(StateMessageContext);

  const handleUpdate = async () => {
    setUpdateLoading(true);
    if (companyFormRef.current && accountSettingsFormRef.current) {
      const isCompanyFormValid = await companyFormRef.current.submitForm();
      const isAccountSettingsFormValid =
        await accountSettingsFormRef.current.submitForm();
      setTimeout(function () {
        setUpdateLoading(false);
      }, 500);
      setSuccesfullyUpdated(true);
    }
  };

  //get subscription info and portal sessions
  useEffect(() => {
    const getPortal = async () => {
      try {
        const session = await getPortalSession();
        if (session.success) {
          setPortalUrl(session.data.url);
          return;
        }
      } catch (err) {}
    };

    (async () => {
      await Promise.all([getPortal()]);
    })();
  });

  const gotoPortal = () => {
    window.open(portalUrl, "_blank");
  };

  // Media query list - Render menu different places based on screen width.
  const mql = window.matchMedia("(min-width: 1200px)");
  let desktopView = mql.matches;

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  return (
    <>
      <ButtonLoading loading={updateLoading} success={successfullyUpdated} />
      <SettingsMainWrapper>
        <TopBarTop>
          <MegaHeader text={t("common.settings")} noIcon />
        </TopBarTop>
        <TopBarBottom>
          {!desktopView && (
            <>
              <MobileMenuButtons>
                <MobileMenuButton
                  onClick={() => handleLinkClick("account")}
                  isActive={activeLink === "account"}
                >
                  {t("common.account")}
                </MobileMenuButton>
                <MobileMenuButton
                  onClick={() => handleLinkClick("subscription")}
                  isActive={activeLink === "subscription"}
                >
                  {t("common.subscription")}
                </MobileMenuButton>
                <MobileMenuButton
                  onClick={() => handleLinkClick("integrations")}
                  isActive={activeLink === "subscription"}
                >
                  {t("common.integrations")}
                </MobileMenuButton>
              </MobileMenuButtons>
            </>
          )}
        </TopBarBottom>
        <SettingsButtonContainer hide={modalState.showModal}>
          {activeLink == "account" && (
            <PrimaryButton red onClick={handleUpdate}>
              {t("common.update")}
              <Icon icon="update" mr="10" />
            </PrimaryButton>
          )}
          {activeLink == "subscription" && (
            <PrimaryButton red onClick={gotoPortal}>
              {t("common.administrateBilling")}
              <Icon icon="credit_card" mr="10" />
            </PrimaryButton>
          )}
        </SettingsButtonContainer>
        <SettingsContentWrapper>
          <SettingsMenuWrapper>
            <SettingsMenuItem
              onClick={() => handleLinkClick("account")}
              isActive={activeLink === "account"}
            >
              <Typography tag="p" nomargin bold>
                {t("common.account")}
              </Typography>
              <Typography tag="p" nomargin lowOpacity large>
                {t("common.manageAccount")}
              </Typography>
            </SettingsMenuItem>
            <SettingsMenuItem
              onClick={() => handleLinkClick("subscription")}
              isActive={activeLink === "subscription"}
            >
              <Typography tag="p" nomargin bold>
                {t("common.subscription")}
              </Typography>
              <Typography tag="p" nomargin lowOpacity large>
                {t("common.manageSubscription")}
              </Typography>
            </SettingsMenuItem>
            <SettingsMenuItem
              onClick={() => handleLinkClick("integrations")}
              isActive={activeLink === "integrations"}
            >
              <Typography tag="p" nomargin bold>
                {t("common.integrations")}
              </Typography>
              <Typography tag="p" nomargin lowOpacity large>
                {t("common.manageIntegrations")}
              </Typography>
            </SettingsMenuItem>
          </SettingsMenuWrapper>
          {activeLink === "account" && (
            <Account
              ref={{
                companyFormRef: companyFormRef,
                accountSettingsFormRef: accountSettingsFormRef,
              }}
            />
          )}
          {activeLink === "subscription" && <Subscription />}
          {activeLink === "integrations" && <Integrations />}
        </SettingsContentWrapper>
      </SettingsMainWrapper>
    </>
  );
};

export default Settings;
