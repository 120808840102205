// React
import React, { useContext, useState } from "react";

// Translations, hooks, plugins, and API endpoints
import { useTranslation } from "react-i18next";
import QuillEditor from "../../QuillEditor/QuillEditor";

// Components
import { FileField, InputFieldWithLabel } from "../../../../../../components";
import { initialField } from "../../../../../../components/FileField/reducer";
import CommonBlockSettings from "./CommonBlockSettings";
import { ImagePositionButton } from "../Block.styled";
import { Typography } from "../../../../../../components/Typography";

// State management
import { PageContext } from "../../../../../context/PageContext";
import { IMAGE_POS } from "../blockConstants";
import { Flex } from "../../../../../style";
import { InputGroup } from "../../../../../../components/Input/Input";

export const ParagraphWithImageContent = ({ block }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  return (
    <>
      <InputGroup block>
        <InputFieldWithLabel
          tiny
          mb="20"
          label={t("component.block.section_header_label", "Section header")}
          byline={t(
            "component.block.section_header_byline",
            "Choose a header for this section of text"
          )}
          onChange={(e) => {
            dispatch({
              type: "update_block",
              payload: { id: block.id, name: "title", value: e.target.value },
            });
          }}
          name="title"
          type="text"
          value={block.title || ""}
        />
      </InputGroup>
      <InputGroup block>
        <QuillEditor
          mb="20"
          label={t("component.block.section_quill_label", "Content")}
          byline={t(
            "component.block.section_quill_byline",
            "Write your body text here"
          )}
          value={block.body}
          changeHandler={(data) =>
            dispatch({
              type: "update_block",
              payload: { id: block.id, name: "body", value: data },
            })
          }
        />
      </InputGroup>
      <FileField
        type="image"
        docType="other"
        uploadBtn
        transparentButton
        label={t("component.block.image_label", "Image")}
        initialFiles={
          block.files?.length > 0 &&
          block?.files.map((file, index) => {
            return {
              ...initialField,
              id: index,
              hasFile: true,
              savedFile: file,
            };
          })
        }
        onUpload={(data) => {
          dispatch({
            type: "add_block_file",
            payload: { id: block.id, file: data },
          });
        }}
        onRemove={(data) => {
          dispatch({
            type: "remove_block_file",
            payload: { id: block.id, file: data },
          });
        }}
      />
    </>
  );
};

export const ParagraphWithImageSettings = ({ block }) => {
  const [selectedImagePosition, setSelectedImagePosition] = useState(
    block.imageLeft
  );
  const { t } = useTranslation();
  const { dispatch } = useContext(PageContext);

  return (
    <>
      <Typography tag="p" bold mb="0">
        {t("component.block.image_position_label", "Image position")}
      </Typography>
      <Typography tag="p" lowOpacity mb="20">
        {t(
          "component.block.image_position_byline",
          "Choose the position of the image"
        )}
      </Typography>
      <Flex>
        {IMAGE_POS.map((p) => (
          <ImagePositionButton
            key={p.value}
            className={`radio-button ${
              block.imageLeft === p.value ? "active" : ""
            }`}
            onClick={() => {
              setSelectedImagePosition(p.value);
              dispatch({
                type: "update_block",
                payload: {
                  id: block.id,
                  name: "imageLeft",
                  value: p.value,
                },
              });
            }}
          >
            {p.label}
          </ImagePositionButton>
        ))}
      </Flex>
      <CommonBlockSettings block={block} />
    </>
  );
};
