import styled from "styled-components/macro";

export const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledSuggestionContainer = styled.div`
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: ${(props) => props.theme.effects.shadow};
`;
