// React
import React from "react";

// Styled
import { CVExperienceWrapper } from "./CV.styled.";
import { Typography } from "../Typography";
import { Flex } from "../../recruiter/style";

// Utility functions
import { getMonth } from "../../utils/various";
import i18next from "i18next";

const CVExperience = ({ data, applicationLanguage }) => {

    // Sort the experience array by 'toYear' in descending order
    const sortedData = [...data.experience, ...data.education].sort((a, b) => b.toYear - a.toYear);
    const at = i18next.getFixedT(applicationLanguage)


  return (
      <>
         {sortedData.map((item, index) => (
          <CVExperienceWrapper key={index}>
            <Flex align="flex-start" justify="space-between">
            <div>
            <Typography tag="p" nomargin fontWeight="800" mb="5">{item.programme ? item.programme : item.title}</Typography>
            <Typography tag="p" nomargin>{item.institution ? item.institution : item.workplace}</Typography>
            </div>

            {item.fromYear && (
        <Typography uppercase lowOpacity tag="p">
          {item.fromYear}
          {item.fromMonth &&
            `, ${getMonth(item.fromMonth, true, at) }`}
          {item.currentWorkplace
            ? ` - present`
            : ` - ${item.toYear}${
              item.toMonth
                  ? ", " + getMonth(item.toMonth, true, at)
                  : ""
              }`}
        </Typography>
      )}
      </Flex>
              {item.workplaceDescription && (
                <Typography tag="p" mt="10" nomargin lowOpacity>{item.workplaceDescription}</Typography>
              )}
          </CVExperienceWrapper>
          ))}
      </>
  );
};

export default CVExperience;
