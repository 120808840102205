import React, { useState, useContext, useRef } from "react";
import { Col, Grid, Row } from "../../../../components/Grid";
import { Typography } from "../../../../components/Typography";
import { ContextMenu, MegaHeader, PositionList } from "../../../components";
import { Formik, Field } from "formik";
import { inviteApplicant } from "../../../../api/endpoints/applicant";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import {
  InputFieldWithLabel,
  VerticalDivider,
} from "../../../../components";
import { Button } from "../../../../components";
import { useHistory } from "react-router";
import { DispatchMessageContext } from "../../../../context/MessageContext";
import { useTranslation } from "react-i18next";

const InviteCandidate = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);
  const [positionId, setPositionId] = useState(null);

  //Formik external form handling
  const formRef = useRef();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const isSubmitting = () => {
    if (formRef.current) {
      return formRef.current.isSubmitting;
    } else {
      return false;
    }
  };

  const onSelect = (e) => {
    //select the position
    setPositionId(e.target.value);
  };

  return (
    <div>
      <ContextMenu backToLabel={t('page.invite_candidate.back')} backToUrl="/candidate">
        <div>
          <Button
            urlLike
            transparent
            faded
            onClick={() => {
              history.push("/candidate");
            }}
            uppercase
          >
            {t('common.cancel')}
          </Button>
          <VerticalDivider />
          <Button
            urlLike
            transparent
            highlight
            bold
            onClick={handleSubmit}
            disabled={isSubmitting()}
            uppercase
          >
            {t('page.invite_candidate.invite_candidate')}
          </Button>
        </div>
      </ContextMenu>
      <MegaHeader text={t('component.sidemenu.candidate.add_new')} />
      <Grid fluid >
        <Row mt={20}>
          <Col size={12} break="xl">
            <Typography bold tag="h1">
              {t('page.invite_candidate.select_position_label')}
            </Typography>
            <Typography tag="h3" mt={10} colorMute>
              {t('page.invite_candidate.select_position_byline')}
            </Typography>
            <PositionList
              header={t('page.invite_candidate.choose_a_position')}
              active
              onSelect={onSelect}
              searchable
              useLabel
            />
          </Col>
          <Col size={12} break="xl">
            <Typography bold tag="h1">
             {t('common.candidate.singular')}
            </Typography>
            <Typography tag="h3" mt={10} colorMute>
              {t('page.invite_candidate.invite_description')}
            </Typography>
            <Formik
              initialValues={{
                email: "",
                firstName: "",
                lastName: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                try {
                  const request = {
                    body: { ...values, positionId: positionId },
                  };
                  const response = await inviteApplicant(request);
                  if (response.success) {
                    resetForm({});
                    messageDispatch({type:"set_message", payload: t('message.succesful_invite')});
                  } else {
                    errorDispatch({
                      type: "set_error",
                      payload: response.data.data,
                    });
                  }
                } catch (err) {
                  errorDispatch({
                    type: "set_error",
                    payload: JSON.stringify(err),
                  });
                }
              }}
              innerRef={formRef}
            >
              {(props) => {
                const { handleSubmit } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Row smallerInnerGutter>
                      <Col>
                        <Field
                          tiny
                          label={false}
                          as={InputFieldWithLabel}
                          placeholder={t('common.first_name')}
                          name="firstName"
                        />
                      </Col>
                      <Col>
                        <Field
                          tiny
                          label={false}
                          as={InputFieldWithLabel}
                          placeholder={t('common.last_name')}
                          name="lastName"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          as={InputFieldWithLabel}
                          tiny
                          label={false}
                          placeholder={t('common.email')}
                          name="email"
                        />
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default InviteCandidate;
