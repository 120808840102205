import styled from "styled-components/macro";

export const AccordionContainer = styled.div`
    background-color: ${props => props.theme.colors.white}; 
  margin-bottom: 1rem;
  margin-top: 16px; 
  border-radius: 5px; 
  @media (min-width: 1200px) {
    ${props => props.metaEditor && `
    margin: 0; 
    border-radius: 0; 
    `}
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.mgrey}; 
  padding: 12px 16px; 
  @media (min-width: 1200px) {
    ${props => props.metaEditor && `
    border-top: 1px solid #F0F0F3;
    box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15); 
    `}
  }
`;

export const AccordionTitle = styled.h2`
  margin: 0;
  font-size: 14px; 
  font-weight: 500; 
  color: ${props => props.theme.colors.primaryBlue}; 
`;

export const AccordionContent = styled.div`
max-height: 0; 
transform: scaleY(0); 
transform-origin: top;
  overflow: hidden;
  transition: 0.3s;
  ${props => props.isOpen && `
  transform: scaleY(1); 
  max-height: 5000px; 
  `}
`;