import styled from "styled-components/macro";

export const SiteNavWrapper = styled.div`
display: flex;
align-items: center; 
`

export const AddCareerButton = styled.div`
margin-left: 16px;
opacity: 0.6; 
cursor: pointer;
`