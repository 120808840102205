import styled from "styled-components/macro";

export const StyledHeader = styled.header`
  h1 {
    font-size: 36px;
  }
  color: #fff;
  ${(props) =>
    props.backgroundType === "solid" &&
    "background-color: var(--secondary-background-color);color: var(--secondary-color);"}
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  ${(props) =>
    props.backgroundType === "image" &&
    `background-image: linear-gradient(rgba(0,0,0,0.35),rgba(0,0,0,0.35)), url(${props.backgroundImageUrl});`}

  .header-top-row {
    top: 30px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
    & > * {
      display: flex;
      /* flex: 1; */
    }
    .arrow-back {
      padding-left: 15px;
      cursor: pointer;
      /* justify-self: flex-start; */
      justify-content: flex-start;
      flex: 1;
    }
    .header-logo {
      max-width: 140px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .account {
      flex: 1;
      justify-content: flex-end;
      padding-right: 15px;
      /* justify-self: flex-end; */
    }
  }

  .scroll-down-wrapper {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    display: block;

    &::after {
      content: "";
      background-color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }

    img.scroll-down-indicator {
      position: absolute;
      width: 16px;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
    }
  }

  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 0 15px;
  }

  .header-inner {
    padding-top: 290px;
    padding-bottom: 290px;
    /* @include breakpoint(lg) {
      padding-top: 190px;
      padding-bottom: 190px;
    }

    @include breakpoint(sm) {
      padding-top: 100px;
      padding-bottom: 125px;
    } */
    @media (max-width: 575px) {
      padding-bottom: 125px;
      padding-top: 100px;
    }

    h1 {
      margin-bottom: 18px;
      text-align: center;
      max-width: 600px;
    }

    p {
      font-size: 18px;
      max-width: 600px;

      margin: auto;
      text-align: center;
    }

    .btn-wrapper {
      margin-top: 33px;
      display: flex;
      align-items: center;
      justify-content: center;

      /* @include breakpoint(sm) {
        flex-direction: column-reverse;
      } */

      .btn:first-of-type + .btn {
        margin-left: 30px;

        /* @include breakpoint(sm) {
          margin-left: 0px;
          margin-top: 10px;
        } */
      }
    }
  }
`;
