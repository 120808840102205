import { useContext, useState, useEffect } from 'react';
import { useFetchApplications } from './useFetchApplications';
import { ApplicationCard, Search } from '..';
import { ApplicationParamsContext } from './ApplicationContextWrapper';
import { BoxLoader } from '../../../components';
import { useDrop } from 'react-dnd';
import { StyledApplicationListContainer } from './style';
import { useHistory } from 'react-router';
import ApplicationMenu from './ApplicationMenu';
import { handleSelectApplications } from '../../../utils/various';
import { Typography } from '../../../components/Typography';
import { useTranslation } from 'react-i18next';
import ApplicationArrowNavigation from './ApplicationArrowNavigation';
import { DispatchErrorContext } from '../../../context/ErrorContext';
import LoadMore from '../LoadMore';
import ApplicationDropContainer from './ApplicationDropContainer';

/**
 * useSearch include search bar
 * useSelect include select and menu
 * useIndex change active application on click
 */
const ApplicationList = ({
  useIndex, //Change active application when clicking on application card
  id,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatchError = useContext(DispatchErrorContext);
  const { state, dispatch } = useContext(ApplicationParamsContext); //Params
  const {
    error,
    data,
    status,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useFetchApplications({ ...state.params });

  //ALL APPLICATIONS FROM REACT QUERY FLATTENED TO A SINGLE ARRAY
  const [flattenedList, setFlattenedList] = useState([]);

  //SEND LIST TO CONTEXT
  useEffect(() => {
    if (status === 'success' && data?.pages) {
      dispatch({
        type: 'update_list',
        payload: data?.pages?.flatMap((page) => page?.data),
      });
      setFlattenedList(data?.pages?.flatMap((page) => page?.data));
      
    } else {
      setFlattenedList([]);
    }
  }, [data]);


  useEffect(() => {
    if (error) {
      dispatchError({ type: 'set_error', payload: error.message });
    }
  }, [error]);



  // Filter the flattenedList based on the id prop
  // const filteredList = flattenedList.filter((app) => app.id === id);

  return (
    <>
        {status === 'success' && data && (
          <>
            {flattenedList.map((app) => (
              <ApplicationCard
                key={app.id}
                useIndex={useIndex}
                application={app}
                    onClick={() => {
                    dispatch({
                      type: 'set_index',
                      payload: app.id,
                    });
                }}
              />
            ))}
          </>
        )}
    </>
  );
};

export default ApplicationList;
