export const IMAGE_MIME_TYPES = [
    "image/jpeg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/bmp",
    "image/tiff",
];

export const DOC_TYPES = [
    "/*"
]