import { createContext, useReducer, useEffect } from 'react';
import Tracker from 'react-user-activity-tracker';
import { submitApplicationViewStat } from '../../../api/endpoints/share';

const DEFAULT_SIZE = 10;
const initialParams = {
  params: {
    includeSignedRequest: true, // boolean include files or dont include files ( aws signedrequest )
    positionId: null, // number or null to ignore
    positionIds: [], // array of positionIds
    excludePositionId: null, // number
    excludeApplicants: false, // ( exclude candidates that have already applied to this positionId )
    applicantId: null, //number
    shortlist: 0, // number (1 / 0)
    not_shortlist: false, //exclude shortlistet application
    sort: 'DESC', // ( DESC / ASC )
    sortby: 'createdAt', // ( firstName / createdAt )
    size: DEFAULT_SIZE, // applications pr page
    // page: 0, // page number
    s: null, // ( search string )
    skills: '{}', // Stringified array {  "Dancing" : "3", "Astrology" : "1" }
    languages: '{}', // Stringified array {  "Dancing" : "3", "Astrology" : "1" }
    status: null, // string ( submitted, rejected, etc.
    hasFiles: null, // number (1/0)
    isAvailable: null, // number (1/0)
    isSubmitted: false,
    isNew: false, // number (1/0)
    isRejected: null,
    isNotRejected: null,
    uuid: null, //When getting applications belonging to a shortlist this has a value ( and will then use another endpoint in react query )
    substatus: null,
    excludeWithSubstatus: false, // exclude applications that has a substatus
  },
  list: [], //the list in this context ( loaded in ApplicationList with react-query )
  index: 0, //the index in this context ( loaded in ApplicationList with react-query )
};

export const ApplicationParamsContext = createContext({});

/**
 * Returns params that are controlled by filter/user
 * @param {object} params
 * @returns
 */
export const filterableParams = (params) => {
  const {
    positionId,
    uuid,
    excludeApplicants,
    excludePositionId,
    includeSignedRequest,
    applicantId,
    size,
    sort,
    s,
    ...rest
  } = params;
  return rest;
};

const applicationParamsReducer = (state, action) => {
  switch (action.type) {
    case 'init_params': {
      return {
        ...state,
        params: { ...state.params, ...action.payload },
      };
    }
    case 'update_params': {
      return {
        ...state,
        params: { ...state.params, ...action.payload },
      };
    }
    case 'clear_all': {
      const params = filterableParams(initialParams.params);
      return {
        ...state,
        params: { ...state.params, ...params },
      };
    }
    case 'update_list': {
      return {
        ...state,
        list: action.payload,
      };
    }
    case 'remove_from_list': {
      return {
        ...state,
        list: state.list.filter(
          (app) => !action.payload.includes(app.id)
        ),
      };
    }
    case 'inc_index': {
      return {
        ...state,
        index:
          state.index < state.list.length - 1 ? state.index + 1 : 0,
      };
    }
    case 'dec_index': {
      return {
        ...state,
        index:
          state.index > 0 ? state.index - 1 : state.list.length - 1,
      };
    }
    case 'set_index': {
      return {
        ...state,
        index: state.list
          .map((app) => app.id)
          .indexOf(action.payload),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const ApplicationContextWrapper = ({
  shortlist,
  not_shortlist,
  positionId,
  excludePositionId,
  uuid,
  size,
  useTracker,
  children,
  status,
  substatus,
  excludeWithSubstatus,
  isRejected,
  isNotRejected,
}) => {
  const [state, dispatch] = useReducer(
    applicationParamsReducer,
    initialParams
  );

  // HERE IS WHERE WE CHECK IF WE SHOULD NARROW LIST
  useEffect(() => {
    dispatch({
      type: 'init_params',
      payload: {
        shortlist: shortlist ? true : false,
        not_shortlist: not_shortlist,
        status: status ? status : null,
        substatus: substatus ? substatus : null,
        excludeWithSubstatus: excludeWithSubstatus ? true : false,
        positionId: positionId ? positionId : null,
        uuid: uuid ? uuid : null,
        size: size ? size : DEFAULT_SIZE,
        excludePositionId: excludePositionId
          ? excludePositionId
          : null,
        excludeApplicants: excludePositionId ? true : false,
        isRejected: isRejected !== null ? isRejected : null,
        isNotRejected: isNotRejected !== null ? isNotRejected : null,
      },
    });
  }, [
    uuid,
    positionId,
    shortlist,
    not_shortlist,
    status,
    substatus,
    excludeWithSubstatus,
    isRejected,
    size,
  ]);

  // IF USING TRACKER ( SHARED EXTERNAL SHORTLIST )
  const handleTrackSubmit = async (id, val, interval) => {
    try {
      const request = {
        selector: uuid,
        applicationId: id,
        body: { time_viewed: val, views: interval ? null : 1 },
      };

      await submitApplicationViewStat(request);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ApplicationParamsContext.Provider value={{ state, dispatch }}>
      {children}
      {useTracker && state.list[state.index]?.id && (
        <Tracker
          trackerId={state.list[state.index].id}
          trackerSubmitInterval={10}
          timeout={60 * 2}
          onTrackerIdChange={(id, val) => handleTrackSubmit(id, val)}
          onIntervalSubmit={(id, val) =>
            handleTrackSubmit(id, val, true)
          }
        />
      )}
    </ApplicationParamsContext.Provider>
  );
};
