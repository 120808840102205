import styled from "styled-components/macro";
export const StyledTag = styled.div`
width: fit-content;
    background-color: rgb(243, 243, 245);
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 12px;
    color: rgba(24, 31, 56, 0.6);
    display: flex;
    border: 1px solid rgba(24, 31, 56, 0.15);
    cursor: pointer;
    transition: all 0.5s ease 0s;
    max-height: 28px; 
    margin-top: 12px;
    &:hover {
        border: 1px solid ${props => props.theme.colors.red};
    }
`;
