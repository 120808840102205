import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import {
  Button,
  Dropdown,
  DropdownItem,
  Icon,
} from '../../../components';
import {
  getSelectedApplications,
  toggleCheckAll,
} from '../../../utils/various';
import { useOffClick } from '../../hooks/useOffClick';
import ApplicationActionModal from '../ApplicationCard/ApplicationActionModal';
import { DropdownGroup } from '../BasicCard/style';

import { ToggleButton } from '../Buttons/Buttons';
import {
  APPLICATION_CARD_ACTIONS,
  DEFAULT_APPLICATION_CARD_ACTIONS,
} from './actions';

const ApplicationMenu = ({
  selected,
  setSelected,
  applications,
  customActions,
}) => {
  const { t } = useTranslation();
  const offRef = useRef();
  const [modalAction, setModalAction] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [disabledList, setDisabledList] = useState({
    delete: true,
    reject: true,
    shortlist: true,
    unshortlist: true,
    add_applicants: true,
    interview: true,
    hired: true,
    to_be_rejected: true,
  });
  const [actions, setActions] = useState(APPLICATION_CARD_ACTIONS);

  useOffClick(offRef, () => setDropdownOpen(false));

  const handleClick = (actionName) => {
    setDropdownOpen(false);
    setModalAction(actionName);
  };

  // FILTER ACTIONS BY INCOMING PROPS
  useEffect(() => {
    setActions(
      APPLICATION_CARD_ACTIONS.filter((action) => {
        if (customActions) {
          return customActions.includes(action.name);
        } else {
          return DEFAULT_APPLICATION_CARD_ACTIONS.includes(
            action.name
          );
        }
      })
    );
  }, [customActions]);

  useEffect(() => {
    setDisabledList({
      delete: selected.length === 0,
      reject:
        selected.length === 0 ||
        getSelectedApplications(selected, applications).filter(
          (a) => a.status !== 'submitted'
        ).length > 0,
      shortlist: selected.length === 0,
      unshortlist: selected.length === 0,
      add_applicants: selected.length === 0,
      interview: canChangeSubstatus('interview'),
      hired: canChangeSubstatus('hired'),
      to_be_rejected: canChangeSubstatus('to_be_rejected'),
    });
  }, [selected]);

  const canChangeSubstatus = (substatusToChangeTo) => {
    return (
      getSelectedApplications(selected, applications).filter(
        (a) =>
          a.status !== 'submitted' ||
          a.substatus === substatusToChangeTo
      ).length > 0
    );
  };

  return (
    <div ref={offRef}>
      {modalAction && (
        <ApplicationActionModal
          setModalAction={setModalAction}
          modalAction={modalAction}
          applications={getSelectedApplications(
            selected,
            applications
          )}
        />
      )}
      <StyledApplicationMenu>
        <ToggleButton
          toggleFunction={() =>
            toggleCheckAll(selected, setSelected, applications)
          }
          selected={selected}
        />
        <DropdownGroup>
          {actions.length > 1 ? (
            <>
              <Button
                transparent
                uppercase
                title={
                  selected.length === 0
                    ? t(
                        'component.application_menu.tooltip',
                        'Select an application to update it.'
                      )
                    : ''
                }
                disabled={selected.length === 0}
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                {t('common.options', 'options')}
                <Icon icon="add" ml={12} />
              </Button>

              {dropdownOpen && (
                <Dropdown>
                  {actions.map((action) => {
                    if (!action.bulk) return; //This action cannot be handled in bulk
                    return (
                      <DropdownItem
                        key={action.name}
                        actionKey={action.name}
                        handleClick={() => handleClick(action.name)}
                        disabled={disabledList[action.name]}
                      >
                        <Icon icon={action.icon} className="fill" />
                        {t(action.i18nKey)}
                      </DropdownItem>
                    );
                  })}
                </Dropdown>
              )}
            </>
          ) : (
            <>
              {/* We only have one action, so do not use dropwdown */}
              <Button
                transparent
                uppercase
                disabled={
                  selected.length === 0 ||
                  disabledList[actions[0].name]
                }
                onClick={() => handleClick(actions[0].name)}
              >
                {t(actions[0].i18nKey)}
                <Icon icon={actions[0].icon} ml={12} />
              </Button>
            </>
          )}
        </DropdownGroup>
      </StyledApplicationMenu>
    </div>
  );
};

export default ApplicationMenu;

const StyledApplicationMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  > div {
    position: relative;
    top: auto;
    right: auto;
    > button {
      padding: 0;
      width: auto;
      flex: 1;
    }
  }
`;
