import { useState, useEffect } from 'react';

/**
 * Get the dimensions of "elRef" which is updated on "resize" events
 */
export const useGetResponsiveDimensions = (elRef) => {
  const [elX, setElX] = useState(null);
  const [elY, setElY] = useState(null);
  const [elWidth, setElWidth] = useState(null);
  const [elHeight, setElHeight] = useState(null);

  useEffect(() => {
    const updateSize = () => {
      if ( !elRef?.current) return;

      if (elRef.current && elRef.current.clientWidth) {
        setElWidth(elRef.current.clientWidth);
      }
      if (elRef.current && elRef.current.clientWidth) {
        setElHeight(elRef.current.clientHeight);
      }
      if (elRef.current) {
        const { top, left } = elRef.current.getBoundingClientRect();
        setElX(left);
        setElY(top);
      }
    };

    window.addEventListener('resize', () => {
      updateSize();
    });
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [elRef]);
  return { elX, elY, elWidth, elHeight };
};
