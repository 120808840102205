import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationParamsContext } from '../ApplicationContextWrapper';
import Accordion from '../../Accordion';

const SORT_KEYS = [
  ['createdAt', 'component.filter.date_created', 'Date created'],
  /* TODO : REMOVE ON FIX BACKEND QUERY */
  // ['firstName', 'component.filter.candidate_name', 'Candidate name'],
  ['starRating', 'component.filter.star_rating', 'Star rating'],
];

const ApplicationFilterSortBy = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ApplicationParamsContext);
  const handleClick = (e) => {
    dispatch({
      type: 'update_params',
      payload: { [e.target.name]: e.target.value },
    });
  };
  return (
    <Accordion
      title={t('component.filter.sort_by')}
      initiallyOpen={false}
    >
      <div>
        {SORT_KEYS.map(([key, t_key, t_key_fallback]) => {
          return (
            <label key={key} htmlFor={key}>
              <input
                type="radio"
                checked={state.params['sortby'] === key}
                id={key}
                value={key}
                name="sortby"
                onChange={handleClick}
              />
              <span>{t(t_key, t_key_fallback)}</span>
            </label>
          );
        })}
      </div>
    </Accordion>
  );
};

export default ApplicationFilterSortBy;
