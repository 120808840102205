// React
import React, { useState, useEffect, useContext } from "react";

// React router
import { useHistory, useLocation } from "react-router-dom";

// State management
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../recruiter/context/PositionContext";
import { SubscriptionContext } from "../../recruiter/context/SubscriptionContext";
import { DispatchErrorContext } from "../../context/ErrorContext";
import { PageContext } from "../../recruiter/context/PageContext";

// Components
import PaymentModal from "../PaymentModal/PaymentModal";
import { PurchaseModal } from "..";
import { ToggleSwitch } from "../../recruiter/components/Buttons/Buttons";
import ButtonLoading from "../Loader/ButtonLoading";

// Translation
import { useTranslation } from "react-i18next";

// API endpoints
import { togglePosition } from "../../api/endpoints/position";

// Utility functions and hooks
import { isSubscriptionStatusOk } from "../../utils/stripe_utils";
import { hasPrivilege } from "../../utils/various";
import usePurchaseAddon from "../../recruiter/hooks/usePurchaseAddon";
import useGetAddon from "../../recruiter/hooks/useGetAddon";

/**
 * Button that activates or deactivates a position
 * Activate position if organization has privileges to activate
 * Show modal to purchase additional position - if not enough privileges for position
 * Deactivate position when user hits deactivate /
 * if deactivating will lead to superflous addon : position, ask user if set to cancel!
 */
const TogglePosition = (mr) => {
  //translations
  const { t } = useTranslation();

  //handle position, error, message dispatch
  const positionDispatch = useContext(DispatchPositionContext);
  const errorDispatch = useContext(DispatchErrorContext);

  //Handle button state
  const [showModal, setShowModal] = useState(false); //show modal if purchasing extra position addon
  const [disabled, setDisabled] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [successfullyUpdated, setSuccesfullyUpdated] = useState(false);

  //About user privileges and whether user has payment ( can purchase things )
  const {
    state: {
      main: { subscription },
      privileges: { privileges, activeItems },
      hasPayment,
    },
    isLoading: subscriptionIsLoading,
  } = useContext(SubscriptionContext);

  //The position this button is referring to
  const { state: positionState, single: positionSingle } =
    useContext(StatePositionContext);
  const { state: pageState, dispatch: pageDispatch } = useContext(PageContext);

  //This handles when coming from a stripe checkout session
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  let query = new URLSearchParams(useLocation().search);
  const removeQueryParams = () => {
    if (queryParams.get("session_id")) {
      queryParams.delete("session_id");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  /**
   * Call to api, set state to toggling
   * true - on, false - off
   * Update subscription active items state
   * @param {boolean} value
   */

  const [isToggling, setIsToggling] = useState(false);

  const handleToggle = async () => {
    if (isToggling || disabled) return;

    try {
      setIsToggling(true);
      const newValue =
        positionSingle.status === "active" ? "inactive" : "active";

      // Optimistic UI update for PositionContext
      positionDispatch({
        type: "updated_position",
        payload: { data: { ...positionSingle, status: newValue }, t: t },
      });

      // Optimistic UI update for PageContext
      pageDispatch({
        type: "update_page",
        payload: {
          name: "position",
          value: { ...positionSingle, status: newValue },
        },
      });

      // Don't wait for the server response
      await togglePosition({
        selector: positionSingle.id,
        params: { activate: newValue === "active" },
      });

      // // Update position context
      // positionDispatch({
      //   type: "refetch_positions",
      //   payload: true,
      // });
      // positionDispatch({
      //   type: "refresh_positions",
      //   payload: true,
      // });

      // // Revert the UI update for PositionContext
      // positionDispatch({
      //   type: "updated_position",
      //   payload: { data: positionSingle, t: t },
      // });

      // dispatch({
      //   type: "update_active_items",
      //   payload: { key: "position", value: newValue === "active" ? 1 : -1 },
      // });

      // dispatch({
      //   type: "update_subscription",
      // });

      // console.log("AFTER DISPATCH activeItems: ", activeItems);

      setIsToggling(false);
    } catch (err) {
      errorDispatch({
        type: "set_error",
        payload: err.message,
      });

      // Revert the UI update for PositionContext
      positionDispatch({
        type: "updated_position",
        payload: { data: positionSingle, t: t },
      });

      // Revert the UI update for PageContext
      pageDispatch({
        type: "update_page",
        payload: { name: "position", value: { ...positionSingle } }, // Revert status to previous value
      });

      setIsToggling(false);
    }
  };

  useEffect(() => {
    setDisabled(
      !subscription ||
        !isSubscriptionStatusOk(subscription.status) ||
        subscriptionIsLoading
    ); //Set disabled if no main subscription active or subscription is being loaded

    setShowModal(hasPayment && !!query.get("session_id"));
  }, [subscription, hasPayment, positionSingle, subscriptionIsLoading]);

  // Handle purchase of addon
  const { handlePurchase, isLoading } = usePurchaseAddon(() => {
    handleToggle(true);
    removeQueryParams();
  });

  // Get product
  const [product] = useGetAddon("Position");

  return (
    <>
      <ButtonLoading
        error={confirmationError}
        loading={updateLoading}
        success={successfullyUpdated}
      />
      {showModal && (
        <>
          {hasPayment ? (
            <PurchaseModal
              onCancel={() => {
                setShowModal(false);
                removeQueryParams();
              }}
              onConfirm={() => {
                // Purchase , then activate , then hide modal
                handlePurchase(product.prices[0].id, "position");
              }}
              type="addonModal"
              prices={product?.prices}
              title={t("common.addPosition")}
              productTitle={t("common.position.singular")}
              desc={t("common.positionModalDesc")}
              isLoading={isLoading}
            />
          ) : (
            <PaymentModal
              onCancel={() => {
                setShowModal(false);
                removeQueryParams();
              }}
              returnUrl={"position/" + positionSingle.id}
            />
          )}
        </>
      )}
      <ToggleSwitch
        mr={mr}
        onClick={() => {
          // console.log("privileges", privileges);
          // console.log("activeItems", activeItems);

          if (positionSingle.status === "active") {
            handleToggle(false);
          } else {
            if (
              !hasPrivilege("activate_position", privileges, activeItems) &&
              !positionSingle.unsolicited
            ) {
              setShowModal(true);
            } else {
              handleToggle(true);
            }
          }
        }}
        active={positionSingle.status === "active"} // Reflect the status directly
        isToggling={isToggling}
        text={
          positionSingle.status === "active"
            ? t("common.active", "Active")
            : t("common.inactive", "Inactive")
        }
      />
    </>
  );
};

export default TogglePosition;
