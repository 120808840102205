import styled from "styled-components/macro";

export const StyledSelectRadio = styled.div`
display: flex;
height: 100%; 
button {
  &.active {
    color: #181F38 !important; 
    &:after {
        content: '';
        position: absolute;
        height: 4px; 
        width: 100%; 
        bottom: 0; 
        left: 0; 
        background-color: #FF385C; 
        animation: animate .5s ease; 
        cursor: pointer; 
    }
}
}

@keyframes animate {
    0% {
        width: 0; 
    }
    100% {
        width: 100%; 
    }
}
button {
    appearance: none;
    background-color: transparent;
    border: none; 
    font-size: 14px; 
    padding: 0 10px;
    color: ${props => props.theme.colors.faceliftBlueLight};
    position: relative;
}
`


export const StyledSelectLanguage = styled.div`
  ${(props) => props.alignRight && `margin-right: 8px;`}
  & > div {
    position: relative;
  }
  & > div > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    &:focus {
      outline: none;
    }
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 0px;
    text-transform: capitalize;
    border: 1px solid
      ${(props) =>
    props.inverted
      ? `${props.theme.colors.dgrey}`
      : `${props.theme.colors.lgrey}`};
    ${(props) => (props.noBorders ? "border:none;" : "")}
    width: 100%;
    border-radius: 5px;
    margin-right: 2rem;
    font-size: 14px; 
    margin-top: 15px; 
        padding: 12px 20px;
    padding-right: 2rem;
`;