// React
import React, { useContext, useState, forwardRef } from "react";

// Hooks - API endpoints - libraries
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../../api/endpoints/me";
import { deleteAccount } from "../../../../api/endpoints/me";
import { updateRecruiter } from "../../../../api/endpoints/recruiter";
import i18n from "../../../../i18n/i18n";

// Formik
import { Formik, Field } from "formik";

// Context
import { StateUserContext } from "../../../../context/UserContext";
import { DispatchUserContext } from "../../../../context/UserContext";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { DispatchMessageContext } from "../../../../context/MessageContext";

// Components - styled
import {
  SettingsBlockWrapper,
  SettingsBlockHeading,
  SettingsBlockContent,
} from "../Settings.styled";
import { Typography } from "../../../../components/Typography";
import { Col, Row, Grid } from "../../../../components/Grid";
import { Icon, InputFieldWithLabel, Modal } from "../../../../components";
import { LanguageSelect } from "../../../../components";
import { ToggleSwitch } from "../../../components/Buttons/Buttons";
import { PrimaryButton } from "../../../../components/Button";
import { Flex } from "../../../style";

const AccountSettings = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { user } = useContext(StateUserContext);
  const userDispatch = useContext(DispatchUserContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);

  //handle password reset
  const [sendPasswordReset, setSendPasswordReset] = useState(false);
  const [passwordResetModal, setPasswordResetModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [sendPasswordResetIsSending, setSendPasswordResetIsSending] =
    useState(false);

  const modalDispatch = useContext(DispatchMessageContext);

  const handlePasswordReset = async () => {
    //Send a password reset email to current user!
    setSendPasswordResetIsSending(true);
    try {
      const pwReset = await resetPassword();
      if (pwReset.success) {
        setSendPasswordReset(true);
        setPasswordResetModal(true);
        modalDispatch({ type: "show_modal", payload: true }); // Set showModal to true
      } else {
        setSendPasswordResetIsSending(false);
        modalDispatch({ type: "show_modal", payload: false }); // Set showModal to true
      }
    } catch (err) {
      errorDispatch({ type: "set_error", payload: err });
    }
  };

  //handle delete user
  const handleDelete = async () => {
    try {
      const deleted = await deleteAccount();
      modalDispatch({ type: "show_modal", payload: true }); // Set showModal to true
      if (deleted.success) logout();
    } catch (err) {
      errorDispatch({ type: "set_error", payload: JSON.stringify(err) });
      modalDispatch({ type: "show_modal", payload: false }); // Set showModal to true
    }
  };

  //submit language update
  const submitUserForm = async (values, setSubmitting) => {
    try {
      const request = { body: { ...values } };
      const submit = await updateRecruiter(request);
      if (submit.success) {
        i18n.changeLanguage(values.language);
        userDispatch({
          type: "userUpdate",
          payload: submit.data,
        });
      } else {
        errorDispatch({
          type: "set_error",
          payload: submit.data.data,
        });
      }
    } catch (err) {
      errorDispatch({
        type: "set_error",
        payload: JSON.stringify(err),
      });
    }
  };

  return (
    <>
      <SettingsBlockWrapper>
        <SettingsBlockHeading>
          <Typography tag="p" nomargin mb="5" bold>
            {t("common.account")}
          </Typography>
          <Typography tag="p" large lowOpacity nomargin>
            {t("common.fillPersonalInformation")}
          </Typography>
        </SettingsBlockHeading>
        <SettingsBlockContent>
          {user && (
            <>
              <Formik
                initialValues={{
                  ...user,
                  language: user.language,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  submitUserForm(values, setSubmitting);
                }}
                innerRef={ref}
              >
                {(props) => {
                  const { values, isSubmitting, setFieldValue, handleSubmit } =
                    props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row mr="0">
                        <Col size={12} break="sm" smallerInnerGutter prMD="23">
                          <LanguageSelect
                            value={values?.language}
                            label={false}
                            name="language"
                            onChange={(selectedLanguage) => {
                              setFieldValue("language", selectedLanguage); // Update Formik field
                            }}
                          />
                        </Col>
                      </Row>
                      <Row mr="0">
                        <Col size={12} break="sm" smallerInnerGutter>
                          <Field
                            as={InputFieldWithLabel}
                            tiny
                            label={false}
                            value={values?.firstName || ""}
                            placeholder={t("common.first_name")}
                            name="firstName"
                          />
                        </Col>

                        <Col size={12} break="sm" smallerInnerGutter>
                          <Field
                            as={InputFieldWithLabel}
                            tiny
                            label={false}
                            value={values?.lastName || ""}
                            placeholder={t("common.last_name")}
                            name="lastName"
                          />
                        </Col>
                      </Row>
                      <Row mr="0">
                        <Col size={12} break="sm" smallerInnerGutter>
                          <Field
                            as={InputFieldWithLabel}
                            tiny
                            label={false}
                            value={values?.phoneNumber || ""}
                            placeholder={t("common.phone_number")}
                            name="phoneNumber"
                          />
                        </Col>
                        <Col size={12} break="sm" smallerInnerGutter>
                          <Field
                            as={InputFieldWithLabel}
                            tiny
                            disabled
                            label={false}
                            value={values?.email || ""}
                            placeholder={t("common.email")}
                            name="email"
                          />
                        </Col>
                      </Row>
                      <Row mt="15">
                        <Col size={12} break="sm" smallerInnerGutter>
                          <ToggleSwitch
                            text={t(
                              "page.account.notify_when_application_submitted"
                            )}
                            active={values?.notifyMe}
                            onClick={() =>
                              setFieldValue("notifyMe", !values.notifyMe)
                            }
                          />
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </SettingsBlockContent>
      </SettingsBlockWrapper>
      <Row paddingSM="20px 16px 176px 16px" pbLG="110">
        <Flex flexWrap gapAll="15">
          <PrimaryButton
            transparent
            disabled={sendPasswordResetIsSending}
            onClick={handlePasswordReset}
          >
            {sendPasswordReset
              ? t("page.account.sent_email")
              : t("page.account.reset_password")}
            <Icon icon="lock" mr="10" />
          </PrimaryButton>
          <PrimaryButton
            white
            onClick={() => {
              setDeleteAccountModal(true);
              modalDispatch({ type: "show_modal", payload: true }); // Set showModal to true
            }}
          >
            {t("page.account.delete_account")}
            <Icon icon="trash" mr="10" />
          </PrimaryButton>
        </Flex>
      </Row>
      {passwordResetModal && (
        <Modal
          title={t("page.account.reset_password")}
          onClose={() => {
            setPasswordResetModal(false);
            modalDispatch({ type: "show_modal", payload: false }); // Set showModal to false
          }}
        >
          <Grid padding={32} alignCenter fluid>
            <Typography tag="p" nomargin>
              {t("common.resetPasswordConfirmation")}
            </Typography>
            <PrimaryButton
              red
              mt="25"
              onClick={() => {
                setPasswordResetModal(false);
                modalDispatch({ type: "show_modal", payload: false }); // Set showModal to false
              }}
            >
              {t("common.great")}
              <Icon icon="thumbs_up" mr="10" />
            </PrimaryButton>
          </Grid>
        </Modal>
      )}
      {deleteAccountModal && (
        <Modal
          title={t("common.deleteAccountModalTitle")}
          onClose={() => {
            setDeleteAccountModal(false);
            modalDispatch({ type: "show_modal", payload: false }); // Set showModal to false
          }}
        >
          <Grid padding={32} alignCenter fluid>
            <Typography tag="p" nomargin>
              {t("common.deleteAccountModalDescription")}
            </Typography>
            <PrimaryButton red mt="25" onClick={handleDelete}>
              {t("common.yesValidation")}
              <Icon icon="checkmark" mr="10" scale={2.5} />
            </PrimaryButton>
            <PrimaryButton
              transparent
              mt="10"
              onClick={() => {
                setDeleteAccountModal(false);
                modalDispatch({ type: "show_modal", payload: false }); // Set showModal to false
              }}
            >
              {t("common.cancel")}
              <Icon icon="cross" mr="10" />
            </PrimaryButton>
          </Grid>
        </Modal>
      )}
    </>
  );
});

export default AccountSettings;
