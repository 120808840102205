import React from "react";
import styled from "styled-components/macro";

const StatusItem = ({ label, value, green, red, page }) => {
  return (
    <StyledStatusItem page={page}>
      <p>{label}</p>
      <p className="status">
        {value ? value : "N/A"}
      </p>
    </StyledStatusItem>
  );
};

export default StatusItem;

export const StyledStatusItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  p {
    font-size: 12px; 
    color: ${props => props.theme.colors.faceliftBlue};
    &.status {
      color: ${props => props.theme.colors.faceliftBlueLight}; 
    }
  }
  ${props => props.page && `
  p {
  font-size: 14px; 
  }
  `}
`;
