import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../Accordion';
import { ApplicationParamsContext } from '../ApplicationContextWrapper';

const STATUS_KEYS = [
  [
    'submitted',
    'component.application_status_list.submitted',
    'Submitted',
  ],
  ['invited', 'component.application_status_list.invited', 'Invited'],
  [
    'rejected',
    'component.application_status_list.rejected',
    'Rejected',
  ],
];
const SUBSTATUS_KEYS = [
  [
    'interview',
    'component.application_status_list.interview',
    'Interview',
  ],
  ['hired', 'component.application_status_list.hired', 'Hired'],
  [
    'to_be_rejected',
    'component.application_status_list.to_be_rejected',
    'To be rejected',
  ],
];

const ApplicationFilterStatus = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ApplicationParamsContext);

  const handleClick = (e) => {
    // can be status, substatus or shortlist
    const isStatusKey =
      STATUS_KEYS.map((key) => key[0]).indexOf(e.target.id) > -1;
    const isSubStatusKey =
      SUBSTATUS_KEYS.map((key) => key[0]).indexOf(e.target.id) > -1;

    const isAll = e.target.id === 'all';
    const isShortlisted = e.target.id === 'shortlisted';

    let key;
    let val;

    if (isStatusKey) {
      key = 'status';
      val = e.target.value;
      dispatch({
        type: 'update_params',
        payload: { ['substatus']: null },
      });
    } else if (isSubStatusKey) {
      key = 'substatus';
      val = e.target.value;
      dispatch({
        type: 'update_params',
        payload: { ['status']: null },
      });
    } else if (isShortlisted) {
      key = 'shortlist';
      val = true;
    } else if (isAll) {
      key = 'substatus';
      val = null;
      dispatch({
        type: 'update_params',
        payload: { ['status']: null },
      });
    }
    dispatch({
      type: 'update_params',
      payload: { [key]: val },
    });
  };
  return (
    <Accordion
      title={t('component.filter.status', 'Application status')}
      initiallyOpen={false}
    >
      <div>
        <label key={'all'} htmlFor={'all'}>
          <input
            type="radio"
            checked={
              !state.params['status'] && !state.params['substatus']
            }
            id={'all'}
            value={null}
            name="status"
            onChange={handleClick}
          />
          <span>{t('common.all', 'All')}</span>
        </label>
        {STATUS_KEYS.map(([key, t_key, t_key_fallback]) => {
          return (
            <label key={key} htmlFor={key}>
              <input
                type="radio"
                checked={state.params['status'] === key}
                id={key}
                value={key}
                name="status"
                onChange={handleClick}
              />
              <span>{t(t_key, t_key_fallback)}</span>
            </label>
          );
        })}
        {SUBSTATUS_KEYS.map(([key, t_key, t_key_fallback]) => {
          return (
            <label key={key} htmlFor={key}>
              <input
                type="radio"
                checked={state.params['substatus'] === key}
                id={key}
                value={key}
                name="status"
                onChange={handleClick}
              />
              <span>{t(t_key, t_key_fallback)}</span>
            </label>
          );
        })}
      </div>
    </Accordion>
  );
};

export default ApplicationFilterStatus;
