import React from "react";
import styled from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { InputLabel, LabelByline, InputGroup } from "../Input/Input";
import { Typography } from "../Typography";

const FileDrop = ({
  label,
  byline,
  required,
  max,
  accept,
  setFieldValue,
  setFieldTouched,
  name,
  message,
  saveBlob,
  children
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: max || 1,
    accept: accept,
    onDrop: (acceptedFiles) => {
      setFieldTouched(name, true);
      if (max) {
        //an array of files
        if ( saveBlob ) saveBlob(name, acceptedFiles);
        setFieldValue(name, acceptedFiles);
      } else {
        //only one file
        if ( saveBlob ) saveBlob(name, acceptedFiles[0]);
        setFieldValue(name, acceptedFiles[0]);
      }
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));
// -- -- - - 
  return (
    <InputGroup
      onClick={() => {
        setFieldTouched(name, true);
      }}
    >
      <InputLabel required={required} byline={byline}>
        {label} {byline && <LabelByline>{byline}</LabelByline>}
      </InputLabel>
      <StyledDropContainer {...getRootProps({ className: "dropzone" })}>
        <input
          {...getInputProps()}
        />
        <Typography tag="p" nomargin center>
          {files.length > 0 ? files : message}
        </Typography>
      </StyledDropContainer>
    </InputGroup>
  );
};

const StyledDropContainer = styled.div`
  border: 1px dashed ${(props) => props.theme.colors.lgrey};
  padding: 25px 50px;
  border-radius: 5px;
  cursor: pointer;
`;

export default FileDrop;
