import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

/**
 * Parse a file in textkernel parser ( submit fileUuid )
 * @param {object} request { params : { fileUuid : THEUUID  }}
 * @returns {object} response object
 */
export const pdfParserUpload = async (request = {}) => {
  const { params } = parseRequest(request);
  try {
    const data = await axios.post(`/parser/${params}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

/**
 * Upload file to tmp endpoint - returns file instance error handling is done outside this function
 * @param {object} request containing file : File
 * @returns {object} return file instance or error message
 */
export const tmpFileUpload = async (request = {}, onProgress, controller) => {
  const { file, type, docType } = parseRequest(request);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  formData.append("docType", docType);

  const axiosParams = {
    method: "post",
    url: "/tmpFiles",
    data: formData,
    headers: {
      ...axios.defaults.headers.common,
      "Content-Type": `multipart/form-data;`,
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      onProgress((loaded / total) * 100);
    },
    signal: controller.signal,
  };
  const response = await axios(axiosParams);
  return responseHandler(response);
};
