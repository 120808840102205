import React from 'react';
import styled from "styled-components/macro";

const StyledModalBackground = styled.div`
    background: ${props=>props.theme.colors.vdgrey};
    position: fixed;
    left: 0; top: 0; width: 100vw; height: 100vh;
    z-index: 10;
`;

const ModalBackground = ({ clickHandler }) => {
    return (
        <StyledModalBackground onClick={ clickHandler }/>
    )
}

export default ModalBackground
