import styled from "styled-components/macro";

export const AddedFields = styled.div`
    width: fit-content;
    background-color: #F3F3F5;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 12px;
    color: ${props => props.theme.colors.faceliftBlueLight};
    display: flex;
    border: 1px solid rgba(24, 31, 56, 0.15);
    cursor: pointer; 
    transition: .5s ease; 
    &:hover {
        background-color: transparent; 
        border: 1px solid ${props => props.theme.colors.red}; 
        transition: .5s ease; 
    }
`


export const AddedFieldsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
`