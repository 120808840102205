// React
import React, { useContext, useEffect, useState, useRef } from "react";

// Hooks - API endpoints - router
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useFetchPages } from "../../../components/cp/useFetchPages";
import { useFetchSites } from "../../../components/cp/useFetchSites";
import { pageUrl } from "../../../../utils/redirectUrls";
import { modifyPage } from "../../../components/cp/SinglePage/QuillEditor/utils";
import { updatePage } from "../../../../api/endpoints/cp";

// Components
import SinglePage from "../../../components/cp/SinglePage/SinglePage";
import {
  StyledTopBar,
  TopBarTop,
  TopBarBottomContent,
  TopBarBottom,
} from "../../../components/TopBar/style";
import { Flex } from "../../../style";
import PositionPublish from "../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PublishMG/PositionPublish";
import { Bar } from "../../Position/EditPositionView/EditPositionView.styled";
import { Icon } from "../../../../components";
import { StyledGotoUrl } from "../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PublishMG/style";
import { PrimaryButton } from "../../../../components/Button";
import SiteNav from "../../../components/cp/SiteNav/SiteNav";
import ButtonLoading from "../../../../components/Loader/ButtonLoading";

// State management
import { PageContext } from "../../../context/PageContext";
import { StateOrganizationContext } from "../../../context/OrganizationContext";
import { Typography } from "../../../../components/Typography";
import {
  PageLeftColumn,
  PageViewSettings,
  PageViewWrapper,
} from "./PageView.styled";
import { Grid } from "../../../../components/Grid";
import PageMetaEditor from "../PageMetaEditor/PageMetaEditor";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { StateMessageContext } from "../../../../context/MessageContext";

const PageView = () => {
  const { t } = useTranslation();
  const { language } = useParams(); // Get the language parameter from the route
  // const { state } = useContext(PageContext);
  const { pages } = useFetchPages({});
  const [mainPages, setMainPages] = useState([]); // Use state to manage mainPages
  const [frontPages, setFrontPages] = useState([]); // Use state to manage mainPages
  const { state, dispatch } = useContext(PageContext);
  const { organization } = useContext(StateOrganizationContext);
  const { activeSite } = useFetchSites(state.page.siteId);
  const brandingSettingsRef = useRef(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [successfullyUpdated, setSuccesfullyUpdated] = useState(false);

  const modalState = useContext(StateMessageContext);

  useEffect(() => {
    // Is main page
    const filteredPages = pages.filter(
      (page) => page.frontpage && page.site.language === language
    );
    setMainPages(filteredPages); // Update mainPages state

    const frontPages = pages.filter((fpage) => fpage.frontpage);
    setFrontPages(frontPages); // Update frontPages state
  }, [language, pages]);

  const saveExcerpt = async () => {
    // dispatch({ type: 'init_page' });

    try {
      // ... Other validation and preparations ...

      // Modify this block to include updating the excerpt
      const modifiedPage = {
        ...state.page,
        description: state.page.description, // Update with the new excerpt value
      };

      const modifiedRequestObject = modifyPage(modifiedPage);

      const request = {
        selector: state.page.id,
        body: { ...modifiedRequestObject },
      };

      const response = await updatePage(request);

      if (!response.success) throw new Error(response.data.data);
      dispatch({ type: "page_loaded", payload: response.data });
    } catch (err) {
      setConfirmationError(true);
      setUpdateLoading(false);
      // errorDispatch({ type: 'set_error', payload: err.message });
      // dispatch({ type: 'error' });
      console.log(err);
    }
  };

  // Branding Settings function callback
  // Function to handle persisting branding state
  const handlePersistBranding = async () => {
    if (brandingSettingsRef.current) {
      setUpdateLoading(true);
      await brandingSettingsRef.current.persistBrandingState();
      await brandingSettingsRef.current.updateSelectedLanguage(); // Update with the desired language
      setUpdateLoading(false);
      setSuccesfullyUpdated(true);
    }
  };

  return (
    <>
      <ButtonLoading
        error={confirmationError}
        loading={updateLoading}
        success={successfullyUpdated}
      />
      <Grid scrollable fluid paddingBottom="75">
        <div style={{ width: "100%" }}>
          <StyledTopBar>
            <TopBarTop>
              <Typography tag="h1" customSize="20">
                {mainPages[0]?.title}
              </Typography>
            </TopBarTop>
            <TopBarBottom>
              <TopBarBottomContent page>
                <Flex widthSM="100%" justify="space-between" height="100%">
                  {/* <SelectLanguagePosition /> */}
                  <div style={{ height: "100%", display: "flex" }}>
                    <SiteNav sites={frontPages} />
                  </div>
                  <Flex widthSM="fit-content">
                    {/* FIX THIS POSITION PUBLISH TO WORK WITH CP */}
                    <PositionPublish />
                  </Flex>
                </Flex>
              </TopBarBottomContent>
            </TopBarBottom>
          </StyledTopBar>
          <PageViewWrapper>
            <Flex
              flexDirection="column"
              flexDirectionLG="row"
              align="flex-start"
            >
              <PageLeftColumn>
                <Bar mtSM="12" mbSM="12" cp hide={modalState.showModal}>
                  <Link to="/position">
                    <Flex>
                      <Icon icon="arrow_left_small" />
                      <Typography tag="p" large lowOpacity nomargin noWrap>
                        {t("page.position.back")}
                      </Typography>
                    </Flex>
                  </Link>
                  <Flex justify="flex-end">
                    <StyledGotoUrl
                      position="relative"
                      target="_blank"
                      rel="noreferrer"
                      publish={state?.page?.status === "publish"}
                      href={
                        state?.page?.status === "publish"
                          ? pageUrl(
                              { ...state.page, site: activeSite },
                              organization
                            )
                          : null
                      }
                      onClick={
                        state?.page?.status === "publish"
                          ? null
                          : () => {
                              return false;
                            }
                      }
                    >
                      <PrimaryButton transparent hideOnMobile>
                        {t("component.metaeditor.view_page", "View page")}
                        <Icon icon="preview" mr="10" $filter />
                      </PrimaryButton>
                    </StyledGotoUrl>
                    <PrimaryButton
                      red
                      ml="15"
                      onClick={() => {
                        // Save excerpt, language, and branding settings here
                        saveExcerpt(); // Function to save excerpt changes
                        handlePersistBranding();
                      }}
                    >
                      {t("common.update", "Update")}
                      <Icon icon="update" mr="10" />
                    </PrimaryButton>
                  </Flex>
                </Bar>
                <SinglePage
                  pageId={mainPages[0]?.id}
                  context="page"
                  cp
                  sites={frontPages}
                />
              </PageLeftColumn>
              <PageViewSettings hide={modalState.showModal}>
                <Typography tag="h1" customSize="20" mb="25">
                  {t("common.settings")}
                </Typography>
                <PageMetaEditor
                  site={mainPages[0]}
                  brandingSettingsRef={brandingSettingsRef}
                />
              </PageViewSettings>
            </Flex>
          </PageViewWrapper>
        </div>
      </Grid>
    </>
  );
};

export default PageView;
