import styled from "styled-components/macro";

export const ManageUsersWrapper = styled.div`
background-color: #f3f3f5;
padding: 25px; 
height: auto; 
min-height: calc(100% - 70px);
animation: fadeIn .5s ease forwards; 
@keyframes fadeIn {
    0% {
        opacity: 0; 
    }
    100% {
        opacity: 1; 
    }
}
@media (max-width: 1200px) {
    overflow: scroll;
    height: calc(100vh - 195px);
    padding-bottom: 50px;
    padding-top: 50px;
}

`


export const UsersWrapper = styled.div`

`

export const NeedHelpWrapper = styled.div`
display: flex; 
justify-content: center;
align-items: center;
padding-bottom: 50px; 
@media (min-width: 1200px) {
    width: calc(100% - 350px); 
    margin-left: auto;
    margin-top: 25px; 
}
`

export const ManageUsersDescription = styled.div`
h2 {
    font-weight: 500; 
    margin-bottom: 10px;
    font-size: 14px; 
}
p {
    font-size: 13px; 
    margin-bottom : 25px;
}
@media (min-width: 1200px) {
    max-width: 350px;
    padding-right: 50px; 
    margin-bottom: 80px;
}
`

export const Scrollable = styled.div`
height: calc(100% - 70px);
overflow: scroll; 
&::-webkit-scrollbar {
              display: none;
              width: 0 !important
            }
            -ms-overflow-style: none;
  scrollbar-width: none;
`