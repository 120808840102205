import styled from 'styled-components/macro';

const fontColor = (props, defaultColor) => {
  if (props.highlight) return props.theme.colors.highlight;
  if (props.light) return props.theme.colors.faceliftBlueLight;
  if (props.mlight || props.colorMute)
    return props.theme.colors.mgrey;
  return defaultColor || props.theme.colors.faceliftBlue;
};

const specialMargins = (props) => {
  let margins = '';
  if (props.ml) {
    margins += `margin-left: ${props.ml ? `${props.ml}px` : 'unset'
      };`;
  }
  if (props.mr) {
    margins += `margin-right: ${props.mr ? `${props.mr}px` : 'unset'
      };`;
  }
  if (props.mt) {
    margins += `margin-top: ${props.mt ? `${props.mt}px` : 'unset'};`;
  }
  if (props.mb) {
    margins += `margin-bottom: ${props.mb ? `${props.mb}px` : 'unset'
      };`;
  }
  return margins;
};

export const ButtonLike = styled.div`
  ${(props) => specialMargins(props)}
  display: inline-block;
  background-color: ${(props) => props.theme.colors.secondary};
  border: 1px solid #ebf3ff;
  padding: 10px 40px;
  border-radius: 150px;
  & + * {
    margin-left: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 4px 16px;
  }
`;

export const StyledH1 = styled.h1`
  margin: 0;
  ${(props) => specialMargins(props)}
  font-size: ${(props) =>
    props.customSize ? props.customSize : '28'}px;
  line-height: ${(props) =>
    props.customLineHeight ? props.customLineHeight : 'normal'};
  font-weight: ${(props) => (props.bold ? '600 !important;' : '400 !important;')};
  color: ${props => props.color ? props.color : fontColor(props)};
  ${props => props.light ? `color: white` : null}
  ${props => props.capitalize ? `text-transform: capitalize` : null};
  ${props => props.mb ? `margin-bottom: ${props.mb}px;` : null}  
  ${props => props.isAnonymous && `
  filter: blur(5px); 
  `}
`;

export const StyledH3 = styled.h3`
  position: relative;
  margin-top: ${(props) => {
    if (props.nomargin) return '0px';
    return '30px';
  }};
  margin-bottom: ${(props) => {
    if (props.nomargin) return '0px';
    if (props.underline) return '24px';
    return '18px';
  }};
  ${(props) => specialMargins(props)}
  font-size: 18px;
  & > strong {
    font-size: 18px;
  }
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  text-align: ${(props) => props.center ? 'center' : props.right ? 'right' : 'left'};
  color: ${(props) => fontColor(props)};
  ${props => props.light ? `color: white` : null}

  ${(props) =>
    props.underline
      ? ` &:after {
      content: "";
      position: absolute;
      bottom: -10px; left: 0;
      display: ${props.underline ? 'block' : 'none'};
      width: 50px; height: 1px;
      background: ${props.theme.colors.mgrey};
    } `
      : ``}

  ${(props) =>
    props.lineThrough
      ? `
      overflow:hidden;
      width: 100%;
      &:after {
      content: "";
      display: inline-block;
      height: 12px;
      vertical-align: bottom;
      width: 100%;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 1px solid ${props.theme.colors.lgrey};
      } &:before {
      content: "";
      display: inline-block;
      height: 12px;
      vertical-align: bottom;
      width: 100%;
      margin-left: -100%;
      border-top: 1px solid ${props.theme.colors.lgrey};
    } `
      : ``}

`;
export const StyledP = styled.p`
  ${(props) => specialMargins(props)}
  font-size: ${(props) =>
    props.small
      ? props.theme.fonts.smallSize
      : props.large
        ? props.theme.fonts.labelSize
        : props.larger
          ? props.theme.fonts.largerSize
          : props.theme.fonts.defaultSize};
  line-height: 20px;
  font-weight: ${(props) => (props.bold ? '500 !important;' : '400 !important;')};
  text-align: ${(props) =>
    props.center ? 'center' : props.right ? 'right' : 'left'};
  margin-bottom: ${(props) => {
    if (props.nomargin || props.mb0) return '0px';
    if (props.m10 || props.mb10) return '10px';
    if (props.m4 || props.mb4) return '4px';
    return '20px';
  }};
  ${(props) => (props.light ? 'color: white;' : null)}
  color: ${(props) => fontColor(props)};
  ${props => props.color ? `color: ${props.color};` : null}
  white-space: normal;
  ${props => props.pointer ? `cursor: pointer` : null}; 
  ${props => props.lowOpacity ? 'opacity: 0.6;' : null}; 
  ${props => props.mt ? `margin-top: ${props.mt}px;` : null};
  ${props => props.noWrap ? `white-space: nowrap` : null};
  ${props => props.mb ? `margin-bottom: ${props.mb}px;` : null};
  ${props => props.lowercase ? `text-transform: lowercase` : null}; 
  ${props => props.uppercase ? 'text-transform: uppercase;' : null}
  ${props => props.letterSpaced ? 'letter-spacing: 1px;' : null}
  ${props => props.mw ? `max-width: ${props.mw}px` : null}; 
  ${props => props.fontSize ? `font-size: ${props.fontSize}px` : null};  
  ${props => props.lineHeight ? `line-height: ${props.lineHeight}px` : null};
  ${props => props.mr ? `margin-right: ${props.mr}px` : null}; 
  ${props => props.ml ? `margin-left: ${props.ml}px !important` : null};  
  ${props => props.height ? `height: ${props.height}px` : null};  
  ${props => props.width ? `width: ${props.width}px` : null};  
  ${props => props.fontWeight ? `font-weight: ${props.fontWeight} !important;` : null};
  ${props => props.hover ? `
  border-bottom: 1px solid transparent; 
  &:hover {
  border-bottom: 1px solid rgba(24, 31, 56, 0.6);
  }
  ` : null} 
  @media (min-width: 992px) {
  ${props => props.mbLG ? `margin-bottom: ${props.mbLG}px` : null};
  ${props => props.fontWeightLG ? `font-weight: ${props.fontWeightLG} !important;` : null}; 
  }  
  @media (min-width: 1200px) {
    ${props => props.mbXL ? `margin-bottom: ${props.mbXL}px` : null}; 
    ${props => props.mtLG ? `margin-top: ${props.mtLG}px !important;` : null}; 
    ${props => props.fontSizeLG ? `font-size: ${props.fontSizeLG}px` : null}; 
  }
  @media (min-width: 1600px) {
    ${props => props.fontSizeXL ? `font-size: ${props.fontSizeXL}px` : null}; 
    ${props => props.lineHeightXL ? `line-height: ${props.lineHeightXL}px` : null}; 
    ${props => props.mtXL ? `margin-top: ${props.mtXL}px` : null};   
  }
`;

export const StyledSpan = styled.span`
  ${(props) => specialMargins(props)}
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  color: ${(props) => (props.inherit ? 'inherit' : fontColor(props))};
  font-size: inherit;
  ${(props) => (props.zeroOpacity ? 'opacity: 0;' : '')}
`;

export const StyledErrorMessage = styled.div`
  ${(props) => specialMargins(props)}
  color: ${(props) => props.theme.colors.highlight};
  margin: 12px 0px;
`;

export const StyledUnOrderedList = styled.ul`
  margin-left: 12px;
`;

export const StyledListItem = styled.li`
  font-size: ${(props) =>
    props.small
      ? props.theme.fonts.smallSize
      : props.theme.fonts.defaultSize};
  list-style: disc;
  list-style-position: outside;
  margin: ${(props) => (props.small ? '6px' : '9px')} 0;
`;

export const StyledLink = styled.a`
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  font-size: ${(props) => props.theme.fonts.defaultSize};
  ${(props) => specialMargins(props)}
  ${(props) =>
    props.colorMute
      ? `
    color: ${props.theme.colors.mgrey};
    &:hover {
      color: ${props.theme.colors.highlight};
    }
    &.active, &.active * {
      color: ${props.theme.colors.highlight};
    }
    `
      : ``}

    ${(props) =>
    props.colorDark
      ? `
    color: ${props.theme.colors.dgrey};
    &:hover {
      color: ${props.theme.colors.highlight};
    }
    &.active, &.active * {
      color: ${props.theme.colors.highlight};
    }
    `
      : ``}

    ${(props) =>
    props.button
      ? `
      padding: 10px 16px;
      border-radius: 40px;
      color: ${props.theme.colors.dgrey};
      background : ${props.theme.colors.primary};
      ${props.useStyleVars &&
      `
        color : var(--primary-color);
        background : var(--primary-background-color)
      `
      }
    `
      : ``}
`;

export const StyledUl = styled.ul`
  margin: 0;
  color: ${(props) => props.theme.colors.dgrey};
  margin-left: 0;
  padding-left: 2em;
  list-style-type: none;
  & > li {
    margin: 8px 0px;
    text-indent: 0.5em;
    &:before {
      content: '-';
      margin-right: 1em;
      margin-left: -2em;
    }
  }
`;

export const Typography = ({ tag, children, ...rest }) => {
  switch (tag) {
    case 'buttonLike':
      return <ButtonLike {...rest}>{children}</ButtonLike>;
    case 'h1':
      return <StyledH1 {...rest}>{children}</StyledH1>;
    case 'h3':
      return <StyledH3 {...rest}>{children}</StyledH3>;
    case 'p':
      return <StyledP {...rest}>{children}</StyledP>;
    case 'a':
      return <StyledLink {...rest}>{children}</StyledLink>;
    case 'small':
      return (
        <StyledP {...rest} small>
          {children}
        </StyledP>
      );
    case 'hiddenFiller':
      return (
        <StyledSpan zeroOpacity {...rest}>
          hidden
        </StyledSpan>
      );
    case 'span':
      return <StyledSpan {...rest}>{children}</StyledSpan>;
    default:
      return <>{children}</>;
  }
};
