import React from "react";
import styled from "styled-components/macro";
import { Typography } from "../Typography";
const BoxLoader = ({ message, expand, tiny, left }) => {
  return (
    <StyledLoader expand={expand} className="box-loader" left={left}>
      <Ring tiny={tiny} />
      {!tiny && <Typography tag="h3">{message}</Typography>}
    </StyledLoader>
  );
};
export default BoxLoader;

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => props.left ? "left" : "center"};
  justify-content: center;
  align-items: ${props => props.left ? "flex-start" : "center"};
  height: ${(props) => (props.expand ? "100%" : "auto")};
  > * {
    color: ${(props) => props.theme.colors.mgrey};
  }
`;


const Ring = styled.div`
  display: inline-block;
  width: ${(props) => (props.tiny ? "20px" : "80px")};
  height: ${(props) => (props.tiny ? "20px" : "80px")};
  margin: ${(props) => (props.tiny ? "0" : "20px")};
  &:after {
    content: " ";
    display: block;
    width: ${(props) => (props.tiny ? "20px" : "64px")};
    height: ${(props) => (props.tiny ? "20px" : "64px")};
    margin: ${(props) => (props.tiny ? "0" : "8px")};
    /* margin: 8px; */
    border-radius: 50%;
    border: ${(props) => (props.tiny ? "2px" : "6px")} solid
      ${(props) => props.theme.colors.mgrey};
    border-color: ${(props) => props.theme.colors.mgrey} transparent
      ${(props) => props.theme.colors.mgrey} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
