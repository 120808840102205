import React, { createContext, useReducer } from "react";
import { GlobalErrorMessage } from "../components";

export const StateErrorContext = createContext();
export const DispatchErrorContext = createContext();

const errorReducer = (state, action) => {
  switch (action.type) {
    case "set_error": {
      return {
        ...state,
        hasError: true,
        error: action.payload,
      };
    }
    case "clear_error": {
      return {
        ...state,
        hasError: false,
        error: "",
      }
    }
    default : {
      return { ...state }
    }
  }
};

export const ErrorProvider = ({ children }) => {
  const errorInitialState = {
    hasError: false,
    error: "",
  };

  const [state, dispatch] = useReducer(errorReducer, errorInitialState);
  
  return (
    <DispatchErrorContext.Provider value={dispatch}>
      <StateErrorContext.Provider value={state}>
        { state.hasError && <GlobalErrorMessage>{state.error || "An error happened."}</GlobalErrorMessage> }
        {children}
      </StateErrorContext.Provider>
    </DispatchErrorContext.Provider>
  );
};
