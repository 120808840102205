// React
import React, { useContext, useState } from "react";

// Translations, hooks, and API endpoints
import { useTranslation } from "react-i18next";
import { destroyPage } from "../../../../../api/endpoints/cp";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useParams } from "react-router";
import { deletePosition } from "../../../../../api/endpoints/position";

// Components
import { BoxLoader, Icon } from "../../../../../components";
import Block from "../Block/Block";
import { StyledBlockEditor } from "./BlockEditor.styled";
import { PrimaryButton } from "../../../../../components/Button";
import { Modal } from "../../../../../components";

// State management
import { PageContext } from "../../../../context/PageContext";
import { DispatchErrorContext } from "../../../../../context/ErrorContext";
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../../../context/PositionContext";
import { DispatchMessageContext } from "../../../../../context/MessageContext";
import { Typography } from "../../../../../components/Typography";

const BlockEditor = ({ cp, loading }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(PageContext);
  const { single } = useContext(StatePositionContext);
  const positionDispatch = useContext(DispatchPositionContext);
  const queryClient = useQueryClient();
  const errorDispatch = useContext(DispatchErrorContext);
  const history = useHistory();
  let { id } = useParams();
  const modalDispatch = useContext(DispatchMessageContext);

  const moveBlock = (dragIndex, hoverIndex) => {
    dispatch({ type: "drag_block", payload: [dragIndex, hoverIndex] });
  };

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteHandler = async () => {
    // dispatch({ type: 'init_page' });

    try {
      setIsLoading(true);
      const deletePageResponse = await destroyPage({ selector: state.page.id });

      if (deletePageResponse.success) {
        const deletePositionResponse = await deletePosition({ selector: id });

        if (deletePositionResponse.success) {
          queryClient.invalidateQueries(["pages"]);
          setIsLoading(false);
          dispatch({ type: "clear_state" });
          positionDispatch({ type: "add_page", payload: null });

          // Redirect to the "positions" page
          history.push("/"); // Update the URL as needed
        } else {
          setIsLoading(false);
          throw new Error(
            deletePositionResponse.data?.data || "Error deleting position"
          );
        }
      } else {
        setIsLoading(false);
        throw new Error(deletePageResponse.data?.data || "Error deleting page");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      errorDispatch({ type: "set_error", payload: err.message });
    }
  };

  return (
    <StyledBlockEditor isLoaded={!loading}>
      {state.page?.blocks &&
        !state.isLoading &&
        state.page.blocks.map((block, index) => (
          <Block
            index={index}
            key={block.id}
            block={block}
            moveBlock={moveBlock}
          />
        ))}
      {!cp && (
        <>
          <PrimaryButton
            onClick={() => {
              setModal(true);
              modalDispatch({ type: "show_modal", payload: true }); // Set showModal to true
            }}
            transparent
            center
            mt="40"
          >
            {t("common.deletePosition")}
            <Icon icon="trash" mr="10" />
          </PrimaryButton>
          {modal && (
            <Modal
              title={t("common.deleteModalTitle")}
              onClose={() => {
                setModal(false);
                modalDispatch({ type: "show_modal", payload: false });
              }}
            >
              <Typography mw="525" tag="p" center mt="25">
                {t("common.deleteModalDescription_1")} {single?.title}{" "}
                {t("common.deleteModalDescription_2")}
              </Typography>
              <PrimaryButton
                red
                mt="20"
                onClick={isLoading ? null : () => onDeleteHandler()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <BoxLoader tiny />
                ) : (
                  <>
                    {t("common.yesValidation")}
                    <Icon icon="checkmark_small" scale="1.5" ml="0" mr="10" />
                  </>
                )}
              </PrimaryButton>
              <PrimaryButton
                transparent
                mt="10"
                mb="15"
                onClick={() => {
                  setModal(false);
                  modalDispatch({ type: "show_modal", payload: false });
                }}
              >
                {t("common.cancel")}
                <Icon icon="cross" scale="0.8" ml="0" mr="10" />
              </PrimaryButton>
            </Modal>
          )}
        </>
      )}
    </StyledBlockEditor>
  );
};

export default BlockEditor;
