import React from "react";
import { CVAbout } from "./CV.styled.";
import CVExperience from "./CVExperience";

export const NON_ATTACHED_DOC_TYPES = [
  "profileImage",
  "personalityTest",
  "skillTest",
];
export const RECRUITER_FILE_TYPES = ["personalityTest", "skillTest"];

/**
 * Filters out empty experiences
 * @param {object} data
 * @returns filtered object
 */
const parseApplicationData = (data) => {
  let returnData;

  if (data.applicant) {
    returnData = { ...data, ...data.applicant.candidate };
  } else {
    returnData = data;
  }

  //filter out empty experiences
  if (returnData.experience) {
    if (returnData.experience.length > 0) {
      returnData.experience = returnData.experience.filter(
        (exp) => exp.workplace !== ""
      );
      //filter out empty tasks in exp
      returnData.experience.forEach((exp) => {
        exp.tasks = exp.tasks.filter((task) => task);
      });
    }
  }

  //filter out empty education
  if (returnData.education) {
    if (returnData.education.length > 0) {
      returnData.education = returnData.education.filter(
        (exp) => exp.institution !== ""
      );
      //filter out empty tasks in exp
    }
  }

  return returnData;
};

const CurriculumVitae = ({ data }) => {
  const {
    links: { linkedin, other },
    about,
    applicationLanguage,
  } = parseApplicationData(data);

  return (
    <>
      <CVAbout>{about}</CVAbout>
      <CVExperience data={data} applicationLanguage={applicationLanguage} />
    </>
  );
};

export default CurriculumVitae;
