import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { StateUserContext } from '../../context/UserContext';
import { getSite } from '../../api/endpoints/cp';

export const StateSiteContext = createContext();
export const DispatchSiteContext = createContext();

const siteReducer = (state, action) => {
  switch (action.type) {
    case 'init_site': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'site_loaded': {
      return {
        ...state,
        isLoading: false,
        sites: action.payload,
      };
    }
    case 'update_site': {
      return {
        ...state,
        unsaved: false,
        isLoading: false,
        sites: action.payload,
      };
    }
    case 'reload': {
      return {
        ...state,
        unsaved: false,
        reload: !state.reload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const SiteProvider = ({ children }) => {
  //only load branding if authenticated
  const { isAuthenticated } = useContext(StateUserContext);

  const siteInitialState = {
    sites: {},
    isLoading: false,
    reload: false,
    unsaved: false,
  };

  const [state, dispatch] = useReducer(siteReducer, siteInitialState);

  //load organization
  useEffect(() => {
    const initSite = async () => {
      dispatch({ type: 'init_site' });
      try {
        const site = await getSite();
        if (site.success) {
          dispatch({
            type: 'site_loaded',
            payload: {
              ...site.data,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated) {
      initSite();
    }
  }, [isAuthenticated, state.reload]);

  return (
    <DispatchSiteContext.Provider value={dispatch}>
      <StateSiteContext.Provider value={state}>
        {children}
      </StateSiteContext.Provider>
    </DispatchSiteContext.Provider>
  );
};
