import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const Terms = ({ organization }) => {
  const { t } = useTranslation();
  const organization_name = organization.name;
  const organization_privacy_url = organization.privacyPolicyUrl;
  const { shortPrivacyPolicyLabel } = organization;
  const hitalento_privacy_url =
    'https://hitalento.com/privacy-policy/';
  return (
    <StyledTerms>
      {organization_privacy_url && (
        <span style={{ display: 'block', fontWeight: 700 }}>
          <Trans
            i18nKey="application_form.submit_terms_d"
            organization_name={organization_name}
          >
            I have read and accept {{ organization_name }}'s
            <a
              target="_blank"
              href={organization_privacy_url}
              rel="noreferrer"
            >
              privacy policy.
            </a>
          </Trans>
        </span>
      )}
      {(!shortPrivacyPolicyLabel || !organization_privacy_url) &&
        <>
          <Trans
            i18nKey="application_form.submit_terms_a"
            organization_name={organization_name}
          >
            {{ organization_name }} will be storing your data in the
            recruitment system HiTalento for 1 year to manage your
            information regarding job opportunities in{' '}
            {{ organization_name }}. You can view HiTalento’s
            <a
              href={hitalento_privacy_url}
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
            on www.hitalento.com. {{ organization_name }} may store your
            data in a Data Centre inside or outside Europe.
          </Trans>{' '}
          {organization_privacy_url && (
            <Trans
              i18nKey="application_form.submit_terms_b"
              organization_name={organization_name}
            >
              You can view {{ organization_name }}’s
              <a
                target="_blank"
                href={organization_privacy_url}
                rel="noreferrer"
              >
                privacy policy here.
              </a>
            </Trans>
          )}{' '}
          {t('application_form.submit_terms_c', {
            organization_name: organization_name,
          })}
        </>
      }
    </StyledTerms>
  );
};

export default Terms;

const StyledTerms = styled.span`
  font-size: ${(props) => '12px'};
  color: ${(props) => props.theme.colors.dgrey};
  margin-top: -4px;
  margin-left: 16px;
  > a,
  * {
    font-size: inherit;
  }
`;
