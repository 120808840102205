import React from 'react';
import { Route } from 'react-router-dom'; 
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loader from  '../Loader/Splash';

const ProtectedRoute = ({ component, ...args }) => (
    <Route component={ withAuthenticationRequired(component, { onRedirecting: () => <Loader msg="Redirecting you to the login page..." />, })} {...args} />
);


export default ProtectedRoute;