import React, { useEffect, useState, useContext } from "react";
import { StyledOnboarding } from "./style";
import { DispatchUserContext } from "../../../context/UserContext";
import { addOrganization } from "../../../api/endpoints/organization";
import { Button, Icon, InputFieldWithLabel, LanguageSelect } from "../../../components";
import { Row, Col } from "../../../components/Grid";
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { useTranslation, Trans } from "react-i18next";
import { validation } from "./validation";
import { CheckboxWidthLabelNoHtmlFor } from "../../../components/Input/Input";

const Onboarding = () => {
  const { t } = useTranslation();
  const [organizationName, setOrganizationName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [terms, setTerms] = useState(false);
  const [userLanguage, setUserLanguage] = useState("en-US");
  const [canSubmit, setCanSubmit] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useContext(DispatchUserContext);
  const errorDispatch = useContext(DispatchErrorContext);

  const formObject = () => {
    return {
      name: organizationName,
      language: userLanguage,
      phone: phoneNumber,
      terms: terms,
    };
  };

  const handleLanguageChange = (selectedLanguage) => {
    setUserLanguage(selectedLanguage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitOrganizationName = async () => {
      try {
        setIsLoading(true);
        //try yup object before submitting
        await validation(formObject(), t);
        const response = await addOrganization({
          name: organizationName,
          language: userLanguage,
          phone: phoneNumber,
          terms: terms ? 1 : 0,
        });
        const { success, data } = response;

        if (success) {
          //update user state!
          dispatch({
            type: "userCreate",
            payload: {
              organizationId: data.id,
              role: "owner",
              phoneNumber: phoneNumber,
            },
          });

          localStorage.removeItem("organizationName");
          setIsLoading(false);

        } else {
          setIsLoading(false);
          errorDispatch({ type: "set_error", payload: data.data });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        errorDispatch({ type: "set_error", payload: err.message });
      }
    };
    submitOrganizationName();
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "name": {
        setOrganizationName(e.target.value);
        break;
      }
      case "phone": {
        //trim unwanted whitespace before updating state
        setPhoneNumber(e.target.value.trim());
        break;
      }
      case "terms": {
        setTerms(!terms);
        break;
      }
      default: {
      }
    }
  };

  useEffect(() => {
    const checkCanSubmit = async () => {
      try {
        await validation(formObject(), t);
        setCanSubmit(true);
        setIsError(null);
      } catch (err) {
        setCanSubmit(false);
        setIsError(err.message);
      }
    };

    checkCanSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationName, userLanguage, phoneNumber, terms]);

  useEffect(() => {
    setOrganizationName(localStorage.getItem("organizationName") || "");
  }, []);

  useEffect(() => {
    localStorage.setItem("organizationName", organizationName);
  }, [organizationName]);

  return (
    <StyledOnboarding>
      <form onSubmit={handleSubmit} style={{ minWidth: '300px' }}>
        <Row mb={40}>
          <Col>
            <Icon color="#ccc" icon="logo_with_name" justifyStart nomargin />
          </Col>
        </Row>
        <Row>
          <Col center vCenter>
            {isError && isError}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <InputFieldWithLabel
                  label={false}
                  placeholder={t("page.onboarding.organization.placeholder")}
                  id="organizationName"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={organizationName}
                  inverted={true}
                  pb="20"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LanguageSelect
                  value={userLanguage === 'en-US' ? t("component.language.en-US", "English") : t("component.language.da-DK", "Danish")}
                  label={false}
                  name="language"
                  onChange={handleLanguageChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFieldWithLabel
                  label={false}
                  placeholder={t("common.phone_number")}
                  id="phoneNumber"
                  name="phone"
                  type="text"
                  onChange={handleChange}
                  value={phoneNumber}
                  inverted={true}
                  mt="20"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CheckboxWidthLabelNoHtmlFor
                  mt={20}
                  type="checkbox"
                  label={
                    <>
                      <Trans i18nKey="page.onboarding.terms">
                        I accept the
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`${process.env.REACT_APP_TERMS}`}
                        >
                          terms and conditions
                        </a>
                      </Trans>
                    </>
                  }
                  checked={terms}
                  name="terms"
                  id="terms"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row mt={40}>
          <Col right>
            <Button secondary primaryColor disabled={!canSubmit || isLoading}>
              {t("page.onboarding.organization.submit")}
            </Button>
          </Col>
        </Row>
      </form>
    </StyledOnboarding>
  );
};

export default Onboarding;
