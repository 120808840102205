//all positions and also frontpage of application

// React
import React, { useEffect, useContext, useState, useLayoutEffect } from "react";

// Components
import { PositionList } from "../../components";
import { Grid, Row, Col } from "../../../components/Grid";
import { Search } from "../../components";
import { PrimaryButton } from "../../../components/Button";
import { Icon } from "../../../components";
import ViewStats from "../ViewStats/ViewStats";

// Styled components
import { ExpandableSidebar, Flex } from "../../style";
import {
  AllPositionsAccordion,
  AllPositionsAccordionHeader,
} from "./AllPositions.styled";

// Assets
import plus from "../../../components/Icon/plus.svg";

// State management
import { StateOrganizationContext } from "../../context/OrganizationContext";
import { DispatchMessageContext } from "../../../context/MessageContext";
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../context/PositionContext";

// Router
import { useLocation } from "react-router";

// Translation
import { useTranslation } from "react-i18next";
import CreatePosition from "../Position/CreatePosition/CreatePosition";

const AllPositions = ({ isHidden }) => {
  const { organization } = useContext(StateOrganizationContext);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [positionsLoaded, setPositionsLoaded] = useState(false);

  //check for valid success session!
  let query = new URLSearchParams(useLocation().search);

  const messageDispatch = useContext(DispatchMessageContext);
  useEffect(() => {
    if (query.get("session_id")) {
      messageDispatch({
        type: "set_message",
        payload: t("message.thank_you", "Thank you"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Match URL to regex - To keep track of state for mobile navigation
  // between screens
  const viewCountDispatch = useContext(DispatchPositionContext);
  const { count, collapsed } = useContext(StatePositionContext);
  const location = useLocation();

  useEffect(() => {
    const regex = /^\/position\/.+$/;
    if (location.pathname.match(regex)) {
      viewCountDispatch({ type: "view_count", payload: 1 });
    }
    const newReg = /^\/position\/$/;
    if (location.pathname.match(newReg)) {
      viewCountDispatch({ type: "view_count", payload: 0 });
    }
  }, [location]);

  const [isActiveOpen, setIsActiveOpen] = useState(true);
  const [isInactiveOpen, setIsInactiveOpen] = useState(true);
  const [activeContentHeight, setActiveContentHeight] = useState(0);
  const [inactiveContentHeight, setInactiveContentHeight] = useState(0);
  const positionDispatch = useContext(DispatchPositionContext);

  const toggleActive = () => {
    setIsActiveOpen(!isActiveOpen);
  };

  const toggleInactive = () => {
    setIsInactiveOpen(!isInactiveOpen);
  };

  const { positions, createPositionModal } = useContext(StatePositionContext);

  useLayoutEffect(() => {
    if (positionsLoaded) {
      const activeContentElement = document.getElementById("active-content");
      if (activeContentElement) {
        setActiveContentHeight(activeContentElement.scrollHeight);
      }

      const inactiveContentElement =
        document.getElementById("inactive-content");
      if (inactiveContentElement) {
        setInactiveContentHeight(inactiveContentElement.scrollHeight);
      }
    }
  }, [positionsLoaded]);

  const hasActivePosition = positions.some(
    (position) => position.status === "active"
  );

  useEffect(() => {
    // Check if positions is not an empty array
    if (positions.length > 0) {
      setPositionsLoaded(true);
    }
  }, [positions]);

  return (
    <>
      {createPositionModal && <CreatePosition />}
      <ExpandableSidebar count={count} collapsed={collapsed}>
        <Flex>
          <Search
            placeholder={t("common.searchPositions", "Search positions")}
            onSearchAction={(q) => {
              setSearchQuery(q);
            }}
          />
        </Flex>
        <Grid fluid allPositions>
          <Row allPositions>
            <Col order="xl-2" allPositions>
              <AllPositionsAccordionHeader
                onClick={toggleActive}
                first
                hasActivePositions={hasActivePosition}
              >
                {t("page.all_positions.active_positions")}
                <Icon icon="arrow_up" isOpen={!isActiveOpen} />
              </AllPositionsAccordionHeader>
              <AllPositionsAccordion
                isOpen={isActiveOpen}
                contentHeight={activeContentHeight}
                id="active-content"
              >
                <PositionList
                  active
                  includeOptions={true}
                  searchQuery={searchQuery}
                />
              </AllPositionsAccordion>
              <AllPositionsAccordionHeader onClick={toggleInactive}>
                {t("page.all_positions.inactive_positions")}
                <Icon icon="arrow_up" isOpen={!isInactiveOpen} />
              </AllPositionsAccordionHeader>
              <AllPositionsAccordion
                isOpen={isInactiveOpen}
                contentHeight={inactiveContentHeight}
                id="inactive-content"
              >
                <PositionList inactive searchQuery={searchQuery} />
              </AllPositionsAccordion>
              <PrimaryButton
                mtSM="30"
                center
                icon={plus}
                pl="45"
                onClick={() =>
                  positionDispatch({
                    type: "create_position_modal",
                    payload: true,
                  })
                }
              >
                {t("common.addNewPosition", "Add new position")}
              </PrimaryButton>
            </Col>
          </Row>
        </Grid>
      </ExpandableSidebar>
      <ViewStats stats={organization.stats} isHidden={isHidden} />
    </>
  );
};

export default AllPositions;
